import { useKeycloak } from "@react-keycloak/web";
import { useEffect } from "react";

export interface ChangePasswordProps {
  currentUserName?: string;
}
export function ChangePassword({ currentUserName }: ChangePasswordProps) {
  const { keycloak } = useKeycloak();
  useEffect(() => {
    keycloak.login({
      action: "UPDATE_PASSWORD",
      redirectUri: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}?showAging=false`,
      loginHint: `${currentUserName}`,
    });
  }, [keycloak, currentUserName]);
  return <div />;
}

import { useEffect, useState } from "react";
import { Merchant } from "../models/merchant";
import { MerchantService } from "../services/merchant";

export function useMerchants(merchantService: MerchantService) {
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    merchantService
      .getAllMerchants()
      .then(setMerchants)
      .finally(() => {
        setIsLoading(false);
      });
  }, [merchantService]);
  return { merchants, isLoading };
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportDateToJSON = exports.ReportDateFromJSONTyped = exports.ReportDateFromJSON = void 0;
var runtime_1 = require("../runtime");
function ReportDateFromJSON(json) {
    return ReportDateFromJSONTyped(json, false);
}
exports.ReportDateFromJSON = ReportDateFromJSON;
function ReportDateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'startDate': !runtime_1.exists(json, 'startDate') ? undefined : json['startDate'],
        'noDataDate': !runtime_1.exists(json, 'noDataDate') ? undefined : json['noDataDate'],
    };
}
exports.ReportDateFromJSONTyped = ReportDateFromJSONTyped;
function ReportDateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'startDate': value.startDate,
        'noDataDate': value.noDataDate,
    };
}
exports.ReportDateToJSON = ReportDateToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgingChargebackResponseToJSON = exports.AgingChargebackResponseFromJSONTyped = exports.AgingChargebackResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function AgingChargebackResponseFromJSON(json) {
    return AgingChargebackResponseFromJSONTyped(json, false);
}
exports.AgingChargebackResponseFromJSON = AgingChargebackResponseFromJSON;
function AgingChargebackResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'chargebackSummary': (json['chargebackSummary'].map(_1.DisputeSummaryFromJSON)),
        'chargebackAgingDetails': (json['chargebackAgingDetails'].map(_1.ChargebackAgingFromJSON)),
        'resultsTotalSummary': !runtime_1.exists(json, 'resultsTotalSummary') ? undefined : json['resultsTotalSummary'],
        'resultsTotalAging': !runtime_1.exists(json, 'resultsTotalAging') ? undefined : json['resultsTotalAging'],
        'offsetSummary': !runtime_1.exists(json, 'offsetSummary') ? undefined : json['offsetSummary'],
        'offsetAging': !runtime_1.exists(json, 'offsetAging') ? undefined : json['offsetAging'],
    };
}
exports.AgingChargebackResponseFromJSONTyped = AgingChargebackResponseFromJSONTyped;
function AgingChargebackResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'chargebackSummary': (value.chargebackSummary.map(_1.DisputeSummaryToJSON)),
        'chargebackAgingDetails': (value.chargebackAgingDetails.map(_1.ChargebackAgingToJSON)),
        'resultsTotalSummary': value.resultsTotalSummary,
        'resultsTotalAging': value.resultsTotalAging,
        'offsetSummary': value.offsetSummary,
        'offsetAging': value.offsetAging,
    };
}
exports.AgingChargebackResponseToJSON = AgingChargebackResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionOutletToJSON = exports.DivisionOutletFromJSONTyped = exports.DivisionOutletFromJSON = void 0;
var runtime_1 = require("../runtime");
function DivisionOutletFromJSON(json) {
    return DivisionOutletFromJSONTyped(json, false);
}
exports.DivisionOutletFromJSON = DivisionOutletFromJSON;
function DivisionOutletFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'divisionNbr': !runtime_1.exists(json, 'divisionNbr') ? undefined : json['divisionNbr'],
        'divisionName': !runtime_1.exists(json, 'divisionName') ? undefined : json['divisionName'],
        'outlet': !runtime_1.exists(json, 'outlet') ? undefined : json['outlet'],
        'outletName': !runtime_1.exists(json, 'outletName') ? undefined : json['outletName'],
    };
}
exports.DivisionOutletFromJSONTyped = DivisionOutletFromJSONTyped;
function DivisionOutletToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'divisionNbr': value.divisionNbr,
        'divisionName': value.divisionName,
        'outlet': value.outlet,
        'outletName': value.outletName,
    };
}
exports.DivisionOutletToJSON = DivisionOutletToJSON;

import { GetUsersParameters, UserService } from "../../services/user";
import {
  Configuration,
  UserPrivilegeTitle as SdkTitle,
  UserProfileApi,
} from "ebocomsdk";
import { getPreAuthMiddleware } from "./auth_middleware";
import {
  User,
  UserEntity,
  UserPrivilege,
  UserPrivilegeTitle,
} from "../../models/user";
import { Role } from "../../models/roles";
import { PaginatedResult } from "../../models/paginated_result";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";

export class EbocomUserService implements UserService {
  private api: UserProfileApi;
  constructor(private baseUrl: string) {
    const configuration = new Configuration({
      basePath: this.baseUrl,
    });
    this.api = new UserProfileApi(configuration)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
  }
  getUserInfo = async () => {
    const profile = await this.api.getUserProfile();
    return new User(
      profile.merchantID,
      profile.email,
      profile.name,
      profile.username,
      profile.roles as Role[],
      profile.pwExpire,
    );
  };
  getImpersonatableAccounts = async () => {
    const response = await this.api.getAccounts();
    return response;
  };
  getUserPrivileges = async (id: string) => {
    const response = await this.api.getUserPrivileges({
      userid: id,
    });
    const privileges = response.map(
      (p) =>
        new UserPrivilege(
          p.id,
          p.order,
          p.category,
          p.option,
          p.enabled,
          (function () {
            switch (p.title) {
              case SdkTitle.DailyOperations:
                return UserPrivilegeTitle.daily;
              case SdkTitle.ManagementModules:
                return UserPrivilegeTitle.management;
              case SdkTitle.ReferenceInformation:
                return UserPrivilegeTitle.reference;
              default:
                throw Error("Unknown privilege title");
            }
          })()
        )
    );
    return privileges;
  };
  getUsers = async ({
    page = 0,
    pageSize = 10,
  }: GetUsersParameters): Promise<PaginatedResult<UserEntity>> => {
    const response = await this.api.getUserCollection({
      maxResults: pageSize,
      offset: page,
    });
    return {
      offset: response.offset ?? 0,
      total: response.totalResults,
      data:
        response.users?.map?.((u) => {
          return new UserEntity(
            u.id,
            u.name,
            u.phone,
            u.email,
            u.accountLocked
          );
        }) ?? [],
    };
  };
}

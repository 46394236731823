import { useCallback, useEffect, useState } from "react";
import { User } from "../models/user";
import { UserService } from "../services/user";

export function useCurrentUser(userService?: UserService) {
  const [userInfo, setUserInfo] = useState<User | undefined>(undefined);
  const refreshUserInfo = useCallback(() => {
    if (!userService) {
      return;
    }
    userService?.getUserInfo().then((userInfo) => {
      setUserInfo(userInfo);
    });
  }, [userService]);
  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);
  return { currentUser: userInfo, refreshUser: refreshUserInfo };
}

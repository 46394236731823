import React from "react";
import { Caret } from "../../../components/caret";
import { Clickable } from "../../../components/clickable";
import { SortTooltip } from "../../../components/sort_tooltip";
import { Tooltip } from "../../../components/tooltip";
import { TransactionStatusTooltip } from "../../../components/transaction_status_tooltip";
import { SortOrder } from "../../../models/sort_order";
import { Transaction, TransactionSortField } from "../../../models/transaction";
import { DateService } from "../../../services/date_service";
import "./transaction_search_results.scss";

export interface TransactionSearchResultProps {
  onRequestDepositActivity(transaction: Transaction): void;
  transactions: Transaction[];
  children?: React.ReactNode;
  sortColumn?: TransactionSortField;
  sortDirection?: SortOrder;
  requestSort?(
    sortColumn: TransactionSortField,
    sortDirection: SortOrder
  ): void;
}
export function TransactionSearchResult({
  transactions = [],
  children,
  sortColumn,
  requestSort = () => {},
  onRequestDepositActivity,
  sortDirection = SortOrder.ASC,
}: TransactionSearchResultProps) {
  const [cardVisibility, setCardVisibility] = React.useState<
    string | undefined
  >();
  const getTransactionId = (transaction: Transaction): string => {
    return transaction.id;
  };
  const getSortDirection = (currentField: TransactionSortField) => {
    if (sortColumn === currentField) {
      return sortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
    }
    return SortOrder.ASC;
  };
  type Header = {
    label: string | React.ReactNode;
    sortColumn?: TransactionSortField;
    id: string;
    width: number | string;
  };
  const headers: Header[] = [
    {
      label: "Merchant Number",
      sortColumn: TransactionSortField.merchantNumber,
      id: "merchant-number",
      width: 69.57,
    },
    {
      label: "Merchant Name",
      sortColumn: TransactionSortField.merchantName,
      id: "merchant-name",
      width: 117.7,
    },
    {
      label: "Outlet Number",
      sortColumn: TransactionSortField.outletNumber,
      id: "outlet-number",
      width: 63,
    },
    {
      label: "Outlet Name",
      sortColumn: TransactionSortField.outletName,
      id: "outlet-name",
      width: 137.8,
    },
    {
      label: (
        <span>
          Deposit/
          <br />
          Transmit Date
        </span>
      ),
      sortColumn: TransactionSortField.depositDate,
      id: "deposit-date",
      width: 60.3,
    },
    {
      label: "Card Type",
      sortColumn: TransactionSortField.cardType,
      id: "card-type",
      width: 34.8,
    },
    {
      label: "Card Number",
      sortColumn: TransactionSortField.cardNumber,
      id: "card-number",
      width: 88.7,
    },
    {
      label: "POS Entry",
      sortColumn: TransactionSortField.posEntry,
      id: "pos-entry",
      width: 41.9,
    },
    {
      label: "Auth Code",
      sortColumn: TransactionSortField.authCode,
      id: "auth-code",
      width: 37.5,
    },
    {
      label: "RFC/CB Code",
      sortColumn: TransactionSortField.rfcCode,
      id: "rfc-code",
      width: 45.08,
    },
    {
      label: "Tran Date",
      sortColumn: TransactionSortField.tranDate,
      id: "tran-date",
      width: 63,
    },
    {
      label: "Tran Type",
      sortColumn: TransactionSortField.tranType,
      id: "tran-type",
      width: 63,
    },
    {
      label: "Tran Amount",
      sortColumn: TransactionSortField.tranAmount,
      id: "tran-amount",
      width: 54.6,
    },
    {
      label: "Tran Status",
      sortColumn: TransactionSortField.tranStatus,
      id: "tran-status",
      width: 73,
    },
  ];
  const toggleCardVisibility = (transaction: Transaction) => {
    setCardVisibility(getTransactionId(transaction));
  };
  const transactionDateText = (transaction: Transaction) => {
    if (
      transaction.lifeCycleIndGroup !== 2 &&
      (transaction.transactionStatus === "TRANSMITTED" ||
        transaction.transactionStatus === "RECEIVED")
    ) {
      return "Link to Transmitted Activity";
    } else {
      return "Link to Deposit Activity";
    }
  };
  const transactionDate = (tx: Transaction) => {
    const lifeCycle = tx.lifeCycleIndGroup;
    const tranStatus = tx.transactionStatus;
    if (
      lifeCycle !== 2 &&
      (tranStatus === "TRANSMITTED" || tranStatus === "RECEIVED")
    ) {
      return tx.processDate;
    } else {
      return tx.billedDate;
    }
  };

  return (
    <>
      {children}
      <div className="transaction-search-results">
        <table className="table is-bordered ">
          <colgroup>
            {headers.map((h) => (
              <col width={h.width} />
            ))}
          </colgroup>
          <tbody>

            <tr
              className="is-uppercase has-text-centered"
              style={{ height: 41 }}
            >
              {headers.map((header) => {
                const isSelected = header.sortColumn === sortColumn;
                return (
                  <th
                    key={JSON.stringify(header.id)}
                    className="has-text-white"
                    data-testid={`${header.id}-header`}
                    style={{ width: header.width }}
                    onClick={
                      header.sortColumn !== undefined
                        ? () =>
                            requestSort(
                              header.sortColumn!,
                              getSortDirection(header.sortColumn!)
                            )
                        : () => {}
                    }
                  >
                    <SortTooltip>
                      <div
                        style={{
                          color: isSelected ? "yellow" : "white",
                        }}
                      >
                        <Clickable>{header.label}</Clickable>
                        {sortColumn === header.sortColumn && (
                          <Caret
                            data-testid="caret"
                            direction={sortDirection}
                          />
                        )}
                      </div>
                    </SortTooltip>
                  </th>
                );
              })}
            </tr>

            {transactions.map((transaction) => {
              const txDate = transactionDate(transaction);
              const txDateText = transactionDateText(transaction);
              return (
                <tr key={getTransactionId(transaction)}>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    {transaction.merchantNumber}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "left" }}>
                    {transaction.merchantName}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    {transaction.outletNumber}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "left" }}>
                    {transaction.outletName}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    <button
                      className="button plain-text"
                      onClick={() => onRequestDepositActivity(transaction)}
                    >
                      <Tooltip.Container>
                        <span className="purple is-underlined">{!!txDate ? DateService.formatDate(txDate, "-") : null}</span>
                        <Tooltip>
                          <span>{txDateText}</span>
                        </Tooltip>
                      </Tooltip.Container>
                    </button>
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    {transaction.cardType}
                  </td>
                  <td className="is-vcentered has-text-centered">
                    {cardVisibility === getTransactionId(transaction) ? (
                      <span>{transaction.cardNumber}</span>
                    ) : (
                      <button
                        onClick={() => toggleCardVisibility(transaction)}
                        className="button is-small plain-text"
                      >
                        <Tooltip.Container>
                        <span className="purple is-underlined">{transaction.maskedCardNumber}</span>
                          <Tooltip>Display Full Card Number</Tooltip>
                        </Tooltip.Container>
                      </button>
                    )}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    <Tooltip.Container>
                      <span className="purple">{transaction.posEntry}</span>
                      <Tooltip>{transaction.posEntryDesc}</Tooltip>
                    </Tooltip.Container>
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    {transaction.authCode}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    <Tooltip.Container>
                      {transaction.rfcCode}
                      <Tooltip>{transaction.disputeDescription}</Tooltip>
                    </Tooltip.Container>
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    {!!transaction.transactionDate
                      ? DateService.formatDate(transaction.transactionDate, "-")
                      : null}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    {(transaction.transactionType === "SALE" ||
                      transaction.transactionType === "REFUND") &&
                    transaction.expDate ? (
                      <Tooltip.Container>
                        <span className="purple">{transaction.transactionType
                          ?.toString()
                          ?.replace(/_/g, " ")}</span>
                        <Tooltip style={{ whiteSpace: "nowrap" }}>
                          Exp Date:{" "}
                          {transaction.expDate?.slice(0, 2) +
                            "/" +
                            transaction.expDate?.slice(2)}
                        </Tooltip>
                      </Tooltip.Container>
                    ) : (
                      transaction.transactionType
                        ?.toString()
                        ?.replace(/_/g, " ")
                    )}
                  </td>
                  <td
                    className="is-vcentered"
                    style={{ textAlign: "end" }}
                    data-testid="transaction-amount"
                  >
                    {transaction.transactionAmount}
                  </td>
                  <td className="is-vcentered" style={{ textAlign: "center" }}>
                    <Tooltip.Container>
                      <span className="purple">{transaction.transactionStatus}</span>
                      <Tooltip style={{ padding: 0 }}>
                        {transaction.transactionStatus &&
                          transaction.transactionType && (
                            <TransactionStatusTooltip
                              status={transaction.transactionStatus}
                              type={transaction.transactionType}
                            />
                          )}
                      </Tooltip>
                    </Tooltip.Container>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UtilityToJSON = exports.UtilityFromJSONTyped = exports.UtilityFromJSON = void 0;
var runtime_1 = require("../runtime");
function UtilityFromJSON(json) {
    return UtilityFromJSONTyped(json, false);
}
exports.UtilityFromJSON = UtilityFromJSON;
function UtilityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNbr': !runtime_1.exists(json, 'merchantNbr') ? undefined : json['merchantNbr'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'assetOwner': !runtime_1.exists(json, 'assetOwner') ? undefined : json['assetOwner'],
        'businessLegalName': !runtime_1.exists(json, 'businessLegalName') ? undefined : json['businessLegalName'],
        'monthName': !runtime_1.exists(json, 'monthName') ? undefined : json['monthName'],
        'yearNumber': !runtime_1.exists(json, 'yearNumber') ? undefined : json['yearNumber'],
        'divisionNbr': !runtime_1.exists(json, 'divisionNbr') ? undefined : json['divisionNbr'],
        'divisionName': !runtime_1.exists(json, 'divisionName') ? undefined : json['divisionName'],
        'outlet': !runtime_1.exists(json, 'outlet') ? undefined : json['outlet'],
        'outletName': !runtime_1.exists(json, 'outletName') ? undefined : json['outletName'],
        'currentRowNumber': !runtime_1.exists(json, 'currentRowNumber') ? undefined : json['currentRowNumber'],
        'hasPrevious': !runtime_1.exists(json, 'hasPrevious') ? undefined : json['hasPrevious'],
        'hasNext': !runtime_1.exists(json, 'hasNext') ? undefined : json['hasNext'],
        'rowCnt': !runtime_1.exists(json, 'rowCnt') ? undefined : json['rowCnt'],
        'rowCntInfo': !runtime_1.exists(json, 'rowCntInfo') ? undefined : json['rowCntInfo'],
        'maxRowsSelected': !runtime_1.exists(json, 'maxRowsSelected') ? undefined : json['maxRowsSelected'],
        'paraName': !runtime_1.exists(json, 'paraName') ? undefined : json['paraName'],
        'refineQueryMsg': !runtime_1.exists(json, 'refineQueryMsg') ? undefined : json['refineQueryMsg'],
        'reportPeriod': !runtime_1.exists(json, 'reportPeriod') ? undefined : json['reportPeriod'],
        'reportCategoryId': !runtime_1.exists(json, 'reportCategoryId') ? undefined : json['reportCategoryId'],
        'reportCategoryDescription': !runtime_1.exists(json, 'reportCategoryDescription') ? undefined : json['reportCategoryDescription'],
        'reportCategoryValue': !runtime_1.exists(json, 'reportCategoryValue') ? undefined : json['reportCategoryValue'],
        'reportCategoryValueId': !runtime_1.exists(json, 'reportCategoryValueId') ? undefined : json['reportCategoryValueId'],
        'reportGroups': !runtime_1.exists(json, 'reportGroups') ? undefined : json['reportGroups'],
    };
}
exports.UtilityFromJSONTyped = UtilityFromJSONTyped;
function UtilityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNbr': value.merchantNbr,
        'merchantName': value.merchantName,
        'region': value.region,
        'assetOwner': value.assetOwner,
        'businessLegalName': value.businessLegalName,
        'monthName': value.monthName,
        'yearNumber': value.yearNumber,
        'divisionNbr': value.divisionNbr,
        'divisionName': value.divisionName,
        'outlet': value.outlet,
        'outletName': value.outletName,
        'currentRowNumber': value.currentRowNumber,
        'hasPrevious': value.hasPrevious,
        'hasNext': value.hasNext,
        'rowCnt': value.rowCnt,
        'rowCntInfo': value.rowCntInfo,
        'maxRowsSelected': value.maxRowsSelected,
        'paraName': value.paraName,
        'refineQueryMsg': value.refineQueryMsg,
        'reportPeriod': value.reportPeriod,
        'reportCategoryId': value.reportCategoryId,
        'reportCategoryDescription': value.reportCategoryDescription,
        'reportCategoryValue': value.reportCategoryValue,
        'reportCategoryValueId': value.reportCategoryValueId,
        'reportGroups': value.reportGroups,
    };
}
exports.UtilityToJSON = UtilityToJSON;

import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { EbocomSelect } from "../../components/select";
import { create999Merchant, Merchant } from "../../models/merchant";
import "./standard_report_search.scss";

export interface StandardReportSearchPanelProps {
  merchants: Merchant[];
  defaultMerchantId?: string;
  onSelectMerchant(merchantNumber: string): void;
}
export const StandardReportSearchPanel = (
  props: StandardReportSearchPanelProps
) => {
  const { control, setValue } = useForm({
    reValidateMode: "onChange",
  });
  const MERCHANT_NAME_INPUT = "merchant-name";
  const MERCHANT_NUMBER_INPUT = "merchant-number";
  const merchantsSortedByName = props.merchants.filter((merchant) => merchant.number !== '9999999999')
    .sort((a, b) => a.name.localeCompare(b.name));
  const names = [create999Merchant(), ...merchantsSortedByName].map((m) => ({
    value: m.number,
    label: m.name,
  }));
  const numbers = props.merchants.map((merchant) => ({
    label: merchant.number,
    value: merchant.number,
  }));
  useEffect(() => {
    if (props.defaultMerchantId) {
      setValue(
        MERCHANT_NUMBER_INPUT,
        numbers.find((n) => n.value === props.defaultMerchantId)
      );
      setValue(
        MERCHANT_NAME_INPUT,
        names.find((n) => n.value === props.defaultMerchantId)
      );
    }
  }, [props.defaultMerchantId, names, numbers, setValue]);
  return (
    <table
      className="table is-bordered has-text-centered standard-report-panel"
      style={{ width: 293 }}
    >
      <tbody>
        <tr>
          <th className="has-text-white">Merchant Number</th>
        </tr>
        <tr>
          <td>
            <div className="is-flex is-fullwidth">
              <Controller
                name={MERCHANT_NUMBER_INPUT}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <EbocomSelect
                    data-testid="merchant-number-input"
                    className="input"
                    placeholder="Select Merchant Number"
                    options={numbers}
                    onChange={(e) => {
                      onChange();

                      setValue(
                        MERCHANT_NAME_INPUT,
                        names.find((n) => n.value === e.value)
                      );
                      setValue(MERCHANT_NUMBER_INPUT, e);
                      props.onSelectMerchant(e.value);
                    }}
                    {...rest}
                  />
                )}
              />
            </div>
          </td>
        </tr>
        <tr>
          <th className="has-text-white">Merchant Name</th>
        </tr>
        <tr>
          <td>
            <Controller
              name={MERCHANT_NAME_INPUT}
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <EbocomSelect
                  data-testid="merchant-name-input"
                  className="input"
                  options={names}
                  placeholder="Select Merchant Name"
                  onChange={(e) => {
                    onChange();

                    setValue(MERCHANT_NAME_INPUT, e);
                    setValue(
                      MERCHANT_NUMBER_INPUT,
                      numbers.find((n) => n.value === e.value)
                    );
                    props.onSelectMerchant(e.value);
                  }}
                  {...rest}
                />
              )}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

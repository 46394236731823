import { useCallback, useEffect, useState } from "react";
import { Merchant } from "../models/merchant";
import {
  MerchantDetailsSortColumn,
  MerchantService,
} from "../services/merchant";
import { usePagination } from "./use_pagination";
import { useSorting } from "./use_sorting";

export function useMerchantDetails(
  merchantService: MerchantService,
  merchantNumber?: string,
  pageSize: number = 500
) {
  const [isLoading, setIsLoading] = useState(false);
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const { sorting, handleSort } =
    useSorting<MerchantDetailsSortColumn>("merchantName");
  const [totalResults, setTotalResults] = useState(0);
  const pagination = usePagination(pageSize, totalResults);
  const reset = useCallback(() => {
    pagination.firstPage();
    handleSort("merchantName");
    setMerchants([]);
    setTotalResults(0);
  }, [pagination, handleSort]);
  const exportToExcel = useCallback(() => {
    if (!merchantNumber) return;
    return merchantService.exportMerchantDetails({
      merchantNumber,
      sortColumn: sorting?.field!,
      sortDirection: sorting?.direction!,
      pageSize: totalResults,
    });
  }, [
    merchantNumber,
    sorting?.direction,
    sorting?.field,
    merchantService,
    totalResults,
  ]);
  useEffect(() => {
    if (merchantNumber) {
      setIsLoading(true);
      merchantService
        .getMerchantDetails({
          merchantNumber,
          sortColumn: sorting?.field!,
          sortDirection: sorting?.direction!,
          pageSize: pagination.pageSize,
          offset: pagination.page,
        })
        .then((result) => {
          setMerchants(result.data);
          setTotalResults(result.total);
        })
        .finally(() => setIsLoading(false));
    }
  }, [
    merchantService,
    merchantNumber,
    sorting,
    pagination.pageSize,
    pagination.page,
  ]);
  return {
    merchants,
    isLoading,
    sorting,
    handleSort,
    pagination,
    reset,
    exportToExcel,
  };
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportCategoryToJSON = exports.ReportCategoryFromJSONTyped = exports.ReportCategoryFromJSON = void 0;
var runtime_1 = require("../runtime");
function ReportCategoryFromJSON(json) {
    return ReportCategoryFromJSONTyped(json, false);
}
exports.ReportCategoryFromJSON = ReportCategoryFromJSON;
function ReportCategoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'categoryId': !runtime_1.exists(json, 'categoryId') ? undefined : json['categoryId'],
        'categoryDesc': !runtime_1.exists(json, 'categoryDesc') ? undefined : json['categoryDesc'],
    };
}
exports.ReportCategoryFromJSONTyped = ReportCategoryFromJSONTyped;
function ReportCategoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'categoryId': value.categoryId,
        'categoryDesc': value.categoryDesc,
    };
}
exports.ReportCategoryToJSON = ReportCategoryToJSON;

import { DisputeSummary } from "../models/dispute";
import faker from "faker";
import {
  ChargebackClosedFilter,
  ChargebackFilter,
  DisputeService,
} from "../services/disputes/dispute_service";
import { ChargebackAging } from "../models/chargeback_aging";
import { ChargebackClosed } from "../models/chargeback_closed";

export interface MockDisputeOptions {
  minDays?: number;
}
export function mockDispute(options: MockDisputeOptions = {}): DisputeSummary {
  const dispute = new DisputeSummary(faker.datatype.uuid());
  dispute.merchantName = faker.company.companyName();
  dispute.merchantNumber = faker.random.alphaNumeric(10);
  dispute.cbkAmount = Math.round(Math.random() * 1000).toString();
  dispute.cbkCount = Math.round(Math.random() * 1000).toString();
  dispute.rfcAmount = Math.round(Math.random() * 1000).toString();
  dispute.rfcCount = Math.round(Math.random() * 1000).toString();
  dispute.daysRemainingThreshold = 0;
  dispute.minDaysRemaining =
    options?.minDays ?? Math.round(Math.random() * 10 - 5);
  return dispute;
}

export interface JSONDispute {
  merchNbr: string;
  merchName: string;
  minDaysRemaining: number;
  daysRemainingThreshold: number;
  cbkCount: string;
  cbkAmount: string;
  rfcCount: string;
  rfcAmount: string;
}
const mockClosedChargeback = (): ChargebackClosed => {
  const c = new ChargebackClosed();
  c.acctNumber = faker.random.alphaNumeric(10);
  c.assetOwner = faker.random.alphaNumeric(10);
  c.businessLegalName = faker.random.alphaNumeric(10);
  c.cardType = "VS";
  c.closedDescription = faker.lorem.words(5);
  c.dateClosed = faker.date.past().toISOString();
  c.disputeDescription = faker.lorem.words(5);
  c.maskedAcctNumber = faker.random.alphaNumeric(10);
  c.merchantNbr = faker.random.alphaNumeric(10);
  c.merchantName = faker.random.alphaNumeric(10);
  c.region = faker.random.alphaNumeric(10);
  c.tranAmount = Math.round(Math.random() * 1000).toString();
  c.tranStatusDescription = faker.lorem.words(5);
  c.tranType = faker.lorem.words(1);
  return c;
};

const mockAgingChargeback = () => {
  const a = new ChargebackAging();
  a.acctNumber = faker.random.alphaNumeric(10);
  a.assetOwner = faker.random.alphaNumeric(10);
  a.beginDate = faker.date.past().toISOString();
  a.cardType = "VS";
  a.dateClosed = faker.date.past().toISOString();
  a.dateResolved = faker.date.past().toISOString();
  a.daysRemaining = Math.round(Math.random() * 10).toString();
  a.loadDate = faker.date.past().toISOString();
  a.divisionName = faker.random.alphaNumeric(10);
  a.dueDate = faker.date.past().toISOString();
  a.merchantNbr = faker.random.alphaNumeric(10);
  a.merchantName = faker.random.alphaNumeric(10);
  a.outletName = faker.random.alphaNumeric(10);
  a.origTranDate = faker.date.past().toISOString();
  a.exceptionAmount = Math.round(Math.random() * 1000).toString();
  a.exceptionReason = faker.lorem.words(5);
  return a;
};

export function mockDisputeService(): DisputeService {
  return {
    getDisputes: async (): Promise<DisputeSummary[]> => {
      return new Promise((resolve) =>
        setTimeout(
          () => resolve(Array.from(new Array(10)).map(mockDispute)),
          1000
        )
      );
    },

    getAgingChargebacks: async (filter: ChargebackFilter) => {
      return {
        isEmpty: false,
        chargebackAgingDetails: {
          data: Array.from(new Array(100)).map(mockAgingChargeback),
          offset: 0,
          total: 40,
        },
        chargebackSummary: {
          data: Array.from(new Array(10)).map(mockDispute),
          offset: 0,
          total: 5,
        },
      };
    },
    getClosedChargebacks: async (filter: ChargebackClosedFilter) => {
      return {
        isEmpty: false,
        chargebackClosed: {
          data: Array.from(new Array(10)).map(mockClosedChargeback),
          offset: 0,
          total: 500,
        },
        chargebackRFC: {
          data: Array.from(new Array(10)).map(mockClosedChargeback),
          offset: 0,
          total: 500,
        },
      };
    },
    exportAgingChargebacks: async (filter: ChargebackFilter) => {
      return [new Blob(), ""];
    },
    exportAgingChargebacksSummary: async (filter: ChargebackFilter) => {
      return [new Blob(), ""];
    },
    exportClosedChargebacks: async (filter: ChargebackClosedFilter) => {
      return [new Blob(), ""];
    },
    exportClosedChargebacksRfc: async (filter: ChargebackClosedFilter) => {
      return [new Blob(), ""];
    },
  };
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentCategoryToJSON = exports.DocumentCategoryFromJSONTyped = exports.DocumentCategoryFromJSON = exports.DocumentCategory = void 0;
/**
 * Category for the Reference Information Documents
 * @export
 * @enum {string}
 */
var DocumentCategory;
(function (DocumentCategory) {
    DocumentCategory["Bestpractices"] = "BESTPRACTICES";
    DocumentCategory["Clientnotices"] = "CLIENTNOTICES";
    DocumentCategory["Datasecurity"] = "DATASECURITY";
    DocumentCategory["Newsletter"] = "NEWSLETTER";
    DocumentCategory["RefdocsOther"] = "REFDOCS_OTHER";
    DocumentCategory["RefdocsPost"] = "REFDOCS_POST";
})(DocumentCategory = exports.DocumentCategory || (exports.DocumentCategory = {}));
function DocumentCategoryFromJSON(json) {
    return DocumentCategoryFromJSONTyped(json, false);
}
exports.DocumentCategoryFromJSON = DocumentCategoryFromJSON;
function DocumentCategoryFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.DocumentCategoryFromJSONTyped = DocumentCategoryFromJSONTyped;
function DocumentCategoryToJSON(value) {
    return value;
}
exports.DocumentCategoryToJSON = DocumentCategoryToJSON;

import { ChargebackAging } from "../../models/chargeback_aging";
import { ChargebackClosed } from "../../models/chargeback_closed";
import { DisputeSummary } from "../../models/dispute";
import { PaginatedResult } from "../../models/paginated_result";
import { SortOrder } from "../../models/sort_order";

export interface AgingChargebackResponse {
  chargebackSummary?: PaginatedResult<DisputeSummary>;
  chargebackAgingDetails?: PaginatedResult<Partial<ChargebackAging>>;
  isEmpty: boolean;
}
export enum AgingChargebackSortColumn {
  daysRemaining,
  dueDate,
  merchantNumber,
  dateReceived,
  merchantName,
  outletName,
  cardNumber,
  tranDate,
  disputeAmount,
  disputeReason,
}
export enum ClosedChargebackSortColumn {
  merchantNumber,
  merchantName,
  cardNumber,
  tranDate,
  cbrfTranDate,
  disputeAmount,
  disputeReason,
  closedDate,
  closedReason,
}
export interface ChargebackFilter {
  merchantNumber: string;
  startDate?: Date;
  endDate?: Date;
}
export interface Pagination {}
export interface ChargebackClosedFilter {
  pageOffsetChargebacks?: number;
  pageSize: number;
  merchantNbr: string;
  startDate: Date;
  jcb: boolean;
  endDate: Date;
  amex: boolean;
  discover: boolean;
  mastercard: boolean;
  visa: boolean;
}
export interface ChargebackSorting {
  sortColumn?: ClosedChargebackSortColumn;
  sortOrder?: SortOrder;
}
export interface AgingSorting {
  sortColumn?: AgingChargebackSortColumn;
  sortOrder?: SortOrder;
}
export interface AgingFilter extends ChargebackFilter {
  pageOffsetSummary: number;
  pageOffsetAging: number;
  daysRemainingThreshold: number;
  pageSize: number;
}
export interface ClosedResponse {
  chargebackClosed?: PaginatedResult<ChargebackClosed>;
  chargebackRFC?: PaginatedResult<ChargebackClosed>;
  isEmpty: boolean;
}
export interface DisputeService {
  getDisputes(): Promise<DisputeSummary[]>;
  getAgingChargebacks(
    parameters: AgingFilter,
    sortOrder?: AgingSorting
  ): Promise<AgingChargebackResponse>;
  exportAgingChargebacks(
    parameters: ChargebackFilter
  ): Promise<[Blob, string?]>;
  exportAgingChargebacksSummary(
    parameters: ChargebackFilter
  ): Promise<[Blob, string?]>;
  exportClosedChargebacks(
    parameters: Omit<
      ChargebackClosedFilter,
      "pageSize" | "pageOffsetChargebacks"
    >
  ): Promise<[Blob, string?]>;
  exportClosedChargebacksRfc(
    parameters: Omit<
      ChargebackClosedFilter,
      "pageSize" | "pageOffsetChargebacks"
    >
  ): Promise<[Blob, string?]>;
  getClosedChargebacks(
    parameters: ChargebackClosedFilter,
    sorting: ChargebackSorting
  ): Promise<ClosedResponse>;
}

import useUserStore from "./store/user";

const getFormattedTransactionAmount =
  (transactionAmount: string | undefined, transactionType: string | undefined) => {
    if (transactionAmount === undefined) return undefined;
    if (transactionType === "CREDIT") return `-${transactionAmount.replaceAll(/[()]/g, '')}`;
    return transactionAmount;
  }

const clearLocalStorage = () => {
  window.localStorage.removeItem("Loggedin");
  console.log('clearing passExpirationRedirectTime');
  useUserStore.getState().setPassExpirationRedirectTime(null);
  window.localStorage.removeItem("username");
  window.localStorage.removeItem('tokenExpirationTime');
  window.localStorage.removeItem('logoutUrl');
}

export { getFormattedTransactionAmount, clearLocalStorage };
export class DisputeSummary {
  constructor(id: string) {
    this.id = id;
  }
  id: string;
  merchantNumber?: string = "";
  merchantName?: string = "";
  minDaysRemaining? = 999;
  daysRemainingThreshold? = 0;
  cbkCount? = "";
  cbkAmount?: string = "";
  rfcCount? = "";
  rfcAmount? = "";
}

import React from "react";

export const TransactionStatusTooltipLine = (data: TransactionStatusDataProps) => (
  <>
  <div className="is-flex is-flex-direction-row is-align-items-start tooltip-line">
    <div className={`is-flex left ${data.current ? "is-bold" : null}`}>
      {data.lhs}
    </div>
    <div className={`is-flex right ${data.current ? "is-bold" : null}`}>
      {data.rhs}
    </div>
  </div>
    {
      data.lhs === "OPEN" || data.lhs === "PENDING" ? <div className="is-flex is-align-items-start">Or one of the following:</div> : null
    }
  </>
)

export interface TransactionStatusDataProps {
  code: string,
  lhs: string,
  rhs: string,
  current: boolean
}

export interface TransactionStatusCodeProps {
  type: string
  status: string,
}

export const TransactionStatusTooltip = ({type, status}: TransactionStatusCodeProps) => {
  return (
    <div className="transaction-status-tooltip">
      <div className="header">{type} - Possible Tran Status</div>
      {
        TransactionStatusCodeSpecifications({type, status}).map(d => {
          return <TransactionStatusTooltipLine code={d.code} lhs={d.lhs} rhs={d.rhs} current={d.current} />
        })
      }
    </div>
  )
}

/**
 * Parses a string "type" and "status" to return a data structure representing:
 * 
 * - the 'transaction code,' a number from 0 to 20
 * - the 'left hand side' value from the displayed tooltip
 * - the 'right hand side' value from the displayed tooltip
 * - a 'current' value to determine if this is the current status or just an example
 * 
 * @returns an array containing an ordered set of data representing the lines to display in the tooltip
 */
export const TransactionStatusCodeSpecifications = ({type, status}: TransactionStatusCodeProps): TransactionStatusDataProps[] => {
  // standardize type and status, just in case
  type = type.toUpperCase().trim();
  status = status.toUpperCase().trim();

  switch (type) {
    case "SALE AUTH":
    case "SALE VAUTH":
    case "REFUND AUTH":
    case "REFUND VAUTH":
    case "RVRSL AUTH": // uses switch case fall-through to catch multiple options
      return [
        {code: "0", lhs: "APPROVED", rhs: "Authorization Code Received", current: status==="APPROVED"},
        {code: "1", lhs: "DECLINED", rhs: "No Authorization Code Received", current: status==="DECLINED"},
      ]

    case "SALE":
    case "REFUND":
      return [
          {code: "2", lhs: "RECEIVED", rhs: "Transaction Received for Processing", current: status==="RECEIVED"},
          {code: "3", lhs: "TRANSMITTED", rhs: "Transaction Transmitted to Processor", current: status==="TRANSMITTED"},
          {code: "4", lhs: "REJECTED", rhs: "Transaction Rejected as Invalid", current: status==="REJECTED"},
          {code: "5", lhs: "PAID", rhs: "Transaction Paid to Merchant", current: status==="PAID"},
      ]  

    case "RFC":
      return [
        {code: "6", lhs: "OPEN", rhs: "Waiting for Response from Merchant", current: status==="OPEN"},
        {code: "6-1", lhs: "RECEIVED", rhs: "Merchant Response Received", current: status==="RECEIVED"},
        {code: "7", lhs: "FULFILLED", rhs: "Responded to Issuer", current: status==="FULFILLED"},
        {code: "8", lhs: "CLOSED", rhs: "Dispute Cycle is Complete", current: status==="NO RESPONSE"},
      ]

    case "1ST CB":
      return [
        {code: "9", lhs: "OPEN", rhs: "Waiting for Response from Merchant", current: status==="OPEN"},
        {code: "9-1", lhs: "RECEIVED", rhs: "Merchant Response Received", current: status==="RECEIVED"},
        {code: "10", lhs: "FULFILLED", rhs: "Responded to Issuer", current: status==="FULFILLED"},
        {code: "11", lhs: "DISPUTED", rhs: "Current Debit/Credit is in Dispute", current: status==="CLOSED"},
        {code: "12", lhs: "CLOSED", rhs: "Dispute Cycle is Complete", current: status==="DISPUTED"},
      ]

    case "REV":
      return [
        {code: "13", lhs: "PENDING", rhs: "Waiting for Final Resolution", current: status==="PENDING"},
        {code: "14", lhs: "DISPUTED", rhs: "Current Debit/Credit is in Dispute", current: status==="DISPUTED"},
        {code: "15", lhs: "CLOSED", rhs: "Dispute Cycle is Complete", current: status==="CLOSED"},
      ]

    case "2ND CB":
      return [
        {code: "16", lhs: "PENDING", rhs: "Waiting for Final Resolution", current: status==="PENDING"},
        {code: "17", lhs: "DISPUTED", rhs: "Current Debit/Credit is in Dispute", current: status==="DISPUTED"},
        {code: "18", lhs: "CLOSED", rhs: "Dispute Cycle is Complete", current: status==="CLOSED"},
      ]

    case "COMP":
      return [
        {code: "19", lhs: "DISPUTED", rhs: "Current Debit/Credit is in Dispute", current: status==="DISPUTED"},
        {code: "20", lhs: "CLOSED", rhs: "Dispute Cycle is Complete", current: status==="CLOSED"},
      ]
    }

    return []
}


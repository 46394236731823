"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CardTypesToJSON = exports.CardTypesFromJSONTyped = exports.CardTypesFromJSON = exports.CardTypes = void 0;
/**
 * Type of credit cards
 * @export
 * @enum {string}
 */
var CardTypes;
(function (CardTypes) {
    CardTypes["Ax"] = "AX";
    CardTypes["Dr"] = "DR";
    CardTypes["Jcb"] = "JCB";
    CardTypes["Mc"] = "MC";
    CardTypes["Vs"] = "VS";
})(CardTypes = exports.CardTypes || (exports.CardTypes = {}));
function CardTypesFromJSON(json) {
    return CardTypesFromJSONTyped(json, false);
}
exports.CardTypesFromJSON = CardTypesFromJSON;
function CardTypesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.CardTypesFromJSONTyped = CardTypesFromJSONTyped;
function CardTypesToJSON(value) {
    return value;
}
exports.CardTypesToJSON = CardTypesToJSON;

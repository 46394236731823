export const downloadBase64EncodedFile = (
  data: string,
  mimeType: string,
  fileName: string
) => {
  const location = `data:${mimeType};charset=utf-8;base64,${data}`;
  const link = document.createElement("a");
  link.download = fileName;
  link.href = location;
  link.click();
};

export const downloadExcelFile = (data: Blob, fileName?: string) => {
  const location = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.download = fileName || "report.xls";
  link.href = location;
  link.click();
  // const fileNameWithExtension = `${fileName}.xlsx` ?? "data.xlsx";
  // downloadBase64EncodedFile(data, mimeType, fileNameWithExtension);
};

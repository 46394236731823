import React, { useEffect } from "react";
import { ReportFileDownloader } from "../../components/download_handler";
import { EbocomMerchantSearchPanel } from "../../components/ebocom_merchant_search";
import { useDefaultMerchant } from "../../hooks/use_default_merchant";
import { useDebounce } from "../../hooks/use_debounce";
import { useMerchants } from "../../hooks/use_merchant";
import { useMerchantReferenceSheet } from "../../hooks/use_merchant_reference_sheet";
import { SearchPage } from "../../layout/search_page";
import { addSuperMerchantNumber } from "../../models/merchant";
import { MerchantService } from "../../services/merchant";
import { ReportService } from "../../services/report";
import { PageTitle } from "../../components/page_title";

export interface MerchantReferenceSheetContainerProps {
  merchantService: MerchantService;
  reportService: ReportService;
}
export function MerchantReferenceSheetContainer({
  merchantService,
  reportService,
}: MerchantReferenceSheetContainerProps) {
  const [merchantNumber, setMerchantNumber] = React.useState("");
  const { reportUrl } = useMerchantReferenceSheet(
    reportService,
    merchantNumber
  );
  const debounce = useDebounce(1000);
  const { merchants } = useMerchants(merchantService);
  const { selectedMerchant, updateSelectedMerchant } = useDefaultMerchant();
  useEffect(() => {
    setMerchantNumber(selectedMerchant ?? "");
  }, [selectedMerchant]);
  return (
    <div className="merchantReference-wrapper">
      <div className="title-bar">
        <PageTitle title="Merchant Reference Sheet" />
      </div>
      <SearchPage
        title={"Merchant Reference Sheet"}
        searchPanel={
          <EbocomMerchantSearchPanel
            defaultMerchantId={selectedMerchant}
            merchants={addSuperMerchantNumber(merchants)}
            onSelectMerchant={(merchant) => {
              debounce(() => setMerchantNumber(merchant.number));
              updateSelectedMerchant(merchant.number);
            }}
          />
        }
        searchResults={
          reportUrl && (
            <ReportFileDownloader.PDF
              url={reportUrl}
              reportService={reportService}
              defaultFilename={"merchantReferenceSheet.pdf"}
            ></ReportFileDownloader.PDF>
          )
        }
      />
    </div>
  );
}

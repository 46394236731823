import {
  GetReportGroupsParameters,
  GetReportParameters,
  ReportService,
} from "../../services/report";
import {
  Configuration,
  ReportApi,
  ReportApiGenerateReportURLRequest,
  ReportGroup as SdkGroup,
  ReportType as SdkReportType,
} from "ebocomsdk";
import {
  ReportCategory,
  ReportCategoryValue,
  ReportGroup,
  ReportType,
} from "../../models/report";
import { getPreAuthMiddleware } from "./auth_middleware";
import axios from "axios";
import { EbocomSdk } from "./init";
import { getFilenameFromContentDisposition } from "./filename_extractor";
import { CONTENT_DISPOSITION } from "./constants";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";

export class EbocomReportSdk implements ReportService {
  private api: ReportApi;
  constructor(baseUrl: string) {
    const config = new Configuration({ basePath: baseUrl });
    this.api = new ReportApi(config)
      .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
      .withPostMiddleware(getPostLoadingMiddleware());
  }
  private serializeApiGroup = (apiGroup: SdkGroup): ReportGroup => {
    return new ReportGroup(
      apiGroup.id!,
      apiGroup.text ?? "",
      apiGroup.children?.map(this.serializeApiGroup),
      apiGroup.url
    );
  };
  async getGroups(params?: GetReportGroupsParameters) {
    const apiGroups = await this.api.getReportGroups({
      type: (params?.reportType !== undefined && params?.reportType !== null)
        ? this.serializeReportType(params?.reportType)
        : undefined,
      merchNbr:
        params?.merchantNumber === "" ? undefined : params?.merchantNumber,
    });
    return apiGroups.map(this.serializeApiGroup);
  }
  private formatDate = (date: Date) => {
    return `${(date.getUTCMonth() + 1).toString().padStart(2, "0")}-${date
      .getUTCDate()
      .toString()
      .padStart(2, "0")}-${date.getUTCFullYear()}`;
  };
  private serializeParameters(
    params: GetReportParameters
  ): ReportApiGenerateReportURLRequest {
    return {
      reportRequest: {
        groups: params.groups ?? [],
        type: this.serializeReportType(params.type),
        endDate: !!params.endDate ? this.formatDate(params.endDate) : undefined,
        beginDate: !!params.beginDate
          ? this.formatDate(params.beginDate)
          : undefined,
        categoryId: params.categoryId,
        categoryValueId: params.categoryValueId,
        period: params.period,
        merchNbr: params.merchantNumber,
        loadBatchNumber: params.loadBatchNumber,
        loadFileNumber: params.loadFileNumber,
        loadEntryNumber: params.loadEntryNumber,
      },
    };
  }
  async getReportUrl(params: GetReportParameters) {
    const serializedParams = this.serializeParameters(params);
    if (!serializedParams.reportRequest.type) {
      throw TypeError("Report type must be defined");
    }
    const result = await this.api.generateReportURL(serializedParams);
    return result;
  }
  async getReportCategories() {
    const categories = await this.api.getReportCategories();
    const deserializedCategories = categories.map<ReportCategory>((c) => ({
      description: c.categoryDesc ?? "",
      id: c.categoryId!,
    }));
    return deserializedCategories;
  }
  async getReportCategoryValues(categoryId: string) {
    const values = await this.api.getReportCategoryValues({
      categoryId,
    });
    const deserializedValues = values.map<ReportCategoryValue>((v) => ({
      categoryId: v.categoryId!,
      value: v.categoryValue ?? "",
      valueId: v.categoryValueId!,
    }));
    return deserializedValues;
  }
  async getReportPeriods(reportType: ReportType) {
    return await this.api.getReportPeriods({
      reportType: this.serializeReportType(reportType),
    });
  }
  async getReportBeginDate(reportType: ReportType) {
    const reportDates = await this.api.getReportDate({
      type: this.serializeReportType(reportType),
    });
    const noDataDateString = reportDates.noDataDate ?? "";

    const noDataDateParts = noDataDateString.split("-");
    const noDataDate = new Date();
    noDataDate.setDate(1);
    noDataDate.setMonth(parseInt(noDataDateParts[0]) - 1);
    noDataDate.setFullYear(parseInt(noDataDateParts[1]));
    const startDateString = reportDates.startDate ?? "";

    const startDateParts = startDateString.split("-");
    const startDate = new Date();
    startDate.setMonth(parseInt(startDateParts[0]) - 1);
    startDate.setFullYear(parseInt(startDateParts[1]));
    return {
      beginDate: startDate,
      noDataDate: noDataDate,
    };
  }
  private serializeReportType = (reportType: ReportType): SdkReportType => {
    return EbocomReportSdk.serviceReportTypeMapping[reportType];
  };
  downloadReport = async (reportUrl: string): Promise<[Blob, string?]> => {
    const headers = EbocomSdk.getAuthHeader();
    const { headers: responseHeaders, data } = await axios.get(reportUrl, {
      headers,
      responseType: "blob",
    });
    const filename = getFilenameFromContentDisposition(
      responseHeaders[CONTENT_DISPOSITION]
    );
    return [data, filename];
  };
  private static serviceReportTypeMapping: {
    [key in ReportType]: SdkReportType;
  } = {
      [ReportType.CHARGEBACKAGINGOUTSTANDING]:
        SdkReportType.Chargebackagingoutstanding,
      [ReportType.DISPUTEAGING]: SdkReportType.Disputeaging,
      [ReportType.EXCESSIVECHARGEBACK]: SdkReportType.Excessivechargeback,
      [ReportType.DISPUTEAGINGLOCATION]: SdkReportType.Disputeaginglocation,
      [ReportType.MONTHLYSTATEMENTLOCATION]:
        SdkReportType.Monthlystatementlocation,
      [ReportType.INTERCHANGEPERFORMANCELOCATION]:
        SdkReportType.Interchangeperformancelocation,
      [ReportType.MERCHANTREFERENCESHEET]: SdkReportType.Merchantreferencesheet,
      [ReportType.REFUNDAUDEXCEPTION]: SdkReportType.Refundaudexception,
      [ReportType.DAILYDEPOSITLOCATION]: SdkReportType.Dailydepositlocation,
      [ReportType.REFUNDAUDLOCATION]: SdkReportType.Refundaudlocation,
      [ReportType.REFUNDAUDTREND]: SdkReportType.Refundaudtrend,
      [ReportType.BYCARDTYPETREND]: SdkReportType.Bycardtypetrend,
      [ReportType.CARDACCEPTANCEREGION]: SdkReportType.Cardacceptanceregion,
      [ReportType.CARDPRODUCTACCEPTANCE]: SdkReportType.Cardproductacceptance,
      [ReportType.CARDPRODUCTACCEPTANCETREND]:
        SdkReportType.Cardproductacceptancetrend,
      [ReportType.CARDREGIONTREND]: SdkReportType.Cardregiontrend,
      [ReportType.CARDTYPE]: SdkReportType.Cardtype,
      [ReportType.CHARGEBACKAGING]: SdkReportType.Chargebackaging,
      [ReportType.CHARGEBACKPERFORMANCE]: SdkReportType.Chargebackperformance,
      [ReportType.CHARGEBACKPERFORMANCEREASON]:
        SdkReportType.Chargebackperformancereason,
      [ReportType.CHARGEBACKPERFORMANCETREND]:
        SdkReportType.Chargebackperformancetrend,
      [ReportType.CLOSEDCHARGEBACKPERFORMANCE]:
        SdkReportType.Closedchargebackperformance,
      [ReportType.CLOSEDCHARGEBACKPERFORMANCEREASON]:
        SdkReportType.Closedchargebackperformancereason,
      [ReportType.CLOSEDCHARGEBACKPERFORMANCETREND]:
        SdkReportType.Closedchargebackperformancetrend,
      [ReportType.DAILYDEPOSIT]: SdkReportType.Dailydeposit,
      [ReportType.DAILYTRANSMITTEDACTIVITY]:
        SdkReportType.Dailytransmittedactivity,
      [ReportType.DEBITCARDPRODUCTACCEPTANCE]:
        SdkReportType.Debitcardproductacceptance,
      [ReportType.DEBITCARDPRODUCTACCEPTANCETREND]:
        SdkReportType.Debitcardproductacceptancetrend,
      [ReportType.DEPOSITACTIVITY]: SdkReportType.Depositactivity,
      [ReportType.DEPOSITACTIVITYTREND]: SdkReportType.Depositactivitytrend,
      [ReportType.EXCESSIVECREDIT]: SdkReportType.Excessivecredit,
      [ReportType.INTERCHANGEDETAIL]: SdkReportType.Interchangedetail,
      [ReportType.INTERCHANGEPERFORMANCE]: SdkReportType.Interchangeperformance,
      [ReportType.INTERCHANGEQUALIFICATIONPERFORMANCE]:
        SdkReportType.Interchangequalificationperformance,
      [ReportType.INTERCHANGEQUALIFICATIONTREND]:
        SdkReportType.Interchangequalificationtrend,
      [ReportType.MERCHANTFEES]: SdkReportType.Merchantfees,
      [ReportType.MERCHANTFEESTREND]: SdkReportType.Merchantfeestrend,
      [ReportType.MONTHLYSTATEMENT]: SdkReportType.Monthlystatement,
      [ReportType.POINTOFACCEPTANCE]: SdkReportType.Pointofacceptance,
      [ReportType.POINTOFACCEPTANCETREND]: SdkReportType.Pointofacceptancetrend,
      [ReportType.RFCAGING]: SdkReportType.Rfcaging,
      [ReportType.RFCPERFORMANCE]: SdkReportType.Rfcperformance,
      [ReportType.RFCPERFORMANCETREND]: SdkReportType.Rfcperformancetrend,
    };
}

import { useCallback, useEffect, useState } from "react";
import { ChargebackAging } from "../models/chargeback_aging";
import { DisputeSummary } from "../models/dispute";
import {
  AgingChargebackSortColumn,
  ChargebackFilter,
  DisputeService,
} from "../services/disputes/dispute_service";
import { usePagination } from "./use_pagination";
import { useSorting } from "./use_sorting";
export interface DisputeSearchParameters {}

const AGING_PAGE_SIZE = 2000;
export function useAging(
  disputeService: DisputeService,
  disputeSearchParameters?: ChargebackFilter
) {
  const [agingDisputes, setAgingDisputes] =
    useState<Partial<ChargebackAging>[]>();
  const [disputeSummaries, setDisputeSummaries] = useState<DisputeSummary[]>();
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalChargeback, setTotalChargeback] = useState<number | undefined>(0);
  const [totalSummary, setTotalSummary] = useState<number | undefined>(0);
  const agingPagination = usePagination(AGING_PAGE_SIZE, totalChargeback);
  const summaryPagination = usePagination(AGING_PAGE_SIZE, totalSummary);
  const { handleSort, sorting } = useSorting<AgingChargebackSortColumn>();
  const reset = useCallback(() => {
    handleSort();
    agingPagination.firstPage();
    summaryPagination.firstPage();
  }, [handleSort, agingPagination, summaryPagination]);

  useEffect(() => {
    if (!disputeSearchParameters?.merchantNumber) {
      return;
    }
    setIsLoading(true);
    disputeService
      .getAgingChargebacks(
        {
          ...disputeSearchParameters,
          daysRemainingThreshold: 90,
          pageSize: AGING_PAGE_SIZE,
          pageOffsetAging: agingPagination.page,
          pageOffsetSummary: summaryPagination.page,
        },
        {
          sortColumn: sorting?.field,
          sortOrder: sorting?.direction,
        }
      )
      .then((results) => {
        setIsEmpty(results.isEmpty);
        setAgingDisputes(results.chargebackAgingDetails?.data);
        setTotalChargeback(results.chargebackAgingDetails?.total);
        setDisputeSummaries(results.chargebackSummary?.data);
        setTotalSummary(results.chargebackSummary?.total);
      })
      .finally(() => setIsLoading(false));
  }, [
    disputeService,
    agingPagination.page,
    summaryPagination.page,
    setAgingDisputes,
    setIsLoading,
    disputeSearchParameters,
    sorting,
  ]);
  return {
    agingDisputes,
    disputeSummaries,
    totalChargeback,
    totalSummary,
    sorting,
    isLoading,
    handleSort,
    isEmpty,
    reset,
    pagination: { summaryPagination, agingPagination },
  };
}

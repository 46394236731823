import React, { useContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useLocation } from "react-router";
import { HoverMenu } from "./components/hover_menu";
import { MainTemplate } from "./layout/main";
import { mockDisputeService } from "./mocks/dispute.mock";
import { EbocomDisputeService } from "./pkg/ebocomsdk/disputes";
import { DepositDetailsOCContainer } from "./routes/deposit_details/deposit_details_oc_container";
import { menuLinks } from "./routes/menu_links";
import { ThanksForYourInterest } from "./routes/thanks_for_your_interest";
import { WelcomeRoute } from "./routes/welcome/welcome";
import { WelcomeContainer } from "./routes/welcome/welcome_container";
import { StandardReportRoute } from "./routes/standard_reports/standard_report_route";
import { StandardReportContainer } from "./routes/standard_reports/standard_report_container";
import { ExternalLinkHandlerRoute } from "./routes/external_link_handler/external_link_handler_route";
import { ManagementModuleContainer } from "./routes/management_modules/management_module_container";
import { ManagementModuleRoute } from "./routes/management_modules/management_module_route";
import { Feature, FeatureProps } from "./components/feature";
import { EbocomTransactionService } from "./pkg/ebocomsdk/transaction";
import { TransactionSearchContainer } from "./routes/transactions/transaction_search/transaction_search_container";
import { mockTransactionService } from "./mocks/transaction.mock";
import { ContactUs } from "./routes/contact_us/contact_us";
import { PrivacyPolicy } from "./routes/privacy_policy";
import { TermsAndConditions } from "./routes/terms";
import "./App.scss";
import "./override.css";
import { mockMerchantService } from "./mocks/merchant.mock";
import { ServiceFacade } from "./models/service_facade";
import { mockDivisionService } from "./mocks/division.mock";
import { EbocomDivisionSdk } from "./pkg/ebocomsdk/division";
import { EbocomMerchantService } from "./pkg/ebocomsdk/merchant";
import { mockReportService } from "./mocks/report.mock";
import { EbocomReportSdk } from "./pkg/ebocomsdk/report";
import { ReportType, reportTypeFromString } from "./models/report";
import { ApplicationConfig, ConfigContext } from "./config";
import { mockTransmittedService } from "./mocks/transmitted.mock";
import { mockAuthService } from "./mocks/auth.mock";
import { EbocomTransmittedService } from "./pkg/ebocomsdk/transmitted";
import { mockUserService } from "./mocks/user.mock";
import { EbocomUserService } from "./pkg/ebocomsdk/user";
import { useCurrentUser } from "./hooks/use_current_user";
import { UserContext } from "./contexts/user_context";
import { reportsToRoles } from "./models/roles";
import { documentsToRoles } from "./models/document";
import { reactKeycloakWebContext } from "@react-keycloak/web/lib/context";
import { ImpersonateService } from "./pkg/keycloak/keycloak_impersonation_service";
import { EbocomSdk } from "./pkg/ebocomsdk/init";
import { mockDocumentService } from "./mocks/document.mock";
import { DocumentRoute } from "./routes/document/document_route";
import { DocumentContainer } from "./routes/document/document_container";
import { DocumentPathToTypesMap } from "./route_constants";
import { EbocomDocumentService } from "./pkg/ebocomsdk/document";
import { ChangePassword } from "./routes/change_password";
import { BusinessInformationContainer } from "./routes/business_information/business_information_container";
import { DepositActivityContainer } from "./routes/deposit_details/deposit_activity_container";
import { WelcomeTemplate } from "./layout/welcome_template";
import { useImpersonatableUsers } from "./hooks/use_impersonatable";
import { IssuerLookupContainer } from "./routes/issuer_lookup/issuer_lookup_container";
import { mockCardService } from "./mocks/card.mock";
import { CardDetailsEbocomSdk } from "./pkg/ebocomsdk/card_details";
import { OutletListingContainer } from "./routes/outlet_listing/outlet_listing_container";
import { SelectedMerchantContextProvider } from "./contexts/selected_merchant_context";
import { BankInfoContainer } from "./routes/bank_info/bank_info_container";
import { ImpersonationContext } from "./contexts/impersonation_context";
import { AuthService } from "./services/auth";
import { TokenContext } from "./contexts/token_context";
import { UserService } from "./services/user";
import { Footer } from "./layout/footer";
import { MerchantReferenceSheetContainer } from "./routes/merchant_reference_sheet/merchant_reference_sheet";
import { UserPrivilegesContainer } from "./routes/user_privileges/user_privileges_container";
import { DepositActivityRoute } from "./routes/deposit_details/deposit_activity_route";
import { DisputeAgingContainer } from "./routes/disputes/aging/dispute_aging_container";
import { DisputeClosedContainer } from "./routes/disputes/aging/dispute_closed_container";
import { mockFeedbackService } from "./mocks/feedback.mock";
import { EbocomFeedbackService } from "./pkg/ebocomsdk/feedback";
import querystring from "query-string";
import { useKeycloak } from "@react-keycloak/web";
import useSuperSearchParametersState from "./store/super_search_parameters_state";
import { clearLocalStorage } from "./utils";
import useGlobalLoaderState from "./store/global_loader_state";
import useUserStore from "./store/user";
import { SSOIFrameRenderer } from "./components/sso_iframe_renderer";
import { useRef } from "react";

const useFailsafeKeycloak = () => {
  const keycloakContext = useContext(reactKeycloakWebContext);
  useEffect(() => {
    console.log("keycloak changed");
  }, [keycloakContext]);
  return keycloakContext.authClient;
};
const useServiceProviders = (
  config: ApplicationConfig
): ServiceFacade | undefined => {
  const [serviceProviders, setServiceProviders] = useState<ServiceFacade>();

  useEffect(() => {
    if (config.shouldUseFaked) {
      setServiceProviders({
        cardService: mockCardService(),
        disputeService: mockDisputeService(),
        documentService: mockDocumentService(),
        transactionService: mockTransactionService(),
        feedbackService: mockFeedbackService(),
        merchantService: mockMerchantService(),
        divisionService: mockDivisionService(),
        reportService: mockReportService(),
        transmittedService: mockTransmittedService(),
        userService: mockUserService(),
      });
    } else {
      setServiceProviders({
        cardService: new CardDetailsEbocomSdk(config.ebocomBackendBaseUrl),
        documentService: new EbocomDocumentService(config.ebocomBackendBaseUrl),
        disputeService: new EbocomDisputeService(config.ebocomBackendBaseUrl),
        feedbackService: new EbocomFeedbackService(config.ebocomBackendBaseUrl),
        transactionService: new EbocomTransactionService(
          config.ebocomBackendBaseUrl
        ),
        merchantService: new EbocomMerchantService(config.ebocomBackendBaseUrl),
        divisionService: new EbocomDivisionSdk(config.ebocomBackendBaseUrl),
        reportService: new EbocomReportSdk(config.ebocomBackendBaseUrl),
        transmittedService: new EbocomTransmittedService(
          config.ebocomBackendBaseUrl
        ),
        userService: new EbocomUserService(config.ebocomBackendBaseUrl),
      });
    }
  }, [config]);
  return serviceProviders;
};

const ServiceContext = React.createContext<ServiceFacade | undefined>(
  undefined
);
const FeatureWithFallback = (
  props: Omit<FeatureProps, "fallbackComponent">
) => <Feature {...props} fallbackComponent={<ThanksForYourInterest />} />;

const withBrowserRouter = (Component: React.FC) => {
  return () => (
    <BrowserRouter>
      <Component />
    </BrowserRouter>
  );
};
// We need an auth service
const ImpersonationContextProvider = ({
  children,
  authService,
  userService,
  onRefresh,
}: {
  children: React.ReactNode;
  authService?: AuthService;
  userService?: UserService;
  onRefresh(): void;
}) => {
  const { token } = useContext(TokenContext);
  const [impersonatedAccountId, setImpersonatedAccountId] = React.useState<
    string | null
  >(null);
  const [rootAccountId, setRootAccountId] = React.useState<string | null>(null);
  const [ssoIFrameLoaded, setSSOIFrameLoaded] = React.useState<boolean>(false);
  const iFrameRef = useRef<HTMLIFrameElement>(null);
  const iFrameCurrent = iFrameRef.current;
  const { keycloak } = useKeycloak();

  const passExpirationRedirectTime = useUserStore((state) => state.passExpirationRedirectTime);

  useEffect(() => {
    iFrameCurrent?.addEventListener('load', () => {
      setSSOIFrameLoaded(true);
      console.log('SSO iFrame loaded')
    });

    return () => {
      iFrameCurrent?.removeEventListener('load', () => setSSOIFrameLoaded(true));
    }
  }, [iFrameCurrent]);

  // useEffect(() => {
  //   const ssoIFrame = document.getElementById('ifr') as HTMLIFrameElement;
  //   const timeout = 60000;
  //   let timePassed = 0;
  //   const checkIFrameLoaded = () => {
  //     const iFrameDoc = ssoIFrame.contentDocument || ssoIFrame.contentWindow?.document;
  //     if (ssoIFrame.contentWindow && iFrameDoc?.readyState === 'complete') {
  //       ssoIFrame.contentWindow.onload = () => console.log('ssoIFrame onload event fired');
  //       // It's loaded
  //       setSSOIFrameLoaded(true);
  //       return;
  //     }
  //     timePassed += 100;
  //     if (timePassed < timeout) {
  //       setTimeout(checkIFrameLoaded, 100);
  //     } else {
  //       //redirect to login screen??
  //       console.log('IFrame loading timeout');
  //     }
  //   }
  //   checkIFrameLoaded();
  //   return () => {
  //     ssoIFrame.contentWindow && (ssoIFrame.contentWindow.onload = () => { });
  //   }
  // }, []);

  useEffect(() => {
    if (authService && impersonatedAccountId) {
      authService.impersonateUser(impersonatedAccountId).then(onRefresh);
    }
  }, [token, impersonatedAccountId, authService, onRefresh]);

  useEffect(() => {
    if (ssoIFrameLoaded) {
      userService?.getUserInfo().then(async (user) => {
        console.log('getting user info');
        if (!user.email) {
          console.log('user has no email');
          return;
        }
        if (impersonatedAccountId) {
          console.log('Setting impresonated account id => This means we are not checking for password expiration time');
          setImpersonatedAccountId(user.username ?? user.email);
        } else {
          setRootAccountId(user.username ?? user.email);
          console.log('user', user);
          console.log('checking for password expiration time');
          if (user.pwExpire) {
            console.log('User has password expiration date');
            window.localStorage.setItem('username', user.username ?? user.email ?? '');
            const passwordExpirationDate = new Date(user.pwExpire);
            const currentDate = new Date();
            console.log('passwordExpirationDate', passwordExpirationDate);
            console.log('current Date', currentDate);
            const timeDifferenceInMilliseconds = passwordExpirationDate.getTime() - currentDate.getTime();
            let timeDifferenceInDays = 0;
            if (timeDifferenceInMilliseconds > 0) {
              timeDifferenceInDays = Math.floor(timeDifferenceInMilliseconds / 1000 / 60 / 60 / 24);
            }
            console.log('Days till pass expiration:', timeDifferenceInDays);
            console.log('passExpirationRedirectTime:', passExpirationRedirectTime);
            const sixHoursInMilliseconds = 6 * 60 * 60 * 1000;
            const passExpirationRedirectTimePlus6H = !!passExpirationRedirectTime ? (passExpirationRedirectTime.redirectTime + sixHoursInMilliseconds) : undefined;
            const ssoIFrame = iFrameCurrent;
            const passwordExpirationData = {
              daysToPasswordExpiration: timeDifferenceInDays,
              username: user.username,
            }
            console.log(ssoIFrame);

            if (ssoIFrame?.contentWindow) {
              ssoIFrame?.contentWindow.postMessage(passwordExpirationData, '*');
              if (
                timeDifferenceInDays <= 14 &&
                (passExpirationRedirectTimePlus6H === undefined || (passExpirationRedirectTimePlus6H && (currentDate.getTime() > passExpirationRedirectTimePlus6H)))
              ) {
                console.log('Setting pass expiration redirect time to localstorage:', currentDate.getTime().toString());
                useUserStore.getState().setPassExpirationRedirectTime({
                  username: user.username ?? user.email ?? '',
                  redirectTime: currentDate.getTime()
                });
                setTimeout(() => {
                  const loginUrl = keycloak.createLoginUrl({
                    action: "UPDATE_PASSWORD",
                    redirectUri: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}?showAging=false`,
                    loginHint: `${user.username}`,
                    scope: 'profile',
                  });
                  window.location.replace(loginUrl);
                }, 1000);
              } else {
                useGlobalLoaderState.getState().setIsUserProfileLoaded();
              }
            }
          }
        }
      });
    }
    //eslint-disable-next-line
  }, [
    userService,
    setRootAccountId,
    setImpersonatedAccountId,
    impersonatedAccountId,
    keycloak,
    ssoIFrameLoaded,
  ]);

  const impersonateUser = async (userId: string) => {
    userService?.getUserInfo().then((user) => {
      if (user.email) {
        setRootAccountId(user.email);
      }
    });
    await authService
      ?.impersonateUser(userId)
      .then(() => setImpersonatedAccountId(userId))
      .then(onRefresh);
  };

  const deimpersonate = async () => {
    setImpersonatedAccountId(null);
    if (token) {
      EbocomSdk.updateToken(token);
    }
    onRefresh();
  };

  return (
    <ImpersonationContext.Provider
      value={{
        deimpersonate,
        impersonatedAccountId,
        impersonateUser,
        rootAccountId,
      }}
    >
      {children}
      <SSOIFrameRenderer iframeRef={iFrameRef} />
    </ImpersonationContext.Provider>
  );
};

function App() {
  const { pathname, search } = useLocation();
  const params = querystring.parse(search);
  const config = useContext(ConfigContext);
  const serviceProviders = useServiceProviders(config!);
  const [hasDismissedModal, setHasDismissedModal] = useState(
    params.showAging === "false"
  );
  const { currentUser, refreshUser } = useCurrentUser(
    serviceProviders?.userService
  );
  const [primaryUser, setPrimaryUser] = useState<string | undefined>();
  const setSuperSearchParametersRoutes = useSuperSearchParametersState(({ setRoutes }) => setRoutes);
  const isUserProfileLoaded = useGlobalLoaderState((state) => state.isUserProfileLoaded);

  useEffect(() => {
    if (primaryUser === undefined && !!currentUser) {
      setPrimaryUser(currentUser.username);
    }
  }, [currentUser, primaryUser]);
  const { impersonatableUsers, refreshImpersonatableUsers } =
    useImpersonatableUsers(serviceProviders?.userService);
  const keycloak = useFailsafeKeycloak();
  const authService = useMemo(() => {
    if (!keycloak) {
      mockAuthService();
    } else {
      return new ImpersonateService(keycloak!, (token) => {
        EbocomSdk.updateToken(token);
      });
    }
  }, [keycloak]);

  useEffect(() => {
    refreshUser();
    refreshImpersonatableUsers();
  }, [keycloak, refreshUser, refreshImpersonatableUsers]);

  useEffect(() => {
    setSuperSearchParametersRoutes(pathname);
    // eslint-disable-next-line
  }, [pathname]);

  if (!serviceProviders) {
    return <div />;
  }

  return (
    <SelectedMerchantContextProvider
      currentUserMerchant={currentUser?.merchantId}
    >
      <UserContext.Provider value={currentUser}>
        <ImpersonationContextProvider
          onRefresh={refreshUser}
          userService={serviceProviders.userService}
          authService={authService}
        >
          <ImpersonationContext.Consumer>
            {({ impersonateUser, impersonatedAccountId, rootAccountId }) =>
              <ServiceContext.Provider value={serviceProviders}>
                <ServiceContext.Consumer>
                  {(serviceProviders) =>
                    !serviceProviders ? null : (
                      <div className="is-family-primary" style={{ display: isUserProfileLoaded ? 'block' : 'none' }}>
                        <div className="Post@Vantage">
                          <Route path="/external">
                            <ExternalLinkHandlerRoute />
                          </Route>
                          <Switch>
                            <Route exact path="/">
                              <Redirect to="/welcome" />
                            </Route>
                            <Route path="/welcome">
                              <WelcomeTemplate
                                onLogOut={() => {
                                  clearLocalStorage();
                                  keycloak?.authenticated &&
                                    keycloak?.logout?.({
                                      redirectUri: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                                    });
                                }}
                                footer={
                                  <Footer
                                    primaryUser={primaryUser}
                                    impersonatedUser={
                                      (impersonatedAccountId !== primaryUser) ? impersonatedAccountId ?? undefined : undefined
                                    }
                                    availableUsers={
                                      ((impersonatedAccountId && impersonatedAccountId !== primaryUser) || currentUser?.roles.includes("InternalUser")) ? impersonatableUsers : []
                                    }
                                    onSelectUser={(user) => {
                                      impersonateUser(user)
                                        .then(refreshUser)
                                    }}
                                    showWidget={true}
                                  />
                                }
                              >
                                <WelcomeContainer
                                  disputeService={
                                    serviceProviders.disputeService
                                  }
                                >
                                  {(disputes, loading) => (
                                    <WelcomeRoute
                                      loadingDisputes={loading}
                                      welcomeMessage={
                                        <>
                                          <div className="headers">
                                            <div
                                              style={{ color: "#392852" }}
                                              className="welcome-message title is-4 m-1"
                                            >
                                              Welcome to POST@Vantage!
                                            </div>
                                            <div
                                              className="is-italic m-0"
                                              style={{
                                                fontSize: 13,
                                                fontWeight: "bold",
                                                color: "#392852",
                                              }}
                                            >
                                              please select one of the following
                                              options
                                            </div>
                                          </div>
                                        </>
                                      }
                                      hasDismissedModal={hasDismissedModal}
                                      onDismissed={() =>
                                        setHasDismissedModal(true)
                                      }
                                      disputes={disputes}
                                      currentUser={currentUser}
                                    >
                                      <div className="is-flex flex-direction-row container is-justify-content-center main-menu">
                                        {menuLinks.map((column) => {
                                          return (
                                            <div
                                              key={column.name}
                                              className="is-flex is-flex-direction-column home-menu-column"
                                            >
                                              <div
                                                className="is-uppercase"
                                                style={{
                                                  whiteSpace: "nowrap",
                                                  paddingBottom: 7,
                                                  fontWeight: "bold",
                                                }}
                                              >
                                                {column.name}
                                              </div>
                                              <HoverMenu
                                                items={column.links}
                                                listStyleImage={`${process.env.PUBLIC_URL}/images/logo_20.gif`}
                                              />
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </WelcomeRoute>
                                  )}
                                </WelcomeContainer>
                              </WelcomeTemplate>
                            </Route>
                            <Route path="*">
                              <MainTemplate
                                footer={
                                  <Footer
                                    primaryUser={primaryUser}
                                    impersonatedUser={
                                      impersonatedAccountId ?? undefined
                                    }
                                    availableUsers={
                                      currentUser?.roles.includes("InternalUser") ? impersonatableUsers : []
                                    }
                                    onSelectUser={(user) => { impersonateUser(user).then(refreshUser) }}
                                    showWidget={false}
                                  />
                                }
                                menuComponent={
                                  <>
                                    {menuLinks.map((column) => {
                                      return (
                                        <div
                                          className="compact-link-menu is-flex is-flex-direction-column"
                                          key={column.name}
                                        >
                                          <div
                                            className="column-title is-uppercase"
                                            style={{ fontSize: 13 }}
                                          >
                                            {column.name}
                                          </div>
                                          <HoverMenu.Compact
                                            inverted
                                            activePath={pathname}
                                            items={column.links}
                                          />
                                        </div>
                                      );
                                    })}
                                  </>
                                }
                              >
                                <Switch>
                                  <Route path="/issuer_lookup">
                                    <FeatureWithFallback wantedRole="IssuerLookup">
                                      <IssuerLookupContainer
                                        cardService={
                                          serviceProviders.cardService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="/transactions/*">
                                    <FeatureWithFallback wantedRole="SuperSearch">
                                      <TransactionSearchContainer
                                        divisionService={
                                          serviceProviders.divisionService
                                        }
                                        merchantService={
                                          serviceProviders.merchantService
                                        }
                                        transactionService={
                                          serviceProviders.transactionService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path={["/depositdetailsoc"]}>
                                    <FeatureWithFallback wantedRole="TransmittedActivity">
                                      <DepositActivityRoute>
                                        {(params) => (
                                          <DepositDetailsOCContainer
                                            divisionService={
                                              serviceProviders?.divisionService
                                            }
                                            outletService={
                                              serviceProviders?.merchantService
                                            }
                                            defaultSearchParameters={{
                                              authCode: params?.authCode,
                                              cardType: params?.cardType,
                                              tranAmount:
                                                params?.transactionAmount,
                                              cardNumber: params?.cardNumber,
                                              date: params?.date,
                                              loadBatchNumber:
                                                params?.loadBatchNumber,
                                              loadEntryNumber:
                                                params?.loadEntryNumber,
                                              merchantId:
                                                params?.merchantNumber,
                                              loadFileNumber:
                                                params?.loadFileNumber,
                                              transactionAmount:
                                                params?.transactionAmount,
                                              transactionType:
                                                params?.transactionType,
                                              outletNumber:
                                                params?.outletNumber,
                                            }}
                                            transmittedService={
                                              serviceProviders.transmittedService
                                            }
                                            merchantService={
                                              serviceProviders.merchantService
                                            }
                                          />
                                        )}
                                      </DepositActivityRoute>
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path={"/depositdetails"}>
                                    <FeatureWithFallback wantedRole="DepositActivity">
                                      <DepositActivityRoute>
                                        {(parameters) => (
                                          <DepositActivityContainer
                                            defaultSearchParameters={{
                                              cardType: parameters?.cardType,
                                              cardNumber:
                                                parameters?.cardNumber,
                                              merchantNumber:
                                                parameters?.merchantNumber,
                                              transactionAmount:
                                                parameters?.transactionAmount,
                                              transactionType:
                                                parameters?.transactionType,
                                              authCode: parameters?.authCode,
                                              date: parameters?.date,
                                            }}
                                            merchantService={
                                              serviceProviders.merchantService
                                            }
                                            transmittedService={
                                              serviceProviders.transmittedService
                                            }
                                          />
                                        )}
                                      </DepositActivityRoute>
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="/unauthorized">
                                    <ThanksForYourInterest />
                                  </Route>
                                  <Route path="/disputes/aging">
                                    <FeatureWithFallback wantedRole="ChargebackRFCAging">
                                      <DisputeAgingContainer
                                        reportService={
                                          serviceProviders.reportService
                                        }
                                        disputeService={
                                          serviceProviders.disputeService
                                        }
                                        merchantService={
                                          serviceProviders.merchantService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="/disputes/closed">
                                    <FeatureWithFallback wantedRole="ChargebackRFCClosed">
                                      <DisputeClosedContainer
                                        disputeService={
                                          serviceProviders.disputeService
                                        }
                                        merchantService={
                                          serviceProviders.merchantService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="/documents/:documentType">
                                    <DocumentRoute
                                      paramKey="documentType"
                                      documentTypeSerializer={(docName) =>
                                        DocumentPathToTypesMap[docName]
                                      }
                                    >
                                      {(documentTypes) => (
                                        <FeatureWithFallback
                                          wantedRole={
                                            documentsToRoles[documentTypes[0]]!
                                          }
                                        >
                                          <DocumentContainer
                                            documentTypes={documentTypes}
                                            documentService={
                                              serviceProviders.documentService
                                            }
                                          />
                                        </FeatureWithFallback>
                                      )}
                                    </DocumentRoute>
                                  </Route>
                                  <Route path="/merchant_reference_sheet">
                                    <FeatureWithFallback wantedRole="MerchantReferenceSheet">
                                      <MerchantReferenceSheetContainer
                                        merchantService={
                                          serviceProviders.merchantService
                                        }
                                        reportService={
                                          serviceProviders.reportService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="/excessive_credit">
                                    <ThanksForYourInterest />
                                  </Route>
                                  <Route path="/standardreports">
                                    <StandardReportRoute
                                      reportTypeSerializer={
                                        reportTypeFromString
                                      }
                                    >
                                      {(
                                        reportType: ReportType,
                                        isCorporate
                                      ) => (
                                        <FeatureWithFallback
                                          wantedRole={
                                            reportsToRoles[reportType]!
                                          }
                                        >
                                          <StandardReportContainer
                                            isCorporate={isCorporate}
                                            merchantService={
                                              serviceProviders.merchantService
                                            }
                                            reportService={
                                              serviceProviders.reportService
                                            }
                                            reportType={reportType}
                                          />
                                        </FeatureWithFallback>
                                      )}
                                    </StandardReportRoute>
                                  </Route>
                                  <Route path="/management_module/:reportCategory/:reportType">
                                    <ManagementModuleRoute>
                                      {(
                                        reportCategory: string,
                                        reportType: ReportType
                                      ) => (
                                        <FeatureWithFallback
                                          wantedRole={
                                            reportsToRoles[reportType]!
                                          }
                                        >
                                          <ManagementModuleContainer
                                            reportService={
                                              serviceProviders.reportService
                                            }
                                            reportCategory={reportCategory}
                                            reportType={reportType}
                                          />
                                        </FeatureWithFallback>
                                      )}
                                    </ManagementModuleRoute>
                                  </Route>
                                  <Route path="/outlet_listing">
                                    <OutletListingContainer
                                      merchantService={
                                        serviceProviders.merchantService
                                      }
                                    />
                                  </Route>
                                  <Route path="/business_information">
                                    <FeatureWithFallback wantedRole="BusinessInformation">
                                      <BusinessInformationContainer
                                        merchantService={
                                          serviceProviders.merchantService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="/change_password">
                                    <ChangePassword
                                      currentUserName={
                                        rootAccountId ??
                                        currentUser?.username ??
                                        undefined
                                      }
                                    />
                                  </Route>
                                  <Route path="/contactus">
                                    <ContactUs
                                      onSubmitFeedback={(feedback) => {
                                        serviceProviders.feedbackService.contactUs(
                                          feedback
                                        );
                                      }}
                                    />
                                  </Route>
                                  <Route path="/security">
                                    <PrivacyPolicy />
                                  </Route>
                                  <Route path="/terms">
                                    <TermsAndConditions />
                                  </Route>
                                  <Route path="/user_privileges">
                                    <FeatureWithFallback wantedRole="UserPrivileges">
                                      <UserPrivilegesContainer
                                        userService={
                                          serviceProviders.userService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="thank_you">
                                    <ThanksForYourInterest />
                                  </Route>
                                  <Route path="/settlement_account_information">
                                    <FeatureWithFallback wantedRole="SettlementAccountInformation">
                                      <BankInfoContainer
                                        merchantService={
                                          serviceProviders.merchantService
                                        }
                                      />
                                    </FeatureWithFallback>
                                  </Route>
                                  <Route path="/">404 Not Found</Route>
                                </Switch>
                              </MainTemplate>
                            </Route>
                          </Switch>
                        </div>
                      </div>
                    )
                  }
                </ServiceContext.Consumer>
              </ServiceContext.Provider>
            }
          </ImpersonationContext.Consumer>
        </ImpersonationContextProvider>
      </UserContext.Provider>
    </SelectedMerchantContextProvider>
  );
}

export default withBrowserRouter(App);

import { SortByColumn } from "ebocomsdk";
import {
  MerchantBankSortColumn,
  MerchantDetailsSortColumn,
  OutletSortColumn,
} from "../../services/merchant";

export function getSdkSortColumn(
  sortColumn:
    | MerchantDetailsSortColumn
    | MerchantBankSortColumn
    | OutletSortColumn
): string {
  switch (sortColumn) {
    case "merchantNumber":
      return SortByColumn.Merchantnbr;
    case "merchantName":
      return SortByColumn.Merchantname;
    case "streetAddress":
      return SortByColumn.Streetaddress;
    case "city":
      return SortByColumn.City;
    case "state":
      return SortByColumn.State;
    case "zipCode":
      return SortByColumn.Zip;
    case "businessLegalName":
      return SortByColumn.Businesslegalname;
    case "federalTaxId":
      return SortByColumn.Federaltaxid;
    case "saleRtg":
      return SortByColumn.Routingtransitnbr;
    case "saleAcct":
      return SortByColumn.Merchantacntnbr;
    case "chbkRtg":
      return SortByColumn.Routingtransitnbrc;
    case "chbkAcct":
      return SortByColumn.Merchantacntnbrc;
    case "feeRtg":
      return SortByColumn.Routingtransitnbrf;
    case "feeAcct":
      return SortByColumn.Merchantacntnbrf;
    case "netRtg":
      return SortByColumn.Routingtransitnbr2;
    case "netAcct":
      return SortByColumn.Merchantacntnbr2;
    case "division":
      return SortByColumn.Divisionname;
    case "outletNumber":
      return SortByColumn.Outlet;
    case "outletName":
      return SortByColumn.Outletname;
    case "amex":
      return SortByColumn.Amexmerchantnumber;
    case "discoverJcb":
      return SortByColumn.Discovermerchantnumber;
    case "propertyCode":
      return SortByColumn.Region;
    default:
      return "";
  }
}

import { Caret } from "../../../components/caret";
import { SortTooltip } from "../../../components/sort_tooltip";
import { TableColumn } from "../../../models/table";
import { ClosedChargebackSortColumn } from "../../../services/disputes/dispute_service";
import "../../../styles/tables.scss";
import { DisputeClosedChargebackTableItem, DisputeClosedTableProps } from "./dispute_closed_table";

const columns: TableColumn<
  Partial<DisputeClosedChargebackTableItem>,
  ClosedChargebackSortColumn
>[] = [
  {
    header: "Merchant Number",
    cell: (chargeback) => (
      <div className="has-text-centered">{chargeback.merchantNumber}</div>
    ),
    sortColumn: ClosedChargebackSortColumn.merchantNumber,
    width: 80,
  },
  {
    header: "Merchant Name",
    cell: (chargeback) => chargeback.merchantName,
    sortColumn: ClosedChargebackSortColumn.merchantName,
    width: 205,
  },
  {
    header: "Card Number",
    cell: (chargeback) => (
      <div className="has-text-centered">{chargeback.cardNumber}</div>
    ),
    sortColumn: ClosedChargebackSortColumn.cardNumber,
    width: 100,
  },
  {
    header: (
      <div>
        Tran
        <br />
        Date
      </div>
    ),
    cell: (chargeback) => (
      <div className="has-text-centered">{chargeback.transactionDate}</div>
    ),
    sortColumn: ClosedChargebackSortColumn.tranDate,
    width: 60,
  },
  {
    header: (
      <div>
        RFC
        <br />
        Date
      </div>
    ),
    cell: (chargeback) => (
      <div className="has-text-centered">{chargeback.transactionDate}</div>
    ),
    sortColumn: ClosedChargebackSortColumn.tranDate,
    width: 60,
  },
  {
    header: "Dispute Amount",
    cell: (chargeback) => (
      <div className="has-text-right">{chargeback.disputeAmount}</div>
    ),
    sortColumn: ClosedChargebackSortColumn.disputeAmount,
    width: 70,
  },
  {
    header: "Dispute Reason",
    cell: (chargeback) => chargeback.disputeReason,
    sortColumn: ClosedChargebackSortColumn.disputeReason,
    width: 220,
  },
  {
    header: "Closed Date",
    cell: (chargeback) => (
      <div className="has-text-centered">{chargeback.closedDate}</div>
    ),
    sortColumn: ClosedChargebackSortColumn.closedDate,
    width: 60,
  },
  {
    header: "Closed Reason",
    cell: (chargeback) => chargeback.closedReason,
    sortColumn: ClosedChargebackSortColumn.closedReason,
    width: 220,
  },
];

export function DisputeClosedRfcsTable({
  chargebacks,
  sortColumn,
  sortDirection,
  onSort,
}: DisputeClosedTableProps) {
  return (
    <table className="table is-bordered">
      <colgroup>
        {columns.map((column) => (
          <col width={column.width} />
        ))}
      </colgroup>
      <tbody>
        <tr style={{ height: 38 }}>
          {columns.map((c) => (
            <th className="is-uppercase  has-text-centered">
              <SortTooltip>
                <div
                  onClick={() =>
                    c.sortColumn !== undefined ? onSort(c.sortColumn) : null
                  }
                  style={{
                    cursor: "pointer",
                    color: sortColumn === c.sortColumn ? "yellow" : "white",
                  }}
                >
                  {c.header}
                  {sortColumn === c.sortColumn && <span>&nbsp;</span>}
                  <Caret.Auto
                    wantedColumn={c.sortColumn}
                    currentColumn={sortColumn}
                    direction={sortDirection}
                  />
                </div>
              </SortTooltip>
            </th>
          ))}
        </tr>
        {chargebacks.map((chargeback) => (
          <tr style={{ height: 26, verticalAlign: "middle" }}>
            {columns
              .filter((chargeback) => !!chargeback)
              .map((c) => (
                <td style={{ verticalAlign: "middle" }}>
                  {c.cell(chargeback)}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}


"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportRequestToJSON = exports.ReportRequestFromJSONTyped = exports.ReportRequestFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ReportRequestFromJSON(json) {
    return ReportRequestFromJSONTyped(json, false);
}
exports.ReportRequestFromJSON = ReportRequestFromJSON;
function ReportRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'groups': !runtime_1.exists(json, 'groups') ? undefined : json['groups'],
        'categoryId': !runtime_1.exists(json, 'categoryId') ? undefined : json['categoryId'],
        'categoryValueId': !runtime_1.exists(json, 'categoryValueId') ? undefined : json['categoryValueId'],
        'period': !runtime_1.exists(json, 'period') ? undefined : json['period'],
        'beginDate': !runtime_1.exists(json, 'beginDate') ? undefined : json['beginDate'],
        'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
        'type': !runtime_1.exists(json, 'type') ? undefined : _1.ReportTypeFromJSON(json['type']),
        'merchNbr': !runtime_1.exists(json, 'merchNbr') ? undefined : json['merchNbr'],
        'loadFileNumber': !runtime_1.exists(json, 'loadFileNumber') ? undefined : json['loadFileNumber'],
        'loadBatchNumber': !runtime_1.exists(json, 'loadBatchNumber') ? undefined : json['loadBatchNumber'],
        'loadEntryNumber': !runtime_1.exists(json, 'loadEntryNumber') ? undefined : json['loadEntryNumber'],
    };
}
exports.ReportRequestFromJSONTyped = ReportRequestFromJSONTyped;
function ReportRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'groups': value.groups,
        'categoryId': value.categoryId,
        'categoryValueId': value.categoryValueId,
        'period': value.period,
        'beginDate': value.beginDate,
        'endDate': value.endDate,
        'type': _1.ReportTypeToJSON(value.type),
        'merchNbr': value.merchNbr,
        'loadFileNumber': value.loadFileNumber,
        'loadBatchNumber': value.loadBatchNumber,
        'loadEntryNumber': value.loadEntryNumber,
    };
}
exports.ReportRequestToJSON = ReportRequestToJSON;

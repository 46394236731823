import React from "react";
import { SortOrder } from "../models/sort_order";
export const Caret = (props: { direction: SortOrder }) => {
  return (
    <span
      className={`sort-caret ${
        props.direction === SortOrder.ASC ? "asc" : "desc"
      }`}
      {...props}
    >
      {props.direction === SortOrder.ASC ? "▲" : "▼"}
    </span>
  );
};

Caret.Auto = <T extends Object = any>(props: {
  direction?: SortOrder;
  wantedColumn: T | undefined;
  currentColumn: T | undefined;
}) => {
  if (
    props.wantedColumn === props.currentColumn &&
    props.direction !== undefined
  ) {
    return (
      <span
        className={`sort-caret ${
          props.direction === SortOrder.ASC ? "asc" : "desc"
        }`}
        {...props}
      >
        {props.direction === SortOrder.ASC ? "▲" : "▼"}
      </span>
    );
  }
  return null;
};

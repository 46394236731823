export function create999Merchant() {
  const m = new Merchant();
  m.number = "9999999999";
  m.name = "*** ALL-LOCATIONS ***";
  return m;
}
export class Merchant {
  public accountNumber?: string = "";
  public accountNumber2?: string = "";
  public accountNumberC?: string = "";
  public accountNumberF?: string = "";
  public accountNumberM?: string = "";
  public accountType?: string = "";
  public address1?: string = "";
  public address2?: string = "";
  public businessLegalName?: string = "";
  public city?: string = "";
  public contractEntity?: string = "";
  public country?: string = "";
  public customerServicePhone?: string = "";
  public formattedCustomerServicePhone?: string = "";
  public formattedMainPhone?: string = "";
  public managementCompany?: string = "";
  public name: string = "";
  public number: string = "";
  public propertyPhone?: string = "";
  public region?: string = "";
  public routingTransitNumber?: string = "";
  public routingTransitNumber2?: string = "";
  public routingTransitNumberC?: string = "";
  public routingTransitNumberF?: string = "";
  public routingTransitNumberM?: string = "";
  public state?: string = "";
  public taxId?: string = "";
  public zip?: string = "";
}

export function addSuperMerchantNumber(merchants: Merchant[]): Merchant[] {
  const sortedMerchants = merchants.sort((a, b) => a.number.localeCompare(b.number))
  return [create999Merchant(), ...sortedMerchants];
}

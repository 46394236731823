import { useEffect, useState } from "react";
import { Division, DivisionOutlet } from "../models/division";
import { DivisionService } from "../services/division";
import useSuperSearchParametersState from "../store/super_search_parameters_state";

export function useDivision(
  divisionService: DivisionService,
  merchantNumber?: string
) {
  const [outlets, setOutlets] = useState<DivisionOutlet[]>([]);
  const [divisions, setDivisions] = useState<Division[]>([]);

  const formFillingInProgress: boolean = useSuperSearchParametersState((state) => state.formFillingInProgress);

  const {setFormFillingState} = useSuperSearchParametersState(
    (state) => ({setFormFillingState: state.setFormFillingState})
  )

  useEffect(() => {
    if (!merchantNumber) return;
    Promise.all([
      divisionService.getAllDivisions(merchantNumber),
      divisionService.getAllOutlets(merchantNumber)
    ]).then(values => {
      setDivisions(values[0]);
      setOutlets(values[1]);
      if (formFillingInProgress) setFormFillingState(false);
    })
    // eslint-disable-next-line
  }, [merchantNumber, divisionService]);

  return { divisions, outlets };
}

import React from "react";

export interface PaginationProps extends React.HTMLAttributes<HTMLDivElement> {
  currentOffset: number; // the current 0-index page value
  totalResults: number; // the current 1-index total page count
  currentItemsShown: number;
  totalItems?: number;
  onNext?(): void;
  onPrevious?(): void;
  onGoToBeginning?(): void;
  onGoToEnd?(): void;
  onShowAll?(): void;
  disabled?: boolean;
  hideShowAllButton?: boolean;
  pageSize?: number;
}
export function Pagination({
  currentOffset,
  totalResults,
  disabled,
  onNext,
  onPrevious,
  onGoToBeginning,
  onGoToEnd,
  onShowAll,
  currentItemsShown,
  hideShowAllButton = false,
  pageSize = 10,
  ...rest
}: PaginationProps) {
  const hasNext = currentOffset < totalResults - currentItemsShown;
  const hasPrevious = currentOffset > 0;
  const beginButton = (
    <span>
      {currentOffset > 0 && onGoToBeginning && hasPrevious && !disabled ? (
        <img
          data-testid="beginning"
          alt="Go to first page"
          onClick={!disabled ? onGoToBeginning : undefined}
          style={{ cursor: "pointer" }}
          src={`${process.env.PUBLIC_URL}/images/pagination/first.png`}
        />
      ) : (
        <img
          data-testid="beginning-disabled"
          alt="Go to first page"
          src={`${process.env.PUBLIC_URL}/images/pagination/first-disabled.png`}
        />
      )}
    </span>
  );
  const prevButton = (
    <span>
      {currentOffset > 0 && onPrevious && hasPrevious && !disabled ? (
        <img
          data-testid="previous"
          onClick={onPrevious}
          alt="Go to previous page"
          style={{ cursor: "pointer" }}
          src={`${process.env.PUBLIC_URL}/images/pagination/prev.png`}
        />
      ) : (
        <img
          alt="Go to previous page"
          data-testid="previous-disabled"
          src={`${process.env.PUBLIC_URL}/images/pagination/prev-disabled.png`}
        />
      )}
    </span>
  );

  const nextButton = (
    <span>
      {currentOffset < totalResults - 1 && onNext && hasNext && !disabled ? (
        <img
          data-testid="next"
          onClick={onNext}
          alt="Go to next page"
          style={{ cursor: "pointer" }}
          src={`${process.env.PUBLIC_URL}/images/pagination/next.png`}
        />
      ) : (
        <img
          data-testid="next-disabled"
          alt="Go to next page"
          src={`${process.env.PUBLIC_URL}/images/pagination/next-disabled.png`}
        />
      )}
    </span>
  );
  const endButton = (
    <span>
      {currentOffset < totalResults - 1 && onGoToEnd && hasNext && !disabled ? (
        <img
          data-testid="end"
          onClick={onGoToEnd}
          alt="Go to end"
          style={{ cursor: "pointer" }}
          src={`${process.env.PUBLIC_URL}/images/pagination/last.png`}
        />
      ) : (
        <img
          data-testid="end-disabled"
          alt="Go to end"
          src={`${process.env.PUBLIC_URL}/images/pagination/last-disabled.png`}
        />
      )}
    </span>
  );
  const allButton = onShowAll ? (
    <span data-testid="end">
      {pageSize < 500 && !disabled ? (
        <img
          onClick={onShowAll}
          alt="Show All"
          style={{ cursor: "pointer" }}
          src={`${process.env.PUBLIC_URL}/images/pagination/all.png`}
        />
      ) : (
        <img
          alt="Show All"
          src={`${process.env.PUBLIC_URL}/images/pagination/all-disabled.png`}
        />
      )}
    </span>
  ) : (
    <img
      alt="Show All"
      src={`${process.env.PUBLIC_URL}/images/pagination/all-disabled.png`}
    />
  );
  return (
    <div {...rest}>
      <div className="is-flex">
        <div>{beginButton}</div>
        <div>{prevButton}</div>
        <div>{nextButton}</div>
        <div>{endButton}</div>
        {!hideShowAllButton && (
          <div>{allButton}</div>
        )}
      </div>
    </div>
  );
}

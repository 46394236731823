"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionToJSON = exports.TransactionFromJSONTyped = exports.TransactionFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function TransactionFromJSON(json) {
    return TransactionFromJSONTyped(json, false);
}
exports.TransactionFromJSON = TransactionFromJSON;
function TransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'merchNbr': !runtime_1.exists(json, 'merchNbr') ? undefined : json['merchNbr'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'divisionDesc': !runtime_1.exists(json, 'divisionDesc') ? undefined : json['divisionDesc'],
        'mid': !runtime_1.exists(json, 'mid') ? undefined : json['mid'],
        'midName': !runtime_1.exists(json, 'midName') ? undefined : json['midName'],
        'processDate': !runtime_1.exists(json, 'processDate') ? undefined : json['processDate'],
        'billedDate': !runtime_1.exists(json, 'billedDate') ? undefined : json['billedDate'],
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : _1.CardTypesFromJSON(json['cardType']),
        'expDate': !runtime_1.exists(json, 'expDate') ? undefined : json['expDate'],
        'acctNumber': !runtime_1.exists(json, 'acctNumber') ? undefined : json['acctNumber'],
        'acctNumberRaw': !runtime_1.exists(json, 'acctNumberRaw') ? undefined : json['acctNumberRaw'],
        'posEntryMode': !runtime_1.exists(json, 'posEntryMode') ? undefined : json['posEntryMode'],
        'authCode': !runtime_1.exists(json, 'authCode') ? undefined : json['authCode'],
        'tranDate': !runtime_1.exists(json, 'tranDate') ? undefined : json['tranDate'],
        'tranType': !runtime_1.exists(json, 'tranType') ? undefined : _1.TransactionTypesFromJSON(json['tranType']),
        'tranAmount': !runtime_1.exists(json, 'tranAmount') ? undefined : json['tranAmount'],
        'tranStatus': !runtime_1.exists(json, 'tranStatus') ? undefined : _1.TransactionStatusFromJSON(json['tranStatus']),
        'messageReasonCode': !runtime_1.exists(json, 'messageReasonCode') ? undefined : json['messageReasonCode'],
        'disputeDescription': !runtime_1.exists(json, 'disputeDescription') ? undefined : json['disputeDescription'],
        'closedDescription': !runtime_1.exists(json, 'closedDescription') ? undefined : json['closedDescription'],
        'lifeCycleIndGroup': !runtime_1.exists(json, 'lifeCycleIndGroup') ? undefined : json['lifeCycleIndGroup'],
        'propertyCode': !runtime_1.exists(json, 'propertyCode') ? undefined : json['propertyCode'],
        'posEntryModeDesc': !runtime_1.exists(json, 'posEntryModeDesc') ? undefined : json['posEntryModeDesc'],
        'assetOwner': !runtime_1.exists(json, 'assetOwner') ? undefined : json['assetOwner'],
        'businessLegalName': !runtime_1.exists(json, 'businessLegalName') ? undefined : json['businessLegalName'],
        'loadFileNumber': !runtime_1.exists(json, 'loadFileNumber') ? undefined : json['loadFileNumber'],
        'loadBatchNumber': !runtime_1.exists(json, 'loadBatchNumber') ? undefined : json['loadBatchNumber'],
        'loadEntryNumber': !runtime_1.exists(json, 'loadEntryNumber') ? undefined : json['loadEntryNumber'],
    };
}
exports.TransactionFromJSONTyped = TransactionFromJSONTyped;
function TransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'merchNbr': value.merchNbr,
        'merchantName': value.merchantName,
        'divisionDesc': value.divisionDesc,
        'mid': value.mid,
        'midName': value.midName,
        'processDate': value.processDate,
        'billedDate': value.billedDate,
        'cardType': _1.CardTypesToJSON(value.cardType),
        'expDate': value.expDate,
        'acctNumber': value.acctNumber,
        'acctNumberRaw': value.acctNumberRaw,
        'posEntryMode': value.posEntryMode,
        'authCode': value.authCode,
        'tranDate': value.tranDate,
        'tranType': _1.TransactionTypesToJSON(value.tranType),
        'tranAmount': value.tranAmount,
        'tranStatus': _1.TransactionStatusToJSON(value.tranStatus),
        'messageReasonCode': value.messageReasonCode,
        'disputeDescription': value.disputeDescription,
        'closedDescription': value.closedDescription,
        'lifeCycleIndGroup': value.lifeCycleIndGroup,
        'propertyCode': value.propertyCode,
        'posEntryModeDesc': value.posEntryModeDesc,
        'assetOwner': value.assetOwner,
        'businessLegalName': value.businessLegalName,
        'loadFileNumber': value.loadFileNumber,
        'loadBatchNumber': value.loadBatchNumber,
        'loadEntryNumber': value.loadEntryNumber,
    };
}
exports.TransactionToJSON = TransactionToJSON;

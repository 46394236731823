"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthToJSON = exports.AuthFromJSONTyped = exports.AuthFromJSON = void 0;
var runtime_1 = require("../runtime");
function AuthFromJSON(json) {
    return AuthFromJSONTyped(json, false);
}
exports.AuthFromJSON = AuthFromJSON;
function AuthFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumber': !runtime_1.exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'authDate': !runtime_1.exists(json, 'authDate') ? undefined : (new Date(json['authDate'])),
        'authTime': !runtime_1.exists(json, 'authTime') ? undefined : (new Date(json['authTime'])),
        'authAmount': !runtime_1.exists(json, 'authAmount') ? undefined : json['authAmount'],
        'authSource': !runtime_1.exists(json, 'authSource') ? undefined : json['authSource'],
        'authResponse': !runtime_1.exists(json, 'authResponse') ? undefined : json['authResponse'],
        'authCode': !runtime_1.exists(json, 'authCode') ? undefined : json['authCode'],
        'bankNetNumber': !runtime_1.exists(json, 'bankNetNumber') ? undefined : json['bankNetNumber'],
        'expDate': !runtime_1.exists(json, 'expDate') ? undefined : json['expDate'],
        'authType': !runtime_1.exists(json, 'authType') ? undefined : json['authType'],
        'avs': !runtime_1.exists(json, 'avs') ? undefined : json['avs'],
        'cvv': !runtime_1.exists(json, 'cvv') ? undefined : json['cvv'],
        'posEntryMode': !runtime_1.exists(json, 'posEntryMode') ? undefined : json['posEntryMode'],
        'formattedCardNumber': !runtime_1.exists(json, 'formattedCardNumber') ? undefined : json['formattedCardNumber'],
        'formattedAuthDate': !runtime_1.exists(json, 'formattedAuthDate') ? undefined : json['formattedAuthDate'],
        'formattedAuthTime': !runtime_1.exists(json, 'formattedAuthTime') ? undefined : json['formattedAuthTime'],
        'formattedAuthAmount': !runtime_1.exists(json, 'formattedAuthAmount') ? undefined : json['formattedAuthAmount'],
        'formattedExpDate': !runtime_1.exists(json, 'formattedExpDate') ? undefined : json['formattedExpDate'],
        'formattedCardType': !runtime_1.exists(json, 'formattedCardType') ? undefined : json['formattedCardType'],
        'cardNumberMasked': !runtime_1.exists(json, 'cardNumberMasked') ? undefined : json['cardNumberMasked'],
    };
}
exports.AuthFromJSONTyped = AuthFromJSONTyped;
function AuthToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cardType': value.cardType,
        'cardNumber': value.cardNumber,
        'authDate': value.authDate === undefined ? undefined : (value.authDate.toISOString()),
        'authTime': value.authTime === undefined ? undefined : (value.authTime.toISOString()),
        'authAmount': value.authAmount,
        'authSource': value.authSource,
        'authResponse': value.authResponse,
        'authCode': value.authCode,
        'bankNetNumber': value.bankNetNumber,
        'expDate': value.expDate,
        'authType': value.authType,
        'avs': value.avs,
        'cvv': value.cvv,
        'posEntryMode': value.posEntryMode,
        'formattedCardNumber': value.formattedCardNumber,
        'formattedAuthDate': value.formattedAuthDate,
        'formattedAuthTime': value.formattedAuthTime,
        'formattedAuthAmount': value.formattedAuthAmount,
        'formattedExpDate': value.formattedExpDate,
        'formattedCardType': value.formattedCardType,
        'cardNumberMasked': value.cardNumberMasked,
    };
}
exports.AuthToJSON = AuthToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionResponseToJSON = exports.TransactionResponseFromJSONTyped = exports.TransactionResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function TransactionResponseFromJSON(json) {
    return TransactionResponseFromJSONTyped(json, false);
}
exports.TransactionResponseFromJSON = TransactionResponseFromJSON;
function TransactionResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalResults': json['totalResults'],
        'transactions': !runtime_1.exists(json, 'transactions') ? undefined : (json['transactions'].map(_1.TransactionFromJSON)),
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    };
}
exports.TransactionResponseFromJSONTyped = TransactionResponseFromJSONTyped;
function TransactionResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalResults': value.totalResults,
        'transactions': value.transactions === undefined ? undefined : (value.transactions.map(_1.TransactionToJSON)),
        'offset': value.offset,
    };
}
exports.TransactionResponseToJSON = TransactionResponseToJSON;

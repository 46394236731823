import { useEffect, useState } from "react";
import { ReportCategory } from "../models/report";
import { ReportService } from "../services/report";

export function useReportCategory(reportService: ReportService) {
  const [reportCategories, setReportCategories] = useState<ReportCategory[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    reportService
      .getReportCategories()
      .then(setReportCategories)
      .finally(() => setIsLoading(false));
  }, [reportService]);

  return {
    isLoading,
    reportCategories,
  };
}

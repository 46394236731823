import { useEffect, useMemo, useState } from "react";
import { NoResults } from "../../../components/no_results";
import { PageTitle } from "../../../components/page_title";
import { TableTitle } from "../../../components/table_title";
import { TransactionSearchPanel, TransactionSearchPanelParameters } from "../../../components/transaction_search_panel";
import { useClosedChargebacks } from "../../../hooks/use_closed_chargebacks";
import { useDefaultMerchant } from "../../../hooks/use_default_merchant";
import { useMerchants } from "../../../hooks/use_merchant";
import { SearchPage } from "../../../layout/search_page";
import { CardType } from "../../../models/card_type";
import { create999Merchant } from "../../../models/merchant";
import { SortOrder } from "../../../models/sort_order";
import { TransactionType } from "../../../models/transaction";
import {
  ChargebackClosedFilter,
  ClosedChargebackSortColumn,
  DisputeService,
} from "../../../services/disputes/dispute_service";
import { downloadExcelFile } from "../../../services/download";
import { MerchantService } from "../../../services/merchant";
import { DisputeClosedTable } from "./dispute_closed_table";
import { DisputeClosedRfcsTable } from "./dispute_closed_rfcs_table";

export interface DisputeClosedContainerProps {
  merchantService: MerchantService;
  disputeService: DisputeService;
}

export function DisputeClosedContainer({
  merchantService,
  disputeService,
}: DisputeClosedContainerProps) {
  const { isLoading: isLoadingMerchants, merchants } =
    useMerchants(merchantService);
  const defaultStartDate = useMemo(() => {
    return new Date(new Date().setMonth(new Date().getMonth() - 1));
  }, []);
  const defaultEndDate = useMemo(() => new Date(), []);
  const [savedFilter, setSavedFilter] =
    useState<Omit<ChargebackClosedFilter, "pageSize">>();
  const { selectedMerchant: defaultMerchant, updateSelectedMerchant } =
    useDefaultMerchant();
  const [selectedMerchant, setSelectedMerchant] = useState<string | undefined>(
    defaultMerchant
  );
  const [loadingAll, setLoadingAll] = useState<boolean>(false);

  const computedFilter = useMemo<
    Omit<ChargebackClosedFilter, "pageSize"> | undefined
  >(() => {
    if (!savedFilter && !defaultMerchant) {
      return;
    }
    const filter: Omit<ChargebackClosedFilter, "pageSize"> = {
      amex: false,
      jcb: false,
      discover: false,
      visa: true,
      mastercard: true,
      endDate: defaultEndDate,
      startDate: defaultStartDate,
      merchantNbr: defaultMerchant ?? "",
      ...(savedFilter as Partial<ChargebackClosedFilter>),
    };
    return filter;
    // eslint-disable-next-line
  }, [
    savedFilter,
    defaultEndDate,
    defaultStartDate,
    selectedMerchant,
    defaultMerchant,
  ]);
  useEffect(() => {
    setSelectedMerchant(defaultMerchant);
  }, [defaultMerchant]);
  const {
    closedDisputes = [],
    rfcs = [],
    totalChargeback,
    isLoading,
    pagination,
    handleSort,
    sorting,
    totalRfc,
  } = useClosedChargebacks(disputeService, computedFilter);
  setTimeout(() => {
    if (loadingAll) {
      setLoadingAll(false)
    }
  }, 2000);
  const searchLoading = isLoading || isLoadingMerchants || loadingAll;
  const results = () => {
    if (isLoading || loadingAll) {
      return null;
    }
    return (
      <><div className="search-results-container">
        <TableTitle
          title="Closed Chargebacks"
          totalResults={totalChargeback}
          currentResultCount={closedDisputes?.length}
          offset={pagination.chargebackPagination.offset}
          onPageFirst={pagination.chargebackPagination.firstPage}
          onPageLast={pagination.chargebackPagination.lastPage}
          onPageNext={pagination.chargebackPagination.nextPage}
          onPagePrevious={pagination.chargebackPagination.previousPage}
          pageSize={pagination.chargebackPagination.pageSize}
          onExport={async () => {
            const exportFilter = savedFilter ? savedFilter : computedFilter;
            if (!exportFilter)
              return;
            const response = await disputeService.exportClosedChargebacks(
              exportFilter
            );
            downloadExcelFile(response[0], response[1]);
          }} />
        <DisputeClosedTable
          sortDirection={sorting?.direction ?? SortOrder.ASC}
          sortColumn={sorting?.field ?? ClosedChargebackSortColumn.closedDate}
          onSort={handleSort}
          chargebacks={closedDisputes.map((c) => {
            return {
              cardNumber: c.maskedAcctNumber,
              closedDate: c.dateClosed,
              disputeAmount: c.tranAmount,
              closedReason: c.closedDescription,
              disputeReason: c.disputeDescription,
              merchantName: c.merchantName ?? "",
              merchantNumber: c.merchantNbr ?? "",
              transactionDate: c.tranDate,
              cbrfTranDate: c.cbrfTranDate,
            };
          })} />
        {closedDisputes.length === 0 && (
          <NoResults.Table text="No Transactions match your search criteria" />
        )}
      </div><div className="search-results-container">
          <TableTitle
            title="Closed RFCS"
            paginationDisabled={searchLoading}
            totalResults={totalRfc}
            currentResultCount={rfcs.length}
            offset={pagination.rfcPagination.page}
            onPageFirst={pagination.rfcPagination.firstPage}
            onPageLast={pagination.rfcPagination.lastPage}
            onPageNext={pagination.rfcPagination.nextPage}
            onPagePrevious={pagination.rfcPagination.previousPage}
            pageSize={pagination.rfcPagination.pageSize}
            onExport={async () => {
              if (!savedFilter)
                return;
              const response = await disputeService.exportClosedChargebacksRfc(
                savedFilter
              );
              downloadExcelFile(response[0], response[1]);
            }} />
          <DisputeClosedRfcsTable
            sortColumn={sorting?.field ?? ClosedChargebackSortColumn.closedDate}
            sortDirection={sorting?.direction ?? SortOrder.ASC}
            onSort={handleSort}
            chargebacks={rfcs?.map?.((c) => {
              return {
                cardNumber: c.maskedAcctNumber,
                closedDate: c.dateClosed,
                disputeAmount: c.tranAmount,
                closedReason: c.closedDescription,
                disputeReason: c.disputeDescription,
                merchantName: c.merchantName ?? "",
                merchantNumber: c.merchantNbr ?? "",
                transactionDate: c.tranDate,
              };
            }) ?? []} />
          {rfcs.length === 0 && (
            <NoResults.Table text="No Transactions match your search criteria" />
          )}
        </div></>
    );
  };

  function getSortedMerchants() {
    const sortedMerchants = merchants.sort((a, b) => a.number.localeCompare(b.number))
    return [create999Merchant(), ...sortedMerchants]
  }

  const triggerSearchRequest = (searchParameters: TransactionSearchPanelParameters) => {
    setSavedFilter({
      pageOffsetChargebacks: pagination.chargebackPagination.offset,
      amex:
        searchParameters.cardTypes?.includes(CardType.americanExpress) ?? false,
      jcb: searchParameters.cardTypes?.includes(CardType.jcb) ?? false,
      discover: searchParameters.cardTypes?.includes(CardType.discover) ?? false,
      visa: searchParameters.cardTypes?.includes(CardType.visa) ?? false,
      mastercard:
        searchParameters.cardTypes?.includes(CardType.mastercard) ?? false,
      endDate: searchParameters.endDate,
      startDate: searchParameters.beginDate,
      merchantNbr: searchParameters.merchantNumber ?? "",
    });
  }

  const search = (searchParameters: TransactionSearchPanelParameters) => {
    pagination.chargebackPagination.setInitialPaginationOffset(0);
    pagination.rfcPagination.setInitialPaginationOffset(0);
    triggerSearchRequest(searchParameters);
  }

  return (
    <div className="disputeClosed-wrapper">
      <div className="title-bar">
        <PageTitle title="Chargeback/RFC - Closed" />
      </div>
      <SearchPage
        isLoading={isLoading || loadingAll}
        title="Chargeback/RFC - Closed"
        searchPanel={
          <TransactionSearchPanel
            merchantRequired={true}
            merchants={getSortedMerchants()}
            currentMerchant={merchants.find((m) => m.number === selectedMerchant)}
            requiresCardNumberForDatesGreaterThan31DaysApart={false}
            defaultMerchantId={defaultMerchant}
            onMerchantChange={(merchant) => {
              setSelectedMerchant(merchant.number);
              updateSelectedMerchant(merchant.number);
            }}
            defaultBeginDate={defaultStartDate}
            defaultEndDate={defaultEndDate}
            onSearch={search}
            disabledFields={[
              "amount",
              "amountOperator",
              "cardNumber",
              "transactionTypes",
              "divisionName",
              "outletName",
            ]}
            cardTypes={[
              {
                cardName: "AX",
                cardValue: CardType.americanExpress,
                tabIndex: 8
              },
              { cardName: "DR", cardValue: CardType.discover, tabIndex: 9 },
              { cardName: "JCB", cardValue: CardType.jcb, tabIndex: 10 },
              {
                cardName: "MC",
                isCheckedByDefault: true,
                cardValue: CardType.mastercard,
                tabIndex: 11
              },
              {
                cardName: "VS",
                isCheckedByDefault: true,
                cardValue: CardType.visa,
                tabIndex: 12
              },
            ]}
            transactionTypes={[
              { txType: TransactionType.AuthOnly, isDisabled: true, tabIndex: 13 },
              {
                txType: TransactionType.SalesCredits,
                isDisabled: true,
                tabIndex: 14
              },
              {
                txType: TransactionType.CBRev,
                isCheckedByDefault: true,
                isDisabled: true,
                tabIndex: 15
              },
              {
                txType: TransactionType.RFC,
                isCheckedByDefault: true,
                isDisabled: true,
                tabIndex: 16
              },
              {
                txType: TransactionType.CBLifeCycle,
                isDisabled: true,
                tabIndex: 17
              },
            ]}
          />
        }
        searchResults={results()}
      />
    </div>
  );
}

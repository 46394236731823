import React, { useEffect, useMemo, useState } from "react";
import { NoResults } from "../../components/no_results";
import { PageTitle } from "../../components/page_title";
import { TableTitle } from "../../components/table_title";
import { TransactionSearchPanel, TransactionSearchPanelParameters } from "../../components/transaction_search_panel";
import {
  DrilldownFields,
  RequiredDepositDetailParameters,
  useDepositDetails,
} from "../../hooks/use_deposit_details";
import { useDivision } from "../../hooks/use_division";
import { useMerchants } from "../../hooks/use_merchant";
import { useSelectedMerchant } from "../../hooks/use_selected_merchant";
import { SearchPage } from "../../layout/search_page";
import { CardType } from "../../models/card_type";
import { DepositDetail } from "../../models/deposit_detail";
import { addSuperMerchantNumber, Merchant } from "../../models/merchant";
import { SalesCredit } from "../../models/sales_credit";
import { TransactionType } from "../../models/transaction";
import { DivisionService } from "../../services/division";
import { downloadExcelFile } from "../../services/download";
import { MerchantService } from "../../services/merchant";
import {
  TransmittedActivity,
  TransmittedActivitySearchParameters,
  TransmittedService,
  TransmittedTable,
} from "../../services/transmitted";
import { DepositDetailTable } from "./components/deposit_detail_table";
import { isEmpty as lodashIsEmpty } from "lodash";
import { getFormattedTransactionAmount } from "../../utils";

export interface DepositDetailsOcDefaultSearchParameters {
  tranAmount?: string;
  merchantId?: string;
  loadFileNumber?: string;
  loadBatchNumber?: string;
  loadEntryNumber?: string;
  date?: Date;
  transactionAmount?: string;
  authCode?: string;
  cardNumber?: string;
  cardType?: CardType;
  transactionType?: string;
  outletNumber?: string;
}
export interface DepositDetailsOCRouteProps {
  merchantService: MerchantService;
  divisionService: DivisionService;
  outletService: MerchantService;
  transmittedService: TransmittedService;
  defaultSearchParameters?: DepositDetailsOcDefaultSearchParameters;
}
type Tables = keyof Pick<
  TransmittedActivitySearchParameters,
  | "pageOffsetMerchantNumber"
  | "pageOffsetBatch"
  | "pageOffsetDate"
  | "pageOffsetCardType"
  | "pageOffsetOutletNumber"
  | "pageOffsetTrans"
  | "pageOffsetSummary"
>;

type TablePages = Record<Tables, number>;
export function DepositDetailsOCContainer({
  merchantService,
  transmittedService,
  defaultSearchParameters,
  divisionService,
}: DepositDetailsOCRouteProps) {
  const { merchants } = useMerchants(merchantService);
  const { selectedMerchant, updateSelectedMerchant } = useSelectedMerchant();
  const [currentMerchant, setCurrentMerchant] = useState<
    Merchant | undefined
  >();

  const [parameters, setParameters] =
    React.useState<
      Omit<
        RequiredDepositDetailParameters,
        | "filterDate"
      >
    >();
  const {
    transmittedActivity,
    isLoading,
    pagination,
    drilldownSelections,
    requestParameters,
    clearPagination,
    clearDrilldown,
    setDrilldownSelections,
  } = useDepositDetails(transmittedService, parameters);
  const defaultBeginDate = useMemo(
    function () {
      if (defaultSearchParameters?.date) {
        return defaultSearchParameters.date;
      }
      const d = new Date();
      d.setMonth(d.getMonth() - 1);
      return d;
    },
    [defaultSearchParameters?.date]
  );
  const defaultEndDate = useMemo(
    () => defaultSearchParameters?.date ?? new Date(),
    [defaultSearchParameters?.date]
  );
  const previousMerchantId = React.useRef<string | undefined>();
  useEffect(() => {
    if (
      selectedMerchant !== previousMerchantId.current &&
      merchants?.length > 0
    ) {
      previousMerchantId.current = selectedMerchant;
      setCurrentMerchant(merchants.find((m) => m.number === selectedMerchant));
    }
  }, [selectedMerchant, merchants]);
  useEffect(() => {
    clearPagination();
    clearDrilldown();
  }, [selectedMerchant, clearPagination, clearDrilldown]);
  const canSearch = useMemo(
    () =>
      selectedMerchant ||
      (defaultSearchParameters?.date &&
        defaultSearchParameters?.merchantId &&
        defaultSearchParameters?.cardType),
    [
      selectedMerchant,
      defaultSearchParameters?.date,
      defaultSearchParameters?.merchantId,
      defaultSearchParameters?.cardType,
    ]
  );

  useEffect(() => {
    if (canSearch && !parameters) {
      setParameters({
        beginDate: defaultBeginDate,
        endDate: defaultEndDate,
        cardTypes: [
          CardType.americanExpress,
          CardType.discover,
          CardType.mastercard,
          CardType.visa,
          CardType.jcb,
        ],
        filterCardType: defaultSearchParameters?.cardType,
        filterCardType2: defaultSearchParameters?.cardType,
        merchantNumber:
          defaultSearchParameters?.merchantId! ?? selectedMerchant,
        cardNumber: defaultSearchParameters?.cardNumber,
        loadFileNumber: defaultSearchParameters?.loadFileNumber,
        loadBatchNumber: defaultSearchParameters?.loadBatchNumber,
        loadEntryNumber: defaultSearchParameters?.loadEntryNumber,
        transactionAmount:
          getFormattedTransactionAmount(defaultSearchParameters?.transactionAmount, defaultSearchParameters?.transactionType),
        authCode: defaultSearchParameters?.authCode,
        filterMerchantNumber: defaultSearchParameters?.merchantId,
        filterOutletNumber: defaultSearchParameters?.outletNumber,
      });
    }
  }, [
    selectedMerchant,
    defaultSearchParameters?.merchantId,
    defaultSearchParameters?.loadBatchNumber,
    defaultSearchParameters?.loadFileNumber,
    defaultSearchParameters?.loadEntryNumber,
    defaultSearchParameters?.transactionAmount,
    defaultSearchParameters?.transactionType,
    defaultSearchParameters?.authCode,
    defaultSearchParameters?.cardType,
    defaultSearchParameters?.cardNumber,
    defaultSearchParameters?.outletNumber,
    parameters,
    canSearch,
    defaultBeginDate,
    defaultEndDate,
  ]);
  const getPaginationObject = (table: Tables) => {
    switch (table) {
      case "pageOffsetBatch":
        return pagination.batchPagination;
      case "pageOffsetCardType":
        return pagination.cardPagination;
      case "pageOffsetDate":
        return pagination.datePagination;
      case "pageOffsetMerchantNumber":
        return pagination.merchantPagination;
      case "pageOffsetOutletNumber":
        return pagination.outletPagination;
      case "pageOffsetTrans":
        return pagination.transactionPagination;
      case "pageOffsetSummary":
        return pagination.summaryPagination;
      default:
        throw Error("Unknown table");
    }
  };
  const { divisions, outlets } = useDivision(
    divisionService,
    selectedMerchant ?? currentMerchant?.number
  );
  const results = () => {
    if (isLoading) {
      return null;
    }
    if (transmittedActivity?.isEmpty) {
      return <NoResults />;
    }

    const isDrilldownActive = !lodashIsEmpty(drilldownSelections);

    const selectedBatch = transmittedActivity?.batchDetails?.items?.find(
      (d) =>
        d.batchNumber ===
        (isDrilldownActive ? drilldownSelections?.filterBatchNumber?.batchNumber : parameters?.filterBatchNumber)
    );

    const selectedCardType = transmittedActivity?.cardTypeDetails?.items?.find(
      (d) => d.cardType === (isDrilldownActive ? drilldownSelections?.filterCardType?.cardType : parameters?.filterCardType)
    );

    const selectedDate = transmittedActivity?.dateDetails?.items?.find(
      (d) =>
        d.reportDate?.valueOf() ===
        drilldownSelections?.filterDate?.reportDate?.valueOf()
    );

    const selectedMerchantNumber = transmittedActivity?.merchantDetails?.items?.find(
      (d) =>
        d.merchantNumber ===
        (isDrilldownActive ? drilldownSelections?.filterMerchantNumber?.merchantNumber : parameters?.filterMerchantNumber)
    );

    const selectedOutletNumber = transmittedActivity?.outletDetails?.items?.find(
      (d) =>
        d.outletNumber ===
        (isDrilldownActive ? drilldownSelections?.filterOutletNumber?.outletNumber : parameters?.filterOutletNumber)
    );

    return (
      <TransmittedActivityTables
        highlightedItems={{
          pageOffsetBatch: selectedBatch,
          pageOffsetCardType: selectedCardType,
          pageOffsetDate: selectedDate,
          pageOffsetMerchantNumber: selectedMerchantNumber,
          pageOffsetOutletNumber: selectedOutletNumber,
          pageOffsetTrans: {
            ...new DepositDetail(),
            ...new SalesCredit(),
            fullCardTypeName: "",
            ...(transmittedActivity?.transactionDetails?.items?.find(
              (d) => d.transactionAmount === parameters?.transactionAmount
            ) ?? {}),
          },
        }}
        onExport={async (table) => {
          if (!parameters) return;

          const isSummaryTable = table === TransmittedTable.SUMMARY;
          const isMerchantTable = table === TransmittedTable.MERCHANT;

          const compiledParameters = {
            ...requestParameters,
            beginDate: parameters.beginDate,
            endDate: parameters.endDate,
            cardTypes: parameters.cardTypes,
            divisionName: parameters.divisionName,
            merchantNumber: (isSummaryTable || isMerchantTable) ?
              parameters.merchantNumber :
              selectedMerchantNumber ?
                (selectedMerchantNumber.merchantNumber ?? '') :
                (transmittedActivity?.merchantDetails?.items[0].merchantNumber ?? parameters.merchantNumber),
            outletName: parameters.outletName,
          }

          if (table === TransmittedTable.CARD_TYPE ||
            table === TransmittedTable.OUTLET ||
            table === TransmittedTable.BATCH ||
            table === TransmittedTable.TRANSACTION) {
            const dateDetailTemp = transmittedActivity?.dateDetails?.items?.sort((a, b) => {
              if (!a.reportDate || !b.reportDate) return -1;
              return new Date(a.reportDate).getTime() - new Date(b.reportDate).getTime()
            }).reverse();

            const dateArrayLength = dateDetailTemp?.length;
            if (!dateDetailTemp ||
              !dateArrayLength ||
              dateArrayLength === 0 ||
              !dateDetailTemp[0].reportDate ||
              !dateDetailTemp[dateArrayLength - 1].reportDate) return;

            const endDate = dateDetailTemp[0].reportDate;
            const beginDate = dateDetailTemp[dateArrayLength - 1].reportDate;

            if (!endDate || !beginDate) return;

            compiledParameters.endDate = endDate;
            compiledParameters.beginDate = beginDate;
          }

          const [data, filename] =
            await transmittedService.getTransmittedReport(
              compiledParameters,
              table
            );
          downloadExcelFile(data, filename);
        }}
        currentPages={{
          pageOffsetBatch: getPaginationObject("pageOffsetBatch").offset,
          pageOffsetCardType: getPaginationObject("pageOffsetCardType").offset,
          pageOffsetSummary: getPaginationObject("pageOffsetSummary").offset,
          pageOffsetDate: getPaginationObject("pageOffsetDate").offset,
          pageOffsetMerchantNumber: getPaginationObject(
            "pageOffsetMerchantNumber"
          ).offset,
          pageOffsetOutletNumber: getPaginationObject("pageOffsetOutletNumber")
            .offset,
          pageOffsetTrans: getPaginationObject("pageOffsetTrans").offset,
        }}
        isLoading={isLoading}
        onFirstPage={(page) => {
          getPaginationObject(page).firstPage();
        }}
        onLastPage={(page) => {
          getPaginationObject(page)?.lastPage?.();
        }}
        onPreviousPage={(page) => {
          getPaginationObject(page).previousPage();
        }}
        onNextPage={(page) => {
          getPaginationObject(page).nextPage();
        }}
        activity={transmittedActivity}
        onSelectDrilldown={setDrilldownSelections}
        pageSize={(page) => getPaginationObject(page)?.pageSize}
      />
    );
  };

  const triggerSearchRequest = (searchParameters: TransactionSearchPanelParameters) => {
    setParameters({
      cardNumber: undefined,
      beginDate: searchParameters.beginDate,
      endDate: searchParameters.endDate,
      cardTypes: searchParameters.cardTypes ?? [],
      divisionName: searchParameters.divisionNumber,
      merchantNumber: searchParameters.merchantNumber,
      outletName: searchParameters.outletName,
    });
  }

  const search = (searchParameters: TransactionSearchPanelParameters) => {
    clearPagination();
    clearDrilldown();
    triggerSearchRequest(searchParameters);
  }

  return (
    <div className="depositActivity-wrapper">
      <div className="title-bar">
        <PageTitle title="Transmitted Activity" />
      </div>
      <SearchPage
        title="Transmitted Activity"
        isLoading={isLoading}
        searchPanel={
          <TransactionSearchPanel
            divisions={divisions}
            outlets={outlets}
            requiresCardNumberForDatesGreaterThan31DaysApart={false}
            isLoading={isLoading}
            merchantRequired={true}
            onSearch={search}
            disabledFields={["cardNumber", "amount", "amountOperator"]}
            defaultMerchantId={selectedMerchant}
            merchants={addSuperMerchantNumber(merchants)}
            onMerchantChange={(e) => {
              setCurrentMerchant(e);
              updateSelectedMerchant(e.number);
              triggerSearchRequest({
                beginDate: defaultBeginDate,
                endDate: defaultEndDate,
                cardTypes: [
                  CardType.americanExpress,
                  CardType.discover,
                  CardType.mastercard,
                  CardType.visa,
                  CardType.jcb,
                ],
                merchantNumber: e.number ?? "9999999999",
                merchantName: '',
              })
            }}
            currentMerchant={currentMerchant}
            loadingComponent={null}
            transactionTypes={[
              { txType: TransactionType.AuthOnly, isDisabled: true, tabIndex: 13 },
              {
                txType: TransactionType.SalesCredits,
                isDisabled: true,
                isCheckedByDefault: true,
                tabIndex: 14
              },
              { txType: TransactionType.CBRev, isDisabled: true, tabIndex: 15 },
              { txType: TransactionType.RFC, isDisabled: true, tabIndex: 16 },
              {
                txType: TransactionType.CBLifeCycle,
                mustBeIsolated: true,
                requiresMerchant: true,
                isDisabled: true,
                tabIndex: 17
              },
            ]}
            cardTypes={[
              {
                cardName: "AX",
                cardValue: CardType.americanExpress,
                isCheckedByDefault: true,
                tabIndex: 8
              },
              {
                cardName: "DR",
                cardValue: CardType.discover,
                isCheckedByDefault: true,
                tabIndex: 9
              },
              {
                cardName: "JCB",
                cardValue: CardType.jcb,
                isCheckedByDefault: true,
                tabIndex: 10
              },
              {
                cardName: "MC",
                isCheckedByDefault: true,
                cardValue: CardType.mastercard,
                tabIndex: 11
              },
              {
                cardName: "VS",
                isCheckedByDefault: true,
                cardValue: CardType.visa,
                tabIndex: 12
              },
            ]}
            defaultBeginDate={defaultBeginDate}
            defaultEndDate={defaultEndDate}
            defaultOutletNumber={defaultSearchParameters?.outletNumber}
            onReset={() => triggerSearchRequest({
              beginDate: defaultBeginDate,
              endDate: defaultEndDate,
              cardTypes: [
                CardType.americanExpress,
                CardType.discover,
                CardType.mastercard,
                CardType.visa,
                CardType.jcb,
              ],
              merchantNumber: currentMerchant?.number ?? "9999999999",
              merchantName: '',
            })}
          />
        }
        searchResults={results()}
      ></SearchPage>
    </div>
  );
}

type SelectDrilldownCallback = <T extends keyof DrilldownFields>(
  field: T,
  value: Partial<DepositDetail>
) => void;

const TransmittedActivityTables = ({
  activity,
  onSelectDrilldown,
  currentPages,
  onExport,
  onNextPage,
  onPreviousPage,
  onFirstPage,
  onLastPage,
  isLoading,
  highlightedItems,
  pageSize,
}: {
  highlightedItems: Partial<
    Record<Tables, (DepositDetail & SalesCredit) | undefined>
  >;
  activity?: TransmittedActivity;
  onSelectDrilldown: SelectDrilldownCallback;
  currentPages: TablePages;
  onExport(table: TransmittedTable): void;
  onFirstPage(page: Tables): void;
  onLastPage(page: Tables): void;
  onNextPage(page: Tables): void;
  onPreviousPage(page: Tables): void;
  isLoading: boolean;
  pageSize(page: Tables): number;
}) => {
  return (
    <div>
      {typeof activity?.summaryDetails?.items.length === "number" &&
        activity.summaryDetails.items.length > 0 && (
          <div className="search-results-container">
            <TableTitle
              title="Transmitted - All Locations Summary"
              offset={currentPages.pageOffsetSummary}
              totalResults={activity?.summaryDetails?.totalResults ?? 0}
              onExport={() => onExport(TransmittedTable.SUMMARY)}
              currentResultCount={activity?.summaryDetails?.items.length ?? 0}
              onPageNext={() => onNextPage("pageOffsetSummary")}
              onPageLast={() => onLastPage("pageOffsetMerchantNumber")}
              onPagePrevious={() => onPreviousPage("pageOffsetMerchantNumber")}
              onPageFirst={() => onFirstPage("pageOffsetMerchantNumber")}
              hideShowAllButton
              pageSize={pageSize("pageOffsetSummary")}
            />
            <DepositDetailTable
              hightlightedItem={
                highlightedItems.pageOffsetSummary ??
                activity?.summaryDetails?.items[0]
              }
              isLoading={isLoading}
              includedColumns={[
                "emptyDetail",
                "emptyReportDate",
                "merchantNumber",
                "merchant name",
                "salesTxCount",
                "salesTxVolume",
                "creditsTxCount",
                "creditsTxVolume",
                "netSalesTxCount",
                "netSalesTxVolume",
              ]}
              deposits={activity?.summaryDetails?.items}
            />
          </div>
        )}
      <div className="search-results-container">
        <TableTitle
          title="Transmitted - Merchant Detail"
          offset={currentPages.pageOffsetMerchantNumber}
          totalResults={activity?.merchantDetails?.totalResults ?? 0}
          onExport={() => onExport(TransmittedTable.MERCHANT)}
          currentResultCount={activity?.merchantDetails?.items.length ?? 0}
          onPageNext={() => onNextPage("pageOffsetMerchantNumber")}
          onPageLast={() => onLastPage("pageOffsetMerchantNumber")}
          onPagePrevious={() => onPreviousPage("pageOffsetMerchantNumber")}
          onPageFirst={() => onFirstPage("pageOffsetMerchantNumber")}
          hideShowAllButton
          pageSize={pageSize("pageOffsetMerchantNumber")}
        />
        <DepositDetailTable
          detailTooltip="Show detail for this merchant"
          hightlightedItem={
            highlightedItems.pageOffsetMerchantNumber ??
            activity?.merchantDetails?.items[0]
          }
          isLoading={isLoading}
          onDrilldown={(detail) =>
            onSelectDrilldown("filterMerchantNumber", detail)
          }
          includedColumns={[
            "detail",
            "emptyReportDate",
            "merchantNumber",
            "merchant name",
            "salesTxCount",
            "salesTxVolume",
            "creditsTxCount",
            "creditsTxVolume",
            "netSalesTxCount",
            "netSalesTxVolume",
          ]}
          deposits={activity?.merchantDetails?.items}
        />
      </div>
      <div className="search-results-container">
        <TableTitle
          title="Transmitted - Date Detail"
          offset={currentPages.pageOffsetDate}
          totalResults={activity?.dateDetails?.totalResults ?? 0}
          onExport={() => onExport(TransmittedTable.DATE)}
          currentResultCount={activity?.dateDetails?.items.length ?? 0}
          onPageNext={() => onNextPage("pageOffsetDate")}
          onPageLast={() => onLastPage("pageOffsetDate")}
          onPagePrevious={() => onPreviousPage("pageOffsetDate")}
          onPageFirst={() => onFirstPage("pageOffsetDate")}
          hideShowAllButton
          pageSize={pageSize("pageOffsetDate")}
        />
        <DepositDetailTable
          detailTooltip="Show detail for this date"
          hightlightedItem={
            highlightedItems.pageOffsetDate ?? activity?.dateDetails?.items[0]
          }
          isLoading={isLoading}
          onDrilldown={(detail) => onSelectDrilldown("filterDate", detail)}
          includedColumns={[
            "detail",
            "report date",
            "merchantNumber",
            "merchant name",
            "salesTxCount",
            "salesTxVolume",
            "creditsTxCount",
            "creditsTxVolume",
            "netSalesTxCount",
            "netSalesTxVolume",
          ]}
          deposits={activity?.dateDetails?.items}
        />
      </div>
      <div className="search-results-container">
        <TableTitle
          title="Transmitted - Outlet Detail"
          offset={currentPages.pageOffsetOutletNumber}
          totalResults={activity?.outletDetails?.totalResults ?? 0}
          onExport={() => onExport(TransmittedTable.OUTLET)}
          onPageNext={() => onNextPage("pageOffsetOutletNumber")}
          onPageLast={() => onLastPage("pageOffsetOutletNumber")}
          onPagePrevious={() => onPreviousPage("pageOffsetOutletNumber")}
          onPageFirst={() => onFirstPage("pageOffsetOutletNumber")}
          currentResultCount={activity?.outletDetails?.items.length ?? 0}
          hideShowAllButton
          pageSize={pageSize("pageOffsetOutletNumber")}
        />
        <DepositDetailTable
          detailTooltip="Show detail for this outlet"
          hightlightedItem={
            highlightedItems.pageOffsetOutletNumber ??
            activity?.outletDetails?.items[0]
          }
          onDrilldown={(detail) =>
            onSelectDrilldown("filterOutletNumber", detail)
          }
          includedColumns={[
            "detail",
            "report date",
            "merchantNumber",
            "outletNumber",
            "outletName",
            "salesTxCount",
            "salesTxVolume",
            "creditsTxCount",
            "creditsTxVolume",
            "netSalesTxCount",
            "netSalesTxVolume",
          ]}
          deposits={activity?.outletDetails?.items}
        />
      </div>
      <div className="search-results-container">
        <TableTitle
          title="Transmitted - Card Type Detail"
          offset={currentPages.pageOffsetCardType}
          totalResults={activity?.cardTypeDetails?.totalResults ?? 0}
          onExport={() => onExport(TransmittedTable.CARD_TYPE)}
          currentResultCount={activity?.cardTypeDetails?.items.length ?? 0}
          onPageNext={() => onNextPage("pageOffsetCardType")}
          onPageLast={() => onLastPage("pageOffsetCardType")}
          onPagePrevious={() => onPreviousPage("pageOffsetCardType")}
          onPageFirst={() => onFirstPage("pageOffsetCardType")}
          hideShowAllButton
          pageSize={pageSize("pageOffsetCardType")}
        />
        <DepositDetailTable
          detailTooltip="Show detail for this card type"
          hightlightedItem={
            highlightedItems.pageOffsetCardType ??
            activity?.cardTypeDetails?.items[0]
          }
          isLoading={isLoading}
          onDrilldown={(detail) => onSelectDrilldown("filterCardType", detail)}
          includedColumns={[
            "detail",
            "report date",
            "merchantNumber",
            "cardType",
            "salesTxCount",
            "salesTxVolume",
            "creditsTxCount",
            "creditsTxVolume",
            "netSalesTxCount",
            "netSalesTxVolume",
          ]}
          deposits={activity?.cardTypeDetails?.items}
        />
      </div>
      <div className="search-results-container">
        <TableTitle
          title="Transmitted - Batch Detail"
          offset={currentPages.pageOffsetBatch}
          totalResults={activity?.batchDetails?.totalResults ?? 0}
          onPageNext={() => onNextPage("pageOffsetBatch")}
          onPageLast={() => onLastPage("pageOffsetBatch")}
          onPagePrevious={() => onPreviousPage("pageOffsetBatch")}
          onPageFirst={() => onFirstPage("pageOffsetBatch")}
          onExport={() => onExport(TransmittedTable.BATCH)}
          currentResultCount={activity?.batchDetails?.items.length ?? 0}
          hideShowAllButton
          pageSize={pageSize("pageOffsetBatch")}
        />
        <DepositDetailTable
          detailTooltip="Show detail for this batch"
          hightlightedItem={
            highlightedItems.pageOffsetBatch ?? activity?.batchDetails?.items[0]
          }
          isLoading={isLoading}
          onDrilldown={(detail) => onSelectDrilldown("filterBatchNumber", detail)}
          includedColumns={[
            "detail",
            "batchNumber",
            "outletName",
            "salesTxCount",
            "salesTxVolume",
            "creditsTxCount",
            "creditsTxVolume",
            "netSalesTxCount",
            "netSalesTxVolume",
          ]}
          deposits={activity?.batchDetails?.items}
          tableName="batch"
        />
      </div>
      <div className="search-results-container">
        <TableTitle
          title="Transmitted - Transaction Detail"
          offset={currentPages.pageOffsetTrans}
          totalResults={activity?.transactionDetails?.totalResults ?? 0}
          onExport={() => onExport(TransmittedTable.TRANSACTION)}
          currentResultCount={activity?.transactionDetails?.items.length ?? 0}
          onPageNext={() => onNextPage("pageOffsetTrans")}
          onPageLast={() => onLastPage("pageOffsetTrans")}
          onPagePrevious={() => onPreviousPage("pageOffsetTrans")}
          onPageFirst={() => onFirstPage("pageOffsetTrans")}
          hideShowAllButton
          pageSize={pageSize("pageOffsetTrans")}
        />
        <DepositDetailTable
          hightlightedItem={activity?.transactionDetails?.items[0]}
          isLoading={isLoading}
          showShortCardType={true}
          includedColumns={[
            "emptyDetail",
            "summaryDate",
            "merchantNumber",
            "merchant name",
            "outletName",
            "abbreviatedCardType",
            "cardNumber",
            "posEntryMode",
            "tranDate",
            "tranType",
            "tranAmount",
          ]}
          deposits={activity?.transactionDetails?.items}
          tableName="tran"
        />
      </div>
    </div>
  );
};

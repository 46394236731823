import faker from "faker";
import { Merchant } from "../models/merchant";
import {
  MerchantDetailsSearchParameters,
  MerchantDetailsSortColumn,
  MerchantService,
} from "../services/merchant";
import { mockOutlet } from "./outlet.mock";

export function mockMerchant() {
  const m = new Merchant();
  m.number = faker.finance.account();
  m.name = faker.company.companyName().split(" ")[0];
  m.address1 = faker.address.streetAddress();
  m.address2 = faker.address.secondaryAddress();
  m.city = faker.address.city();
  m.state = faker.address.stateAbbr();
  m.zip = faker.address.zipCode();
  m.country = faker.address.country();
  m.region = faker.address.county();
  m.propertyPhone = faker.phone.phoneNumber();
  m.customerServicePhone = faker.phone.phoneNumber();
  m.taxId = faker.finance.account();
  m.contractEntity = faker.commerce.department();
  m.managementCompany = faker.company.companyName();
  m.routingTransitNumber = faker.finance.account();
  m.accountNumber = faker.finance.account();
  m.accountType = faker.finance.accountName();
  m.formattedMainPhone = faker.phone.phoneNumber();
  m.formattedCustomerServicePhone = faker.phone.phoneNumber();
  m.routingTransitNumber2 = faker.finance.account();
  m.routingTransitNumberC = faker.finance.account();
  m.routingTransitNumberF = faker.finance.account();
  m.routingTransitNumberM = faker.finance.account();
  m.accountNumber2 = faker.finance.account();
  m.accountNumberC = faker.finance.account();
  m.accountNumberF = faker.finance.account();
  m.accountNumberM = faker.finance.account();
  return m;
}
const MERCHANTS = Array.from(new Array(10)).map(mockMerchant);

export function mockMerchantService(): MerchantService {
  return {
    async getAllMerchants() {
      return MERCHANTS;
    },
    async getMerchantDetails(
      parameters: MerchantDetailsSearchParameters<MerchantDetailsSortColumn>
    ) {
      return new Promise((r) =>
        setTimeout(
          () =>
            r({
              data: Array.from(new Array(10)).map(mockMerchant),
              offset: 0,
              total: 1000,
            }),
          2000
        )
      );
    },
    async getMerchantOutlets() {
      return {
        total: faker.datatype.number(1000) + 100,
        offset: faker.datatype.number(1000),
        data: Array.from(new Array(10)).map(mockOutlet),
      };
    },
    async getMerchantBankDetails() {
      return {
        data: Array.from(new Array(10)).map(mockMerchant),
        total: 10,
        offset: 0,
      };
    },
    async exportMerchantBankDetails() {
      return [new Blob(), ""];
    },
    async exportMerchantOutlets() {
      return [new Blob(), ""];
    },
    async exportMerchantDetails() {
      return [new Blob(), ""];
    },
  };
}

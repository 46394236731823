import { Role } from "./roles";

export enum DocumentType {
  BestPractices,
  ClientNotices,
  DataSecurity,
  Newsletter,
  RefdocsOther,
  RefdocsPost,
}
export class Document {
  constructor(
    public id: string,
    public title: string,
    public url: string,
    public shouldOpenInNewWindow: boolean = false,
    public description?: string,
    public type?: DocumentType,
    public documentDate?: Date,
    public isInternal: boolean = false
  ) {}
  private static filenameFromUrlRegex = new RegExp(/([^/]+$)/);
  get filename(): string | undefined {
    if (!this.url) {
      return undefined;
    } else {
      const filename = Document.filenameFromUrlRegex.exec(this.url)?.[1];
      return filename;
    }
  }
}

export const HumanReadableDocumentType: { [key in DocumentType]: string } = {
  [DocumentType.BestPractices]: "Best Practices",
  [DocumentType.ClientNotices]: "Client Notices",
  [DocumentType.DataSecurity]: "Data Security",
  [DocumentType.Newsletter]: "Newsletters",
  [DocumentType.RefdocsOther]: "Reference Documents",
  [DocumentType.RefdocsPost]: "Post",
};

export const documentsToRoles: { [key in DocumentType]?: Role } = {
  [DocumentType.BestPractices]: "BestPractices",
  [DocumentType.ClientNotices]: "ClientNotices",
  [DocumentType.DataSecurity]: "DataSecurity",
  [DocumentType.Newsletter]: "Newsletters",
  [DocumentType.RefdocsOther]: "ReferenceDocuments",
  [DocumentType.RefdocsPost]: "ReferenceDocuments",
};

import faker from "faker";
import {
  ReportCategory,
  ReportCategoryValue,
  ReportGroup,
  ReportType,
} from "../models/report";
import { GetReportGroupsParameters, ReportService } from "../services/report";

export function mockReportGroup(
  reportName = faker.commerce.productName(),
  hasChildren = true,
  depth = 0
): ReportGroup {
  const r = new ReportGroup(
    faker.datatype.uuid(),
    reportName,
    hasChildren
      ? Array.from(new Array(5)).map(() =>
          mockReportGroup(
            faker.date.recent().toLocaleDateString(),
            faker.datatype.number(10) % 10 === 0 && depth < 5,
            depth + 1
          )
        )
      : []
  );
  if (!hasChildren) {
    r.url = faker.internet.url();
  }
  return r;
}

export function mockReportCategory(): ReportCategory {
  return new ReportCategory(faker.datatype.uuid(), faker.lorem.words(2));
}

export function mockReportCategoryValue(): ReportCategoryValue {
  return new ReportCategoryValue(
    faker.commerce.productName(),
    faker.datatype.uuid(),
    faker.lorem.words(3)
  );
}

export function mockReportService(): ReportService {
  return {
    async getGroups(params?: GetReportGroupsParameters) {
      return ["2022", "2021"].map((s) => mockReportGroup(s));
    },
    async getReportUrl(...params) {
      console.log(params);
      return faker.internet.url();
    },
    async getReportCategories() {
      return Array.from(new Array(5)).map(mockReportCategory);
    },
    async getReportCategoryValues(categoryId: string) {
      return Array.from(new Array(5)).map(() => mockReportCategoryValue());
    },
    async getReportPeriods(reportType: ReportType) {
      return [
        ...Array.from(new Array(5)).map(() => faker.lorem.words(3)),
        "Date Range",
      ];
    },
    async getReportBeginDate(reportType: ReportType) {
      return {
        beginDate: faker.date.recent(),
        noDataDate: new Date(1999, 0, 1),
      };
    },
    async downloadReport(reportUrl: string): Promise<[Blob, string?]> {
      return new Promise((resolve) =>
        setTimeout(() => resolve([new Blob(), "testPdf.xls"]), 2000)
      );
    },
  };
}

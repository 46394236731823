import React from "react";

import "./tooltip.scss";

export interface TooltipProps {
  title?: string;
  children?: any;
  height?: string | number;
  width?: string | number;
}
export function Tooltip({
  title,
  children,
  height = "auto",
  width = "auto",
  ...props
}: TooltipProps & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      style={{
        height,
        width,
        border: "0.5px solid grey",
      }}
      className="tooltip"
    >
      {title && <div className="header has-text-centered">{title}</div>}
      {children && (
        <div className="content" {...props}>
          {children}
        </div>
      )}
    </div>
  );
}
Tooltip.Container = ({ children }: { children: any }) => {
  return (
    <div style={{ width: "100%" }} className={`tooltip-container purple`}>
      {children}
    </div>
  );
};

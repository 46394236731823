import { useCallback, useState } from "react";
import { SortOrder } from "../models/sort_order";

export interface Sorting<T> {
  field: T;
  direction: SortOrder;
}
export function useSorting<T>(defaultSortColumn?: T) {
  const [sorting, setSorting] = useState<Sorting<T> | undefined>(
    !(defaultSortColumn === undefined)
      ? {
          field: defaultSortColumn,
          direction: SortOrder.ASC,
        }
      : undefined
  );

  const handleSort = (field?: T) => {
    if (field === undefined) {
      setSorting(undefined);
      return;
    }
    if (field === sorting?.field) {
      setSorting({
        field,
        direction:
          sorting?.direction === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC,
      });
    } else {
      setSorting({
        field,
        direction: SortOrder.ASC,
      });
    }
  };
  const reset = useCallback(() => {
    setSorting(
      !(defaultSortColumn === undefined)
        ? {
            field: defaultSortColumn,
            direction: SortOrder.ASC,
          }
        : undefined
    );
  }, [defaultSortColumn]);

  return {
    reset,
    sorting,
    handleSort,
  };
}

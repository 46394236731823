import React from "react";
import Select, { CSSObjectWithLabel, Props } from "react-select";

export function EbocomSelect<T>(props: Props<T>) {
  const height = "21px";
  return (
    <Select
      styles={{
        ...props.styles,
        control: (provided, state) => ({
          ...provided,
          background: "#fff",
          borderColor: "#9e9e9e",
          minHeight: height,
          height,
          width: "100%",
          textAlign: "left",
          fontSize: "11px",
        }) as CSSObjectWithLabel,
        valueContainer: (provided, state) => ({
          ...provided,
          height,
          padding: "0 6px",
        }) as CSSObjectWithLabel,
        input: (provided, state) => ({
          ...provided,
          height,
          margin: "0px",
          textAlign: "left",
        }) as CSSObjectWithLabel,
        container: (provided, state) => ({
          ...provided,
          padding: 0,
          height,
        }) as CSSObjectWithLabel,
        indicatorSeparator: (state) => ({
          display: "none",
        }),
        indicatorsContainer: (provided, state) => ({
          ...provided,
          height,
        }) as CSSObjectWithLabel,
        option: (provided, state) => ({
          ...provided,
          textAlign: "left",
          fontSize: "11px",
          lineHeight: "20px",
          margin: 0,
          padding: 0,
        }) as CSSObjectWithLabel,
      }}
      {...props}
    />
  );
}

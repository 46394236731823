import { CardType } from "../models/card_type";
import { DepositDetail } from "../models/deposit_detail";
import { SalesCredit } from "../models/sales_credit";
import { TransactionType } from "../models/transaction";

export interface TransactionSearchPaginatedTable<T> {
  totalResults: number;
  pageSize: number;
  offset: number;
  items: T[];
}
interface CommonTransmittedParams {
  cardTypes?: CardType[];
  beginDate: Date;
  endDate: Date;
  divisionName?: string;
  merchantNumber: string;
  cardNumber?: string;
  outletName?: string;
  loadFileNumber?: string;
  loadBatchNumber?: string;
  loadEntryNumber?: string;
  transactionAmount?: string;
  authCode?: string;
}
export interface TransmittedActivitySearchParameters
  extends CommonTransmittedParams {
  filterBatchNumber?: string;
  filterCardType?: string;
  filterCardType2?: string;
  filterDate?: Date;
  filterMerchantNumber?: string;
  filterOutletNumber?: string;
  filterTransactionType?: TransactionType;
  pageOffsetBatch?: number;
  pageOffsetCardType?: number;
  pageOffsetDate?: number;
  pageOffsetMerchantNumber?: number;
  pageOffsetOutletNumber?: number;
  pageOffsetTrans?: number;
  pageOffsetSummary?: number;
}

interface CanBeEmpty {
  isEmpty: boolean;
}
export interface DepositActivity extends CanBeEmpty {
  merchantDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  dateDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  outletDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  transactionDetails?: TransactionSearchPaginatedTable<SalesCredit>;
  summaryDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  chargebackDetails?: TransactionSearchPaginatedTable<DepositDetail>;
}
export enum DepositActivityTable {
  MERCHANT,
  DATE,
  OUTLET,
  TRANSACTION,
  SUMMARY,
  CHARGEBACK
}
export interface TransmittedActivity extends CanBeEmpty {
  merchantDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  dateDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  cardTypeDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  outletDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  batchDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  transactionDetails?: TransactionSearchPaginatedTable<SalesCredit>;
  summaryDetails?: TransactionSearchPaginatedTable<DepositDetail>;
  isEmpty: boolean;
}
export interface DepositActivityParams extends CommonTransmittedParams {
  cardNumber?: string;
  filterMerchantNumber?: string;
  filterDate?: Date;
  filterCardType?: string;
  filterCardType2?: string;
  filterOutletNumber?: number;
  pageOffsetMerchant?: number;
  pageOffsetDate?: number;
  pageOffsetOutlet?: number;
  pageOffsetChargeback?: number;
  pageOffsetTransaction?: number;
  pageOffsetSummary?: number;
  tranType?: TransactionType;
}
export enum TransmittedTable {
  MERCHANT,
  DATE,
  CARD_TYPE,
  OUTLET,
  BATCH,
  TRANSACTION,
  SUMMARY,
}
export const CardTypeLongDictionary = {
  AX: "AMERICAN EXPRESS",
  DR: "DISCOVER",
  JCB: "JCB",
  MC: "MASTERCARD",
  VS: "VISA",
};
export interface TransmittedService {
  getTransmittedActivity(
    parameters: TransmittedActivitySearchParameters
  ): Promise<TransmittedActivity>;
  getDepositActivity(
    parameters: DepositActivityParams
  ): Promise<DepositActivity>;
  getTransmittedReport(
    parameters: TransmittedActivitySearchParameters,
    reportTable: TransmittedTable
  ): Promise<[Blob, string]>;
  getDepositReport(
    parameters: DepositActivityParams,
    reportTable: DepositActivityTable
  ): Promise<[Blob, string]>;
}

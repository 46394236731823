import { useEffect, useState } from "react";
import { ReportCategoryValue } from "../models/report";
import { ReportService } from "../services/report";

export function useReportCategoryValues(
  reportService: ReportService,
  categoryId?: string
) {
  const [reportCategoryValues, setReportCategoryValues] = useState<
    ReportCategoryValue[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!categoryId) {
      setReportCategoryValues([]);
      return;
    }
    setIsLoading(true);
    reportService
      .getReportCategoryValues(categoryId)
      .then((values) => {
        setReportCategoryValues(values);
      })
      .finally(() => setIsLoading(false));
  }, [categoryId, reportService]);

  return {
    reportCategoryValues,
    isLoading,
  };
}

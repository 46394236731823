import React from "react";

export interface ModalProps {
  children: React.ReactNode;
  isActive?: boolean;
  closeDisabled?: boolean;
  onClose?: () => void;
}

export function Modal({
  children,
  onClose = () => {},
  isActive = false,
  closeDisabled = false,
}: ModalProps) {
  let closeButton;
  if (!!onClose && !closeDisabled) {
    closeButton = (
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    );
  }
  return (
    <div className={`modal ${isActive ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={closeDisabled ? undefined : onClose}
      ></div>
      <div className="modal-content">{children}</div>
      {closeButton}
    </div>
  );
}

import faker from "faker";
import { CardType } from "../models/card_type";
import { PaginatedResult } from "../models/paginated_result";
import { Transaction } from "../models/transaction";
import {
  TransactionSearchParameters,
  TransactionService,
} from "../services/transaction";

export function mockTransaction(): Transaction {
  const tx = new Transaction();
  tx.cardType = CardType.visa;
  tx.depositDate = faker.date.past();
  tx.merchantName = faker.company.companyName();
  tx.merchantNumber = (Math.random() * 1000000 + 1000000).toString();
  tx.outletName = faker.company.companyName();
  tx.expDate = "1021";
  tx.maskedCardNumber = faker.finance
    .creditCardNumber()
    .split("")
    .splice(0, 8, "*")
    .join("");
  tx.outletNumber = (Math.random() * 1000000 + 1000000).toString();
  tx.cardNumber = faker.finance.creditCardNumber();
  tx.posEntry = Math.floor(Math.random() * 10).toString();
  tx.posEntryDesc = "KEYED";
  tx.authCode = Math.floor(Math.random() * 100000 + 100000).toString();
  tx.rfcCode = "";
  tx.transactionDate = faker.date.past();
  tx.transactionType = "1ST_CB";
  tx.transactionAmount = ((Math.random() * 100000) / 100 - 500).toString();
  tx.transactionStatus = "PAID";
  tx.id = faker.datatype.uuid();
  tx.billedDate = faker.date.past();
  return tx;
}

class FakeTransactionService implements TransactionService {
  async getTransactions(
    params: TransactionSearchParameters
  ): Promise<PaginatedResult<Transaction>> {
    return new Promise((resolve) =>
      setTimeout(
        () =>
          resolve({
            data: Array.from(new Array(10), mockTransaction),
            offset: faker.datatype.number(1000),
            total: faker.datatype.number(10000) + 10000,
          }),
        2000
      )
    );
  }
  async getTransactionReport(): Promise<[Blob, string?]> {
    return [new Blob(), "test.xls"];
  }
}

export function mockTransactionService(): TransactionService {
  return new FakeTransactionService();
}

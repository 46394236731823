import { useContext } from "react";
import { RefObject } from "react";
import { FC } from "react";
import { ConfigContext } from "../config";

interface Props {
    iframeRef?: RefObject<HTMLIFrameElement>;
}

export const SSOIFrameRenderer: FC<Props> = ({ iframeRef }) => {
    const config = useContext(ConfigContext);

    return (
        <iframe
            ref={iframeRef}
            style={{ display: 'none' }}
            src={`${config?.kcUrl}/realms/POSTAT/login-actions/reset-credentials`}
            title="SSO iframe"
        />
    )
}
import faker from "faker";
import { CardType } from "../models/card_type";
import { DepositDetail } from "../models/deposit_detail";

export function mockDepositDetail() {
  const dd = new DepositDetail(
    faker.datatype.number(100000000).toString(),
    faker.company.companyName()
  );

  dd.reportDate = faker.date.recent();
  dd.cardType = CardType.visa;
  dd.totalSalesTransactionsCount = faker.datatype.number(100).toString();
  dd.totalSalesTransactionsVolume = faker.datatype.number(100).toString();
  dd.totalCreditTransactionCount = faker.datatype.number(100).toString();
  dd.totalCreditTransactionVolume = faker.datatype.number(100).toString();
  dd.totalNetSalesTransactionCount = faker.datatype.number(100).toString();
  dd.totalNetSalesTransactionVolume = faker.datatype.number(100).toString();
  dd.outletNumber = faker.datatype.number(10000000).toString();
  dd.batchNumber = faker.datatype.number(10000000).toString();
  dd.outletName = faker.lorem.word();
  dd.cardNumber = faker.finance.creditCardNumber();
  dd.posEntryMode = "1";
  dd.ddas = Array.from(new Array(5)).map(() => ({
    last4: faker.finance.creditCardNumber().slice(-4),
    netDeposit: faker.finance.amount(0, 100000),
  }));
  dd.transactionDate = faker.date.recent();
  dd.transactionType = "2";
  dd.transactionAmount = faker.datatype.number(200).toString();
  dd.miscAdj = faker.datatype.number(100).toString();
  dd.dailyFees = faker.datatype.number(100).toString();
  dd.netDeposit = faker.datatype.number(100000).toString();
  return dd;
}

export function mockDepositDetailService() {
  return {};
}

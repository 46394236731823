import React from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { CORPORATE_REPORT_TYPES, ReportType } from "../../models/report";

export interface StandardReportRouteProps {
  reportTypeSerializer: (reportKey: string) => ReportType | undefined;
  children: (reportType: ReportType, isCorporate?: boolean) => JSX.Element;
}
export const StandardReportRoute = (props: StandardReportRouteProps) => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const rawReportType = values.reportType;
  if (typeof rawReportType !== "string" || rawReportType?.length === 0) {
    return <div />;
  }
  const reportType = props.reportTypeSerializer(rawReportType);
  if (!reportType) {
    return <div />;
  }
  const isCorporate = CORPORATE_REPORT_TYPES.includes(reportType);

  return props.children(reportType, isCorporate);
};

const format = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  currencySign: "accounting",
  style: "currency",
  currency: "USD",
});
export function ebocomSdkNumberFormatter(
  stringifiedNumber?: string | number
): string {
  if (stringifiedNumber === "" || stringifiedNumber === undefined) {
    return "";
  }
  let parsedNumber: number;
  if (typeof stringifiedNumber === "string") {
    let cleanedNumber = stringifiedNumber.replace(/[^0-9\-.]/g, "");
    parsedNumber = parseFloat(cleanedNumber);
  } else if (typeof stringifiedNumber === "number") {
    parsedNumber = stringifiedNumber;
  } else {
    throw TypeError("number is incorrect type");
  }

  return format.format(parsedNumber).replace("$", "");
}

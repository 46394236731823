import React from "react";
import { Caret } from "../../components/caret";
import { SortTooltip } from "../../components/sort_tooltip";
import { Outlet } from "../../models/outlet";
import { SortOrder } from "../../models/sort_order";
import { OutletSortColumn } from "../../services/merchant";
export interface OutletListingResultProps {
  outlets: Outlet[];
  sortColumn?: OutletSortColumn;
  onSort?: (sortColumn: OutletSortColumn) => void;
  sortDirection?: SortOrder;
}
export function OutletListingResult({
  outlets,
  sortColumn,
  sortDirection,
  onSort = () => {},
}: OutletListingResultProps) {
  return (
    <table className="table is-bordered">
      <tbody>
        <tr style={{ height: 41 }}>
          <th className="has-text-white is-uppercase has-text-centered">
            <SortTooltip>
              <div
                onClick={() => onSort("merchantName")}
                style={{
                  color: sortColumn === "merchantName" ? "yellow" : "white",
                  cursor: "pointer",
                }}
              >
                Merchant Name&nbsp;
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={"merchantName"}
                  direction={sortDirection}
                />
              </div>
            </SortTooltip>
          </th>
          <th className="has-text-white is-uppercase has-text-centered">
            <SortTooltip>
              <div
                onClick={() => onSort("division")}
                style={{
                  color: sortColumn === "division" ? "yellow" : "white",
                  cursor: "pointer",
                }}
              >
                Division&nbsp;
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={"division"}
                  direction={sortDirection}
                />
              </div>
            </SortTooltip>
          </th>
          <th className="has-text-white is-uppercase has-text-centered">
            <SortTooltip>
              <div
                onClick={() => onSort("outletNumber")}
                style={{
                  color: sortColumn === "outletNumber" ? "yellow" : "white",
                  cursor: "pointer",
                }}
              >
                Outlet Number&nbsp;
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={"outletNumber"}
                  direction={sortDirection}
                />
              </div>
            </SortTooltip>
          </th>
          <th className="has-text-white is-uppercase has-text-centered">
            <SortTooltip>
              <div
                onClick={() => onSort("outletName")}
                style={{
                  color: sortColumn === "outletName" ? "yellow" : "white",
                  cursor: "pointer",
                }}
              >
                Outlet Name&nbsp;
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={"outletName"}
                  direction={sortDirection}
                />
              </div>
            </SortTooltip>
          </th>
          <th className="has-text-white is-uppercase has-text-centered">
            <SortTooltip>
              <div
                onClick={() => onSort("mcVisa")}
                style={{
                  color: sortColumn === "mcVisa" ? "yellow" : "white",
                  cursor: "pointer",
                }}
              >
                MC / Visa&nbsp;
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={"mcVisa"}
                  direction={sortDirection}
                />
              </div>
            </SortTooltip>
          </th>
          <th className="has-text-white is-uppercase has-text-centered">
            <SortTooltip>
              <div
                onClick={() => onSort("amex")}
                style={{
                  color: sortColumn === "amex" ? "yellow" : "white",
                  cursor: "pointer",
                }}
              >
                Amex&nbsp;
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={"amex"}
                  direction={sortDirection}
                />
              </div>
            </SortTooltip>
          </th>
          <th className="has-text-white is-uppercase has-text-centered">
            <SortTooltip>
              <div
                onClick={() => onSort("discoverJcb")}
                style={{
                  color: sortColumn === "discoverJcb" ? "yellow" : "white",
                  cursor: "pointer",
                }}
              >
                Discover / JCB&nbsp;
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={"discoverJcb"}
                  direction={sortDirection}
                />
              </div>
            </SortTooltip>
          </th>
        </tr>
        {outlets.map((outlet, index) => (
          <tr style={{ height: 26 }} key={outlet.number}>
            <td className="has-text-left is-vcentered">
              {outlet.merchantName}
            </td>
            <td className="has-text-centered is-vcentered">
              {outlet.division}
            </td>
            <td className="has-text-centered is-vcentered">{outlet.number}</td>
            <td className="has-text-left is-vcentered">{outlet.name}</td>
            <td className="has-text-centered is-vcentered">{outlet.mcVisa}</td>
            <td className="has-text-centered is-vcentered">{outlet.amex}</td>
            <td className="has-text-centered is-vcentered">
              {outlet.discoverJcb}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

import React from "react";

export interface ImpersonationContextValue {
  impersonateUser(userId: string): Promise<void>;
  deimpersonate(): Promise<void>;
  rootAccountId: string | null;
  impersonatedAccountId: string | null;
}
export const ImpersonationContext =
  React.createContext<ImpersonationContextValue>({
    impersonateUser: async (userId: string) => {},
    deimpersonate: async () => {},
    rootAccountId: null,
    impersonatedAccountId: null,
  });

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortOrderToJSON = exports.SortOrderFromJSONTyped = exports.SortOrderFromJSON = exports.SortOrder = void 0;
/**
 * Order in which to sort, ASC or DESC
 * @export
 * @enum {string}
 */
var SortOrder;
(function (SortOrder) {
    SortOrder["Asc"] = "ASC";
    SortOrder["Desc"] = "DESC";
})(SortOrder = exports.SortOrder || (exports.SortOrder = {}));
function SortOrderFromJSON(json) {
    return SortOrderFromJSONTyped(json, false);
}
exports.SortOrderFromJSON = SortOrderFromJSON;
function SortOrderFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SortOrderFromJSONTyped = SortOrderFromJSONTyped;
function SortOrderToJSON(value) {
    return value;
}
exports.SortOrderToJSON = SortOrderToJSON;

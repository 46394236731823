import { useEffect, useState } from "react";
import { ReportType } from "../models/report";
import { ReportService } from "../services/report";

export function useMerchantReferenceSheet(
  reportService: ReportService,
  merchantNumber?: string
) {
  const [reportUrl, setReportUrl] = useState<string>();
  const [loading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    setReportUrl(undefined);
    if (merchantNumber) {
      reportService
        .getReportUrl({
          type: ReportType.MERCHANTREFERENCESHEET,
          merchantNumber: merchantNumber,
        })
        .then(setReportUrl)
        .finally(() => setIsLoading(false));
    }
    return () => setIsLoading(false);
  }, [reportService, merchantNumber]);
  return { reportUrl, loading };
}

import { Middleware } from "ebocomsdk";
import { clearLocalStorage } from "../../utils";
import { EbocomSdk } from "./init";

export const getAuthHeader = () => {
  return {
    Authorization: `Bearer ${window.localStorage.getItem(
      EbocomSdk.TOKEN_LOCAL_STORAGE_KEY
    )}`,
  };
};

export function getPreAuthMiddleware(): Middleware["pre"] {
  return async (context) => {
    console.log('preAuth context', context);
    const tokenExpTimeString = window.localStorage.getItem('tokenExpirationTime');
    const tokenExpTime = tokenExpTimeString ? parseInt(tokenExpTimeString, 10) : 0;
    console.log('tokenExpTime', tokenExpTime);
    console.log('tokenExpTimeString', tokenExpTimeString);
    if ((new Date()).getTime() > tokenExpTime && tokenExpTime > 0) {
      // const logoutUrl = window.localStorage.getItem('logoutUrl');
      // clearLocalStorage();
      console.log('=======> should redirect user to logoutUrl before making an unauthorized request');
      // window.location.replace(logoutUrl!);
    }
    return {
      ...context,
      init: {
        ...context.init,
        headers: {
          ...context.init.headers,
          ...getAuthHeader(),
        },
      },
    };
  };
}
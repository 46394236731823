import React, { useCallback, useEffect, useMemo } from "react";
import { DepositDetail } from "../models/deposit_detail";
import {
  DepositActivity,
  DepositActivityParams,
  TransmittedService,
} from "../services/transmitted";
import { usePagination } from "./use_pagination";
import { isEmpty as lodashIsEmpty } from "lodash";
export type DrilldownFields = Pick<
  DepositActivityParams,
  "filterDate" | "filterMerchantNumber" | "filterOutletNumber"
>;
export type DrilldownFieldNames = keyof DrilldownFields;
export const fieldHierarchy: Map<DrilldownFieldNames, number> = new Map([
  ["filterMerchantNumber", 2],
  ["filterDate", 1],
  ["filterOutletNumber", 0],
]);
const PAGE_SIZE = 5;
export type Drilldowns = Partial<
  Record<DrilldownFieldNames, Partial<DepositDetail>>
>;
export function useDepositActivity(
  service: TransmittedService,
  parameters?: DepositActivityParams
) {
  const [depositActivity, setDepositActivity] = React.useState<
    DepositActivity | undefined
  >();
  const [isEmpty, setIsEmpty] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<Error>();
  const [drilldownSelections, setDrilldownSelections] =
    React.useState<Drilldowns>();
  const compiledParameters: DepositActivityParams | undefined = useMemo<
    DepositActivityParams | undefined
  >(() => {
    if (!parameters) {
      return undefined;
    }
    const isDrilldownActive = !lodashIsEmpty(drilldownSelections);
    return {
      ...parameters,
      outletName: drilldownSelections?.filterOutletNumber?.outletName,
      filterDate: drilldownSelections?.filterDate?.reportDate,
      filterMerchantNumber:
          drilldownSelections?.filterMerchantNumber?.merchantNumber ?? parameters.filterMerchantNumber,
      filterOutletNumber:
      isDrilldownActive ?
        (drilldownSelections?.filterOutletNumber?.outletNumber !== undefined ?
        parseInt(drilldownSelections?.filterOutletNumber?.outletNumber, 10) : undefined) : parameters.filterOutletNumber,
      transactionAmount: isDrilldownActive ? undefined : parameters.transactionAmount,
      authCode: isDrilldownActive ? undefined : parameters.authCode,
      cardNumber: isDrilldownActive ? undefined : parameters.cardNumber,
    };
  }, [drilldownSelections, parameters]);

  const datePagination = usePagination(
    PAGE_SIZE,
    depositActivity?.dateDetails?.totalResults
  );
  const merchantPagination = usePagination(
    PAGE_SIZE,
    depositActivity?.merchantDetails?.totalResults
  );
  const chargebackPagination = usePagination(
    PAGE_SIZE,
    depositActivity?.chargebackDetails?.totalResults
  );
  const outletPagination = usePagination(
    PAGE_SIZE,
    depositActivity?.outletDetails?.totalResults
  );
  const summaryPagination = usePagination(
    PAGE_SIZE,
    depositActivity?.transactionDetails?.totalResults
  );
  const transactionPagination = usePagination(
    PAGE_SIZE,
    depositActivity?.transactionDetails?.totalResults
  );

  const { firstPage: dateFirstPage, page: datePageNumber } = datePagination;
  const { firstPage: chargebackFirstPage } = chargebackPagination;
  const { firstPage: outletFirstPage, page: outletPageNumber } =
    outletPagination;
  const { firstPage: transactionFirstPage } = transactionPagination;
  const { firstPage: merchantFirstPage, page: merchantPageNumber } =
    merchantPagination;
  const clearPagination = useCallback(() => {
    dateFirstPage();
    chargebackFirstPage();
    outletFirstPage();
    transactionFirstPage();
    merchantFirstPage();
  }, [
    dateFirstPage,
    chargebackFirstPage,
    outletFirstPage,
    transactionFirstPage,
    merchantFirstPage,
  ]);

  const setDrilldown = useCallback(
    (field: DrilldownFieldNames, deposit: Partial<DepositDetail>): void => {
      switch (field) {
        case "filterMerchantNumber":
          setDrilldownSelections({
            filterMerchantNumber: deposit,
          });
          dateFirstPage();
          chargebackFirstPage();
          outletFirstPage();
          transactionFirstPage();
          break;
        case "filterDate":
          setDrilldownSelections((previous) => ({
            ...previous,
            filterDate: deposit,
            filterOutletNumber: undefined,
          }));
          chargebackFirstPage();
          outletFirstPage();
          transactionFirstPage();
          break;
        case "filterOutletNumber":
          setDrilldownSelections((previous) => ({
            ...previous,
            filterOutletNumber: deposit,
          }));
          chargebackFirstPage();
          transactionFirstPage();
          break;
      }
    },
    [dateFirstPage, chargebackFirstPage, outletFirstPage, transactionFirstPage]
  );
  const clearDrilldowns = useCallback(() => {
    setDrilldownSelections({});
  }, []);
  useEffect(() => {
    setDrilldownSelections(previous => ({
      ...previous,
      filterMerchantNumber: undefined,
    }));
    clearDrilldowns();
    dateFirstPage();
    transactionFirstPage();
    chargebackFirstPage();
    outletFirstPage();
  }, [
    merchantPageNumber,
    dateFirstPage,
    transactionFirstPage,
    chargebackFirstPage,
    outletFirstPage,
    clearDrilldowns,
  ]);
  useEffect(() => {
    setDrilldownSelections(previous => ({
      ...previous,
      filterDate: undefined,
    }));
    outletFirstPage();
    transactionFirstPage();
    chargebackFirstPage();
  }, [
    outletFirstPage,
    transactionFirstPage,
    chargebackFirstPage,
    datePageNumber,
  ]);
  useEffect(() => {
    setDrilldownSelections(previous => ({
      ...previous,
      filterOutletNumber: undefined,
    }));
    transactionFirstPage();
    chargebackFirstPage();
  }, [outletPageNumber, transactionFirstPage, chargebackFirstPage]);
  React.useEffect(() => {
    if (!compiledParameters) {
      return;
    }
    let cancelled = false;
    setLoading(true);
    service
      .getDepositActivity({
        ...compiledParameters,
        pageOffsetTransaction: transactionPagination.page,
        pageOffsetChargeback: chargebackPagination.page,
        pageOffsetSummary: summaryPagination.page,
        pageOffsetMerchant: merchantPagination.page,
        pageOffsetDate: datePagination.page,
        pageOffsetOutlet: outletPagination.page,
      })
      .then((response) => {
        if (!cancelled) {
          setDepositActivity(response);
          setIsEmpty(response.isEmpty);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (!cancelled) {
          setError(error);
          setLoading(false);
        }
      });
    return () => {
      cancelled = true;
    };
  }, [
    service,
    compiledParameters,
    chargebackPagination.page,
    datePagination.page,
    merchantPagination.page,
    summaryPagination.page,
    outletPagination.page,
    transactionPagination.page,
  ]);

  return {
    depositActivity,
    loading,
    error,
    isEmpty,
    compiledParameters,
    drilldownSelections,
    setDrilldownSelections: setDrilldown,
    clearDrilldowns,
    clearPagination,
    pagination: {
      transactionPagination,
      datePagination,
      chargebackPagination,
      merchantPagination,
      summaryPagination,
      outletPagination,
    },
  };
}

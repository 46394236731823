import { HoverMenuItem } from "../components/hover_menu";
import { ReportType } from "../models/report";
import { DocumentRoutes } from "../route_constants";

export interface MenuColumn {
  id?: string;
  name: string;
  links: HoverMenuItem[];
}

export const menuLinks: MenuColumn[] = [
  {
    name: "Daily Operations",
    links: [
      {
        title: "Transaction Search",
        children: [
          {
            title: "Super Search",
            to: "/transactions/search",
          },
        ],
      },
      {
        title: "Batch Confirmations",
        children: [
          {
            title: "Transmitted Activity",
            to: "/depositdetailsoc",
          },
          {
            title: "Deposit Activity",
            to: "/depositdetails",
          },
        ],
      },
      {
        title: "Disputes",
        children: [
          {
            title: "Chargeback/RFC - Aging",
            to: "/disputes/aging",
          },
          {
            title: "Chargeback/RFC - Closed",
            to: "/disputes/closed",
          },
        ],
      },
      {
        title: "Reports",
        children: [
          {
            title: "Location",
            children: [
              {
                title: "Daily Deposit Report",
                id: "Location Daily Deposit Report",
                to: `/standardreports?reportType=${ReportType[ReportType.DAILYDEPOSITLOCATION]
                  }`,
              },
              {
                title: "Weekly Chargeback/RFC Aging Report",
                id: "Location Weekly Chargeback/RFC Aging Report",
                to: `/standardreports?reportType=${ReportType[ReportType.DISPUTEAGINGLOCATION]
                  }`,
              },
              {
                title: "Monthly Statement",
                id: "Location Monthly Statement",
                to: `/standardreports?reportType=${ReportType[ReportType.MONTHLYSTATEMENTLOCATION]
                  }`,
              },
              {
                title: "Monthly Interchange Report",
                id: "Location Monthly Interchange Report",
                to: `/standardreports?reportType=${ReportType[ReportType.INTERCHANGEPERFORMANCELOCATION]
                  }`,
              },
            ],
          },
          {
            title: "Corporate",
            children: [
              {
                title: "Daily Deposit Report",
                id: "Corporate Daily Deposit Report",
                to: `/standardreports?reportType=${ReportType[ReportType.DAILYDEPOSIT]
                  }`,
              },
              {
                title: "Weekly Chargeback/RFC Aging Report",
                id: "Corporate Weekly Chargeback/RFC Aging Report",
                to: `/standardreports?reportType=${ReportType[ReportType.DISPUTEAGING]
                  }`,
              },
              {
                title: "Monthly Statement",
                id: "Corporate Monthly Statement",
                to: `/standardreports?reportType=${ReportType[ReportType.MONTHLYSTATEMENT]
                  }`,
              },
              {
                title: "Monthly Interchange Report",
                id: "Corporate Monthly Interchange Report",
                to: `/standardreports?reportType=${ReportType[ReportType.INTERCHANGEPERFORMANCE]
                  }`,
              },
            ],
          },
          {
            title: "Refund Audit Exception Report",
            children: [
             {
              
                title: "Refund Audit Exception Report",
                to:
                  "/standardreports?reportType=" +
                  ReportType[ReportType.REFUNDAUDEXCEPTION],
    
             }, 
            ],
          }
        ],
      },
    ],
  },
  {
    name: "Management Modules",
    links: [
      {
        title: "Disputes Module",
        children: [
          {
            title: "Chargeback Performance By Location",
            to:
              "/management_module/disputes/" +
              ReportType[ReportType.CHARGEBACKPERFORMANCE],
          },
          {
            title: "Chargeback Performance By Reason",
            to:
              "/management_module/disputes/" +
              ReportType[ReportType.CHARGEBACKPERFORMANCEREASON],
          },
          {
            title: "Closed Chargeback Performance By Location",
            to:
              "/management_module/disputes/" +
              ReportType[ReportType.CLOSEDCHARGEBACKPERFORMANCE],
          },
          {
            title: "Closed Chargeback Performance By Reason",
            to:
              "/management_module/disputes/" +
              ReportType[ReportType.CLOSEDCHARGEBACKPERFORMANCEREASON],
          },
          {
            title: "RFC Performance By Location",
            to:
              "/management_module/disputes/" +
              ReportType[ReportType.RFCPERFORMANCE],
          },
        ],
      },
      {
        title: "Interchange Module",
        children: [
          {
            title: "Card Brand Performance By Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.CARDTYPE],
          },
          {
            title: "Card Product Acceptance By Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.CARDPRODUCTACCEPTANCE],
          },
          {
            title: "Debit Card Product Acceptance By Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.DEBITCARDPRODUCTACCEPTANCE],
          },
          {
            title: "Deposit Activity By Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.DEPOSITACTIVITY],
          },
          {
            title: "Interchange Performance Detail",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.INTERCHANGEDETAIL],
          },
          {
            title: "Interchange Qualification Performance by Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.INTERCHANGEQUALIFICATIONPERFORMANCE],
          },
          {
            title: "International Card Acceptance By Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.CARDACCEPTANCEREGION],
          },
          {
            title: "Merchant Fees By Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.MERCHANTFEES],
          },
          {
            title: "Point Of Acceptance Performance By Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.POINTOFACCEPTANCE],
          },
        ],
      },
      {
        title: "Risk Module",
        children: [
          {
            title: "Refund Audit Exception by Location",
            to:
              "/management_module/interchange/" +
              ReportType[ReportType.REFUNDAUDLOCATION],
          },
          {
            title: "Excessive Chargeback",
            to:
              "/standardreports?reportType=" +
              ReportType[ReportType.EXCESSIVECHARGEBACK],
          },
          {
            title: "Excessive Refund",
            to: `/excessive_credit`,
          },
        ],
      },
      {
        title: "Trend Module",
        children: [
          {
            title: "Deposit",
            children: [
              {
                title: "Deposit Activity",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.DEPOSITACTIVITYTREND],
              },
              {
                title: "Card Brand Performance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.BYCARDTYPETREND],
              },
            ],
          },
          {
            title: "Dispute",
            children: [
              {
                title: "Chargeback Performance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.CHARGEBACKPERFORMANCETREND],
              },
              {
                title: "Closed Chargeback Performance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.CLOSEDCHARGEBACKPERFORMANCETREND],
              },

              {
                title: "RFC Performance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.RFCPERFORMANCETREND],
              },
            ],
          },
          {
            title: "Interchange",
            children: [
              {
                title: "Card Product Acceptance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.CARDPRODUCTACCEPTANCETREND],
              },
              {
                title: "Debit Card Product Acceptance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.DEBITCARDPRODUCTACCEPTANCETREND],
              },
              {
                title: "Interchange Qualification Performance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.INTERCHANGEQUALIFICATIONTREND],
              },
              {
                title: "International Card Acceptance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.CARDREGIONTREND],
              },
              {
                title: "Merchant Fees",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.MERCHANTFEESTREND],
              },
              {
                title: "Point Of Acceptance Performance",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.POINTOFACCEPTANCETREND],
              },
            ],
          },
          {
            title: "Risk",
            children: [
              {
                title: "Refund Audit Exceptions",
                to:
                  "/management_module/trend/" +
                  ReportType[ReportType.REFUNDAUDTREND],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    name: "Reference Information",
    links: [
      {
        title: "Issuer Lookup",
        children: [{ title: "Issuer Lookup", to: "/issuer_lookup" }],
      },
      {
        title: "For Your Information",
        children: [
          {
            title: "Best Practices",
            to: `/documents/${DocumentRoutes.bestpractices}`,
          },
          {
            title: "Client Notices",
            to: `/documents/${DocumentRoutes.clientnotices}`,
          },
          {
            title: "Data Security",
            to: `/documents/${DocumentRoutes.datasecurity}`,
          },
          {
            title: "Newsletters",
            to: `/documents/${DocumentRoutes.newsletter}`,
          },
          {
            title: "Reference Documents",
            to: `/documents/${DocumentRoutes.refdocsother}`,
          },
        ],
      },
      {
        title: "User Information",
        children: [
          { title: "Change Password", to: "/change_password" },
          { title: "User Privileges", to: "/user_privileges" },
        ],
      },
      {
        title: "Merchant Information",
        children: [
          { title: "Business Information", to: "/business_information" },
          {
            title: "Merchant Reference Sheet",
            to: `/merchant_reference_sheet`,
          },
          { title: "Outlet Listing", to: "/outlet_listing" },
          {
            title: "Settlement Account Information",
            to: "/settlement_account_information",
          },
        ],
      },
    ],
  },
];

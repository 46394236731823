import { ReportType } from "./report";

export const reportsToRoles: { [key in ReportType]?: Role } = {
  [ReportType.REFUNDAUDLOCATION]: "RefundAudLocation",
  [ReportType.REFUNDAUDTREND]: "RefundAudTrend",
  [ReportType.BYCARDTYPETREND]: "CardBrandPerformanceTrend",
  [ReportType.CARDACCEPTANCEREGION]: "InternationalCardAcceptanceByLocation",
  [ReportType.CARDPRODUCTACCEPTANCE]: "CardProductAcceptanceByLocation",
  [ReportType.CARDPRODUCTACCEPTANCETREND]: "CardProductAcceptanceTrend",
  [ReportType.CARDREGIONTREND]: "InternationalCardAcceptanceTrend",
  [ReportType.CARDTYPE]: "CardBrandPerformanceByLocation",
  [ReportType.CHARGEBACKAGING]: "ChargebackRFCAging",
  [ReportType.CHARGEBACKPERFORMANCE]: "ChargebackPerformanceByLocation",
  [ReportType.CHARGEBACKPERFORMANCEREASON]: "ChargebackPerformanceByReason",
  [ReportType.CHARGEBACKPERFORMANCETREND]: "ChargebackPerformanceTrend",
  [ReportType.CLOSEDCHARGEBACKPERFORMANCE]:
    "ClosedChargebackPerformanceByLocation",
  [ReportType.CLOSEDCHARGEBACKPERFORMANCEREASON]:
    "ClosedChargebackPerformanceByReason",
  [ReportType.CLOSEDCHARGEBACKPERFORMANCETREND]:
    "ClosedChargebackPerformanceTrend",
  [ReportType.DAILYDEPOSIT]: "DailyDepositReportCorporate",
  [ReportType.DAILYDEPOSITLOCATION]: "DailyDepositReportLocation",
  [ReportType.DAILYTRANSMITTEDACTIVITY]: "DepositActivityByLocation",
  [ReportType.DEBITCARDPRODUCTACCEPTANCE]:
    "DebitCardProductAcceptanceByLocation",
  [ReportType.DEBITCARDPRODUCTACCEPTANCETREND]:
    "DebitCardProductAcceptanceTrend",
  [ReportType.DEPOSITACTIVITY]: "DepositActivityByLocation",
  [ReportType.DEPOSITACTIVITYTREND]: "DepositActivityTrend",
  [ReportType.DISPUTEAGING]: "WeeklyChargebackRFCAgingReportCorporate",
  [ReportType.DISPUTEAGINGLOCATION]: "WeeklyChargebackRFCAgingReportLocation",
  [ReportType.MONTHLYSTATEMENTLOCATION]: "MonthlyStatementLocation",
  [ReportType.MONTHLYSTATEMENT]: "MonthlyStatementCorporate",
  [ReportType.EXCESSIVECHARGEBACK]: "ExcessiveChargeback",
  [ReportType.EXCESSIVECREDIT]: "ExcessiveCredit",
  [ReportType.INTERCHANGEDETAIL]: "InterchangePerformanceDetail",
  [ReportType.INTERCHANGEPERFORMANCE]: "MonthlyInterchangeReportCorporate",
  [ReportType.INTERCHANGEPERFORMANCELOCATION]:
    "MonthlyInterchangeReportLocation",
  [ReportType.INTERCHANGEQUALIFICATIONPERFORMANCE]:
    "InterchangeQualificationPerformanceByLocation",
  [ReportType.INTERCHANGEQUALIFICATIONTREND]:
    "InterchangeQualificationPerformanceTrend",
  [ReportType.MERCHANTFEES]: "MerchantFeesByLocation",
  [ReportType.MERCHANTFEESTREND]: "MerchantFeesTrend",
  [ReportType.MERCHANTREFERENCESHEET]: "MerchantReferenceSheet",
  [ReportType.POINTOFACCEPTANCE]: "PointOfAcceptancePerformanceByLocation",
  [ReportType.POINTOFACCEPTANCETREND]: "PointOfAcceptancePerformanceTrend",
  [ReportType.RFCAGING]: "ChargebackRFCAging",
  [ReportType.RFCPERFORMANCE]: "RFCPerformanceByLocation",
  [ReportType.RFCPERFORMANCETREND]: "RFCPerformanceTrend",
  [ReportType.REFUNDAUDEXCEPTION]: "RefundAudException",
};
export type Role =
  | "InternalUser"
  | "SuperSearch"
  | "TransmittedActivity"
  | "DepositActivity"
  | "ChargebackRFCAging"
  | "ChargebackRFCClosed"
  | "DailyDepositReportLocation"
  | "WeeklyChargebackRFCAgingReportLocation"
  | "MonthlyStatementLocation"
  | "MonthlyInterchangeReportLocation"
  | "DailyDepositReportCorporate"
  | "WeeklyChargebackRFCAgingReportCorporate"
  | "MonthlyStatementCorporate"
  | "MonthlyInterchangeReportCorporate"
  | "ChargebackPerformanceByLocation"
  | "ChargebackPerformanceByReason"
  | "ClosedChargebackPerformanceByLocation"
  | "ClosedChargebackPerformanceByReason"
  | "RFCPerformanceByLocation"
  | "CardBrandPerformanceByLocation"
  | "CardProductAcceptanceByLocation"
  | "DebitCardProductAcceptanceByLocation"
  | "DepositActivityByLocation"
  | "InterchangePerformanceDetail"
  | "InterchangeQualificationPerformanceByLocation"
  | "InternationalCardAcceptanceByLocation"
  | "MerchantFeesByLocation"
  | "PointOfAcceptancePerformanceByLocation"
  | "RefundAudLocation"
  | "ExcessiveChargeback"
  | "ExcessiveCredit"
  | "RefundAudException"
  | "CardBrandPerformanceTrend"
  | "ChargebackPerformanceTrend"
  | "ClosedChargebackPerformanceTrend"
  | "RFCPerformanceTrend"
  | "CardProductAcceptanceTrend"
  | "DebitCardProductAcceptanceTrend"
  | "DepositActivityTrend"
  | "InterchangeQualificationPerformanceTrend"
  | "InternationalCardAcceptanceTrend"
  | "MerchantFeesTrend"
  | "PointOfAcceptancePerformanceTrend"
  | "RefundAudTrend"
  | "IssuerLookup"
  | "BestPractices"
  | "ClientNotices"
  | "DataSecurity"
  | "Newsletters"
  | "ReferenceDocuments"
  | "ChangePassword"
  | "UserPrivileges"
  | "BusinessInformation"
  | "MerchantReferenceSheet"
  | "OutletListing"
  | "SettlementAccountInformation"
  | "FakeTestRole"
  | "test-role"

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchRequestToJSON = exports.BatchRequestFromJSONTyped = exports.BatchRequestFromJSON = void 0;
function BatchRequestFromJSON(json) {
    return BatchRequestFromJSONTyped(json, false);
}
exports.BatchRequestFromJSON = BatchRequestFromJSON;
function BatchRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNbr': json['merchantNbr'],
        'dateLowString': json['dateLowString'],
        'divisionName': json['divisionName'],
        'jcb': json['jcb'],
        'dateHighString': json['dateHighString'],
        'outletName': json['outletName'],
        'amex': json['amex'],
        'discover': json['discover'],
        'mastercard': json['mastercard'],
        'visa': json['visa'],
    };
}
exports.BatchRequestFromJSONTyped = BatchRequestFromJSONTyped;
function BatchRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNbr': value.merchantNbr,
        'dateLowString': value.dateLowString,
        'divisionName': value.divisionName,
        'jcb': value.jcb,
        'dateHighString': value.dateHighString,
        'outletName': value.outletName,
        'amex': value.amex,
        'discover': value.discover,
        'mastercard': value.mastercard,
        'visa': value.visa,
    };
}
exports.BatchRequestToJSON = BatchRequestToJSON;

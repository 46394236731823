import { useState, useEffect, useMemo, useCallback } from "react";
import { DepositDetail } from "../models/deposit_detail";
import {
  TransmittedActivity,
  TransmittedActivitySearchParameters,
  TransmittedService,
} from "../services/transmitted";
import { usePagination } from "./use_pagination";
import { isEmpty as lodashIsEmpty } from "lodash";

export type RequiredDepositDetailParameters = Omit<
  TransmittedActivitySearchParameters,
  | "pageOffsetBatch"
  | "pageOffsetCardType"
  | "pageOffsetDate"
  | "pageOffsetMerchantNumber"
  | "pageOffsetOutletNumber"
  | "pageOffsetTrans"
  | "pageOffsetSummary"
>;

export type DrilldownFields = Pick<
  TransmittedActivitySearchParameters,
  | "filterMerchantNumber"
  | "filterBatchNumber"
  | "filterCardType"
  | "filterCardType2"
  | "filterDate"
  | "filterOutletNumber"
>;
type DrilldownFieldNames = keyof DrilldownFields;
const PAGE_SIZE = 5;
export function useDepositDetails(
  transmittedService: TransmittedService,
  parameters?: RequiredDepositDetailParameters
) {
  const [transmittedActivity, setTransmittedActivity] =
    useState<TransmittedActivity>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [isEmpty, setIsEmpty] = useState(false);
  const [drilldownSelections, setDrilldownSelections] = useState<
    Partial<Record<DrilldownFieldNames, Partial<DepositDetail>>>
  >({});
  // const existingTransactionIsSaleOrCredit = useMemo(
  //   () =>
  //     defaultSearchParameters?.transactionType === "SALE" ||
  //     defaultSearchParameters?.transactionType === "CREDIT",
  //   [defaultSearchParameters?.transactionType]
  // );
  const requestParameters: RequiredDepositDetailParameters | undefined =
    useMemo<RequiredDepositDetailParameters | undefined>(() => {
      if (!parameters) {
        return undefined;
      }
      const isDrilldownActive = !lodashIsEmpty(drilldownSelections);
      return {
        ...parameters,
        filterDate: drilldownSelections?.filterDate?.reportDate,
        filterBatchNumber: drilldownSelections?.filterBatchNumber?.batchNumber ?? parameters.filterBatchNumber,
        filterCardType: drilldownSelections?.filterCardType?.cardType ?? parameters.filterCardType,
        filterCardType2: parameters.filterCardType,
        filterMerchantNumber:
          drilldownSelections?.filterMerchantNumber?.merchantNumber ?? parameters.filterMerchantNumber,
        filterOutletNumber:
        isDrilldownActive ? drilldownSelections?.filterOutletNumber?.outletNumber : parameters.filterOutletNumber,
        transactionAmount: isDrilldownActive ? undefined : parameters.transactionAmount,
        authCode: isDrilldownActive ? undefined : parameters.authCode,
        cardNumber: isDrilldownActive ? undefined : parameters.cardNumber,
      };
    }, [parameters, drilldownSelections]);
  const batchPagination = usePagination(
    PAGE_SIZE,
    transmittedActivity?.batchDetails?.totalResults
  );
  const cardPagination = usePagination(
    PAGE_SIZE,
    transmittedActivity?.cardTypeDetails?.totalResults
  );
  const datePagination = usePagination(
    PAGE_SIZE,
    transmittedActivity?.dateDetails?.totalResults
  );
  const merchantPagination = usePagination(
    PAGE_SIZE,
    transmittedActivity?.merchantDetails?.totalResults
  );
  const outletPagination = usePagination(
    PAGE_SIZE,
    transmittedActivity?.outletDetails?.totalResults
  );
  const summaryPagination = usePagination(
    PAGE_SIZE,
    transmittedActivity?.summaryDetails?.totalResults
  );
  const transactionPagination = usePagination(
    PAGE_SIZE,
    transmittedActivity?.transactionDetails?.totalResults
  );
  const { firstPage: batchFirstPage, page: batchPageNumber } = batchPagination;
  const { firstPage: cardFirstPage, page: cardPageNumber } = cardPagination;
  const { firstPage: dateFirstPage, page: datePageNumber } = datePagination;
  const { firstPage: outletFirstPage, page: outletPageNumber } =
    outletPagination;
  const { firstPage: transactionFirstPage } = transactionPagination;
  const { firstPage: merchantFirstPage } = merchantPagination;
  const { page: merchantPageNumber } = merchantPagination;
  const clearPagination = useCallback(() => {
    merchantFirstPage();
    batchFirstPage();
    cardFirstPage();
    dateFirstPage();
    outletFirstPage();
    transactionFirstPage();
    batchPagination.setInitialPaginationOffset(0);
    cardPagination.setInitialPaginationOffset(0);
    datePagination.setInitialPaginationOffset(0);
    merchantPagination.setInitialPaginationOffset(0);
    outletPagination.setInitialPaginationOffset(0);
    summaryPagination.setInitialPaginationOffset(0);
    transactionPagination.setInitialPaginationOffset(0);
    // eslint-disable-next-line
  }, [
    batchFirstPage,
    merchantFirstPage,
    cardFirstPage,
    dateFirstPage,
    outletFirstPage,
    transactionFirstPage,
  ]);
  const setDrilldown = useCallback(
    (field: DrilldownFieldNames, value: Partial<DepositDetail>) => {
      setDrilldownSelections((previous) => {
        switch (field) {
          case "filterMerchantNumber":
            batchFirstPage();
            cardFirstPage();
            dateFirstPage();
            outletFirstPage();
            transactionFirstPage();
            return {
              filterMerchantNumber: value,
            };
          case "filterDate":
            batchFirstPage();
            cardFirstPage();
            outletFirstPage();
            transactionFirstPage();
            return {
              ...previous,
              filterDate: value,
              filterCardType: undefined,
              filterBatchNumber: undefined,
              filterOutletNumber: undefined,
            };
          case "filterOutletNumber":
            batchFirstPage();
            cardFirstPage();
            transactionFirstPage();
            return {
              ...previous,
              filterOutletNumber: value,
              filterCardType: undefined,
              filterBatchNumber: undefined,
            };
          case "filterCardType":
            batchFirstPage();
            transactionFirstPage();
            return {
              ...previous,
              filterCardType: value,
              filterBatchNumber: undefined,
            };
          case "filterBatchNumber":
            transactionFirstPage();
            return {
              ...previous,
              filterBatchNumber: value,
            };
        }
        return previous;
      });
    },
    [
      batchFirstPage,
      cardFirstPage,
      dateFirstPage,
      outletFirstPage,
      transactionFirstPage,
    ]
  );
  const clearDrilldown = useCallback(() => {
    setDrilldownSelections({});
  }, []);

  useEffect(() => {
    dateFirstPage();
  }, [merchantPageNumber, dateFirstPage]);

  useEffect(() => {
    setDrilldownSelections({});
  }, [merchantPageNumber]);

  useEffect(() => {
    setDrilldownSelections(previous => ({
      ...previous,
      filterDate: undefined,
      filterOutletNumber: undefined,
      filterCardType: undefined,
      filterCardType2: undefined,
      filterBatchNumber: undefined,
    }));
    outletFirstPage();
    cardFirstPage();
    transactionFirstPage();
    batchFirstPage();
  }, [datePageNumber, transactionFirstPage, cardFirstPage, outletFirstPage, batchFirstPage]);

  useEffect(() => {
    setDrilldownSelections(previous => ({
      ...previous,
      filterOutletNumber: undefined,
      filterCardType: undefined,
      filterCardType2: undefined,
      filterBatchNumber: undefined,
    }));
    cardFirstPage();
    transactionFirstPage();
    batchFirstPage();
  }, [outletPageNumber, transactionFirstPage, batchFirstPage, cardFirstPage]);

  useEffect(() => {
    setDrilldownSelections(previous => ({
      ...previous,
      filterCardType: undefined,
      filterCardType2: undefined,
      filterBatchNumber: undefined,
    }));
    transactionFirstPage();
    batchFirstPage();
  }, [cardPageNumber, transactionFirstPage, batchFirstPage]);
  
  useEffect(() => {
    setDrilldownSelections(previous => ({
      ...previous,
      filterBatchNumber: undefined,
    }));
    transactionFirstPage();
  }, [batchPageNumber, transactionFirstPage]);
  
  const compiledPagination = useMemo(() => {
    return {
      pageOffsetBatch: batchPagination.page,
      pageOffsetCardType: cardPagination.page,
      pageOffsetDate: datePagination.page,
      pageOffsetMerchantNumber: merchantPagination.page,
      pageOffsetOutletNumber: outletPagination.page,
      pageOffsetTrans: transactionPagination.page,
      pageOffsetSummary: summaryPagination.page,
    };
  }, [
    batchPagination.page,
    cardPagination.page,
    datePagination.page,
    summaryPagination.page,
    merchantPagination.page,
    outletPagination.page,
    transactionPagination.page,
  ]);
  useEffect(() => {
    const handler = setTimeout(() => {
      if (!requestParameters) {
        return;
      }
      setIsLoading(true);
  
      transmittedService
        .getTransmittedActivity({
          ...requestParameters,
          ...compiledPagination,
        })
        .then((transmittedActivity) => {
          setTransmittedActivity(transmittedActivity);
          setIsEmpty(transmittedActivity.isEmpty);
          setIsLoading(false);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => setIsLoading(false));
    }, 500);

    return () => {
      clearTimeout(handler);
    }
  }, [transmittedService, compiledPagination, requestParameters]);
  return {
    isLoading,
    error,
    isEmpty,
    drilldownSelections,
    setDrilldownSelections: setDrilldown,
    clearDrilldown,
    clearPagination,
    transmittedActivity,
    requestParameters,
    pagination: {
      batchPagination,
      cardPagination,
      datePagination,
      summaryPagination,
      merchantPagination,
      outletPagination,
      transactionPagination,
    },
  };
}

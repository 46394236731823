import React from "react";
import { useCardDetails } from "../../hooks/use_card_details";
import { ValidatedSearchPage } from "../../layout/search_page";
import { CardService } from "../../services/card_service";
import { IssuerLookupResult } from "./issuer_lookup_result";
import { IssuerLookupSearchPanel } from "./issuer_lookup_search_panel";
import "./issuer_lookup.scss";
import { PageTitle } from "../../components/page_title";
export interface IssuerLookupContainerProps {
  cardService: CardService;
}
export function IssuerLookupContainer({
  cardService,
}: IssuerLookupContainerProps) {
  const [cardNumber, setCardNumber] = React.useState<string>("");
  const { loading, result, cardDetails } = useCardDetails(cardService, cardNumber);

  return (
    <div className="issuerLookup-wrapper">
      <div className="title-bar">
        <PageTitle title="Issuer Look-up" />
      </div>
      <ValidatedSearchPage
        isLoading={loading}
        searchPanel={
          <IssuerLookupSearchPanel
            onSearch={(cardNumber) => setCardNumber(cardNumber)}
          />
        }
        errorResults={result}
        searchResults={
          cardDetails && <IssuerLookupResult cardDetails={cardDetails} />
        }
      />
    </div>
  );
}

import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";

import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';

export interface IssuerLookupSearchPanelProps {
  onSearch: (search: string) => void;
}
export function IssuerLookupSearchPanel({
  onSearch,
}: IssuerLookupSearchPanelProps) {
  const { register, formState, handleSubmit } = useForm({ mode: 'onSubmit', reValidateMode: 'onSubmit' });

  const cardNumberErrorTooltipRef = useRef<TooltipRefProps>(null);

  useEffect(() => {
    if (formState.errors.cardNumber) cardNumberErrorTooltipRef.current?.open();
  }, [
    formState.errors.cardNumber
  ]);

  return (
    <div className="issuerLookup">
      <form>
        <table className="table is-bordered">
          <tbody>
            <tr>
              <th colSpan={2} className="is-uppercase">
                Enter First 8-Digits of Card Number
              </th>
            </tr>
            <tr>
              <td className="has-text-centered is-vcentered">
                <input
                  data-tooltip-id={!!formState.errors["cardNumber"] ? "error-tooltip-card-number" : undefined}
                  data-tooltip-content={
                    !!formState.errors["cardNumber"] ?
                      formState.errors["cardNumber"].message as string :
                      undefined
                  }
                  className={`${!!formState.errors["cardNumber"] ? 'input-error ' : ''}issuerLookupInput`}
                  data-testid="issuer-lookup-input"
                  type="text"
                  pattern="[0-9]*"
                  required
                  {...register("cardNumber", {
                    required: "This value is required",
                    validate: {
                      onlyDigits: (value) => {
                        return !isNaN(value) || "Only digits allowed"
                      },
                      length: (value) => {
                        return value.length === 8 || "Input must be 8 digits"
                      },
                    },
                  })}
                />
                <ReactTooltip
                  id="error-tooltip-card-number"
                  ref={cardNumberErrorTooltipRef}
                  opacity={1}
                  style={{
                    color: '#fff',
                    backgroundColor: '#cc0f35',
                    fontSize: '12px',
                  }}
                />
              </td>
              <td className="has-text-centered is-vcentered">
                <button
                  data-testid="issuer-lookup-search-button"
                  className="is-uppercase ie-styled"
                  type="submit"
                  onClick={
                    handleSubmit((data) => onSearch(data.cardNumber))
                  }
                >
                  Submit
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </form>
    </div>
  );
}

import { CardTypeLongDictionary } from "../services/transmitted";

export type DDA = {
  last4?: string;
  netDeposit?: string;
};
export class DepositDetail {
  public batchNumber: string = "";
  public cardNumber?: string;
  public cardType?: string;
  public dailyFees: string = "";
  public miscAdj: string = "";
  public netActivity: string = "";
  public netDeposit: string = "";
  public outletName: string = "";
  public outletNumber: string = "";
  public posEntryMode: string = "";
  public reasonCode: string = "";
  public reportDate?: Date;
  public ddas: DDA[] = [];
  public totalChargebackCount: string = "";
  public totalChargebackVolume: string = "";
  public totalCreditTransactionCount: string = "";
  public totalCreditTransactionVolume: string = "";
  public totalNetSalesTransactionCount: string = "";
  public totalNetSalesTransactionVolume: string = "";
  public description: string = "";
  public totalSalesTransactionsCount: string = "";
  public totalSalesTransactionsVolume: string = "";
  public transactionAmount?: string;
  public transactionDate?: Date;
  public settleDate?: Date;
  public cardNumberMasked?: string;
  public transactionType: string = "";
  public get fullCardTypeName() {
    if (!this.cardType) {
      return undefined;
    }
    return (CardTypeLongDictionary as any)[this.cardType];
  }
  constructor(public merchantNumber?: string, public merchantName?: string) {}
}
export class LegacyDepositDetail {
  public amount: number = 0;
  public transactionDate?: Date;
  public settleDate?: Date;
  public billingDate?: Date;
  public rowNumber: number = 0;
  public reportDate: string = "";
  public formattedReportDate: string = "";
  public merchantNbr: string = "";
  public merchantName: string = "";
  public divisionNbr: string = "";
  public divisionName: string = "";
  public outletNbr: string = "";
  public outletName: string = "";
  public merchantBatchNbr: string = "";
  public grossItems: string = "";
  public grossVolume: string = "";
  public creditItems: string = "";
  public creditVolume: string = "";
  public visaGrossItems: string = "";
  public visaGrossVolume: string = "";
  public visaCreditItems: string = "";
  public visaCreditVolume: string = "";
  public visaNetSales: string = "";
  public visaNetVolume: string = "";
  public mcGrossItems: string = "";
  public mcGrossVolume: string = "";
  public mcCreditItems: string = "";
  public mcCreditVolume: string = "";
  public mcNetSales: string = "";
  public mcNetVolume: string = "";
  public amexGrossItems: string = "";
  public amexGrossVolume: string = "";
  public amexCreditItems: string = "";
  public amexCreditVolume: string = "";
  public amexNetSales: string = "";
  public amexNetVolume: string = "";
  public discoverGrossItems: string = "";
  public discoverGrossVolume: string = "";
  public discoverCreditItems: string = "";
  public discoverCreditVolume: string = "";
  public discoverNetSales: string = "";
  public discoverNetVolume: string = "";
  public jcbGrossItems: string = "";
  public jcbGrossVolume: string = "";
  public jcbCreditItems: string = "";
  public jcbCreditVolume: string = "";
  public jcbNetSales: string = "";
  public jcbNetVolume: string = "";
  public netSales: string = "";
  public netVolume: string = "";
  public netRejects: string = "";
  public netChargebackItems: string = "";
  public netChargebacks: string = "";
  public dailyFees: string = "";
  public netDeposit: string = "";
  public cardType: string = "";
  public cardNumber: string = "";
  public cardNumberMasked: string = "";
  public formattedCardNumber: string = "";
  public formattedCardType: string = "";
  public cardTypes: string[] = [];
  public beginDate: string = "";
  public endDate: string = "";
  public merchantDDA1: string = "";
  public last4DDA1: string = "";
  public netDepositDDA1: string = "";
  public merchantDDA2: string = "";
  public last4DDA2: string = "";
  public netDepositDDA2: string = "";
  public merchantDDA3: string = "";
  public last4DDA3: string = "";
  public netDepositDDA3: string = "";
  public merchantDDA4: string = "";
  public last4DDA4: string = "";
  public netDepositDDA4: string = "";
  public merchantDDA5: string = "";
  public last4DDA5: string = "";
  public netDepositDDA5: string = "";
  public numberDDAs: string = "";
  public region: string = "";
  public reasonCode: string = "";
  public tranType: string = "";
  public formattedTranDate: string = "";
  public formattedSettleDate: string = "";
  public formattedBillingDate: string = "";
  public formattedAmount: string = "";
  public description: string = "";
  public assetOwner: string = "";
  public businessLegalName: string = "";
}

import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./main.scss";
import "./main_container.scss";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import { useEffect } from "react";

const PromiseRejectionHandler = () => {
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    const promiseRejectionHandler = (event: PromiseRejectionEvent) => {
      showBoundary('');
    }
    window.addEventListener('unhandledrejection', promiseRejectionHandler);
    return () => {
      window.removeEventListener('unhandledrejection', promiseRejectionHandler);
    }
  })

  return <div />
}

const UnhandledErrorMessage = () => {
  return (
    <div style={{ padding: '16px 0' }}>
      <h1 style={{
        fontSize: 16,
        fontWeight: 700,
        color: 'red'
      }}>System Error</h1>
      <p style={{
        marginTop: 8,
      }}>We are currently experiencing system difficulties with the option you have chosen. You may continue using other areas of our website by returning to the <Link to="/">
          home page.
        </Link></p>
      <p style={{
        marginTop: 8,
      }}>Please contact POST Client Service should you continue to experiencing difficulties.</p>
      <p style={{
        marginTop: 8,
      }}>{(new Date()).toString()}</p>
    </div>
  );
}

export interface MainTemplateProps {
  children?: React.ReactNode;
  menuComponent?: JSX.Element;
  onLogOut?: () => void;
  footer?: React.ReactNode;
}
export const MainTemplate = ({
  children = <div />,
  menuComponent = <div />,
  footer,
}: MainTemplateProps) => {

  const location = useLocation();

  return (
    <div className="mx-2 is-flex is-flex-direction-column main-container">
      <div className="inner-page-container">
        <div
          className="p-1 columns is-gapless primary-bg m-0"
          style={{ width: "100%" }}
        >
          <Link to="/">
            <img
              id="brand-logo"
              alt="Post@Vantage logo"
              src={`${process.env.PUBLIC_URL}/images/postadvantage_home_01.jpg`}
            />
          </Link>
          <div className="is-flex is-justify-content-center is-flex-direction-row is-align-items-flex-end column">
            {menuComponent}
          </div>
        </div>
        <div className="is-flex-grow-1 inner-frame">
          <div className="inner-children">
            <ErrorBoundary fallback={<UnhandledErrorMessage />} key={location.pathname}>
              {children}
              <PromiseRejectionHandler />
            </ErrorBoundary>
          </div>
        </div>
        {footer}
      </div>
    </div>
  );
};

import { useMemo } from "react";
import { useLocation } from "react-router";
import querystring from "query-string";
import { CardType } from "../../models/card_type";
import { TransactionType } from "../../models/transaction";

export interface DepositActivityRouteProps {
  children: (parameters?: DepositActivityRouteChildParameters) => JSX.Element;
}
export interface DepositActivityRouteChildParameters {
  date?: Date;
  merchantNumber?: string;
  outletNumber?: string;
  authCode?: string;
  transactionAmount?: string;
  loadFileNumber?: string;
  loadEntryNumber?: string;
  loadBatchNumber?: string;
  cardType?: CardType;
  cardNumber?: string;
  transactionType?: TransactionType;
}
export interface TxParams {
  accountNumber?: string;
  loadFileNumber?: string;
  loadBatchNumber?: string;
  loadEntryNumber?: string;
  date?: string;
  tranAmount?: string;
  authCode?: string;
  cardType?: string;
  merchantNumber?: string;
  outletNumber?: string;
  cardNumber?: string;
  transactionType?: string;
}
export function DepositActivityRoute({ children }: DepositActivityRouteProps) {
  const location = useLocation();
  const { q } = querystring.parse(location.search);
  const parametersString = typeof q === "string" ? atob(q) : undefined;
  const rawParameters: TxParams | undefined = parametersString
    ? JSON.parse(parametersString)
    : undefined;
  const parsedParams: DepositActivityRouteChildParameters =
    useMemo<DepositActivityRouteChildParameters>(() => {
      return {
        date: rawParameters?.date
          ? new Date(Date.parse(rawParameters.date))
          : undefined,
        outletNumber: rawParameters?.outletNumber,
        merchantNumber: rawParameters?.merchantNumber,
        authCode: rawParameters?.authCode,
        transactionAmount: rawParameters?.tranAmount,
        loadFileNumber: rawParameters?.loadFileNumber,
        loadEntryNumber: rawParameters?.loadEntryNumber,
        loadBatchNumber: rawParameters?.loadBatchNumber,
        cardType: rawParameters?.cardType as CardType,
        cardNumber: rawParameters?.cardNumber ?? rawParameters?.accountNumber,
        accountNumber: rawParameters?.accountNumber,
        transactionType: rawParameters?.transactionType as TransactionType,
      };
    }, [
      rawParameters?.authCode,
      rawParameters?.accountNumber,
      rawParameters?.transactionType,
      rawParameters?.cardNumber,
      rawParameters?.cardType,
      rawParameters?.loadBatchNumber,
      rawParameters?.loadEntryNumber,
      rawParameters?.date,
      rawParameters?.loadFileNumber,
      rawParameters?.merchantNumber,
      rawParameters?.outletNumber,
      rawParameters?.tranAmount,
    ]);
  if (typeof q === "string" && q.length > 0) {
    return children(parsedParams);
  }
  return children();
}

import { useState, useEffect } from "react";
import { ReportType } from "../models/report";
import { ReportDates, ReportService } from "../services/report";

export function useReportBeginDate(
  reportService: ReportService,
  reportType: ReportType
) {
  const [reportBeginDate, setReportBeginDate] = useState<
    ReportDates | undefined
  >();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    reportService
      .getReportBeginDate(reportType)
      .then(setReportBeginDate)
      .catch(console.error)
      .finally(() => setIsLoading(false));
  }, [reportService, reportType]);

  return { reportBeginDate, isLoading };
}

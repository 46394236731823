import React from "react";
import { UserEntity } from "../../models/user";
export interface AvailableUsersTableProps {
  users: UserEntity[];
  onSelectUser: (user: UserEntity) => void;
  isLoading?: boolean;
  highlightedUserId?: string;
}
export function AvailableUsersTable({
  users,
  onSelectUser,
  isLoading = false,
  highlightedUserId,
}: AvailableUsersTableProps) {
  return (
    <table className="table is-bordered is-fullwidth">
      <tbody>
        <tr className="has-text-centered" style={{ height: 41 }}>
          <th className="is-uppercase has-text-white">Detail</th>
          <th className="is-uppercase has-text-white">User Name</th>
          <th className="is-uppercase has-text-white">User Id</th>
          <th className="is-uppercase has-text-white">Phone</th>
          <th className="is-uppercase has-text-white">Email</th>
          <th className="is-uppercase has-text-white">Status</th>
        </tr>
      </tbody>
      {users.map((user) => {
        return (
          <tr
            style={{
              backgroundColor:
                highlightedUserId === user.id ? "yellow" : undefined,
            }}
          >
            <td className="has-text-centered is-vcentered has-background-white">
              <div
                data-testid="deposit-detail-button"
                onClick={isLoading ? () => {} : () => onSelectUser(user)}
              >
                <img
                  style={
                    isLoading
                      ? { filter: "grayscale(100%)", opacity: 0.3 }
                      : { cursor: "pointer" }
                  }
                  src={`${process.env.PUBLIC_URL}/images/detail.jpg`}
                  alt="Details"
                />
              </div>
            </td>
            <td className="has-text-centered is-vcentered">{user.name}</td>
            <td className="has-text-centered is-vcentered">{user.id}</td>
            <td className="has-text-centered is-vcentered">{user.phone}</td>
            <td className="has-text-centered is-vcentered">{user.email}</td>
            <td className="has-text-centered is-vcentered">
              <input
                name="status"
                type="checkbox"
                disabled={true}
                checked={!user.accountLocked}
              />
              &nbsp;
              <label htmlFor="status" className="is-uppercase">
                Enabled
              </label>
            </td>
          </tr>
        );
      })}
    </table>
  );
}

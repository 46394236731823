import { useCallback, useState } from "react";
import { ReportService } from "../services/report";
import useGlobalLoaderState from "../store/global_loader_state";

export function useDownload(
  reportService: ReportService,
  startCallback?: () => void,
  endCallback?: () => void
) {
  const [isDownloading, setIsDownloading] = useState(false);

  const {addRunningRequest, subtractRunningRequest} = useGlobalLoaderState(
    (state) => ({addRunningRequest: state.addRunningRequest, subtractRunningRequest: state.subtractRunningRequest})
  )

  const download = useCallback(
    async (url, defaultFilename = "") => {
      startCallback?.();
      setIsDownloading(true);
      addRunningRequest('report/generate');
      try {
        const [blob, filename] = await reportService.downloadReport(url);
        const link = document.createElement("a");
        link.download = filename ?? defaultFilename;
        link.href = window.URL.createObjectURL(blob);
        link.click();
      } finally {
        setIsDownloading(false);
        subtractRunningRequest('report/generate');
        endCallback?.();
      }
    },
    [setIsDownloading, reportService, startCallback, endCallback, addRunningRequest, subtractRunningRequest]
  );
  return { download, isDownloading };
}

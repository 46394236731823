"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsDataToJSON = exports.ReportsDataFromJSONTyped = exports.ReportsDataFromJSON = void 0;
var runtime_1 = require("../runtime");
function ReportsDataFromJSON(json) {
    return ReportsDataFromJSONTyped(json, false);
}
exports.ReportsDataFromJSON = ReportsDataFromJSON;
function ReportsDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'reportDt': !runtime_1.exists(json, 'reportDt') ? undefined : json['reportDt'],
        'idSequence': !runtime_1.exists(json, 'idSequence') ? undefined : json['idSequence'],
        'division': !runtime_1.exists(json, 'division') ? undefined : json['division'],
        'merchantNbr': !runtime_1.exists(json, 'merchantNbr') ? undefined : json['merchantNbr'],
        'reportPeriod': !runtime_1.exists(json, 'reportPeriod') ? undefined : json['reportPeriod'],
        'contactId': !runtime_1.exists(json, 'contactId') ? undefined : json['contactId'],
        'reportName': !runtime_1.exists(json, 'reportName') ? undefined : json['reportName'],
        'displayDt': !runtime_1.exists(json, 'displayDt') ? undefined : json['displayDt'],
        'startYear': !runtime_1.exists(json, 'startYear') ? undefined : json['startYear'],
        'year': !runtime_1.exists(json, 'year') ? undefined : json['year'],
        'month': !runtime_1.exists(json, 'month') ? undefined : json['month'],
    };
}
exports.ReportsDataFromJSONTyped = ReportsDataFromJSONTyped;
function ReportsDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'reportDt': value.reportDt,
        'idSequence': value.idSequence,
        'division': value.division,
        'merchantNbr': value.merchantNbr,
        'reportPeriod': value.reportPeriod,
        'contactId': value.contactId,
        'reportName': value.reportName,
        'displayDt': value.displayDt,
        'startYear': value.startYear,
        'year': value.year,
        'month': value.month,
    };
}
exports.ReportsDataToJSON = ReportsDataToJSON;

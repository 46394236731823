import { useCallback, useEffect, useState } from "react";
import { ChargebackClosed } from "../models/chargeback_closed";
import {
  ChargebackClosedFilter,
  ClosedChargebackSortColumn,
  DisputeService,
} from "../services/disputes/dispute_service";
import { usePagination } from "./use_pagination";
import { useSorting } from "./use_sorting";
export interface DisputeSearchParameters {}

const CLOSED_PAGE_SIZE = 500;
export function useClosedChargebacks(
  disputeService: DisputeService,
  disputeSearchParameters?: Omit<ChargebackClosedFilter, "pageSize">
) {
  const [closedDisputes, setClosedDisputes] =
    useState<Partial<ChargebackClosed>[]>();
  const [rfcs, setRfcs] = useState<Partial<ChargebackClosed>[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [totalChargeback, setTotalChargeback] = useState<number | undefined>(0);
  const [totalRfc, setTotalRfc] = useState<number | undefined>(0);
  const [isEmpty, setIsEmpty] = useState(true);
  const chargebackPagination = usePagination(CLOSED_PAGE_SIZE, totalChargeback);
  const rfcPagination = usePagination(CLOSED_PAGE_SIZE, totalRfc);
  const { handleSort, sorting } = useSorting<ClosedChargebackSortColumn>();
  const reset = useCallback(() => {
    handleSort();
    chargebackPagination.firstPage();
    rfcPagination.firstPage();
    setTotalChargeback(0);
    setTotalRfc(0);
    setIsEmpty(true);
    setRfcs(undefined);
    setClosedDisputes(undefined);
  }, [
    handleSort,
    setTotalChargeback,
    setTotalRfc,
    setIsEmpty,
    setRfcs,
    setClosedDisputes,
    chargebackPagination,
    rfcPagination,
  ]);
  useEffect(() => {
    if (!disputeSearchParameters?.merchantNbr) {
      return;
    }
    setIsLoading(true);
    disputeService
      .getClosedChargebacks(
        {
          ...disputeSearchParameters,
          pageSize: CLOSED_PAGE_SIZE,
          pageOffsetChargebacks: chargebackPagination.page,
        },
        {
          sortColumn: sorting?.field,
          sortOrder: sorting?.direction,
        }
      )
      .then((results) => {
        setIsEmpty(results.isEmpty);
        setClosedDisputes(results.chargebackClosed?.data);
        setTotalChargeback(results.chargebackClosed?.total);
        setRfcs(results.chargebackRFC?.data);
        setTotalRfc(results.chargebackRFC?.total);
      })
      .finally(() => setIsLoading(false));
  }, [
    disputeService,
    chargebackPagination.page,
    setClosedDisputes,
    setIsLoading,
    disputeSearchParameters,
    sorting,
  ]);
  return {
    closedDisputes,
    rfcs,
    totalChargeback,
    totalRfc,
    isEmpty,
    reset,
    sorting,
    isLoading,
    handleSort,
    pagination: { rfcPagination, chargebackPagination },
  };
}

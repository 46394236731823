import create from 'zustand';
import { persist, devtools } from 'zustand/middleware';

export type PassExpirationRedirectTime = {
    username: string,
    redirectTime: number
}

interface UserStoreState {
    passExpirationRedirectTime: PassExpirationRedirectTime | null,
    setPassExpirationRedirectTime: (passExpirationRedirectTime: PassExpirationRedirectTime | null) => void
}

const useUserStore = create<UserStoreState>()(
    devtools(
        persist(
            (set) => ({
                passExpirationRedirectTime: null,
                setPassExpirationRedirectTime: (passExpirationRedirectTime: PassExpirationRedirectTime | null) => set((state) => ({
                    ...state,
                    passExpirationRedirectTime: passExpirationRedirectTime,
                }))
            }),
            {
                name: 'user-storage',
            }
        )
    )
)

export default useUserStore;
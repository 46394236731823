import { useEffect, useState } from "react";
import { UserPrivilege } from "../models/user";
import { UserService } from "../services/user";

export function useUserPrivileges(userService: UserService, userId?: string) {
  const [userPrivileges, setUserPrivileges] = useState<UserPrivilege[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      setLoading(true);
      userService
        .getUserPrivileges(userId)
        .then((userPrivileges) => {
          setUserPrivileges(userPrivileges);
        })
        .finally(() => setLoading(false));
    }
  }, [userService, userId]);
  return { userPrivileges, loading };
}

import { Configuration, DocumentCategory, DocumentsApi } from "ebocomsdk";
import { Document, DocumentType } from "../../models/document";
import { DocumentService } from "../../services/document";
import { getPreAuthMiddleware } from "./auth_middleware";
import { getFilenameFromContentDisposition } from "./filename_extractor";
import { CONTENT_DISPOSITION } from "./constants";
import { PaginatedResult } from "../../models/paginated_result";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";

export class EbocomDocumentService implements DocumentService {
  private api: DocumentsApi;
  constructor(private baseUrl: string) {
    const config = new Configuration({
      basePath: baseUrl,
    });
    this.api = new DocumentsApi(config)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
  }
  async downloadDocument(document: Document): Promise<[Blob, string?]> {
    const docType = this.serializeDocumentType(document.type);
    if (docType && document.filename) {
      const file = await this.api.retrievePostDocRaw({
        category: docType,
        file: document.filename,
      });

      const filename = getFilenameFromContentDisposition(
        file.raw.headers.get(CONTENT_DISPOSITION) ?? ""
      );

      return [await file.raw.blob(), filename];
    } else {
      throw Error("Document type not found");
    }
  }
  private deserializeDocumentType(
    type?: DocumentCategory
  ): DocumentType | undefined {
    switch (type) {
      case DocumentCategory.Bestpractices:
        return DocumentType.BestPractices;
      case DocumentCategory.Clientnotices:
        return DocumentType.ClientNotices;
      case DocumentCategory.Datasecurity:
        return DocumentType.DataSecurity;
      case DocumentCategory.Newsletter:
        return DocumentType.Newsletter;
      case DocumentCategory.RefdocsOther:
        return DocumentType.RefdocsOther;
      case DocumentCategory.RefdocsPost:
        return DocumentType.RefdocsPost;
      default:
        return;
    }
  }
  private serializeDocumentType(
    type?: DocumentType
  ): DocumentCategory | undefined {
    switch (type) {
      case DocumentType.BestPractices:
        return DocumentCategory.Bestpractices;
      case DocumentType.ClientNotices:
        return DocumentCategory.Clientnotices;
      case DocumentType.DataSecurity:
        return DocumentCategory.Datasecurity;
      case DocumentType.Newsletter:
        return DocumentCategory.Newsletter;
      case DocumentType.RefdocsOther:
        return DocumentCategory.RefdocsOther;
      case DocumentType.RefdocsPost:
        return DocumentCategory.RefdocsPost;
      default:
        return;
    }
  }
  getDocuments = async (
    name: DocumentType
  ): Promise<PaginatedResult<Document>> => {
    const category = this.serializeDocumentType(name);
    if (category === undefined) {
      throw TypeError("Invalid document type");
    }

    const response = await this.api.getDocuments({ category });
    const documents = response.documents?.map((apiDoc) => {
      const doc = new Document(
        apiDoc.id ?? "",
        apiDoc.title ?? "",
        apiDoc.url ?? "",
        apiDoc.newWindowFlag ?? false
      );
      if (apiDoc.category) {
        doc.type = this.deserializeDocumentType(apiDoc.category!);
      }
      doc.isInternal = apiDoc.internalFlag ?? false;
      return doc;
    });
    return {
      data: documents ?? [],
      offset: response.offset ?? 0,
      total: response.totalResults,
    };
  };
}

import { Role } from "./roles";

export class User {
  constructor(
    public merchantId?: string,
    public email?: string,
    public name?: string,
    public username?: string,
    public roles: Role[] = [],
    public pwExpire?: string,
  ) {}
  public id?: string;
  public phone?: string;
  public accountLocked: boolean = false;
}

export class UserEntity {
  constructor(
    public id?: string,
    public name?: string,
    public phone?: string,
    public email?: string,
    public accountLocked?: boolean
  ) {}
}

export class UserPrivilege {
  constructor(
    public id?: string,
    public order?: number,
    public category?: string,
    public option?: string,
    public enabled?: boolean,
    public title?: UserPrivilegeTitle
  ) {}
}
export enum UserPrivilegeTitle {
  reference,
  daily,
  management,
}

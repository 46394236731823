import React from "react";
import { EbocomWarningMessage } from "../components/ebocom_warning_message";
import "./search_page.scss";

export interface SearchPageProps {
  searchPanel?: React.ReactNode;
  searchResults?: React.ReactNode;
  title?: React.ReactNode;
  isLoading?: boolean;
}
export interface ValidatedSearchPageProps {
  searchPanel?: React.ReactNode;
  searchResults?: React.ReactNode;
  errorResults?: React.ReactNode;
  title?: React.ReactNode;
  isLoading?: boolean;
}

export enum LookupResult {  
  OK = 0,
  StillLoading,
  ObjectDoesNotExist,
  MultipleObjectsReturned,
  IssuerValidationError,
}

export function SearchPage({
  searchPanel = null,
  searchResults = null,
}: SearchPageProps) {
  return (
    <div>
      {searchPanel}
      <div className="search-results">{searchResults}</div>
    </div>
  );
}

export function ValidatedSearchPage({
  searchPanel = null,
  searchResults = null,
  errorResults = null,
  isLoading = false,
}: ValidatedSearchPageProps) {
  return (
    <div className="validated-search">
      {searchPanel}
        {
          (errorResults?.valueOf() === LookupResult.StillLoading || isLoading) ? null :
          errorResults?.valueOf() === LookupResult.IssuerValidationError ? 
          <EbocomWarningMessage message={"Please Enter First 8-Digits of Card Number"} /> : 
          errorResults?.valueOf() === LookupResult.ObjectDoesNotExist ?
          <EbocomWarningMessage message={"WARNING: No data found - Please refine your search"} /> : 
          errorResults?.valueOf() === LookupResult.MultipleObjectsReturned ?
          <EbocomWarningMessage message={"Database Error"} /> : <div className="search-results-container">{searchResults}</div>
          }
    </div>
  );
}

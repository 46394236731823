import React, { useEffect } from "react";
import { EbocomMerchantSearchPanel } from "../../components/ebocom_merchant_search";
import { TableTitle } from "../../components/table_title";
import { useDebounce } from "../../hooks/use_debounce";
import { useDefaultMerchant } from "../../hooks/use_default_merchant";
import { useMerchants } from "../../hooks/use_merchant";
import { useMerchantDetails } from "../../hooks/use_merchant_details";
import { ValidatedSearchPage } from "../../layout/search_page";
import { addSuperMerchantNumber } from "../../models/merchant";
import { LookupResult } from "../../layout/search_page";
import { downloadExcelFile } from "../../services/download";
import { MerchantService } from "../../services/merchant";
import { BusinessInformationResults } from "./business_information_results";
import { PageTitle } from "../../components/page_title";

export interface BusinessInformationContainerProps {
  merchantService: MerchantService;
}
export function BusinessInformationContainer({
  merchantService,
}: BusinessInformationContainerProps) {
  const [merchantNumber, setMerchantNumber] = React.useState("");
  const {
    merchants: merchantDetails,
    isLoading,
    sorting,
    pagination,
    exportToExcel,
    handleSort,
    reset,
  } = useMerchantDetails(merchantService, merchantNumber);
  const debounce = useDebounce(1000);
  const { merchants } = useMerchants(merchantService);
  const { selectedMerchant, updateSelectedMerchant } = useDefaultMerchant();
  useEffect(() => {
    if (selectedMerchant && selectedMerchant !== merchantNumber) {
      setMerchantNumber(selectedMerchant);
    }
  }, [selectedMerchant, merchantNumber]);
  return (
    <div className="businessInformation-wrapper">
      <div className="title-bar">
        <PageTitle title="Business Information" />
      </div>
      <ValidatedSearchPage
        searchPanel={
          <EbocomMerchantSearchPanel
            defaultMerchantId={selectedMerchant}
            merchants={addSuperMerchantNumber(merchants)}
            onSelectMerchant={(merchant) => {
              debounce(() => {
                reset();
                setMerchantNumber(merchant.number);
              });
              updateSelectedMerchant(merchant.number);
            }}
          />
        }
        isLoading={isLoading}
        errorResults={pagination.totalResults === 0 ? isLoading ? LookupResult.StillLoading : LookupResult.ObjectDoesNotExist : LookupResult.OK}
        searchResults={
          <>
            <TableTitle
              title="Business Entity Detail"
              onPageFirst={pagination.firstPage}
              onPageLast={pagination.lastPage}
              onPagePrevious={pagination.previousPage}
              onPageNext={pagination.nextPage}
              currentResultCount={merchantDetails.length}
              totalResults={pagination.totalResults}
              offset={pagination.offset}
              pageSize={pagination.pageSize}
              onExport={async () => {
                const response = await exportToExcel();
                if (!response) return;
                downloadExcelFile(response[0], response[1]);
              }}
            />
            <BusinessInformationResults
              onSort={handleSort}
              sortColumn={sorting?.field ?? "merchantName"}
              sortDirection={sorting?.direction}
              merchants={merchantDetails}
            />
          </>
        }
      />
    </div>
  );
}

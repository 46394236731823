import React from "react";
import { Caret } from "../../../components/caret";
import { Clickable } from "../../../components/clickable";
import { SortTooltip } from "../../../components/sort_tooltip";
import { SortOrder } from "../../../models/sort_order";
import { AgingChargebackSortColumn } from "../../../services/disputes/dispute_service";

interface Column {
  header: React.ReactNode;
  sortColumn?: AgingChargebackSortColumn;
  cell: (chargeback: OutstandingChargebackTableItem) => React.ReactNode;
  width: string | number;
}

export interface OutstandingChargebackTableProps {
  chargebacks: OutstandingChargebackTableItem[];
  onRequestAttach?(): void;
  onRequestPrint?(chargeback: OutstandingChargebackTableItem): void;
  onSort(column: AgingChargebackSortColumn): void;
  sortColumn?: AgingChargebackSortColumn;
  sortDirection?: SortOrder;
  isLoading?: boolean;
}

export interface OutstandingChargebackTableItem {
  id: string;
  daysRemaining?: string;
  dueDate?: string;
  dateReceived?: string;
  merchantNumber?: string;
  merchantName?: string;
  outletname?: string;
  cardNumber?: string;
  txDate?: string;
  disputeAmount?: string;
  disputeReason?: string;
  loadBatchNumber?: string;
  loadEntryNumber?: string;
  loadFileNumber?: string;
}

export function OutstandingChargebackTable({
  chargebacks,
  sortColumn,
  sortDirection,
  onSort,
  onRequestAttach,
  onRequestPrint,
  isLoading = false,
}: OutstandingChargebackTableProps) {
  const columns: Column[] = [
    {
      header: "Print",
      width: "25",
      cell: (chbk) => (
        <img
          style={{
            ...(isLoading
              ? { cursor: "default", filter: "grayscale(100%)" }
              : { cursor: "pointer" }),
          }}
          width={26}
          src={`${process.env.PUBLIC_URL}/images/pdficon.jpg`}
          onClick={() => onRequestPrint?.(chbk)}
          alt="Print chargeback notice"
        />
      ),
    },
    {
      header: "Days Remaining",
      sortColumn: AgingChargebackSortColumn.daysRemaining,
      cell: (chbk) => chbk.daysRemaining,
      width: "60",
    },
    {
      header: "Due Date",
      sortColumn: AgingChargebackSortColumn.dueDate,
      cell: (chbk) => chbk.dueDate,
      width: 50,
    },
    {
      header: (
        <span>
          Date
          <br />
          REC'D
        </span>
      ),
      sortColumn: AgingChargebackSortColumn.dateReceived,
      cell: (chbk) => chbk.dateReceived,
      width: 45,
    },
    {
      header: "MERCH #",
      sortColumn: AgingChargebackSortColumn.merchantNumber,
      cell: (chbk) => chbk.merchantNumber,
      width: 68,
    },
    {
      header: "Merchant Name",
      sortColumn: AgingChargebackSortColumn.merchantName,
      cell: (chbk) => <div className="has-text-left">{chbk.merchantName}</div>,
      width: 133,
    },
    {
      header: "Outlet Name",
      sortColumn: AgingChargebackSortColumn.outletName,
      cell: (chbk) => <div className="has-text-left">{chbk.outletname}</div>,
      width: 165,
    },
    {
      header: "Card Number",
      sortColumn: AgingChargebackSortColumn.cardNumber,
      cell: (chbk) => chbk.cardNumber,
      width: 100,
    },
    {
      header: "Tran Date",
      sortColumn: AgingChargebackSortColumn.tranDate,
      cell: (chbk) => chbk.txDate,
      width: 50,
    },
    {
      header: "Dispute Amount",
      sortColumn: AgingChargebackSortColumn.disputeAmount,
      cell: (chbk) => (
        <div className="has-text-right">{chbk.disputeAmount}</div>
      ),
      width: 70,
    },
    {
      header: "Dispute Reason",
      sortColumn: AgingChargebackSortColumn.disputeReason,
      cell: (chbk) => <div className="has-text-left">{chbk.disputeReason}</div>,
      width: 160,
    },
    {
      header: "Attach Docs",
      cell: (chbk) => (
        <div onClick={onRequestAttach} style={{ cursor: "pointer" }}>
          <img
            alt="Attach Docs"
            src={`${process.env.PUBLIC_URL}/images/upload.png`}
          />
        </div>
      ),
      width: "",
    },
  ];
  return (
    <table className="table is-bordered has-text-centered m-0 p-0">
      <colgroup>
        {columns.map((col) => (
          <col key={col.header?.toString()} style={{ width: col.width }} />
        ))}
      </colgroup>
      <tbody>
        <tr style={{ height: 38 }}>
          {columns.map((c) => {
            const child = (
              <span
                style={{
                  color: c.sortColumn === sortColumn ? "yellow" : "white",
                }}
              >
                {c.sortColumn !== undefined ? (
                  <Clickable>{c.header}</Clickable>
                ) : (
                  c.header
                )}
                <Caret.Auto
                  currentColumn={sortColumn}
                  wantedColumn={c.sortColumn}
                  data-testid="caret"
                  direction={sortDirection ?? SortOrder.ASC}
                />
              </span>
            );
            return (
              <th
                key={c.header?.toString()}
                className="has-text-white is-uppercase"
                data-testid={`${c.header}-header`}
                onClick={
                  c.sortColumn !== undefined
                    ? () => onSort(c.sortColumn!)
                    : () => {}
                }
              >
                {c.sortColumn !== undefined ? (
                  <SortTooltip>{child}</SortTooltip>
                ) : (
                  child
                )}
              </th>
            );
          })}
        </tr>
        {chargebacks.map((chbk) => (
          <tr key={chbk.id}>
            {columns.map((c) => (
              <td
                key={c.header?.toString()}
                className="is-vcentered"
                style={{ padding: "0 2px" }}
              >
                {c.cell(chbk)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DepositDetailsOCResponseToJSON = exports.DepositDetailsOCResponseFromJSONTyped = exports.DepositDetailsOCResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DepositDetailsOCResponseFromJSON(json) {
    return DepositDetailsOCResponseFromJSONTyped(json, false);
}
exports.DepositDetailsOCResponseFromJSON = DepositDetailsOCResponseFromJSON;
function DepositDetailsOCResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantDetails': (json['merchantDetails'].map(_1.DepositDetailsFromJSON)),
        'dateDetails': (json['dateDetails'].map(_1.DepositDetailsFromJSON)),
        'cardTypeDetails': (json['cardTypeDetails'].map(_1.DepositDetailsFromJSON)),
        'outletDetails': (json['outletDetails'].map(_1.DepositDetailsFromJSON)),
        'batchDetails': (json['batchDetails'].map(_1.DepositDetailsFromJSON)),
        'transactionDetails': (json['transactionDetails'].map(_1.SalesCreditsFromJSON)),
        'summaryDetails': !runtime_1.exists(json, 'summaryDetails') ? undefined : (json['summaryDetails'].map(_1.DepositDetailsFromJSON)),
        'resultsTotalMerchants': !runtime_1.exists(json, 'resultsTotalMerchants') ? undefined : json['resultsTotalMerchants'],
        'resultsTotalDates': !runtime_1.exists(json, 'resultsTotalDates') ? undefined : json['resultsTotalDates'],
        'resultsTotalCardTypes': !runtime_1.exists(json, 'resultsTotalCardTypes') ? undefined : json['resultsTotalCardTypes'],
        'resultsTotalOutlets': !runtime_1.exists(json, 'resultsTotalOutlets') ? undefined : json['resultsTotalOutlets'],
        'resultsTotalBatch': !runtime_1.exists(json, 'resultsTotalBatch') ? undefined : json['resultsTotalBatch'],
        'resultsTotalTrans': !runtime_1.exists(json, 'resultsTotalTrans') ? undefined : json['resultsTotalTrans'],
        'resultsTotalSummary': !runtime_1.exists(json, 'resultsTotalSummary') ? undefined : json['resultsTotalSummary'],
        'offsetMerchants': !runtime_1.exists(json, 'offsetMerchants') ? undefined : json['offsetMerchants'],
        'offsetDates': !runtime_1.exists(json, 'offsetDates') ? undefined : json['offsetDates'],
        'offsetCardTypes': !runtime_1.exists(json, 'offsetCardTypes') ? undefined : json['offsetCardTypes'],
        'offsetOutlets': !runtime_1.exists(json, 'offsetOutlets') ? undefined : json['offsetOutlets'],
        'offsetBatch': !runtime_1.exists(json, 'offsetBatch') ? undefined : json['offsetBatch'],
        'offsetTrans': !runtime_1.exists(json, 'offsetTrans') ? undefined : json['offsetTrans'],
    };
}
exports.DepositDetailsOCResponseFromJSONTyped = DepositDetailsOCResponseFromJSONTyped;
function DepositDetailsOCResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantDetails': (value.merchantDetails.map(_1.DepositDetailsToJSON)),
        'dateDetails': (value.dateDetails.map(_1.DepositDetailsToJSON)),
        'cardTypeDetails': (value.cardTypeDetails.map(_1.DepositDetailsToJSON)),
        'outletDetails': (value.outletDetails.map(_1.DepositDetailsToJSON)),
        'batchDetails': (value.batchDetails.map(_1.DepositDetailsToJSON)),
        'transactionDetails': (value.transactionDetails.map(_1.SalesCreditsToJSON)),
        'summaryDetails': value.summaryDetails === undefined ? undefined : (value.summaryDetails.map(_1.DepositDetailsToJSON)),
        'resultsTotalMerchants': value.resultsTotalMerchants,
        'resultsTotalDates': value.resultsTotalDates,
        'resultsTotalCardTypes': value.resultsTotalCardTypes,
        'resultsTotalOutlets': value.resultsTotalOutlets,
        'resultsTotalBatch': value.resultsTotalBatch,
        'resultsTotalTrans': value.resultsTotalTrans,
        'resultsTotalSummary': value.resultsTotalSummary,
        'offsetMerchants': value.offsetMerchants,
        'offsetDates': value.offsetDates,
        'offsetCardTypes': value.offsetCardTypes,
        'offsetOutlets': value.offsetOutlets,
        'offsetBatch': value.offsetBatch,
        'offsetTrans': value.offsetTrans,
    };
}
exports.DepositDetailsOCResponseToJSON = DepositDetailsOCResponseToJSON;

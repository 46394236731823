export class EbocomSdk {
  public static get authToken() {
    return window.localStorage.getItem(this.TOKEN_LOCAL_STORAGE_KEY);
  }
  public static TOKEN_LOCAL_STORAGE_KEY = "ebocomsdktoken";
  public static getAuthHeader() {
    return { Authorization: `Bearer ${this.authToken}` };
  }
  public static updateToken(token: string) {
    window.localStorage.setItem(this.TOKEN_LOCAL_STORAGE_KEY, token);
  }
}

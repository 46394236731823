import React, { useEffect } from "react";
import { DisputeSummary } from "../../models/dispute";
import { DisputeService } from "../../services/disputes/dispute_service";

export interface WelcomeContainerProps {
  disputeService: DisputeService;
  children: (
    disputes: DisputeSummary[],
    isLoading: boolean
  ) => JSX.Element | null;
}
export function WelcomeContainer(props: WelcomeContainerProps) {
  const { disputeService } = props;
  const [disputes, setDisputes] = React.useState<DisputeSummary[]>([]);
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    setLoading(true);
    disputeService
      .getDisputes()
      .then(setDisputes)
      .finally(() => setLoading(false));
  }, [disputeService]);
  return props.children(disputes, loading);
}

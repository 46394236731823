import { Middleware } from "../../../../ebocomsdk/dist";
import useGlobalLoaderState from "../../store/global_loader_state";

export const getPreLoadingMiddleware = (): Middleware["pre"] => {
    return async (context) => {
        if (
            !(context.url.indexOf('app/userprofile') >= 0) ||
            ((context.url.indexOf('app/userprofile') >= 0) && (context.url.indexOf('app/userprofile/') >= 0))
        ) {
            useGlobalLoaderState.getState().addRunningRequest(context.url);
        }
        return context;
    };
}

export const getPostLoadingMiddleware = (): Middleware["post"] => {
    return async (context) => {
        context.response.json()
            .catch(e => { })
            .finally(() => {
                useGlobalLoaderState.getState().subtractRunningRequest(context.url);
            });
    };
};
import React from "react";
import { Caret } from "../../components/caret";
import { SortTooltip } from "../../components/sort_tooltip";
import { Merchant } from "../../models/merchant";
import { SortOrder } from "../../models/sort_order";
import { MerchantDetailsSortColumn } from "../../services/merchant";
import "./business_information_results.scss";

export interface BusinessInformationResultsProps {
  merchants: Merchant[];
  sortColumn?: MerchantDetailsSortColumn;
  onSort: (sortColumn: MerchantDetailsSortColumn) => void;
  sortDirection?: SortOrder;
}
interface TableColumn<T> {
  title: string | React.ReactNode;
  sortColumn?: MerchantDetailsSortColumn;
  render: (row: T) => React.ReactNode;
  key?: string;
}
const headers: TableColumn<Merchant>[] = [
  {
    title: (
      <span>
        Merchant
        <br />
        Number
      </span>
    ),
    key: "merchantNumber",
    render: (merchant: Merchant) => (
      <div style={{ textAlign: "center" }}>{merchant.number}</div>
    ),
    sortColumn: "merchantNumber",
  },
  {
    title: (
      <span>
        Property
        <br />
        Code
      </span>
    ),
    render: (merchant: Merchant) => (
      <div className="has-text-centered">{merchant.region}</div>
    ),
    key: "propertyCode",
    sortColumn: "propertyCode",
  },
  {
    title: "Merchant Name",
    key: "merchantName",
    render: (merchant: Merchant) => merchant.name,
    sortColumn: "merchantName",
  },
  {
    title: "Street Address",
    key: "streetAddress",
    render: (merchant: Merchant) => merchant.address1,
    sortColumn: "streetAddress",
  },
  {
    title: "City",
    key: "city",
    render: (merchant: Merchant) => merchant.city,
    sortColumn: "city",
  },
  {
    title: "State",
    key: "state",
    render: (merchant: Merchant) => (
      <div style={{ textAlign: "center" }}>{merchant.state}</div>
    ),
    sortColumn: "state",
  },
  {
    title: "Zip",
    key: "zip",
    render: (merchant: Merchant) => (
      <div style={{ textAlign: "center" }}>{merchant.zip}</div>
    ),
    sortColumn: "zipCode",
  },
  {
    title: "Business Legal Name",
    key: "businessLegalName",
    render: (merchant: Merchant) => merchant.businessLegalName,
    sortColumn: "businessLegalName",
  },
  {
    title: (
      <span>
        Federal
        <br />
        Tax Id
      </span>
    ),
    key: "federalTaxId",
    render: (merchant: Merchant) => (
      <div style={{ textAlign: "center" }}>{merchant.taxId}</div>
    ),
    sortColumn: "federalTaxId",
  },
];
export function BusinessInformationResults({
  onSort = () => {},
  sortColumn,
  sortDirection,
  merchants = [],
}: BusinessInformationResultsProps) {
  if (merchants.length === 0) {
    return <div />;
  }
  return (
    <table className="table business-information is-fullwidth is-bordered" data-testid="business-information-table">
      <colgroup>
        <col style={{ width: 80 }} />
        <col style={{ width: 60 }} />
        <col style={{ width: 225 }} />
        <col style={{ width: 240 }} />
        <col style={{ width: 70 }} />
        <col style={{ width: 30 }} />
        <col style={{ width: 40 }} />
        <col style={{ width: 185 }} />
        <col style={{ width: 70 }} />
      </colgroup>
      <thead>
        <tr>
          {headers.map((header) => (
            <th key={header.key} className="is-uppercase">
              <SortTooltip>
                <div
                  style={{
                    textAlign: "center",
                    color:
                      sortColumn === header.sortColumn ? "yellow" : "white",
                    cursor: "pointer",
                  }}
                  onClick={
                    header.sortColumn
                      ? () => onSort(header.sortColumn!)
                      : undefined
                  }
                >
                  {header.title}
                  {sortColumn === header.sortColumn && <span>&nbsp;</span>}
                  <Caret.Auto
                    wantedColumn={header.sortColumn}
                    currentColumn={sortColumn}
                    direction={sortDirection}
                  />
                </div>
              </SortTooltip>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {merchants.map((merchant) => {
          return (
            <tr key={merchant.number} style={{ minHeight: 23, height: 23 }}>
              {headers.map((header) => (
                <td key={header.key} style={{ verticalAlign: "middle" }}>
                  {header.render(merchant)}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

import { Configuration, IssuerLookupApi } from "ebocomsdk";
import { CardDetails } from "../../models/card_details";
import { CardService } from "../../services/card_service";
import { getPreAuthMiddleware } from "./auth_middleware";
import { LookupResult } from "../../layout/search_page";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";

export class CardDetailsEbocomSdk implements CardService {
  private api: IssuerLookupApi;
  constructor(private baseUrl: string) {
    const config = new Configuration({ basePath: baseUrl });
    this.api = new IssuerLookupApi(config)
      .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
      .withPostMiddleware(getPostLoadingMiddleware());
  }
  async getCardDetails(
    first8Digits: string
  ): Promise<{ card: CardDetails; result: LookupResult }> {
    const response = await this.api.issuerlookupGet({
      issuerNbr: parseInt(first8Digits, 10),
    });

    let cardDetails = new CardDetails();

    if (first8Digits.length === 8) {
      if (response.length > 0) {
        cardDetails.binNumber = response[0].binnbr;
        cardDetails.cardType = response[0].cardType;
        cardDetails.instCountry = response[0].instCountry;
        cardDetails.instName = response[0].instName;
        cardDetails.provider = response[0].provider;
        cardDetails.serviceProviders = response.map((s) => ({
          fax: s.serviceFAX,
          name: s.providerService,
          phone: s.servicePhone,
        }));

        return {
          card: cardDetails,
          result: LookupResult.OK
        };
      } else {
        return {
          card: cardDetails,
          result: LookupResult.ObjectDoesNotExist,
        };
      }
    } else {
      return {
        card: cardDetails,
        result: LookupResult.IssuerValidationError
      };
    }
  }
}

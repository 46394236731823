"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesChargeBacksToJSON = exports.SalesChargeBacksFromJSONTyped = exports.SalesChargeBacksFromJSON = void 0;
var runtime_1 = require("../runtime");
function SalesChargeBacksFromJSON(json) {
    return SalesChargeBacksFromJSONTyped(json, false);
}
exports.SalesChargeBacksFromJSON = SalesChargeBacksFromJSON;
function SalesChargeBacksFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumber': !runtime_1.exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'cbDate': !runtime_1.exists(json, 'cbDate') ? undefined : (new Date(json['cbDate'])),
        'billingDate': !runtime_1.exists(json, 'billingDate') ? undefined : (new Date(json['billingDate'])),
        'reasonCode': !runtime_1.exists(json, 'reasonCode') ? undefined : json['reasonCode'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'cbType': !runtime_1.exists(json, 'cbType') ? undefined : json['cbType'],
        'cbAmount': !runtime_1.exists(json, 'cbAmount') ? undefined : json['cbAmount'],
        'formattedCardNumber': !runtime_1.exists(json, 'formattedCardNumber') ? undefined : json['formattedCardNumber'],
        'formattedCbDate': !runtime_1.exists(json, 'formattedCbDate') ? undefined : json['formattedCbDate'],
        'formattedBillingDate': !runtime_1.exists(json, 'formattedBillingDate') ? undefined : json['formattedBillingDate'],
        'formattedCbAmount': !runtime_1.exists(json, 'formattedCbAmount') ? undefined : json['formattedCbAmount'],
        'formattedCardType': !runtime_1.exists(json, 'formattedCardType') ? undefined : json['formattedCardType'],
        'cardNumberMasked': !runtime_1.exists(json, 'cardNumberMasked') ? undefined : json['cardNumberMasked'],
    };
}
exports.SalesChargeBacksFromJSONTyped = SalesChargeBacksFromJSONTyped;
function SalesChargeBacksToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cardType': value.cardType,
        'cardNumber': value.cardNumber,
        'cbDate': value.cbDate === undefined ? undefined : (value.cbDate.toISOString()),
        'billingDate': value.billingDate === undefined ? undefined : (value.billingDate.toISOString()),
        'reasonCode': value.reasonCode,
        'description': value.description,
        'cbType': value.cbType,
        'cbAmount': value.cbAmount,
        'formattedCardNumber': value.formattedCardNumber,
        'formattedCbDate': value.formattedCbDate,
        'formattedBillingDate': value.formattedBillingDate,
        'formattedCbAmount': value.formattedCbAmount,
        'formattedCardType': value.formattedCardType,
        'cardNumberMasked': value.cardNumberMasked,
    };
}
exports.SalesChargeBacksToJSON = SalesChargeBacksToJSON;

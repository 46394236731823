import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";

export interface Pagination {
  offset: number;
  page: number;
  pageSize: number;
  totalResults?: number;
  setPageSize: (pageSize: number) => void;
  showAll: () => void;
  nextPage: () => void;
  previousPage(): void;
  firstPage(): void;
  lastPage?(): void;
  hasNext: boolean;
  hasPrevious: boolean;
  setInitialPaginationOffset: Dispatch<SetStateAction<number>>;
}
export function usePagination(
  initialPageSize: number,
  totalResults?: number
): Pagination {
  const [initialPaginationOffset, setInitialPaginationOffset] = useState<number>(0);
  const [page, setPage] = useState(initialPaginationOffset / initialPageSize || 0);
  const [pageSize, setPageSize] = useState(initialPageSize);

  useEffect(() => {
    setPage(initialPaginationOffset / pageSize);
  }, [initialPaginationOffset, pageSize])

  const nextPage = useCallback(() => {
    setPage(page + 1);
  }, [page]);
  const previousPage = useCallback(() => {
    setPage(Math.max(page - 1, 0));
  }, [page]);
  const firstPage = useCallback(() => {
    setPage(0);
  }, [setPage]);
  const lastPage =
    totalResults || totalResults === 0
      ? () => {
        const newPage = Math.max(
          Math.floor((totalResults - 1) / pageSize),
          0
        );
        setPage(newPage);
      }
      : undefined;
  const showAll = useCallback(() => {
    if (typeof totalResults === "number" && totalResults > 0) {
      setPage(0);
      setPageSize(500);
    }
  }, [totalResults]);

  const hasNext = page < (totalResults ?? 0) - pageSize;
  const hasPrevious = page > 0;
  const offset = page * pageSize;

  return {
    offset,
    page,
    pageSize,
    totalResults,
    setPageSize,
    showAll,
    nextPage,
    previousPage,
    firstPage,
    lastPage,
    hasNext,
    hasPrevious,
    setInitialPaginationOffset,
  };
}

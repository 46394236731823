import { useEffect, useState } from "react";
import { CardDetails } from "../models/card_details";
import { LookupResult } from "../layout/search_page";
import { CardService } from "../services/card_service";

export function useCardDetails(
  cardService: CardService,
  first8Digits?: string
) {
  const [cardDetails, setCardDetails] = useState<CardDetails>();
  const [result, setResult] = useState<LookupResult>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (first8Digits) {
      setLoading(true);
      cardService
        .getCardDetails(first8Digits)
        .then((cardDetails) => {
          setResult(cardDetails.result)
          setCardDetails(cardDetails.card)
        })
        .finally(() => setLoading(false));
    }
  }, [first8Digits, cardService]);
  return { cardDetails, result, loading };
}

export enum CardType {
  mastercard = "MC",
  visa = "VS",
  americanExpress = "AX",
  discover = "DR",
  jcb = "JCB",
}

export const reversedCardTypes: { [key: string]: CardType } = {
  MC: CardType.mastercard,
  VS: CardType.visa,
  AX: CardType.americanExpress,
  DR: CardType.discover,
  JCB: CardType.jcb,
};

export const abbreviatedCardTypes = {
  Mastercard: "MC",
  Visa: "VS",
  "American Express": "AMEX",
  Discover: "DR",
  JCB: "JCB",
};

export const cardTypes: CardType[] = [
  CardType.americanExpress,
  CardType.discover,
  CardType.jcb,
  CardType.mastercard,
  CardType.visa,
];

import React from "react";
import "./spinner.scss";
export interface SpinnerProps {
  size?: number;
  color?: string;
  lineWidth?: number;
}
export function Spinner({
  size = 40,
  color = "#7d5195",
  lineWidth = 8,
}: SpinnerProps) {
  return (
    <div
      className="spinner"
      style={{
        height: size,
        width: size,
        borderWidth: lineWidth,
        borderColor: `${color} ${color} ${color} transparent`,
      }}
    />
  );
}

export class ChargebackClosed {
  merchantNbr?: string = "";
  merchantName?: string = "";
  region?: string = "";
  assetOwner?: string = "";
  businessLegalName?: string = "";
  acctNumber?: string = "";
  cardType?: string = "";
  tranAmount?: string = "";
  tranDate?: string = "";
  tranType?: string = "";
  cbrfTranDate?: string = "";
  dateClosed?: string = "";
  disputeDescription?: string = "";
  maskedAcctNumber?: string = "";
  tranStatusDescription?: string = "";
  closedDescription?: string = "";
}

import { Document, DocumentType } from "../models/document";
import faker from "faker";
import { DocumentService } from "../services/document";
import { PaginatedResult } from "../models/paginated_result";

export function mockDocument() {
  const doc = new Document(
    faker.datatype.uuid(),
    faker.lorem.sentence(),
    "https://uat.postint.net/app/documents/postdocs/REFDOCS_POST/Controlling Credit Card Fees.pdf",
    faker.datatype.boolean(),
    faker.lorem.paragraph(),
    DocumentType.BestPractices,
    faker.date.past()
  );
  console.log(doc.filename);
  return doc;
}

export function mockDocumentService(): DocumentService {
  return {
    async getDocuments(type: DocumentType): Promise<PaginatedResult<Document>> {
      return new Promise((resolve) => {
        setTimeout(
          () =>
            resolve({
              data: Array.from(new Array(10), () => mockDocument()),
              offset: 0,
              total: 20,
            }),
          1000
        );
      });
    },
    async downloadDocument(document: Document): Promise<[Blob, string]> {
      return [new Blob(), "test.pdf"];
    },
  };
}

import {
  User,
  UserEntity,
  UserPrivilege,
  UserPrivilegeTitle,
} from "../models/user";
import { UserService } from "../services/user";
import faker from "faker";
import { create999Merchant } from "../models/merchant";
import { PaginatedResult } from "../models/paginated_result";

const mockUserEntity = () => {
  return new UserEntity(
    faker.datatype.uuid(),
    faker.name.firstName() + " " + faker.name.lastName(),
    faker.phone.phoneNumber(),
    faker.internet.email(),
    faker.datatype.boolean()
  );
};
const mockUserPrivilege = () => {
  return new UserPrivilege(
    faker.datatype.uuid(),
    faker.datatype.number(10) + 1,
    faker.lorem.word(),
    faker.lorem.word(),
    faker.datatype.boolean(),
    (function () {
      let num = faker.datatype.number(3);
      switch (num) {
        case 0:
          return UserPrivilegeTitle.daily;
        case 1:
          return UserPrivilegeTitle.reference;
        case 2:
          return UserPrivilegeTitle.management;
      }
    })()
  );
};
export function mockUserService(): UserService {
  return {
    async getUsers(): Promise<PaginatedResult<UserEntity>> {
      return {
        data: Array.from(new Array(10), mockUserEntity),
        offset: 0,
        total: 10,
      };
    },
    async getUserPrivileges(userId: string): Promise<UserPrivilege[]> {
      return Array.from(new Array(100), mockUserPrivilege);
    },
    async getImpersonatableAccounts(): Promise<string[]> {
      return Array.from(new Array(10)).map(() => faker.internet.email());
    },
    async getUserInfo() {
      return new User(
        create999Merchant().number,
        faker.internet.email(),
        faker.name.firstName() + " " + faker.name.lastName(),
        faker.internet.email(),
        [
          "RefundAudLocation",
          "RefundAudTrend",
          "CardBrandPerformanceTrend",
          "InternationalCardAcceptanceByLocation",
          "CardProductAcceptanceByLocation",
          "CardProductAcceptanceTrend",
          "InternationalCardAcceptanceTrend",
          "CardBrandPerformanceByLocation",
          "ChargebackRFCAging",
          "ChargebackPerformanceByLocation",
          "ChargebackPerformanceByReason",
          "ChargebackPerformanceTrend",
          "ClosedChargebackPerformanceByLocation",
          "ClosedChargebackPerformanceByReason",
          "ClosedChargebackPerformanceTrend",
          "DailyDepositReportCorporate",
          "DailyDepositReportLocation",
          "DepositActivityByLocation",
          "DebitCardProductAcceptanceByLocation",
          "DebitCardProductAcceptanceTrend",
          "DepositActivity",
          "DepositActivityTrend",
          "WeeklyChargebackRFCAgingReportCorporate",
          "WeeklyChargebackRFCAgingReportLocation",
          "MonthlyInterchangeReportLocation",
          "MonthlyInterchangeReportCorporate",
          "ExcessiveCredit",
          "InterchangePerformanceDetail",
          "InternalUser",
          "MerchantReferenceSheet",
          "MonthlyInterchangeReportCorporate",
          "MonthlyInterchangeReportLocation",
          "InterchangeQualificationPerformanceByLocation",
          "BestPractices",
          "SettlementAccountInformation",
          "BusinessInformation",
          "InterchangeQualificationPerformanceTrend",
          "MerchantFeesByLocation",
          "MerchantFeesTrend",
          "MonthlyStatementLocation",
          "PointOfAcceptancePerformanceByLocation",
          "ChargebackRFCClosed",
          "PointOfAcceptancePerformanceTrend",
          "ChargebackRFCAging",
          "RFCPerformanceByLocation",
          "RFCPerformanceTrend",
          "RefundAudException",
          "SuperSearch",
          "TransmittedActivity",
          "ReferenceDocuments",
          "FakeTestRole",
          "IssuerLookup",
        ]
      );
    },
  };
}

import { AxiosInstance } from "axios";
import {
  Configuration,
  DepositDetails,
  DepositdetailsocApi,
  BatchRequest,
  DepositdetailsApi,
  DepositdetailsocApiDepositdetailsvmcPostRequest,
  SalesCredits,
  DepositdetailsocApiDepositdetailsocPostRequest,
  ApiResponse,
} from "ebocomsdk";
import { CardType } from "../../models/card_type";
import { DDA, DepositDetail } from "../../models/deposit_detail";
import { SalesCredit } from "../../models/sales_credit";
import { DateService } from "../../services/date_service";
import {
  DepositActivity,
  DepositActivityParams,
  DepositActivityTable,
  TransmittedActivity,
  TransmittedActivitySearchParameters,
  TransmittedService,
  TransmittedTable,
} from "../../services/transmitted";
import { getPreAuthMiddleware } from "./auth_middleware";
import { createAxiosInstance } from "./axios";
import { CONTENT_DISPOSITION } from "./constants";
import { getFilenameFromContentDisposition } from "./filename_extractor";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";
import { ebocomSdkNumberFormatter } from "./number_parser";

type ExportMethod = () => Promise<[Blob, string]>;
export class EbocomTransmittedService implements TransmittedService {
  private api: DepositdetailsocApi;
  private depositDetailsApi: DepositdetailsApi;
  private axios: AxiosInstance;
  constructor(private baseUrl: string) {
    const configuration = new Configuration({
      basePath: this.baseUrl,
    });
    this.depositDetailsApi = new DepositdetailsApi(configuration)
      .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
      .withPostMiddleware(getPostLoadingMiddleware());
    this.axios = createAxiosInstance(baseUrl);
    this.api = new DepositdetailsocApi(configuration)
      .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
      .withPostMiddleware(getPostLoadingMiddleware());
  }
  private static transmittedPageSize = 5;
  private serializeDepositActivityParams(
    params: DepositActivityParams
  ): DepositdetailsocApiDepositdetailsvmcPostRequest {
    return {
      filterDate: params.filterDate
        ? DateService.formatDate(params.filterDate!, "-")
        : undefined,
      filterMerchantNbr: params.filterMerchantNumber,
      tranAmount: params.transactionAmount,
      filterTranType: params.tranType,
      authCode: params.authCode,
      acctNumber: params.cardNumber,
      batchRequest: {
        amex: params.cardTypes?.includes(CardType.americanExpress) ?? false,
        dateHighString: DateService.formatDate(params.endDate),
        dateLowString: DateService.formatDate(params.beginDate),
        discover: params.cardTypes?.includes(CardType.discover) ?? false,
        jcb: params.cardTypes?.includes(CardType.jcb) ?? false,
        merchantNbr: params.merchantNumber,
        mastercard: params.cardTypes?.includes(CardType.mastercard) ?? false,

        visa: params.cardTypes?.includes(CardType.visa) ?? false,
        divisionName: !!params.divisionName
          ? params.divisionName
          : "------------ALL DIVISIONS---------",
        outletName: !!params.outletName
          ? params.outletName
          : "------------ALL OUTLETS---------",
      },
      filterCardNbr: params.cardNumber,
      filterCardType: params.filterCardType,
      filterOutletNbr: params.filterOutletNumber?.toString(),
      pageOffsetChargeback: params.pageOffsetChargeback,
      pageOffsetDate: params.pageOffsetDate,
      pageOffsetMerchant: params.pageOffsetMerchant,
      pageOffsetOutlet: params.pageOffsetOutlet,
      pageOffsetTrans: params.pageOffsetTransaction,
    };
  }
  async getDepositActivity(
    params: DepositActivityParams
  ): Promise<DepositActivity> {
    const raw = await this.api.depositdetailsvmcPostRaw(
      this.serializeDepositActivityParams(params)
    );
    if (raw.raw.status === 204) {
      return {
        isEmpty: true,
      };
    }
    const response = await raw.value();
    return {
      isEmpty: false,
      merchantDetails: {
        items: response.merchantDetails?.map?.(this.deserializeDepositDetail),
        pageSize: EbocomTransmittedService.transmittedPageSize,
        offset: response.offsetMerchants ?? 0,
        totalResults: response.resultsTotalMerchants ?? 0,
      },
      outletDetails: {
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items: response.outletDetails?.map?.(this.deserializeDepositDetail),
        offset: response.offsetOutlets ?? 0,
        totalResults: response.resultsTotalOutlets ?? 0,
      },
      dateDetails: {
        offset: response.offsetDates ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items: response.dateDetails?.map?.(this.deserializeDepositDetail),
        totalResults: response.resultsTotalDates ?? 0,
      },
      summaryDetails: {
        offset: 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items:
          response.summaryDetails?.map?.(this.deserializeDepositDetail) ?? [],
        totalResults: response.resultsTotalSummary ?? 0,
      },
      chargebackDetails: {
        offset: response.offsetChargeback ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items: response.chargebackDetails?.map?.((value) => {
          return this.deserializeDepositDetail(value);
        }),
        totalResults: response.resultsTotalChargeback ?? 0,
      },
      transactionDetails: {
        offset: response.offsetTrans ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items:
          response.transactionDetails?.map?.(this.deserializeSalesCredits) ??
          [],
        totalResults: response.resultsTotalTrans ?? 0,
      },
    };
  }
  serializeParameters = (
    incomingParams: TransmittedActivitySearchParameters
  ): BatchRequest => {
    return {
      amex:
        incomingParams.cardTypes?.includes(CardType.americanExpress) ?? false,
      dateHighString: DateService.formatDate(incomingParams.endDate, "-"),
      dateLowString: DateService.formatDate(incomingParams.beginDate, "-"),
      discover: incomingParams.cardTypes?.includes(CardType.discover) ?? false,
      jcb: incomingParams.cardTypes?.includes(CardType.jcb) ?? false,
      merchantNbr: incomingParams.merchantNumber,
      mastercard:
        incomingParams.cardTypes?.includes(CardType.mastercard) ?? false,
      visa: incomingParams.cardTypes?.includes(CardType.visa) ?? false,
      divisionName: !!incomingParams.divisionName
        ? incomingParams.divisionName
        : "------------ALL DIVISIONS---------",
      outletName: !!incomingParams.outletName
        ? incomingParams.outletName
        : "------------ALL OUTLETS---------",
    };
  };
  private getTransmittedActivityAdditionalParameters = (
    parameters: TransmittedActivitySearchParameters
  ): DepositdetailsocApiDepositdetailsocPostRequest => {
    return {
      filterBatchNbr: parameters.filterBatchNumber,
      filterCardType: parameters.filterCardType,
      filterDate: parameters.filterDate
        ? DateService.formatDate(parameters.filterDate, "-")
        : undefined,
      filterMerchantNbr: parameters.filterMerchantNumber,
      filterOutletNbr: parameters.filterOutletNumber,
      filterCardType2: parameters.filterCardType,
      filterCardNbr: parameters.cardNumber,
      filterTranType: parameters.filterTransactionType,
      tranAmount: parameters.transactionAmount,
      authCode: parameters.authCode,
      acctNumber: parameters.cardNumber,
      pageOffsetBatch: parameters.pageOffsetBatch,
      pageOffsetCardType: parameters.pageOffsetCardType,
      pageOffsetDate: parameters.pageOffsetDate,
      pageOffsetMerchant: parameters.pageOffsetMerchantNumber,
      pageOffsetOutlet: parameters.pageOffsetOutletNumber,
      pageOffsetTrans: parameters.pageOffsetTrans,
      batchRequest: this.serializeParameters(parameters),
    };
  };
  private getTransmittedActivityReportParameters = (
    parameters: TransmittedActivitySearchParameters
  ): DepositdetailsocApiDepositdetailsocPostRequest => {
    return {
      filterBatchNbr: parameters.filterBatchNumber,
      filterCardType: parameters.filterCardType,
      filterDate: parameters.filterDate
        ? DateService.formatDate(parameters.filterDate, "-")
        : undefined,
      filterMerchantNbr: parameters.filterMerchantNumber,
      filterOutletNbr: parameters.filterOutletNumber,
      batchRequest: this.serializeParameters(parameters),
    };
  };
  getTransmittedActivity = async (
    parameters: TransmittedActivitySearchParameters
  ): Promise<TransmittedActivity> => {
    const raw = await this.api.depositdetailsocPostRaw({
      ...this.getTransmittedActivityAdditionalParameters(parameters),
    });
    if (raw.raw.status === 204) {
      return {
        isEmpty: true,
      };
    }
    const response = await raw.value();

    return {
      isEmpty: false,
      batchDetails: {
        offset: response.offsetBatch ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items: response.batchDetails?.map?.(this.deserializeDepositDetail),
        totalResults: response.resultsTotalBatch ?? 0,
      },
      summaryDetails: {
        offset: 0,
        items:
          response.summaryDetails?.map?.(this.deserializeDepositDetail) ?? [],
        totalResults: response.resultsTotalSummary ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
      },
      cardTypeDetails: {
        offset: response.offsetCardTypes ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items: response.cardTypeDetails?.map?.(this.deserializeDepositDetail),
        totalResults: response.resultsTotalCardTypes ?? 0,
      },
      dateDetails: {
        offset: response.offsetDates ?? 0,
        items: response.dateDetails?.map?.(this.deserializeDepositDetail),
        pageSize: EbocomTransmittedService.transmittedPageSize,
        totalResults: response.resultsTotalDates ?? 0,
      },
      merchantDetails: {
        offset: response.offsetMerchants ?? 0,
        items: response.merchantDetails?.map?.(this.deserializeDepositDetail),
        totalResults: response.resultsTotalMerchants ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
      },
      outletDetails: {
        offset: response.offsetOutlets ?? 0,
        items: response.outletDetails?.map?.(this.deserializeDepositDetail),
        pageSize: EbocomTransmittedService.transmittedPageSize,
        totalResults: response.resultsTotalOutlets ?? 0,
      },
      transactionDetails: {
        offset: response.offsetTrans ?? 0,
        pageSize: EbocomTransmittedService.transmittedPageSize,
        items: response.transactionDetails?.map?.(this.deserializeSalesCredits),
        totalResults: response.resultsTotalTrans ?? 0,
      },
    };
  };
  deserializeDepositDetail = (sdkDetail: DepositDetails): DepositDetail => {
    const dd = new DepositDetail(
      sdkDetail.merchantNbr ?? "",
      sdkDetail.merchantName ?? ""
    );
    const ddas: DDA[] = [
      {
        last4: sdkDetail.last4DDA1,
        netDeposit: ebocomSdkNumberFormatter(sdkDetail.netDepositDDA1),
      },
      {
        last4: sdkDetail.last4DDA2,
        netDeposit: ebocomSdkNumberFormatter(sdkDetail.netDepositDDA2),
      },
      {
        last4: sdkDetail.last4DDA3,
        netDeposit: ebocomSdkNumberFormatter(sdkDetail.netDepositDDA3),
      },
      {
        last4: sdkDetail.last4DDA4,
        netDeposit: ebocomSdkNumberFormatter(sdkDetail.netDepositDDA4),
      },
      {
        last4: sdkDetail.last4DDA5,
        netDeposit: ebocomSdkNumberFormatter(sdkDetail.netDepositDDA5),
      },
    ].filter((dda) => dda.last4 && dda.netDeposit);
    dd.totalCreditTransactionCount = sdkDetail.creditItems ?? "";
    dd.totalChargebackVolume = ebocomSdkNumberFormatter(
      sdkDetail.netChargebacks ?? ""
    );
    dd.totalChargebackCount = sdkDetail.netChargebackItems ?? "";
    dd.totalSalesTransactionsCount = sdkDetail.grossItems ?? "";
    dd.totalSalesTransactionsVolume = ebocomSdkNumberFormatter(
      sdkDetail.grossVolume ?? ""
    );
    dd.totalNetSalesTransactionCount = sdkDetail.netSales ?? "";
    dd.totalNetSalesTransactionVolume = ebocomSdkNumberFormatter(
      sdkDetail.netVolume ?? ""
    );
    dd.totalCreditTransactionVolume = ebocomSdkNumberFormatter(
      sdkDetail.creditVolume ?? ""
    );
    dd.batchNumber = sdkDetail.merchantBatchNbr ?? "";
    dd.transactionDate = sdkDetail.transactionDate;
    dd.outletName = sdkDetail.outletName ?? "";
    dd.transactionType = sdkDetail.tranType ?? "";
    dd.transactionAmount = ebocomSdkNumberFormatter(sdkDetail.amount);
    dd.description = sdkDetail.description ?? "";
    dd.cardType =
      sdkDetail.cardTypes?.length === 1
        ? sdkDetail.cardTypes[0]
        : sdkDetail.cardType ?? "";
    dd.transactionDate = sdkDetail.transactionDate;
    dd.merchantNumber = sdkDetail.merchantNbr ?? "";
    dd.settleDate = sdkDetail.settleDate;
    dd.cardNumber = sdkDetail.cardNumber;
    dd.cardNumberMasked = sdkDetail.cardNumberMasked;
    dd.transactionType = sdkDetail.tranType ?? "";
    dd.reportDate = sdkDetail.reportDate;
    dd.outletNumber = sdkDetail.outletNbr ?? "";
    dd.dailyFees = ebocomSdkNumberFormatter(sdkDetail.dailyFees ?? "");
    dd.miscAdj = ebocomSdkNumberFormatter(sdkDetail.netRejects ?? "");
    dd.reasonCode = sdkDetail.reasonCode ?? "";
    dd.netDeposit = ebocomSdkNumberFormatter(sdkDetail.netDeposit ?? "");
    dd.ddas = ddas;
    return dd;
  };
  deserializeSalesCredits = (sdkResponse: SalesCredits): SalesCredit => {
    const s = new SalesCredit();
    s.posEntryMode = sdkResponse.posEntryMode ?? "";
    s.transactionType = sdkResponse.activityType ?? "";
    s.cardType = sdkResponse.cardType ?? "";
    s.cardNumber = sdkResponse.cardNumber ?? "";
    s.cardNumberMasked = sdkResponse.cardNumberMasked ?? "";
    s.transactionAmount = ebocomSdkNumberFormatter(sdkResponse.amount);
    s.assetOwner = sdkResponse.assetOwner ?? "";
    s.expDate = sdkResponse.expDate
      ? new Date(Date.parse(sdkResponse.expDate))
      : undefined;
    s.merchantNumber = sdkResponse.merchantNbr ?? "";
    s.merchantName = sdkResponse.merchantName ?? "";
    s.billingDate = sdkResponse.billingDate;
    s.outletName = sdkResponse.outletName ?? "";
    s.cardType = sdkResponse.cardType ?? "";
    s.cardNumber = sdkResponse.cardNumber ?? "";
    s.posEntryMode = sdkResponse.posEntryMode ?? "";
    s.posEntryModeDesc = sdkResponse.posEntryModeDesc ?? "";
    s.settleDate = sdkResponse.settleDate;
    s.transactionDate = sdkResponse.transactionDate;
    s.transactionType = sdkResponse.activityType ?? "";
    return s;
  };

  depositReportTableTomethod = (
    table: DepositActivityTable
  ): ((params: DepositActivityParams) => ExportMethod) => {
    return (params: DepositActivityParams) => {
      const request = this.serializeDepositActivityParams(params);
      let method: () => Promise<ApiResponse<Blob>>;

      switch (table) {
        case DepositActivityTable.DATE:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsvmcDatesPostRaw(request);
          break;
        case DepositActivityTable.MERCHANT:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsvmcMerchantPostRaw(
              request
            );
          break;
        case DepositActivityTable.OUTLET:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsvmcOutletPostRaw(
              request
            );
          break;
        case DepositActivityTable.SUMMARY:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsvmcSummaryPostRaw(
              request
            );
          break;
        case DepositActivityTable.TRANSACTION:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsvmcTransactionPostRaw(
              request
            );
          break;
        case DepositActivityTable.CHARGEBACK:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsvmcChargebacksPostRaw(
              request
            );
          break;
        default:
          throw Error();
      }
      return async () => {
        const response = await method();
        const filename = getFilenameFromContentDisposition(
          response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
        );
        return [await response.raw.blob(), filename ?? "deposit_activity.xls"];
      };
    };
  };
  getDepositReport = async (
    parameters: DepositActivityParams,
    table: DepositActivityTable
  ): Promise<[Blob, string]> => {
    return this.depositReportTableTomethod(table)(parameters)();
  };

  private reportTableToReportMethod = (
    table: TransmittedTable
  ): ((params: TransmittedActivitySearchParameters) => ExportMethod) => {
    return (params: TransmittedActivitySearchParameters) => {
      let method: () => Promise<ApiResponse<Blob>>;
      const request = this.getTransmittedActivityReportParameters(params);
      switch (table) {
        case TransmittedTable.BATCH:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsocBatchPostRaw(request);
          break;
        case TransmittedTable.CARD_TYPE:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsocCardtypePostRaw(
              request
            );
          break;
        case TransmittedTable.DATE:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsocDatesPostRaw(request);
          break;
        case TransmittedTable.MERCHANT:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsocMerchantPostRaw(
              request
            );
          break;
        case TransmittedTable.OUTLET:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsocOutletPostRaw(request);
          break;
        case TransmittedTable.TRANSACTION:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsocTransactionPostRaw(
              request
            );
          break;
        case TransmittedTable.SUMMARY:
          method = () =>
            this.depositDetailsApi.exportDepositdetailsocSummaryPostRaw(
              request
            );
          break;
        default:
          throw Error("Invalid table");
      }
      return async () => {
        const response = await method();
        const filename = getFilenameFromContentDisposition(
          response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
        );
        return [await response.raw.blob(), filename ?? "transmitted.xls"];
      };
    };
  };
  getTransmittedReport = async (
    parameters: TransmittedActivitySearchParameters,
    reportTable: TransmittedTable
  ): Promise<[Blob, string]> => {
    return this.reportTableToReportMethod(reportTable)(parameters)();
  };
}

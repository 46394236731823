import React, { useContext, useMemo } from "react";
import { TableTitle } from "../../../components/table_title";
import { SHAREFILE_URL } from "../../../constants";
import { useAging } from "../../../hooks/use_aging";
import { useDefaultMerchant } from "../../../hooks/use_default_merchant";
import { useDownload } from "../../../hooks/use_download";
import { useMerchants } from "../../../hooks/use_merchant";
import { ValidatedSearchPage } from "../../../layout/search_page";
import { create999Merchant } from "../../../models/merchant";
import { ReportType } from "../../../models/report";
import {
  AgingChargebackSortColumn,
  ChargebackFilter,
  DisputeService,
} from "../../../services/disputes/dispute_service";
import { downloadExcelFile } from "../../../services/download";
import { MerchantService } from "../../../services/merchant";
import { ReportService } from "../../../services/report";
import { DisputeSearchPanel } from "./dispute_search_panel";
import { v4 as uuidV4 } from "uuid";
import {
  OutstandingChargebackTable,
  OutstandingChargebackTableItem,
} from "./outstanding_chargeback_table";
import { OutstandingDisputeTable } from "./outstanding_dispute_table";
import { NoResults } from "../../../components/no_results";
import { EbocomWarningMessage } from "../../../components/ebocom_warning_message";
import { PageTitle } from "../../../components/page_title";
import { UserContext } from "../../../contexts/user_context";

export interface DisputeAgingProps {
  disputeService: DisputeService;
  merchantService: MerchantService;
  reportService: ReportService;
}
export function DisputeAgingContainer({
  disputeService,
  merchantService,
  reportService,
}: DisputeAgingProps) {
  const { selectedMerchant, updateSelectedMerchant } = useDefaultMerchant();
  const [filter, setFilter] = React.useState<ChargebackFilter | undefined>();
  const [isEmptyPrint, setIsEmptyPrint] = React.useState(false);
  const computedFilter = useMemo<ChargebackFilter | undefined>(() => {
    if (!selectedMerchant) {
      return undefined;
    }
    return { ...filter, merchantNumber: selectedMerchant };
  }, [filter, selectedMerchant]);
  const {
    agingDisputes = [],
    disputeSummaries = [],
    pagination,
    handleSort,
    sorting,
    isLoading,
    isEmpty,
    reset,
  } = useAging(disputeService, computedFilter);
  const { download } = useDownload(reportService);
  const { merchants } = useMerchants(merchantService);
  const user = useContext(UserContext);

  const results = () => {
    if (isLoading) {
      return null;
    }
    if (isEmptyPrint) {
      return (
        <EbocomWarningMessage message={"No Disputes are available for printing based on the date range selected"} />
      );
    }
    return (
      <div>
        <TableTitle
          paginationDisabled={isLoading}
          title="All Outstanding Disputes"
          onExport={async () => {
            if (!computedFilter) return;
            const result = await disputeService.exportAgingChargebacksSummary(
              computedFilter
            );
            downloadExcelFile(result[0], result[1]);
          }}
          currentResultCount={disputeSummaries.length}
          totalResults={pagination.summaryPagination.totalResults}
          offset={pagination.summaryPagination.offset}
          onPageFirst={pagination.summaryPagination.firstPage}
          onPageLast={pagination.summaryPagination.lastPage}
          onPageNext={pagination.summaryPagination.nextPage}
          onPagePrevious={pagination.summaryPagination.previousPage}
          displayResultCount={false}
          hideShowAllButton
          pageSize={pagination.summaryPagination.pageSize}
        />
        <OutstandingDisputeTable
          disputes={
            isEmpty
              ? [
                {
                  id: "null",
                  merchantName: merchants?.find(
                    (m) => m.number === selectedMerchant
                  )?.name,
                  merchantNumber: selectedMerchant,
                  rfcCount: "0",
                  rfcAmount: "0.00",
                  cbkAmount: "0.00",
                  cbkCount: "0",
                },
              ]
              : disputeSummaries
          }
        />
        {(disputeSummaries.length === 0 || isEmpty) && (
          <NoResults.Table text="No outstanding Disputes" />
        )}
        <div className="search-results-container">
          <TableTitle
            paginationDisabled={isLoading}
            title="Outstanding Chargebacks"
            onExport={async () => {
              if (!computedFilter) return;
              const result = await disputeService.exportAgingChargebacks(computedFilter);
              downloadExcelFile(result[0], result[1]);
            }}
            currentResultCount={agingDisputes.length}
            totalResults={pagination.agingPagination.totalResults}
            offset={pagination.agingPagination.offset}
            onPageFirst={pagination.agingPagination.firstPage}
            onPageLast={pagination.agingPagination.lastPage}
            onPageNext={pagination.agingPagination.nextPage}
            onPagePrevious={pagination.agingPagination.previousPage}
            hideShowAllButton
            pageSize={pagination.agingPagination.pageSize}
          />
          <div
            className="has-text-weight-bold title is-6 p-0 m-0"
            style={{ fontSize: 12, fontStyle: "italic", lineHeight: 1.5 }}
          >
            Please respond by the Due Date to Chargebacks in this section to
            provide POST the best opportunity to defend your Chargebacks
          </div>
          <OutstandingChargebackTable
            isLoading={isLoading}
            onSort={handleSort}
            sortColumn={sorting?.field ?? AgingChargebackSortColumn.dateReceived}
            onRequestAttach={() => {
              window.open(SHAREFILE_URL, "newwindow", "width=500,height=600");
            }}
            onRequestPrint={(chbk) => {
              reportService
                .getReportUrl({
                  type: ReportType.CHARGEBACKAGINGOUTSTANDING,
                  loadBatchNumber: chbk.loadBatchNumber,
                  loadEntryNumber: chbk.loadEntryNumber,
                  loadFileNumber: chbk.loadFileNumber,
                  merchantNumber: chbk.merchantNumber,
                })
                .then(download);
            }}
            sortDirection={sorting?.direction}
            chargebacks={agingDisputes.map<OutstandingChargebackTableItem>(
              (a) => {
                return {
                  id: uuidV4(),
                  dateReceived: a.loadDate,
                  daysRemaining: a?.daysRemaining?.toString(),
                  disputeAmount: a.exceptionAmount,
                  disputeReason: a.exceptionMessage,
                  dueDate: a.dueDate,
                  merchantName: a.merchantName,
                  merchantNumber: a.merchantNbr,
                  outletname: a.outletName,
                  txDate: a.origTranDate,
                  loadBatchNumber: a.loadBatchNumber,
                  loadEntryNumber: a.loadEntryNumber,
                  loadFileNumber: a.loadFileNumber,
                  cardNumber: a.maskedAcctNumber,
                };
              }
            )}
          />
          {(isEmpty || agingDisputes.length === 0) && (
            <NoResults.Table text="No outstanding Chargebacks" />
          )}
          <div style={{ margin: '40px 0' }}>
            <TableTitle
              title="Outstanding RFCS"
              onExport={() => { }}
              totalResults={1}
              displayResultCount={false}
              hideShowAllButton
            />
            <div
              className="has-text-weight-bold title is-6 p-0 m-0"
              style={{ fontSize: 12, fontStyle: "italic", lineHeight: 1.5 }}
            >
              Please respond by the Due Date to RFCs in this section to
              provide POST the best opportunity to defend your RFCs
            </div>
            <OutstandingChargebackTable
              onSort={() => { }}
              chargebacks={[]}
              sortColumn={AgingChargebackSortColumn.dateReceived}
            />
            <NoResults.Table text="No outstanding RFCS" />
          </div>
        </div>
      </div>
    );
  };

  function getSortedMerchants() {
    const sortedMerchants = merchants.sort((a, b) => a.number.localeCompare(b.number))
    return [create999Merchant(), ...sortedMerchants]
  }

  return (
    <div className="chargebackAging-wrapper">
      <div className="title-bar">
        <PageTitle title="Chargeback/RFC - Aging" />
      </div>
      <ValidatedSearchPage
        isLoading={isLoading}
        searchPanel={
          <DisputeSearchPanel
            isLoading={isLoading}
            onPrint={(params) => {
              if (disputeSummaries.length === 0 || isEmpty) {
                setIsEmptyPrint(true)
              }
              else {
                setIsEmptyPrint(false)
                reportService
                  .getReportUrl({
                    type: ReportType.CHARGEBACKAGING,
                    beginDate: params.startDate,
                    endDate: params.endDate,
                    merchantNumber: params.merchantNumber === "9999999999" ? user?.username : params.merchantNumber,
                  })
                  .then(download, () => { });
              }
            }}
            onSearch={(data) => {
              setIsEmptyPrint(false)
              reset();
              updateSelectedMerchant(data.merchantNumber);
              setFilter({
                merchantNumber: data.merchantNumber,
                startDate: data.startDate,
                endDate: data.endDate,
              });
            }}
            defaultMerchantId={selectedMerchant}
            merchants={getSortedMerchants()}
          />
        }
        searchResults={results()}
      />
    </div>
  );
}

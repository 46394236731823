import { CardTypeLongDictionary } from "../services/transmitted";
import { CardType } from "./card_type";

export class SalesCredit {
  public get fullCardTypeName() {
    if (!this.cardType) {
      return undefined;
    }
    return (CardTypeLongDictionary as any)[this.cardType];
  }
  assetOwner?: string;
  authCode?: string;
  batchNbr?: number;
  billingDate?: Date;
  businessLegalName?: string;
  cardNumber?: string;
  cardNumberMasked?: string;
  cardType?: string;
  cardTypes?: CardType[];
  divisionName?: string;
  divisionNbr?: string;
  downgradeReason?: string;
  expDate?: Date;
  formattedAmount?: string;
  formattedBillingDate?: string;
  formattedCardNumber?: string;
  formattedCardType?: string;
  formattedExpDate?: string;
  formattedSettleDate?: string;
  formattedTranDate?: string;
  isBadTransaction?: string;
  loadBatch?: number;
  mcc?: string;
  merchantBatchNumber?: string;
  merchantName?: string;
  merchantNumber?: string;
  outletName?: string;
  outletNbr?: string;
  percentMonthlyItems?: string;
  percentYearlyItems?: string;
  posEntryMode?: string;
  posEntryModeDesc?: string;
  productType?: string;
  qualificationTypeDesc?: string;
  region?: string;
  settleDate?: Date;
  settledInterchangeCode?: number;
  settledInterchangeDesc?: string;
  totalItems?: string;
  totalVolume?: string;
  tranNbr?: number;
  transactionAmount?: string;
  transactionDate?: Date;
  transactionStatus?: string;
  transactionType?: string;
}

import React from "react";
import { StandardLinks } from "./standard_links";
import { useLogout } from "../hooks/use_logout";


export interface PageTitleProps {
  title: React.ReactNode;
}
export function PageTitle({ title }: PageTitleProps) {
  const logout = useLogout();
  
  return (
    <div className="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-flex-start page-title">
      <h1 className="m-0 title is-5 is-uppercase" style={{ fontSize: 16 }}>
        {title}
      </h1>
      <StandardLinks onLogOut={logout} />
    </div>
  );
}
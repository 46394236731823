import React from "react";
import { Link } from "react-router-dom";
import { StandardLinks } from "../components/standard_links";
import "./main_container.scss";
import "./welcome.scss";

export interface WelcomeTemplateProps {
  children?: React.ReactNode;
  menuComponent?: JSX.Element;
  onLogOut?: () => void;
  footer?: React.ReactNode;
}
export const WelcomeTemplate = ({
  children = <div />,
  onLogOut,
  footer,
}: WelcomeTemplateProps) => {
  return (
    <div className="is-flex is-flex-direction-column main-container center">
      <div className="front-page-container">
      <div className="p-1 is-gapless primary-bg m-0 is-flex is-justify-content-space-between is-align-items-flex-end">
        <div style={{ width: 700 }}>
          <Link to="/">
            <img
              id="brand-logo"
              alt="Post@Vantage logo"
              src={`${process.env.PUBLIC_URL}/images/postadvantage_home_01.jpg`}
            />
          </Link>
        </div>
        <div>
          <StandardLinks showHome={false} isLight={false} onLogOut={onLogOut} />
        </div>
      </div>
      <div className="front-page-body">{children}</div>
      {footer}
      </div>
    </div>
  );
};

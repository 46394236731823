import { useEffect, useMemo, useState, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import EBTreeView, { TreeviewItem } from "../../components/treeview_mgmt_module";
import Checkbox from "@material-ui/core/Checkbox";
import {
  ReportCategory,
  ReportCategoryValue,
  ReportGroup,
  ReportType,
} from "../../models/report";
import "./management_module_search.scss";
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';

export interface ManagementModuleSearchProps {
  isBeginDateLoading: boolean;
  noDataDate?: Date;
  reportCriteria: ReportCategory[];
  reportType: ReportType;
  selectedCriteria: ReportCategoryValue[];
  reportPeriod: string[];
  reportGroups: ReportGroup[];
  isLoading: boolean;
  defaultBeginDate?: Date;
  defaultEndDate?: Date;
  handleSearch?(data: SearchPanelParams): void;
  handleSelectCategory?(category: string): void;
}

export interface SearchPanelParams {
  reportGroup: string[];
  reportPeriod: string;
  reportCriteria: string;
  selectedCriteria: string;
  beginDate?: Date;
  endDate?: Date;
}

enum ManagementModuleSearchFields {
  reportPeriod = "reportPeriod",
  selectedCriteria = "selectedCriteria",
  beginDate = "beginDate",
  endDate = "endDate",
  reportCriteria = "reportCriteria",
}

function labelValueOption(label: string, value: string) {
  return (
    <option className="is-uppercase" value={value}>
      {label.toUpperCase()}
    </option>
  );
}
const getChildrenIds = (reportGroup: ReportGroup): string[] => {
  return (
    reportGroup.children?.flatMap((child) => {
      return [child.id, ...getChildrenIds(child)];
    }) ??
    [] ??
    []
  );
};
const getAllSelectableNodes = (reportGroups: ReportGroup[]): string[] =>
  reportGroups.flatMap(
    (group) =>
      [
        group.id,
        ...getAllNodes(group.children ?? []),
      ].filter((a) => a !== undefined) as string[]
  );

const getAllNodes = (reportGroups: ReportGroup[]): string[] =>
  reportGroups.flatMap((group) => [
    group.id,
    ...getAllNodes(group.children ?? []),
  ]);

const getTopLevelNodes = (reportGroups: ReportGroup[]): string[] =>
  reportGroups.flatMap(group => group.id);

export const ManagementModuleSearch = ({
  isBeginDateLoading,
  reportCriteria,
  selectedCriteria,
  reportPeriod,
  reportGroups,
  handleSearch,
  handleSelectCategory,
  isLoading,
  defaultBeginDate,
  defaultEndDate,
  noDataDate,
  reportType,
}: ManagementModuleSearchProps) => {
  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    reset(
      {
        [ManagementModuleSearchFields.selectedCriteria]: "NONE",
        [ManagementModuleSearchFields.beginDate]: defaultBeginDate,
        [ManagementModuleSearchFields.endDate]: defaultEndDate,
        [ManagementModuleSearchFields.reportCriteria]: "NONE",
        [ManagementModuleSearchFields.reportPeriod]: "Date Range",
      },
      {
        keepDefaultValues: true,
      }
    );
  }, [reportType, reset, defaultBeginDate, defaultEndDate]);

  const beginDateErrorTooltipRef = useRef<TooltipRefProps>(null);
  const endDateErrorTooltipRef = useRef<TooltipRefProps>(null);

  useEffect(() => {
    if (errors.beginDate) beginDateErrorTooltipRef.current?.open();
    if (errors.endDate) endDateErrorTooltipRef.current?.open();
  }, [
    errors.beginDate,
    errors.endDate
  ])

  const [selected, setSelected] = useState<string[]>([]);
  const search = (data: any) => {
    const providedEndDate: Date = data.endDate;
    const todaysDate = new Date();
    const endDate = (() => {
      if (
        providedEndDate.getFullYear() === todaysDate.getFullYear() &&
        providedEndDate.getMonth() === todaysDate.getMonth()
      ) {
        return new Date(
          todaysDate.getFullYear(),
          todaysDate.getMonth(),
          todaysDate.getDate() - 1
        );
      } else {
        return new Date(
          providedEndDate.getFullYear(),
          providedEndDate.getMonth() + 1,
          0
        );
      }
    })();
    handleSearch?.({
      ...data,
      reportGroup: selected,
      endDate,
    });
    setSelected(getAllSelectableNodes(reportGroups));
  };
  useEffect(() => {
    if (defaultEndDate) {
      setValue("endDate", defaultEndDate);
    }
  }, [defaultEndDate, setValue]);
  useEffect(() => {
    setValue("beginDate", defaultBeginDate);
  }, [defaultBeginDate, setValue]);
  const [beginDate, endDate] = watch(["beginDate", "endDate"]);
  useEffect(() => {
    setSelected(getAllSelectableNodes(reportGroups));
  }, [reportGroups]);

  const reportGroupToTree = (reportGroup: ReportGroup): TreeviewItem => {
    const children = getAllNodes(reportGroup.children ?? []);
    const isSelected = (reportGroup: ReportGroup): boolean => {
      return reportGroup.hasChildren
        ? reportGroup.children!.every(isSelected)
        : selected.includes(reportGroup.id);
    };
    return {
      id: reportGroup.id,
      title: (
        <div className="is-flex is-justify-content-start" style={{ backgroundColor: "white" }}>
          <Checkbox
            id={`${reportGroup.id}-checkbox`}
            data-testid={`${reportGroup.id}-checkbox`}
            defaultChecked={true}
            color="primary"
            size="small"
            disableRipple={true}
            value={reportGroup.id}
            checked={isSelected(reportGroup)}
            style={{ height: 20 }}
            indeterminate={
              children.some((g) => selected.includes(g)) &&
              !children.every((g) => selected.includes(g))
            }
            onClick={(e) => e.stopPropagation()}
            onChange={(event) => {
              const { checked } = event.target;
              if (checked) {
                const newSelected = [...selected, ...children];
                newSelected.push(reportGroup.id);
                setSelected(newSelected);
              } else {
                const children = getChildrenIds(reportGroup);
                setSelected(
                  selected.filter(
                    (id) => !children.includes(id) && id !== reportGroup.id
                  )
                );
              }
            }}
            disabled={isLoading}
          />
          &nbsp;&nbsp;
          <label htmlFor={`${reportGroup.id}-checkbox`}>
            {reportGroup.name}
          </label>
        </div>
      ),
      children:
        Array.isArray(reportGroup.children) && reportGroup.children.length > 0
          ? reportGroup.children.map(reportGroupToTree)
          : [],
    };
  };
  const reportCriteriaWatch = watch(
    ManagementModuleSearchFields.reportCriteria
  );
  useEffect(() => {
    handleSelectCategory?.(reportCriteriaWatch);
  }, [reportCriteriaWatch, handleSelectCategory]);

  const expandedNodes = useMemo(() => getTopLevelNodes(reportGroups), [reportGroups]);

  return (
    <div className="is-relative management-search">

      <table className="table is-bordered has-text-centered is-fullwidth m-0">
        <colgroup>
          <col width="25%" />
          <col width="25%" />
          <col width="25%" />
          <col width="13%" />
        </colgroup>
        <tbody>
          <tr style={{ height: 41 }}>
            <th className="has-text-white is-uppercase">Report Group</th>
            <th className="has-text-white is-uppercase">Report Criteria</th>
            <th className="has-text-white is-uppercase">Report Period</th>
            <th className="has-text-white">
              <p className="is-uppercase">Begin Date</p>
            </th>
          </tr>
          <tr>
            <td rowSpan={3}>
              <div style={{ overflow: "auto" }}>
                <EBTreeView
                  selected={selected}
                  tree={reportGroups.map(reportGroupToTree)}
                  defaultExpanded={expandedNodes}
                  height={264}
                />
              </div>
            </td>
            <td>
              <select
                tabIndex={3}
                {...register(ManagementModuleSearchFields.reportCriteria)}
              >
                <option selected value={undefined}>
                  NONE
                </option>
                {reportCriteria.map((c) =>
                  labelValueOption(c.description, c.id)
                )}
              </select>
            </td>
            <td>
              <select tabIndex={4} {...register(ManagementModuleSearchFields.reportPeriod)}>
                {reportPeriod.length === 0 ? (
                  <option selected value={undefined}>
                    NONE
                  </option>
                ) : (
                  reportPeriod.map((p) => labelValueOption(p, p))
                )}
              </select>
            </td>
            <td>
              <Controller
                name={ManagementModuleSearchFields.beginDate}
                defaultValue={defaultBeginDate}
                control={control}
                rules={{
                  required: true,
                  validate: {
                    beforeNoDataDate: () => {
                      const value = getValues(
                        ManagementModuleSearchFields.beginDate
                      );
                      if (!noDataDate) {
                        return true;
                      }
                      if (value) {
                        value.setDate(1);
                        value.setHours(0, 0, 0, 0);
                        noDataDate.setDate(1);
                        noDataDate.setHours(0, 0, 0, 0);
                        if (value.getTime() < noDataDate.getTime()) {
                          return false;
                        }
                      }

                      return true;
                    },
                    endDateBeforeBeginDate: () => {
                      const beginDate = getValues(ManagementModuleSearchFields.beginDate);
                      const endDate = getValues(ManagementModuleSearchFields.endDate);
                      beginDate.setDate(1);
                      beginDate.setHours(0, 0, 0, 0);
                      endDate.setDate(1);
                      endDate.setHours(0, 0, 0, 0);
                      const endDateIsAfterBeginDate = beginDate.getTime() <= endDate.getTime();

                      if (!endDateIsAfterBeginDate) {
                        return "End date must be after begin date";
                      }
                      return true;
                    }
                  },
                }}
                render={({ field: { value, ...rest } }) => {
                  return (
                    <ReactDatePicker
                      customInput={<input
                        className={!!errors.beginDate ? 'input-error' : undefined}
                        data-tooltip-id={!!errors.beginDate ? "error-tooltip-begin-date" : undefined}
                        data-tooltip-content={
                          !!errors.beginDate ?
                            ((errors.beginDate.type === 'beforeNoDataDate') ?
                              `No data for this report prior to: ${noDataDate?.toLocaleDateString()}` :
                              (errors.beginDate.type === 'endDateBeforeBeginDate') ?
                                errors.beginDate.message as string :
                                (errors.beginDate.type === "required") ?
                                  'Begin date is required' : '') :
                            undefined
                        }
                        type="text"
                        autoComplete="off"
                      />}
                      tabIndex={1}
                      dateFormat="MMMM yyyy"
                      selected={isBeginDateLoading ? undefined : beginDate}
                      {...rest}
                      disabled={isBeginDateLoading}
                      showMonthYearPicker
                    />
                  );
                }}
              />
              <ReactTooltip
                id="error-tooltip-begin-date"
                ref={beginDateErrorTooltipRef}
                opacity={1}
                style={{
                  color: '#fff',
                  backgroundColor: '#cc0f35',
                  fontSize: '12px',
                }}
              />
            </td>
          </tr>
          <tr style={{ height: 41 }}>
            <th className="has-text-white is-uppercase">Selected Criteria</th>
            <th className="has-text-white is-uppercase"></th>
            <th className="has-text-white is-uppercase">End Date</th>
          </tr>
          <tr>
            <td>
              <select
                tabIndex={5}
                style={{ textTransform: "uppercase" }}
                className="is-uppercase"
                {...register(ManagementModuleSearchFields.selectedCriteria)}
              >
                <option selected>NONE</option>
                {selectedCriteria.map((c) =>
                  labelValueOption(c.value, c.valueId)
                )}
              </select>
            </td>
            <td />
            <td>
              <Controller
                name={ManagementModuleSearchFields.endDate}
                control={control}
                rules={{
                  required: true,
                  validate: {
                    endDateBeforeBeginDate: () => {
                      const beginDate = getValues(ManagementModuleSearchFields.beginDate);
                      const endDate = getValues(ManagementModuleSearchFields.endDate);
                      beginDate.setDate(1);
                      beginDate.setHours(0, 0, 0, 0);
                      endDate.setDate(1);
                      endDate.setHours(0, 0, 0, 0);
                      const endDateIsAfterBeginDate = beginDate.getTime() <= endDate.getTime();

                      if (!endDateIsAfterBeginDate) {
                        return "End date must be after begin date";
                      }
                      return true;
                    }
                  },
                }}
                render={({ field: { value, ...field } }) => {
                  return (
                    <ReactDatePicker
                      customInput={<input
                        data-tooltip-id={!!errors.endDate ? "error-tooltip-end-date" : undefined}
                        data-tooltip-content={
                          !!errors.endDate ?
                            ((errors.endDate.type === "required") ?
                              'End date is required' :
                              (errors.endDate.type === 'endDateBeforeBeginDate') ?
                                errors.endDate.message as string : '') :
                            undefined
                        }
                        className={`${!!errors.endDate ? 'input-error' : ''}`}
                        type="text"
                        autoComplete="off"
                      />}
                      tabIndex={2}
                      dateFormat="MMMM yyyy"
                      selected={endDate}
                      showMonthYearPicker
                      {...field}
                    />
                  );
                }}
              />
              <ReactTooltip
                id="error-tooltip-end-date"
                ref={endDateErrorTooltipRef}
                opacity={1}
                style={{
                  color: '#fff',
                  backgroundColor: '#cc0f35',
                  fontSize: '12px',
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="is-flex is-flex-direction-row is-justify-content-flex-end search-button-bar">
        <button tabIndex={6} className="is-uppercase ie-styled" onClick={handleSubmit(search)}>Submit</button>
      </div>
    </div>
  );
};

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DepositDetailsToJSON = exports.DepositDetailsFromJSONTyped = exports.DepositDetailsFromJSON = void 0;
var runtime_1 = require("../runtime");
function DepositDetailsFromJSON(json) {
    return DepositDetailsFromJSONTyped(json, false);
}
exports.DepositDetailsFromJSON = DepositDetailsFromJSON;
function DepositDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'rowNumber': !runtime_1.exists(json, 'rowNumber') ? undefined : json['rowNumber'],
        'reportDate': !runtime_1.exists(json, 'reportDate') ? undefined : (new Date(json['reportDate'])),
        'formattedReportDate': !runtime_1.exists(json, 'formattedReportDate') ? undefined : json['formattedReportDate'],
        'merchantNbr': !runtime_1.exists(json, 'merchantNbr') ? undefined : json['merchantNbr'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'divisionNbr': !runtime_1.exists(json, 'divisionNbr') ? undefined : json['divisionNbr'],
        'divisionName': !runtime_1.exists(json, 'divisionName') ? undefined : json['divisionName'],
        'outletNbr': !runtime_1.exists(json, 'outletNbr') ? undefined : json['outletNbr'],
        'outletName': !runtime_1.exists(json, 'outletName') ? undefined : json['outletName'],
        'merchantBatchNbr': !runtime_1.exists(json, 'merchantBatchNbr') ? undefined : json['merchantBatchNbr'],
        'grossItems': !runtime_1.exists(json, 'grossItems') ? undefined : json['grossItems'],
        'grossVolume': !runtime_1.exists(json, 'grossVolume') ? undefined : json['grossVolume'],
        'creditItems': !runtime_1.exists(json, 'creditItems') ? undefined : json['creditItems'],
        'creditVolume': !runtime_1.exists(json, 'creditVolume') ? undefined : json['creditVolume'],
        'visaGrossItems': !runtime_1.exists(json, 'visaGrossItems') ? undefined : json['visaGrossItems'],
        'visaGrossVolume': !runtime_1.exists(json, 'visaGrossVolume') ? undefined : json['visaGrossVolume'],
        'visaCreditItems': !runtime_1.exists(json, 'visaCreditItems') ? undefined : json['visaCreditItems'],
        'visaCreditVolume': !runtime_1.exists(json, 'visaCreditVolume') ? undefined : json['visaCreditVolume'],
        'visaNetSales': !runtime_1.exists(json, 'visaNetSales') ? undefined : json['visaNetSales'],
        'visaNetVolume': !runtime_1.exists(json, 'visaNetVolume') ? undefined : json['visaNetVolume'],
        'mcGrossItems': !runtime_1.exists(json, 'mcGrossItems') ? undefined : json['mcGrossItems'],
        'mcGrossVolume': !runtime_1.exists(json, 'mcGrossVolume') ? undefined : json['mcGrossVolume'],
        'mcCreditItems': !runtime_1.exists(json, 'mcCreditItems') ? undefined : json['mcCreditItems'],
        'mcCreditVolume': !runtime_1.exists(json, 'mcCreditVolume') ? undefined : json['mcCreditVolume'],
        'mcNetSales': !runtime_1.exists(json, 'mcNetSales') ? undefined : json['mcNetSales'],
        'mcNetVolume': !runtime_1.exists(json, 'mcNetVolume') ? undefined : json['mcNetVolume'],
        'amexGrossItems': !runtime_1.exists(json, 'amexGrossItems') ? undefined : json['amexGrossItems'],
        'amexGrossVolume': !runtime_1.exists(json, 'amexGrossVolume') ? undefined : json['amexGrossVolume'],
        'amexCreditItems': !runtime_1.exists(json, 'amexCreditItems') ? undefined : json['amexCreditItems'],
        'amexCreditVolume': !runtime_1.exists(json, 'amexCreditVolume') ? undefined : json['amexCreditVolume'],
        'amexNetSales': !runtime_1.exists(json, 'amexNetSales') ? undefined : json['amexNetSales'],
        'amexNetVolume': !runtime_1.exists(json, 'amexNetVolume') ? undefined : json['amexNetVolume'],
        'discoverGrossItems': !runtime_1.exists(json, 'discoverGrossItems') ? undefined : json['discoverGrossItems'],
        'discoverGrossVolume': !runtime_1.exists(json, 'discoverGrossVolume') ? undefined : json['discoverGrossVolume'],
        'discoverCreditItems': !runtime_1.exists(json, 'discoverCreditItems') ? undefined : json['discoverCreditItems'],
        'discoverCreditVolume': !runtime_1.exists(json, 'discoverCreditVolume') ? undefined : json['discoverCreditVolume'],
        'discoverNetSales': !runtime_1.exists(json, 'discoverNetSales') ? undefined : json['discoverNetSales'],
        'discoverNetVolume': !runtime_1.exists(json, 'discoverNetVolume') ? undefined : json['discoverNetVolume'],
        'jcbGrossItems': !runtime_1.exists(json, 'jcbGrossItems') ? undefined : json['jcbGrossItems'],
        'jcbGrossVolume': !runtime_1.exists(json, 'jcbGrossVolume') ? undefined : json['jcbGrossVolume'],
        'jcbCreditItems': !runtime_1.exists(json, 'jcbCreditItems') ? undefined : json['jcbCreditItems'],
        'jcbCreditVolume': !runtime_1.exists(json, 'jcbCreditVolume') ? undefined : json['jcbCreditVolume'],
        'jcbNetSales': !runtime_1.exists(json, 'jcbNetSales') ? undefined : json['jcbNetSales'],
        'jcbNetVolume': !runtime_1.exists(json, 'jcbNetVolume') ? undefined : json['jcbNetVolume'],
        'netSales': !runtime_1.exists(json, 'netSales') ? undefined : json['netSales'],
        'netVolume': !runtime_1.exists(json, 'netVolume') ? undefined : json['netVolume'],
        'netRejects': !runtime_1.exists(json, 'netRejects') ? undefined : json['netRejects'],
        'netChargebackItems': !runtime_1.exists(json, 'netChargebackItems') ? undefined : json['netChargebackItems'],
        'netChargebacks': !runtime_1.exists(json, 'netChargebacks') ? undefined : json['netChargebacks'],
        'dailyFees': !runtime_1.exists(json, 'dailyFees') ? undefined : json['dailyFees'],
        'netDeposit': !runtime_1.exists(json, 'netDeposit') ? undefined : json['netDeposit'],
        'amount': !runtime_1.exists(json, 'amount') ? undefined : json['amount'],
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumber': !runtime_1.exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'cardNumberMasked': !runtime_1.exists(json, 'cardNumberMasked') ? undefined : json['cardNumberMasked'],
        'formattedCardNumber': !runtime_1.exists(json, 'formattedCardNumber') ? undefined : json['formattedCardNumber'],
        'formattedCardType': !runtime_1.exists(json, 'formattedCardType') ? undefined : json['formattedCardType'],
        'cardTypes': !runtime_1.exists(json, 'cardTypes') ? undefined : json['cardTypes'],
        'beginDate': !runtime_1.exists(json, 'beginDate') ? undefined : json['beginDate'],
        'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
        'merchantDDA1': !runtime_1.exists(json, 'merchantDDA1') ? undefined : json['merchantDDA1'],
        'last4DDA1': !runtime_1.exists(json, 'last4DDA1') ? undefined : json['last4DDA1'],
        'netDepositDDA1': !runtime_1.exists(json, 'netDepositDDA1') ? undefined : json['netDepositDDA1'],
        'merchantDDA2': !runtime_1.exists(json, 'merchantDDA2') ? undefined : json['merchantDDA2'],
        'last4DDA2': !runtime_1.exists(json, 'last4DDA2') ? undefined : json['last4DDA2'],
        'netDepositDDA2': !runtime_1.exists(json, 'netDepositDDA2') ? undefined : json['netDepositDDA2'],
        'merchantDDA3': !runtime_1.exists(json, 'merchantDDA3') ? undefined : json['merchantDDA3'],
        'last4DDA3': !runtime_1.exists(json, 'last4DDA3') ? undefined : json['last4DDA3'],
        'netDepositDDA3': !runtime_1.exists(json, 'netDepositDDA3') ? undefined : json['netDepositDDA3'],
        'merchantDDA4': !runtime_1.exists(json, 'merchantDDA4') ? undefined : json['merchantDDA4'],
        'last4DDA4': !runtime_1.exists(json, 'last4DDA4') ? undefined : json['last4DDA4'],
        'netDepositDDA4': !runtime_1.exists(json, 'netDepositDDA4') ? undefined : json['netDepositDDA4'],
        'merchantDDA5': !runtime_1.exists(json, 'merchantDDA5') ? undefined : json['merchantDDA5'],
        'last4DDA5': !runtime_1.exists(json, 'last4DDA5') ? undefined : json['last4DDA5'],
        'netDepositDDA5': !runtime_1.exists(json, 'netDepositDDA5') ? undefined : json['netDepositDDA5'],
        'numberDDAs': !runtime_1.exists(json, 'numberDDAs') ? undefined : json['numberDDAs'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'reasonCode': !runtime_1.exists(json, 'reasonCode') ? undefined : json['reasonCode'],
        'transactionDate': !runtime_1.exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'settleDate': !runtime_1.exists(json, 'settleDate') ? undefined : (new Date(json['settleDate'])),
        'billingDate': !runtime_1.exists(json, 'billingDate') ? undefined : (new Date(json['billingDate'])),
        'tranType': !runtime_1.exists(json, 'tranType') ? undefined : json['tranType'],
        'formattedTranDate': !runtime_1.exists(json, 'formattedTranDate') ? undefined : json['formattedTranDate'],
        'formattedSettleDate': !runtime_1.exists(json, 'formattedSettleDate') ? undefined : json['formattedSettleDate'],
        'formattedBillingDate': !runtime_1.exists(json, 'formattedBillingDate') ? undefined : json['formattedBillingDate'],
        'formattedAmount': !runtime_1.exists(json, 'formattedAmount') ? undefined : json['formattedAmount'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'assetOwner': !runtime_1.exists(json, 'assetOwner') ? undefined : json['assetOwner'],
        'businessLegalName': !runtime_1.exists(json, 'businessLegalName') ? undefined : json['businessLegalName'],
    };
}
exports.DepositDetailsFromJSONTyped = DepositDetailsFromJSONTyped;
function DepositDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'rowNumber': value.rowNumber,
        'reportDate': value.reportDate === undefined ? undefined : (value.reportDate.toISOString()),
        'formattedReportDate': value.formattedReportDate,
        'merchantNbr': value.merchantNbr,
        'merchantName': value.merchantName,
        'divisionNbr': value.divisionNbr,
        'divisionName': value.divisionName,
        'outletNbr': value.outletNbr,
        'outletName': value.outletName,
        'merchantBatchNbr': value.merchantBatchNbr,
        'grossItems': value.grossItems,
        'grossVolume': value.grossVolume,
        'creditItems': value.creditItems,
        'creditVolume': value.creditVolume,
        'visaGrossItems': value.visaGrossItems,
        'visaGrossVolume': value.visaGrossVolume,
        'visaCreditItems': value.visaCreditItems,
        'visaCreditVolume': value.visaCreditVolume,
        'visaNetSales': value.visaNetSales,
        'visaNetVolume': value.visaNetVolume,
        'mcGrossItems': value.mcGrossItems,
        'mcGrossVolume': value.mcGrossVolume,
        'mcCreditItems': value.mcCreditItems,
        'mcCreditVolume': value.mcCreditVolume,
        'mcNetSales': value.mcNetSales,
        'mcNetVolume': value.mcNetVolume,
        'amexGrossItems': value.amexGrossItems,
        'amexGrossVolume': value.amexGrossVolume,
        'amexCreditItems': value.amexCreditItems,
        'amexCreditVolume': value.amexCreditVolume,
        'amexNetSales': value.amexNetSales,
        'amexNetVolume': value.amexNetVolume,
        'discoverGrossItems': value.discoverGrossItems,
        'discoverGrossVolume': value.discoverGrossVolume,
        'discoverCreditItems': value.discoverCreditItems,
        'discoverCreditVolume': value.discoverCreditVolume,
        'discoverNetSales': value.discoverNetSales,
        'discoverNetVolume': value.discoverNetVolume,
        'jcbGrossItems': value.jcbGrossItems,
        'jcbGrossVolume': value.jcbGrossVolume,
        'jcbCreditItems': value.jcbCreditItems,
        'jcbCreditVolume': value.jcbCreditVolume,
        'jcbNetSales': value.jcbNetSales,
        'jcbNetVolume': value.jcbNetVolume,
        'netSales': value.netSales,
        'netVolume': value.netVolume,
        'netRejects': value.netRejects,
        'netChargebackItems': value.netChargebackItems,
        'netChargebacks': value.netChargebacks,
        'dailyFees': value.dailyFees,
        'netDeposit': value.netDeposit,
        'amount': value.amount,
        'cardType': value.cardType,
        'cardNumber': value.cardNumber,
        'cardNumberMasked': value.cardNumberMasked,
        'formattedCardNumber': value.formattedCardNumber,
        'formattedCardType': value.formattedCardType,
        'cardTypes': value.cardTypes,
        'beginDate': value.beginDate,
        'endDate': value.endDate,
        'merchantDDA1': value.merchantDDA1,
        'last4DDA1': value.last4DDA1,
        'netDepositDDA1': value.netDepositDDA1,
        'merchantDDA2': value.merchantDDA2,
        'last4DDA2': value.last4DDA2,
        'netDepositDDA2': value.netDepositDDA2,
        'merchantDDA3': value.merchantDDA3,
        'last4DDA3': value.last4DDA3,
        'netDepositDDA3': value.netDepositDDA3,
        'merchantDDA4': value.merchantDDA4,
        'last4DDA4': value.last4DDA4,
        'netDepositDDA4': value.netDepositDDA4,
        'merchantDDA5': value.merchantDDA5,
        'last4DDA5': value.last4DDA5,
        'netDepositDDA5': value.netDepositDDA5,
        'numberDDAs': value.numberDDAs,
        'region': value.region,
        'reasonCode': value.reasonCode,
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString()),
        'settleDate': value.settleDate === undefined ? undefined : (value.settleDate.toISOString()),
        'billingDate': value.billingDate === undefined ? undefined : (value.billingDate.toISOString()),
        'tranType': value.tranType,
        'formattedTranDate': value.formattedTranDate,
        'formattedSettleDate': value.formattedSettleDate,
        'formattedBillingDate': value.formattedBillingDate,
        'formattedAmount': value.formattedAmount,
        'description': value.description,
        'assetOwner': value.assetOwner,
        'businessLegalName': value.businessLegalName,
    };
}
exports.DepositDetailsToJSON = DepositDetailsToJSON;

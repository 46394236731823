export enum TransactionSortField {
  cardNumber,
  merchantNumber,
  merchantName,
  outletNumber,
  outletName,
  depositDate,
  cardType,
  posEntry,
  authCode,
  rfcCode,
  tranDate,
  tranType,
  tranAmount,
  tranStatus,
}
export enum TransactionType {
  AuthOnly = "Auth Only",
  SalesCredits = "Sales/Refunds",
  CBRev = "CB/REV",
  RFC = "RFC",
  CBLifeCycle = "CB Life Cycle",
}
export const reversedTxType: { [key: string]: TransactionType } = {
  "Auth Only": TransactionType.AuthOnly,
  "Sales/Refunds": TransactionType.SalesCredits,
  "CB/REV": TransactionType.CBRev,
  RFC: TransactionType.RFC,
  "CB Life Cycle": TransactionType.CBLifeCycle,
};
export class Transaction {
  id: string = "";
  merchantNumber?: string = "";
  merchantName?: string = "";
  mid?: string = "";
  midName?: string = "";
  billedDate?: Date;
  processDate?: Date;
  expDate?: string;
  disputeDescription?: string;
  closedDescription?: string;
  propertyCode?: string;
  assetOwner?: string;
  outletNumber?: string = "";
  outletName?: string = "";
  depositDate?: Date;
  cardType?: string;
  cardNumber?: string = "";
  posEntry?: string = "";
  posEntryDesc?: string = "";
  authCode?: string = "";
  rfcCode?: string = "";
  transactionDate?: Date;
  transactionType?: string;
  transactionAmount?: string = "";
  transactionStatus?: string = "";
  maskedCardNumber?: string = "";
  lifeCycleIndGroup?: number;
  loadBatchNumber?: string;
  loadEntryNumber?: string;
  loadFileNumber?: string;
}

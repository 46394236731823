import { useEffect, useState } from "react";
import { UserEntity } from "../models/user";
import { UserService } from "../services/user";
import { usePagination } from "./use_pagination";

export function useUserCollection(
  userService: UserService,
  pageSize: number = 10
) {
  const [users, setUsers] = useState<UserEntity[]>([]);
  const [totalResults, setTotalResults] = useState(0);
  const pagination = usePagination(pageSize, totalResults);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    userService
      .getUsers({
        pageSize,
        page: pagination.page,
      })
      .then((result) => {
        setUsers(result.data ?? []);
        setTotalResults(result.total);
      })
      .finally(() => setLoading(false));
  }, [userService, pageSize, pagination.page]);
  return { users, loading, pagination };
}

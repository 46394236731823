import React, { useEffect } from "react";
import { alpha, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView, { MultiSelectTreeViewProps } from "@material-ui/lab/TreeView";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";
import { Link } from "react-router-dom";

function Expandable({ children }: { children: any }) {
  return <div className="expandable">{children}</div>;
}

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  label: {
    fontSize: 12,
    fontWeight: "bold",
    // textTransform: "uppercase",
    textAlign: "left",
  },
}))((props: TreeItemProps) => <TreeItem {...props} />);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    maxWidth: 400,
  },
});
export interface TreeviewItem {
  id: string;
  title: React.ReactNode | string;
  to?: string;
  children?: TreeviewItem[];
  icon?: React.ReactNode;
}

const wrapWithLink = (item: TreeviewItem) => {
  if (!!item.to) {
    return <Link to={item.to}>{item.title}</Link>;
  } else return <div>{item.title}</div>;
};
function getTreeNodes(tree: TreeviewItem[] = []) {
  return tree.map((item) => {
    return (
      <StyledTreeItem
        key={item.id}
        nodeId={item.id}
        label={wrapWithLink(item)}
        icon={item.icon}
      >
        {getTreeNodes(item.children)}
      </StyledTreeItem>
    );
  });
}

export interface EBTreeViewProps
  extends Omit<MultiSelectTreeViewProps, "children"> {
  tree?: TreeviewItem[];
  height?: number;
}
export default function EBTreeView({
  tree,
  defaultExpandIcon = (
    <img
      src={`${process.env.PUBLIC_URL}/images/folderClosed.gif`}
      alt="expand"
    />
  ),
  defaultCollapseIcon = (
    <img
      src={`${process.env.PUBLIC_URL}/images/folderOpen.gif`}
      alt="collapse"
    />
  ),
  defaultEndIcon = (
    <img src={`${process.env.PUBLIC_URL}/images/leaf.gif`} alt="leaf" />
  ),
  ...props
}: EBTreeViewProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState<string[]>([]);
  useEffect(() => {
    if (expanded.length === 0) {
      setExpanded(props.defaultExpanded ?? []);
    }
  }, [props.defaultExpanded, expanded]);
  return (
    <TreeView
      className={classes.root}
      style={{
        height: props.height,
      }}
      {...(props as MultiSelectTreeViewProps)}
      defaultExpandIcon={<Expandable>{defaultExpandIcon}</Expandable>}
      defaultEndIcon={<Expandable>{defaultEndIcon}</Expandable>}
      defaultCollapseIcon={<Expandable>{defaultCollapseIcon}</Expandable>}
      expanded={expanded}
      onNodeToggle={(e, nodeIds) => {
        setExpanded(nodeIds);
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {getTreeNodes(tree)}
    </TreeView>
  );
}

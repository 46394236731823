import React from "react";
import { EbocomWarningMessage } from "./ebocom_warning_message";
export const NoResults = () => {
  return (
    <EbocomWarningMessage message={"No results for given search parameters"} />
  );
};

NoResults.Table = ({ text }: { text: string }) => {
  return (
    <div
      style={{
        fontSize: 12,
        fontWeight: "bold",
        fontStyle: "italic",
        marginTop: 10,
      }}
    >
      {text}
    </div>
  );
};

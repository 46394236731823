import React from "react";
import { Pagination } from "./pagination";

export interface TableTitleProps {
  onExport?: VoidFunction;
  onPageNext?: VoidFunction;
  onPagePrevious?: VoidFunction;
  onPageFirst?: VoidFunction;
  onPageLast?: VoidFunction;
  onShowAll?: VoidFunction;
  pageSize?: number;
  title: string;
  offset?: number;
  totalResults?: number;
  currentResultCount?: number;
  paginationDisabled?: boolean;
  displayResultCount?: boolean;
  hideShowAllButton?: boolean;
}
export function TableTitle({
  onExport,
  onPageNext,
  onPageFirst,
  onPageLast,
  onPagePrevious,
  onShowAll,
  pageSize = 10,
  paginationDisabled = false,
  offset = 0,
  currentResultCount = 0,
  totalResults,
  title = "",
  displayResultCount = true,
  hideShowAllButton = false,
}: TableTitleProps) {
  const resultsCountFrom = totalResults ? ((offset + currentResultCount) > totalResults ? totalResults - 4 : (offset + 1)) : 0;
  const resultsCountTo = totalResults ? ((offset + currentResultCount) > totalResults ? totalResults : (offset + currentResultCount)) : 0;

  return (
    <div className="is-flex flex-direction-row is-align-items-flex-end paginated-table-header-row">
      <div
        className="title is-6 m-0 p-0 is-uppercase has-text-weight-bold"
        style={{ fontSize: 13, flex: 1 }}
      >
        {title}
      </div>
      <div
        style={{
          fontWeight: "bold",
        }}
        className="is-flex flex-direction-row middle is-justify-content-center"
      >
        {totalResults && displayResultCount ? (
          <span data-testid="display-pagination">{resultsCountFrom} - {resultsCountTo} of {totalResults}</span>
        ) : (
          ""
        )}
      </div>
      <div
        className="is-flex is-flex-direction-row middle is-justify-content-flex-end"
        style={{ flex: 1 }}
      >
        {
          totalResults && totalResults > 0 ? (
            <div className="is-flex is-flex-direction-row is-align-item-center">
              {onExport && (
                <div
                  onClick={paginationDisabled ? undefined : onExport}
                  style={
                    paginationDisabled
                      ? { filter: "grayscale(100%)" }
                      : { cursor: "pointer" }
                  }
                >
                  <img
                    alt="export"
                    src={`${process.env.PUBLIC_URL}/images/export.png`}
                    style={{
                      // height: 35.6,
                      // width: 32,
                      // objectFit: "contain",
                      // objectPosition: "50% 78%",
                    }}
                  />
                </div>
              )}
              <Pagination
                disabled={paginationDisabled}
                className="column columns is-justify-content-center is-align-items-center"
                currentItemsShown={currentResultCount}
                currentOffset={offset}
                totalResults={totalResults ?? 0}
                onGoToEnd={onPageLast}
                onGoToBeginning={onPageFirst}
                onPrevious={onPagePrevious}
                onNext={onPageNext}
                onShowAll={onShowAll}
                hideShowAllButton={hideShowAllButton}
                pageSize={pageSize}
              />
            </div>
          ) : null}

      </div>
    </div>
  );
}


"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionSearchToJSON = exports.TransactionSearchFromJSONTyped = exports.TransactionSearchFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function TransactionSearchFromJSON(json) {
    return TransactionSearchFromJSONTyped(json, false);
}
exports.TransactionSearchFromJSON = TransactionSearchFromJSON;
function TransactionSearchFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNbr': !runtime_1.exists(json, 'merchantNbr') ? undefined : json['merchantNbr'],
        'divisionNbr': !runtime_1.exists(json, 'divisionNbr') ? undefined : json['divisionNbr'],
        'outlet': !runtime_1.exists(json, 'outlet') ? undefined : json['outlet'],
        'amount': !runtime_1.exists(json, 'amount') ? undefined : json['amount'],
        'amountOp': !runtime_1.exists(json, 'amountOp') ? undefined : json['amountOp'],
        'cardTypes': !runtime_1.exists(json, 'cardTypes') ? undefined : (json['cardTypes'].map(_1.CardTypesFromJSON)),
        'cardNbr': !runtime_1.exists(json, 'cardNbr') ? undefined : json['cardNbr'],
        'dateBegin': !runtime_1.exists(json, 'dateBegin') ? undefined : json['dateBegin'],
        'dateEnd': !runtime_1.exists(json, 'dateEnd') ? undefined : json['dateEnd'],
        'transactionTypes': !runtime_1.exists(json, 'transactionTypes') ? undefined : (json['transactionTypes'].map(_1.TransactionSearchTypesFromJSON)),
        'sortBy': !runtime_1.exists(json, 'sortBy') ? undefined : _1.SortByColumnFromJSON(json['sortBy']),
        'sortOrder': !runtime_1.exists(json, 'sortOrder') ? undefined : _1.SortOrderFromJSON(json['sortOrder']),
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
        'maxResults': !runtime_1.exists(json, 'maxResults') ? undefined : json['maxResults'],
    };
}
exports.TransactionSearchFromJSONTyped = TransactionSearchFromJSONTyped;
function TransactionSearchToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNbr': value.merchantNbr,
        'divisionNbr': value.divisionNbr,
        'outlet': value.outlet,
        'amount': value.amount,
        'amountOp': value.amountOp,
        'cardTypes': value.cardTypes === undefined ? undefined : (value.cardTypes.map(_1.CardTypesToJSON)),
        'cardNbr': value.cardNbr,
        'dateBegin': value.dateBegin,
        'dateEnd': value.dateEnd,
        'transactionTypes': value.transactionTypes === undefined ? undefined : (value.transactionTypes.map(_1.TransactionSearchTypesToJSON)),
        'sortBy': _1.SortByColumnToJSON(value.sortBy),
        'sortOrder': _1.SortOrderToJSON(value.sortOrder),
        'offset': value.offset,
        'maxResults': value.maxResults,
    };
}
exports.TransactionSearchToJSON = TransactionSearchToJSON;

import React from "react";
import { useParams } from "react-router";
import { DocumentType } from "../../models/document";

export interface DocumentRouteProps {
  documentTypeSerializer: (documentType: string) => DocumentType[] | undefined;
  paramKey: string;
  children: (documentTypes: DocumentType[]) => JSX.Element;
}
export function DocumentRoute({
  paramKey,
  documentTypeSerializer,
  children,
}: DocumentRouteProps) {
  const params = useParams<{ [key: string]: string }>();
  if (!params[paramKey]) {
    return null;
  }
  const serializedType = documentTypeSerializer(params[paramKey]);
  if (serializedType === undefined) {
    return <div />;
  }
  return children(serializedType);
}

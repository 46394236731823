import React from "react";
import { useDownload } from "../hooks/use_download";
import { ReportService } from "../services/report";

export interface DownloadHandlerProps {
  url: string;
  children: (isLoading: boolean) => any;
  reportService: ReportService;
  defaultFilename: string;
  style?: React.CSSProperties;
  onStartDownload?: () => void;
  onFinishDownload?: () => void;
}

export function ReportFileDownloader({
  url,
  reportService,
  children,
  defaultFilename,
  style,
  onStartDownload,
  onFinishDownload,
}: DownloadHandlerProps) {
  const { download, isDownloading } = useDownload(
    reportService,
    onStartDownload,
    onFinishDownload
  );
  return (
    <div
      className="button is-text is-small"
      onClick={() => download(url, defaultFilename)}
      style={{ textDecoration: "none", ...(style ?? {}) }}
    >
      {children(isDownloading)}
    </div>
  );
}

ReportFileDownloader.PDF = ({
  defaultFilename: fileName,
  reportService,
  url,
  title,
}: Omit<DownloadHandlerProps, "children"> & { title?: string }) => (
  <ReportFileDownloader
    defaultFilename={fileName}
    reportService={reportService}
    url={url}
  >
    {(isLoading) => (
      <div
        className={"is-flex is-justify-content-center is-align-items-center"}
        style={{
          cursor: isLoading ? "default" : "pointer",
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/images/pdficon.jpg`}
          alt="pdf logo"
          style={{
            objectFit: "contain",
            height: 20,
            filter: isLoading ? "grayscale(100%)" : "none",
          }}
        />
        <div style={{ width: 10 }} />
        <span className="dl-mgmt-report">{title ?? "Click here to view report"}</span>
      </div>
    )}
  </ReportFileDownloader>
);

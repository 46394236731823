import { useCallback, useEffect, useState } from "react";
import { Transaction, TransactionSortField } from "../models/transaction";
import {
  TransactionSearchParameters,
  TransactionService,
} from "../services/transaction";
import { Pagination, usePagination } from "./use_pagination";
import { Sorting, useSorting } from "./use_sorting";

export interface UseTransactionResult {
  transactions: Transaction[];
  isLoading: boolean;
  pagination: Pagination;
  reset(): void;
  error?: string;
  handleSort: (sortField: TransactionSortField) => void;
  sorting?: Sorting<TransactionSortField>;
}
export const useTransactions = (
  transactionService: TransactionService,
  parameters?: TransactionSearchParameters
): UseTransactionResult => {
  const {
    sorting,
    handleSort,
    reset: resetSorting,
  } = useSorting<TransactionSortField>();
  const [isLoading, setIsLoading] = useState(false);
  const [totalResults, setTotalResults] = useState(0);
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [error, setError] = useState<string>();
  const pagination = usePagination(10, totalResults);

  const { page, firstPage, setPageSize } = pagination;
  const [searchParameters, setSearchParameters] = useState<
    TransactionSearchParameters | undefined
  >(parameters);

  useEffect(() => {
    setSearchParameters(parameters);
  }, [parameters, setSearchParameters]);

  useEffect(() => {
    if (!searchParameters) {
      return;
    }
    setError(undefined);
    setIsLoading(true);
    transactionService
      .getTransactions({
        ...searchParameters,
        limit: pagination.pageSize,
        offset: page,
        sortField: sorting?.field,
        sortOrder: sorting?.direction,
      })
      .then((txResult) => {
        setTransactions(txResult.data);
        setTotalResults(txResult.total);
      })
      .catch((e) => {
        if (e.message) {
          return setError(e.message);
        }
        return setError("There was an error during the request");
      })
      .finally(() => setIsLoading(false));
  }, [
    transactionService,
    searchParameters,
    page,
    sorting,
    pagination.pageSize,
  ]);

  const reset = useCallback(() => {
    setTransactions([]);
    setIsLoading(false);
    setTotalResults(0);
    setPageSize(10);
    setError(undefined);
    handleSort();
    firstPage();
    resetSorting();
  }, [resetSorting, firstPage, handleSort, setPageSize]);

  return {
    pagination,
    handleSort,
    sorting,
    transactions,
    isLoading,
    error,
    reset,
  };
};

import { createContext } from "react";

export interface ApplicationConfig {
  ebocomBackendBaseUrl: string;
  shouldUseFaked: boolean;
  kcRealm: string;
  kcUrl: string;
  kcClientId: string;
}

const config: { init(config: ApplicationConfig): void } & ApplicationConfig = {
  init({
    ebocomBackendBaseUrl,
    shouldUseFaked,
    kcClientId,
    kcRealm,
    kcUrl,
  }: ApplicationConfig) {
    this.ebocomBackendBaseUrl = ebocomBackendBaseUrl;
    this.shouldUseFaked = shouldUseFaked;
    this.kcClientId = kcClientId;
    this.kcRealm = kcRealm;
    this.kcUrl = kcUrl;
  },
  ebocomBackendBaseUrl: "",
  kcClientId: "",
  kcRealm: "",
  kcUrl: "",
  shouldUseFaked: false,
};
export function createApplicationConfig({
  ebocomBaseUrl,
  ebocomKcClientId,
  ebocomKcRealm,
  ebocomKcUrl,
}: {
  ebocomBaseUrl: string;
  ebocomKcClientId: string;
  ebocomKcRealm: string;
  ebocomKcUrl: string;
}): ApplicationConfig {
  config.init({
    ebocomBackendBaseUrl: ebocomBaseUrl,
    shouldUseFaked: process.env.REACT_APP_USE_FAKED === "true",
    kcClientId: ebocomKcClientId,
    kcRealm: ebocomKcRealm,
    kcUrl: ebocomKcUrl,
  });
  return config;
}

export const ConfigContext = createContext<ApplicationConfig | null>(null);

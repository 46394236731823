export interface ServiceProvider {
  name?: string;
  phone?: string;
  fax?: string;
}
export class CardDetails {
  cardType?: string;
  instName?: string;
  provider?: string;
  instCountry?: string;
  binNumber?: number;
  serviceProviders?: ServiceProvider[];
}

import create from 'zustand';
import { TransactionSearchPanelParameters } from '../components/transaction_search_panel';

interface SuperSearchParametersState {
    params: TransactionSearchPanelParameters | null;
    setParamsState: (params: TransactionSearchPanelParameters) => void;
    resetParamsState: () => void;
    routes: {
        current: string | null;
        previous: string | null;
    },
    setRoutes: (route: string) => void;
    shouldFillWithPreviousParameters: boolean;
    formFillingInProgress: boolean;
    setFormFillingState: (formFillingInProgress: boolean) => void;
}

const useSuperSearchParametersState = create<SuperSearchParametersState>((set) => ({
    params: null,
    setParamsState: (params) => set({ params }),
    resetParamsState: () => set({ params: null }),
    routes: {
        current: null,
        previous: null,
    },
    setRoutes: (route) => set(state => {
        if (route === state.routes.current) return state;
        return ({
            ...state,
            routes: {
                previous: state.routes.current,
                current: route,
            },
            shouldFillWithPreviousParameters: route === '/transactions/search' && (state.routes.current === '/depositdetailsoc' || state.routes.current === '/depositdetails')
        })
    }),
    shouldFillWithPreviousParameters: false,
    formFillingInProgress: false,
    setFormFillingState: (formFillingInProgress) => set({ formFillingInProgress })
}))

export default useSuperSearchParametersState;
import React from "react";
import { EbocomDetailWarningMessage } from "../../../components/ebocom_warning_message";
import { Tooltip } from "../../../components/tooltip";
import { DepositDetail } from "../../../models/deposit_detail";
import { SalesCredit } from "../../../models/sales_credit";
import { DateService } from "../../../services/date_service";
import "./deposit_detail_table.scss";
import faker from "faker";

export type DepositDetailColumns =
  | "emptyDetail"
  | "emptyReportDate"
  | "batchNumber"
  | "cardNumber"
  | "cardType"
  | "abbreviatedCardType"
  | "creditsTxCount"
  | "creditsTxVolume"
  | "dailyFees"
  | "detail"
  | "merchant name"
  | "merchantNumber"
  | "miscAdj"
  | "netActivity"
  | "netChbksItemsCount"
  | "netChbksItemsVolume"
  | "netDeposit"
  | "netSalesTxCount"
  | "netSalesTxVolume"
  | "outletName"
  | "outletNumber"
  | "posEntryMode"
  | "reasonCode"
  | "report date"
  | "salesTxCount"
  | "salesTxVolume"
  | "settleDate"
  | "summaryDate"
  | "tranAmount"
  | "tranDate"
  | "tranType";
export interface DepositDetailProps<
  T extends Partial<DepositDetail | SalesCredit | undefined>
  > {
  deposits?: Array<Partial<T>>;
  onDrilldown?: (deposit: Partial<DepositDetail>) => void;
  includedColumns?: Array<DepositDetailColumns>;
  isLoading?: boolean;
  hightlightedItem?: Partial<T>;
  showNetDepositTooltip?: boolean;
  detailTooltip?: string;
  tableName?: string;
  isActivity?: boolean;
  showShortCardType?: boolean;
  NoResultsErrorMessage?: string;
}
export function DepositDetailTable<T extends DepositDetail & SalesCredit>({
  deposits = [],
  onDrilldown,
  includedColumns = [],
  isLoading = false,
  hightlightedItem,
  detailTooltip = "",
  showNetDepositTooltip = false,
  tableName = "",
  showShortCardType = false,
  isActivity = false,
  NoResultsErrorMessage = ""
}: DepositDetailProps<T>) {
  const isFieldIncluded = includedColumns?.reduce<{
    [key in DepositDetailColumns]?: boolean;
  }>((acc, curr) => ({ ...acc, [curr]: true }), {});
  return (
    <div className="deposit-detail-table">
      <table className={`table is-bordered has-text-centered`}>
        <tbody>
          <tr className={`is-uppercase has-text-white`}>
            {isFieldIncluded.emptyDetail && (
              <th
                style={{ width: "42px" }}
                rowSpan={2}
                className="has-text-white"
              >
                &nbsp;
              </th>
            )}
            {isFieldIncluded.detail && (
              <th
                style={{ width: "42px" }}
                rowSpan={2}
                className="has-text-white"
              >
                {!!onDrilldown ? "Detail" : null}
              </th>
            )}
            {isFieldIncluded.emptyReportDate && (
              <th
                style={{ width: "77px" }}
                rowSpan={2}
                className="has-text-white"
              >
                &nbsp;
              </th>
            )}
            {isFieldIncluded.settleDate && (
              <th
                style={{ width: "77px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Report Date
              </th>
            )}
            {isFieldIncluded["summaryDate"] && (
              <th
                style={{ width: "77px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Report Date
              </th>
            )}
            {isFieldIncluded["report date"] && (
              <th
                style={{ width: "77px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Report Date
              </th>
            )}
            {isFieldIncluded["batchNumber"] && (
              <th
                style={{ width: "80px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Batch Number
              </th>
            )}
            {isFieldIncluded.merchantNumber && (
              <th
                style={{ width: "66px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Merchant Number
              </th>
            )}
            {isFieldIncluded["merchant name"] && (
              <th
                style={{ width: isActivity ? "153px" : "207px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Merchant Name
              </th>
            )}
            {isFieldIncluded.outletNumber && (
              <th
                style={{ width: "60px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Outlet Number
              </th>
            )}
            {isFieldIncluded.outletName && (
              <th
                style={{
                  width:
                    tableName === "batch"
                      ? "270px"
                      : tableName === "tran"
                        ? "186px"
                        : "147px",
                }}
                rowSpan={2}
                className="has-text-white"
              >
                Outlet Name
              </th>
            )}
            {isFieldIncluded.cardType && (
              <th
                style={{
                  width:
                    tableName === "tran"
                      ? isActivity
                        ? "82px"
                        : "35px"
                      : "207px",
                }}
                rowSpan={2}
                className="has-text-white"
              >
                Card Type
              </th>
            )}
            {isFieldIncluded.abbreviatedCardType && (
              <th
                style={{
                  width:
                    tableName === "tran"
                      ? isActivity
                        ? "82px"
                        : "35px"
                      : "82px",
                }}
                rowSpan={2}
                className="has-text-white"
              >
                Card Type
              </th>
            )}
            {isFieldIncluded.cardNumber && (
              <th
                style={{ width: "103px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Card Number
              </th>
            )}
            {isFieldIncluded.reasonCode && (
              <th
                style={{ width: "74px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Reason Code
              </th>
            )}
            {isFieldIncluded.posEntryMode && (
              <th
                style={{ width: isActivity ? "74px" : "47px" }}
                rowSpan={2}
                className="has-text-white"
              >
                POS Entry Mode
              </th>
            )}
            {isFieldIncluded.tranDate && (
              <th
                style={{ width: "47px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Tran Date
              </th>
            )}
            {isFieldIncluded.tranType && (
              <th
                style={{ width: "68px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Tran Type
              </th>
            )}
            {isFieldIncluded.tranAmount && (
              <th
                style={{ width: "68px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Tran Amt
              </th>
            )}
            {(isFieldIncluded.salesTxCount ||
              isFieldIncluded.salesTxVolume) && (
                <th
                  style={{
                    width: isActivity
                      ? tableName === "date"
                        ? "146px"
                        : "126px"
                      : "186px",
                  }}
                  colSpan={
                    [
                      isFieldIncluded.salesTxCount,
                      isFieldIncluded.salesTxVolume,
                    ].filter(Boolean).length
                  }
                  className="has-text-white"
                >
                  Sales
                </th>
              )}
            {(isFieldIncluded.creditsTxCount ||
              isFieldIncluded.creditsTxVolume) && (
                <th
                  style={{
                    width: isActivity
                      ? tableName === "date"
                        ? "146px"
                        : "126px"
                      : "186px",
                  }}
                  colSpan={
                    [
                      isFieldIncluded.creditsTxCount,
                      isFieldIncluded.creditsTxVolume,
                    ].filter(Boolean).length
                  }
                  className="has-text-white"
                >
                  Refunds
                </th>
              )}
            {(isFieldIncluded.netSalesTxCount ||
              isFieldIncluded.netSalesTxVolume) && (
                <th
                  style={{
                    width: isActivity
                      ? tableName === "date"
                        ? "146px"
                        : "126px"
                      : "186px",
                  }}
                  colSpan={
                    [
                      isFieldIncluded.netSalesTxCount,
                      isFieldIncluded.netSalesTxVolume,
                    ].filter(Boolean).length
                  }
                  className="has-text-white"
                >
                  Net Sales
                </th>
              )}
            {(isFieldIncluded.netChbksItemsCount ||
              isFieldIncluded.netChbksItemsVolume) && (
                <th
                  style={{
                    width: isActivity
                      ? tableName === "date"
                        ? "146px"
                        : "126px"
                      : "186px",
                  }}
                  colSpan={
                    [
                      isFieldIncluded.netChbksItemsCount,
                      isFieldIncluded.netChbksItemsVolume,
                    ].filter(Boolean).length
                  }
                  className="has-text-white"
                >
                  Net Chbks
                </th>
              )}
            {isFieldIncluded.miscAdj && (
              <th
                style={{ width: "48px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Misc Adj
              </th>
            )}
            {isFieldIncluded.dailyFees && (
              <th
                style={{ width: "58px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Daily Fees
              </th>
            )}
            {isFieldIncluded.netDeposit && (
              <th
                style={{ width: "68px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Net Deposit
              </th>
            )}
            {isFieldIncluded.netActivity && (
              <th
                style={{ width: "68px" }}
                rowSpan={2}
                className="has-text-white"
              >
                Net Activity
              </th>
            )}
          </tr>
          <tr>
            {isFieldIncluded.salesTxCount && (
              <th
                style={{ width: isActivity ? "63px" : "83px" }}
                className="has-text-white"
                data-testid="sales-transaction-count-header"
              >
                # Trans
              </th>
            )}
            {isFieldIncluded.salesTxVolume && (
              <th
                style={{ width: isActivity ? "83px" : "103px" }}
                className="has-text-white"
                data-testid="sales-volume-header"
              >
                $ Volume
              </th>
            )}
            {isFieldIncluded.creditsTxCount && (
              <th
                style={{ width: isActivity ? "63px" : "83px" }}
                className="has-text-white"
                data-testid="credit-transaction-count-header"
              >
                # Trans
              </th>
            )}
            {isFieldIncluded.creditsTxVolume && (
              <th
                style={{ width: isActivity ? "83px" : "103px" }}
                className="has-text-white"
                data-testid="credit-volume-header"
              >
                $ Volume
              </th>
            )}
            {isFieldIncluded.netSalesTxCount && (
              <th
                style={{ width: isActivity ? "63px" : "83px" }}
                className="has-text-white"
                data-testid="net-sales-transaction-count-header"
              >
                # Trans
              </th>
            )}
            {isFieldIncluded.netSalesTxVolume && (
              <th
                style={{ width: isActivity ? "83px" : "103px" }}
                className="has-text-white"
                data-testid="net-sales-volume-header"
              >
                $ Volume
              </th>
            )}
            {isFieldIncluded.netChbksItemsCount && (
              <th
                style={{ width: isActivity ? "63px" : "83px" }}
                className="has-text-white"
                data-testid="net-chbks-count-header"
              >
                # Trans
              </th>
            )}
            {isFieldIncluded.netChbksItemsVolume && (
              <th
                style={{ width: isActivity ? "83px" : "103px" }}
                className="has-text-white"
                data-testid="net-chbks-sales-volume-header"
              >
                $ Volume
              </th>
            )}
          </tr>
          {
            (
              deposits.length > 0
                ? deposits.map((deposit) => (
                  <DepositDetailRow
                    detailTooltip={detailTooltip}
                    showNetDepositTooltip={showNetDepositTooltip}
                    isLoading={isLoading}
                    onDrilldown={onDrilldown}
                    isHighlighted={deposit === hightlightedItem}
                    key={`${JSON.stringify(deposit)}-${faker.random.alphaNumeric(20)}`}
                    isFieldIncluded={isFieldIncluded}
                    deposit={deposit}
                    showShortCardType={showShortCardType}
                  />
                ))
                : <tr><td style={{ textAlign: "left", paddingTop: ".5rem", paddingBottom: ".5rem", border: "none" }} colSpan={includedColumns.length}><EbocomDetailWarningMessage message={NoResultsErrorMessage} /></td></tr>
            )
          }
        </tbody>
      </table>
    </div>
  );
}

interface DepositDetailRowProps {
  deposit: Partial<DepositDetail & SalesCredit>;
  onDrilldown?: (deposit: Partial<SalesCredit & DepositDetail>) => void;
  isFieldIncluded?: { [key in DepositDetailColumns]?: boolean };
  isLoading: boolean;
  isHighlighted: boolean;
  showNetDepositTooltip: boolean;
  showShortCardType?: boolean;
  detailTooltip?: string;
}
function DepositDetailRow({
  deposit,
  isFieldIncluded = {},
  onDrilldown,
  isLoading = false,
  isHighlighted,
  showNetDepositTooltip,
  detailTooltip,
  showShortCardType = false,
}: DepositDetailRowProps) {
  const detailImage = !!onDrilldown ? (
    <div
      data-testid="deposit-detail-button"
      onClick={isLoading ? () => { } : () => onDrilldown?.(deposit)}
      style={{ cursor: 'pointer' }}
    >
      <img
        style={{
          height: 20,
          filter: isLoading ? "grayscale(100%)" : "grayscale(0%)",
          opacity: isLoading ? 0.3 : 1,
          mixBlendMode: "multiply",
        }}
        src={`${process.env.PUBLIC_URL}/images/detail.jpg`}
        alt="Details"
      />
    </div>
  ) : (
    <div style={{ width: "40px" }} />
  );
  return (
    <tr
      key={`${deposit.merchantName}${deposit.transactionAmount}${deposit.posEntryMode}`}
      className={`${isHighlighted ? "is-highlighted" : ""}`}
    >
      {isFieldIncluded.emptyDetail && (
        <td className="detail-cell m-0 p-0">&nbsp;</td>
      )}
      {isFieldIncluded["detail"] && (
        <td style={{ width: 38, height: 26 }} className="detail-cell m-0 p-0">
          {detailTooltip ? (
            <Tooltip.Container>
              {detailImage}
              <Tooltip style={{ whiteSpace: "nowrap" }}>
                {detailTooltip}
              </Tooltip>
            </Tooltip.Container>
          ) : (
            detailImage
          )}
        </td>
      )}
      {isFieldIncluded.emptyReportDate && <td>&nbsp;</td>}
      {isFieldIncluded.batchNumber && (
        <td>
          <span data-testid="deposit-detail-date">{deposit.batchNumber}</span>
        </td>
      )}
      {isFieldIncluded.settleDate && (
        <td style={{ width: 80 }}>
          <span data-testid="deposit-detail-date">
            {DateService.formatDate(deposit.settleDate, "-")}
          </span>
        </td>
      )}
      {isFieldIncluded["summaryDate"] && (
        <td style={{ width: 80 }}>
          <span data-testid="deposit-detail-date">
            {DateService.formatDate(deposit.billingDate, "-")}
          </span>
        </td>
      )}
      {isFieldIncluded["report date"] && (
        <td style={{ width: 80 }}>
          <span data-testid="deposit-detail-date">
            {DateService.formatDate(deposit.reportDate, "-")}
          </span>
        </td>
      )}
      {isFieldIncluded["merchantNumber"] && (
        <td style={{ width: 85 }}>
          <span data-testid="deposit-detail-merchant-number">
            {deposit.merchantNumber}
          </span>
        </td>
      )}
      {isFieldIncluded["merchant name"] && (
        <td style={{ width: 150 }} className="has-text-left">
          <span data-testid="deposit-detail-merchant-name">
            {deposit.merchantName}
          </span>
        </td>
      )}
      {isFieldIncluded.outletNumber && <td>{deposit.outletNumber}</td>}
      {isFieldIncluded.outletName && (
        <td style={{ width: 155 }} className="has-text-left">
          {deposit.outletName}
        </td>
      )}
      {isFieldIncluded.abbreviatedCardType && <td>{deposit.cardType}</td>}
      {isFieldIncluded.cardType && (
        <td style={{ width: 72 }} className="has-text-left">
          {showShortCardType ? deposit.cardType : deposit.fullCardTypeName}
        </td>
      )}
      {isFieldIncluded.cardNumber && (
        <td style={{ width: 130 }}>{deposit.cardNumberMasked}</td>
      )}
      {isFieldIncluded.reasonCode && (
        <td style={{ width: 65 }}>
          <Tooltip.Container>
            {deposit.reasonCode}
            <Tooltip>{deposit.description}</Tooltip>
          </Tooltip.Container>
        </td>
      )}
      {isFieldIncluded.posEntryMode && (
        <td style={{ width: 65 }}>
          <Tooltip.Container>
            {deposit.posEntryMode}
            <Tooltip>{deposit.posEntryModeDesc}</Tooltip>
          </Tooltip.Container>
        </td>
      )}
      {isFieldIncluded.tranDate && (
        <td style={{ width: 65 }}>
          {deposit.transactionDate
            ? DateService.formatDate(deposit.transactionDate)
            : null}
        </td>
      )}
      {isFieldIncluded.tranType && (
        <td style={{ width: 70 }}>{deposit.transactionType}</td>
      )}
      {isFieldIncluded.tranAmount && (
        <td style={{ width: 72 }} className="has-text-right">
          {deposit.transactionAmount}
        </td>
      )}
      {isFieldIncluded["salesTxCount"] && (
        <td style={{ width: 50 }} className="has-text-right">
          <span data-testid="deposit-detail-sales-transactions-count">
            {deposit.totalSalesTransactionsCount}
          </span>
        </td>
      )}
      {isFieldIncluded["salesTxVolume"] && (
        <td style={{ width: 70 }} className="has-text-right">
          <span data-testid="deposit-detail-sales-volume">
            {deposit.totalSalesTransactionsVolume}
          </span>
        </td>
      )}
      {isFieldIncluded["creditsTxCount"] && (
        <td style={{ width: 50 }} className="has-text-right">
          <span data-testid="deposit-detail-credit-transactions-count">
            {deposit.totalCreditTransactionCount}
          </span>
        </td>
      )}
      {isFieldIncluded["creditsTxVolume"] && (
        <td style={{ width: 70 }} className="has-text-right">
          <span data-testid="deposit-detail-credit-volume">
            {deposit.totalCreditTransactionVolume}
          </span>
        </td>
      )}
      {isFieldIncluded["netSalesTxCount"] && (
        <td style={{ width: 50 }} className="has-text-right">
          <span data-testid="deposit-detail-net-sales-transactions-count">
            {deposit.totalNetSalesTransactionCount}
          </span>
        </td>
      )}
      {isFieldIncluded["netSalesTxVolume"] && (
        <td style={{ width: 70 }} className="has-text-right">
          <span data-testid="deposit-detail-net-sales-volume">
            {deposit.totalNetSalesTransactionVolume}
          </span>
        </td>
      )}
      {isFieldIncluded["netChbksItemsCount"] && (
        <td style={{ width: 50 }} className="has-text-right">
          <span data-testid="deposit-detail-net-chbks-count">
            {deposit.totalChargebackCount}
          </span>
        </td>
      )}
      {isFieldIncluded["netChbksItemsVolume"] && (
        <td style={{ width: 70 }} className="has-text-right">
          <span data-testid="deposit-detail-net-chbks-volume">
            {deposit.totalChargebackVolume}
          </span>
        </td>
      )}
      {isFieldIncluded["miscAdj"] && (
        <td style={{ width: 35 }} className="has-text-right">
          <span data-testid="deposit-detail-misc-adj">{deposit.miscAdj}</span>
        </td>
      )}
      {isFieldIncluded["dailyFees"] && (
        <td style={{ width: 55 }} className="has-text-right">
          <span data-testid="deposit-detail-daily-fees">
            {deposit.dailyFees}
          </span>
        </td>
      )}
      {isFieldIncluded["netDeposit"] && (
        <td style={{ width: 65 }} className="has-text-right">
          <span data-testid="deposit-detail-net-deposit">
            {showNetDepositTooltip &&
              deposit?.ddas &&
              deposit.ddas.length > 0 ? (
              <Tooltip.Container>
                {deposit.netDeposit}
                <Tooltip title="Deposit DDA Accounts">
                  <table>
                    <tbody>
                      {deposit?.ddas?.map((dda) => (
                        <tr>
                          <td>{dda.last4}</td>
                          <td>{dda.netDeposit}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Tooltip>
              </Tooltip.Container>
            ) : (
              deposit.netDeposit
            )}
          </span>
        </td>
      )}
      {isFieldIncluded["netActivity"] && (
        <td style={{ width: 65 }} className="has-text-right">
          <span data-testid="deposit-detail-net-activity">
            {deposit.netActivity}
          </span>
        </td>
      )}
    </tr>
  );
}

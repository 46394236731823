"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewContactsToJSON = exports.ViewContactsFromJSONTyped = exports.ViewContactsFromJSON = void 0;
var runtime_1 = require("../runtime");
function ViewContactsFromJSON(json) {
    return ViewContactsFromJSONTyped(json, false);
}
exports.ViewContactsFromJSON = ViewContactsFromJSON;
function ViewContactsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'display': !runtime_1.exists(json, 'display') ? undefined : json['display'],
        'address1': !runtime_1.exists(json, 'address1') ? undefined : json['address1'],
        'address2': !runtime_1.exists(json, 'address2') ? undefined : json['address2'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'state': !runtime_1.exists(json, 'state') ? undefined : json['state'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'country': !runtime_1.exists(json, 'country') ? undefined : json['country'],
        'phone': !runtime_1.exists(json, 'phone') ? undefined : json['phone'],
        'fax': !runtime_1.exists(json, 'fax') ? undefined : json['fax'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'stmttype': !runtime_1.exists(json, 'stmttype') ? undefined : json['stmttype'],
        'role': !runtime_1.exists(json, 'role') ? undefined : json['role'],
        'bookmark': !runtime_1.exists(json, 'bookmark') ? undefined : json['bookmark'],
        'phoneext': !runtime_1.exists(json, 'phoneext') ? undefined : json['phoneext'],
        'formattedphone': !runtime_1.exists(json, 'formattedphone') ? undefined : json['formattedphone'],
        'formattedphoneext': !runtime_1.exists(json, 'formattedphoneext') ? undefined : json['formattedphoneext'],
        'formattedfax': !runtime_1.exists(json, 'formattedfax') ? undefined : json['formattedfax'],
        'formattedstmttype': !runtime_1.exists(json, 'formattedstmttype') ? undefined : json['formattedstmttype'],
        'merchantnbr': !runtime_1.exists(json, 'merchantnbr') ? undefined : json['merchantnbr'],
    };
}
exports.ViewContactsFromJSONTyped = ViewContactsFromJSONTyped;
function ViewContactsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'display': value.display,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'country': value.country,
        'phone': value.phone,
        'fax': value.fax,
        'email': value.email,
        'stmttype': value.stmttype,
        'role': value.role,
        'bookmark': value.bookmark,
        'phoneext': value.phoneext,
        'formattedphone': value.formattedphone,
        'formattedphoneext': value.formattedphoneext,
        'formattedfax': value.formattedfax,
        'formattedstmttype': value.formattedstmttype,
        'merchantnbr': value.merchantnbr,
    };
}
exports.ViewContactsToJSON = ViewContactsToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserProfileToJSON = exports.UserProfileFromJSONTyped = exports.UserProfileFromJSON = void 0;
var runtime_1 = require("../runtime");
function UserProfileFromJSON(json) {
    return UserProfileFromJSONTyped(json, false);
}
exports.UserProfileFromJSON = UserProfileFromJSON;
function UserProfileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'username': !runtime_1.exists(json, 'username') ? undefined : json['username'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'merchantID': !runtime_1.exists(json, 'merchantID') ? undefined : json['merchantID'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'pwExpire': !runtime_1.exists(json, 'pwExpire') ? undefined : json['pwExpire'],
        'roles': !runtime_1.exists(json, 'roles') ? undefined : json['roles'],
    };
}
exports.UserProfileFromJSONTyped = UserProfileFromJSONTyped;
function UserProfileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'username': value.username,
        'name': value.name,
        'merchantID': value.merchantID,
        'email': value.email,
        'pwExpire': value.pwExpire,
        'roles': value.roles,
    };
}
exports.UserProfileToJSON = UserProfileToJSON;

import React, { useEffect, useMemo, useState } from "react";
import { ReportFileDownloader } from "../../components/download_handler";
import { PageTitle } from "../../components/page_title";
import { useReportBeginDate } from "../../hooks/use_report_begin_date";
import { useReportCategory } from "../../hooks/use_report_category";
import { useReportCategoryValues } from "../../hooks/use_report_category_values";
import { useReportGroups } from "../../hooks/use_report_groups";
import { useReportPeriod } from "../../hooks/use_report_period";
import { SearchPage } from "../../layout/search_page";
import {
  ReportCategory,
  ReportType,
  reportTypeToFullName,
} from "../../models/report";
import { ReportService } from "../../services/report";
import { ManagementModuleSearch } from "./management_module_search";

export interface ManagementModuleContainerProps {
  reportType: ReportType;
  reportCategory: string;
  reportService: ReportService;
}
export const ManagementModuleContainer = ({
  reportType,
  reportService,
}: ManagementModuleContainerProps) => {
  const { reportGroups: groups } = useReportGroups(reportService, {
    reportType,
  });
  const [selectedCategory, setSelectedCategory] = React.useState<
    ReportCategory | undefined
  >(undefined);
  const { reportBeginDate, isLoading: isLoadingBeginDate } = useReportBeginDate(
    reportService,
    reportType
  );
  const { reportCategories } = useReportCategory(reportService);
  const { reportCategoryValues } = useReportCategoryValues(
    reportService,
    selectedCategory?.id
  );
  const { reportPeriods } = useReportPeriod(reportService, reportType);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [generatedUrl, setGeneratedUrl] = React.useState<string | undefined>();
  useEffect(() => {
    setGeneratedUrl(undefined);
  }, [reportType]);
  const defaultEndDate = useMemo(() => new Date(), []);
  return (
    <div className="report-wrapper">
      <div className="title-bar">
        <PageTitle title={`${reportTypeToFullName[reportType]}`} />
      </div>
      <SearchPage
        searchPanel={
          <ManagementModuleSearch
            defaultBeginDate={reportBeginDate?.beginDate}
            noDataDate={reportBeginDate?.noDataDate}
            defaultEndDate={defaultEndDate}
            reportType={reportType}
            isBeginDateLoading={isLoadingBeginDate}
            handleSearch={async (data) => {
              try {
                setIsLoadingReport(true);
                const url = await reportService.getReportUrl({
                  groups: data.reportGroup,
                  categoryId: data.reportCriteria,
                  categoryValueId: data.selectedCriteria,
                  beginDate: data.beginDate,
                  endDate: data.endDate,
                  period: data.reportPeriod,
                  type: reportType,
                });
                setGeneratedUrl(url);
              } catch (e) {
                console.error(e);
              } finally {
                setIsLoadingReport(false);
              }
            }}
            isLoading={isLoadingReport}
            handleSelectCategory={(categoryId) => {
              setSelectedCategory(
                reportCategories.find((c) => c.id === categoryId)
              );
            }}
            reportCriteria={reportCategories}
            reportGroups={groups}
            reportPeriod={reportPeriods}
            selectedCriteria={reportCategoryValues}
          />
        }
        isLoading={isLoadingBeginDate}
        searchResults={
          <div>
            {generatedUrl && (
              <ReportFileDownloader.PDF
                url={generatedUrl}
                defaultFilename={`${ReportType[reportType]}.pdf`}
                reportService={reportService}
              />
            )}
          </div>
        }
      />
    </div>
  );
};

import React from "react";
import { PageTitle } from "../components/page_title";

export function ThanksForYourInterest() {
  return (
    <div>
      <PageTitle title="" />
      <p className="title is-6 is-uppercase m-2" style={{fontSize: '11px'}}>&nbsp;&nbsp;&nbsp;&nbsp;Thank you for your interest</p>
      <br />
      <p className="m-2">
        &nbsp;&nbsp;&nbsp;&nbsp;This report is used internally by POST to manage your portfolio
        performance.
      </p>
      <br />
      <p className="m-2">
        &nbsp;&nbsp;&nbsp;&nbsp;Please contact your Client Relationship Manager for further information.
      </p>
      <br />
    </div>
  );
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionTypesToJSON = exports.TransactionTypesFromJSONTyped = exports.TransactionTypesFromJSON = exports.TransactionTypes = void 0;
/**
 * Type of transaction used in search results
 * @export
 * @enum {string}
 */
var TransactionTypes;
(function (TransactionTypes) {
    TransactionTypes["Unknown"] = "UNKNOWN";
    TransactionTypes["SaleAuth"] = "SALE_AUTH";
    TransactionTypes["RefundAuth"] = "REFUND_AUTH";
    TransactionTypes["Capture"] = "CAPTURE";
    TransactionTypes["SaleVauth"] = "SALE_VAUTH";
    TransactionTypes["RefundVauth"] = "REFUND_VAUTH";
    TransactionTypes["Sale"] = "SALE";
    TransactionTypes["Refund"] = "REFUND";
    TransactionTypes["Rejected"] = "REJECTED";
    TransactionTypes["Rfc"] = "RFC";
    TransactionTypes["_1StCb"] = "1ST_CB";
    TransactionTypes["Rev"] = "REV";
    TransactionTypes["_2NdCb"] = "2ND_CB";
    TransactionTypes["Comp"] = "COMP";
    TransactionTypes["RvrslAuth"] = "RVRSL_AUTH";
})(TransactionTypes = exports.TransactionTypes || (exports.TransactionTypes = {}));
function TransactionTypesFromJSON(json) {
    return TransactionTypesFromJSONTyped(json, false);
}
exports.TransactionTypesFromJSON = TransactionTypesFromJSON;
function TransactionTypesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionTypesFromJSONTyped = TransactionTypesFromJSONTyped;
function TransactionTypesToJSON(value) {
    return value;
}
exports.TransactionTypesToJSON = TransactionTypesToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortByColumnToJSON = exports.SortByColumnFromJSONTyped = exports.SortByColumnFromJSON = exports.SortByColumn = void 0;
/**
 * Available Columns to sort by
 * @export
 * @enum {string}
 */
var SortByColumn;
(function (SortByColumn) {
    SortByColumn["Merchantnbr"] = "MERCHANTNBR";
    SortByColumn["Merchantname"] = "MERCHANTNAME";
    SortByColumn["Outlet"] = "OUTLET";
    SortByColumn["Outletname"] = "OUTLETNAME";
    SortByColumn["Billingdate"] = "BILLINGDATE";
    SortByColumn["Cardtype"] = "CARDTYPE";
    SortByColumn["Cardnumber"] = "CARDNUMBER";
    SortByColumn["Posmode"] = "POSMODE";
    SortByColumn["Authcode"] = "AUTHCODE";
    SortByColumn["Disputecode"] = "DISPUTECODE";
    SortByColumn["Trandate"] = "TRANDATE";
    SortByColumn["Cbrftrandate"] = "CBRFTRANDATE";
    SortByColumn["Tranamt"] = "TRANAMT";
    SortByColumn["Trantype"] = "TRANTYPE";
    SortByColumn["Transtatus"] = "TRANSTATUS";
    SortByColumn["Region"] = "REGION";
    SortByColumn["Streetaddress"] = "STREETADDRESS";
    SortByColumn["City"] = "CITY";
    SortByColumn["State"] = "STATE";
    SortByColumn["Zip"] = "ZIP";
    SortByColumn["Businesslegalname"] = "BUSINESSLEGALNAME";
    SortByColumn["Federaltaxid"] = "FEDERALTAXID";
    SortByColumn["Disputereason"] = "DISPUTEREASON";
    SortByColumn["Disputeamt"] = "DISPUTEAMT";
    SortByColumn["Datenotified"] = "DATENOTIFIED";
    SortByColumn["Duedate"] = "DUEDATE";
    SortByColumn["Daysremaining"] = "DAYSREMAINING";
    SortByColumn["Routingtransitnbr"] = "ROUTINGTRANSITNBR";
    SortByColumn["Merchantacntnbrc"] = "MERCHANTACNTNBRC";
    SortByColumn["Merchantacntnbr"] = "MERCHANTACNTNBR";
    SortByColumn["Routingtransitnbrc"] = "ROUTINGTRANSITNBRC";
    SortByColumn["Routingtransitnbrf"] = "ROUTINGTRANSITNBRF";
    SortByColumn["Merchantacntnbrf"] = "MERCHANTACNTNBRF";
    SortByColumn["Routingtransitnbr2"] = "ROUTINGTRANSITNBR2";
    SortByColumn["Merchantacntnbr2"] = "MERCHANTACNTNBR2";
    SortByColumn["Closeddate"] = "CLOSEDDATE";
    SortByColumn["Closedreason"] = "CLOSEDREASON";
    SortByColumn["Divisionname"] = "DIVISIONNAME";
    SortByColumn["Mid"] = "MID";
    SortByColumn["Amexmerchantnumber"] = "AMEXMERCHANTNUMBER";
    SortByColumn["Discovermerchantnumber"] = "DISCOVERMERCHANTNUMBER";
    SortByColumn["Recievedate"] = "RECIEVEDATE";
    SortByColumn["Origtrandate"] = "ORIGTRANDATE";
    SortByColumn["Mcvisamerchantnumber"] = "MCVISAMERCHANTNUMBER";
    SortByColumn["Jcbmerchantnumber"] = "JCBMERCHANTNUMBER";
})(SortByColumn = exports.SortByColumn || (exports.SortByColumn = {}));
function SortByColumnFromJSON(json) {
    return SortByColumnFromJSONTyped(json, false);
}
exports.SortByColumnFromJSON = SortByColumnFromJSON;
function SortByColumnFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.SortByColumnFromJSONTyped = SortByColumnFromJSONTyped;
function SortByColumnToJSON(value) {
    return value;
}
exports.SortByColumnToJSON = SortByColumnToJSON;

import { useHistory } from "react-router-dom";
import { clearLocalStorage } from "../utils";
import "./standard_links.scss";

export interface StandardLinkProps {
  onLogOut?: VoidFunction;
  isLight?: boolean;
  showHome?: boolean;
}
export function StandardLinks({
  showHome = true,
  onLogOut,
  isLight = true,
}: StandardLinkProps) {
  const router = useHistory();

  return (
    <div
      className={`standard-links column ${
        isLight ? "" : "is-light"
      } is-justify-content-flex-end is-align-items-flex-end columns`}
    >
      {showHome && (
        <button
          style={{minWidth: "3.475rem"}}
          onClick={() => router.push("/")}
          className={`plain-text has-text-weight-bold`}
        >
          <span className="is-pointer">Home</span>
        </button>
      )}
      <button
        style={{minWidth: "5rem"}}
        onClick={() => router.push("/contactus")}
        className={`is-small plain-text ${
          isLight ? "" : "has-text-white"
        } has-text-weight-bold`}
      >
        <span className="is-pointer">Contact Us</span>
      </button>
      <button
        style={{minWidth: "4.375rem"}}
        onClick={() => router.push("/security")}
        className={`is-small plain-text ${
          isLight ? "" : "has-text-white"
        } has-text-weight-bold`}
      >
        <span className="is-pointer">Security</span>
      </button>
      <button
        style={{minWidth: "3.75rem"}}
        onClick={() => router.push("/terms")}
        className={`is-small plain-text ${isLight ? "" : "has-text-white"}
       has-text-weight-bold`}
      >
        <span className="is-pointer">Terms</span>
      </button>
      <button
        style={{minWidth:"2.5rem"}}
        onClick={() => { 
          clearLocalStorage();
          onLogOut?.()
        }}
        className={`is-small plain-text ${
          isLight ? "" : "has-text-white"
        } has-text-weight-bold`}
      >
        <span className="is-pointer">Logout</span>
      </button>
    </div>
  );
}

import {
  Configuration,
  DivisionApi,
  DivisionoutletApi,
  Division as SdkDivision,
  DivisionOutlet as SdkDivisionOutlet,
} from "ebocomsdk";
import { Division, DivisionOutlet } from "../../models/division";
import { DivisionService } from "../../services/division";
import { getPreAuthMiddleware } from "./auth_middleware";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";

export class EbocomDivisionSdk implements DivisionService {
  private divisionApi: DivisionApi;
  private divisionOutletApi: DivisionoutletApi;
  constructor(baseUrl: string) {
    const config = new Configuration({
      basePath: baseUrl,
    });
    this.divisionApi = new DivisionApi(config)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
    this.divisionOutletApi = new DivisionoutletApi(config)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
  }
  serializeDivision(sdkDivision: SdkDivision): Division {
    const d = new Division();
    d.name = sdkDivision.divisionName!;
    d.number = sdkDivision.divisionNbr?.toString() ?? "";
    return d;
  }
  async getAllDivisions(merchantNumber: string) {
    try {
      const sdkDivisions = await this.divisionApi.getDivisions({
        merchNbr: merchantNumber,
      });
      return sdkDivisions.map(this.serializeDivision);
    } catch (e) {
      throw e;
    }
  }
  serializeOutlet(sdkOutlet: SdkDivisionOutlet): DivisionOutlet {
    const d = new DivisionOutlet();
    d.name = sdkOutlet.outletName!;
    d.number = sdkOutlet.outlet?.toString?.() ?? "";
    d.divisionName = sdkOutlet.divisionName ?? "";
    d.divisionNumber = sdkOutlet.divisionNbr?.toString?.() ?? "";
    return d;
  }
  async getAllOutlets(merchantNumber: string) {
    try {
      const outlets = await this.divisionOutletApi.getDivisionOutlets({
        merchNbr: merchantNumber,
      });
      return outlets.map(this.serializeOutlet);
    } catch (e) {
      throw e;
    }
  }
}

import React, { useEffect } from "react";
import { createContext } from "react";

export type SelectedMerchantContextValue = {
  updateSelectedMerchant(merchantNumber: string): void;
  selectedMerchant?: string;
};

export const SelectedMerchantContext =
  createContext<SelectedMerchantContextValue>({
    updateSelectedMerchant: () => {},
  });

export interface SelectedMerchantContextProviderProps {
  children: React.ReactNode;
  currentUserMerchant?: string;
}

export function SelectedMerchantContextProvider({
  children,
  currentUserMerchant,
}: SelectedMerchantContextProviderProps) {
  const [selectedMerchant, setSelectedMerchant] = React.useState<
    string | undefined
  >(undefined);
  const [isDirty, setIsDirty] = React.useState(false);
  useEffect(() => {
    if (!currentUserMerchant || isDirty) {
      return;
    }
    setSelectedMerchant(currentUserMerchant);
  }, [setSelectedMerchant, currentUserMerchant, isDirty]);
  const onUpdateMerchant = (merchantNumber?: string) => {
    if (!merchantNumber) {
      return;
    }
    setSelectedMerchant(merchantNumber);
    setIsDirty(true);
  };
  return (
    <SelectedMerchantContext.Provider
      value={{
        updateSelectedMerchant: onUpdateMerchant,
        selectedMerchant,
      }}
    >
      {children}
    </SelectedMerchantContext.Provider>
  );
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisputeSummaryToJSON = exports.DisputeSummaryFromJSONTyped = exports.DisputeSummaryFromJSON = void 0;
var runtime_1 = require("../runtime");
function DisputeSummaryFromJSON(json) {
    return DisputeSummaryFromJSONTyped(json, false);
}
exports.DisputeSummaryFromJSON = DisputeSummaryFromJSON;
function DisputeSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchNbr': !runtime_1.exists(json, 'merchNbr') ? undefined : json['merchNbr'],
        'merchName': !runtime_1.exists(json, 'merchName') ? undefined : json['merchName'],
        'minDaysRemaining': !runtime_1.exists(json, 'minDaysRemaining') ? undefined : json['minDaysRemaining'],
        'daysRemainingThreshold': !runtime_1.exists(json, 'daysRemainingThreshold') ? undefined : json['daysRemainingThreshold'],
        'cbkCount': !runtime_1.exists(json, 'cbkCount') ? undefined : json['cbkCount'],
        'cbkAmount': !runtime_1.exists(json, 'cbkAmount') ? undefined : json['cbkAmount'],
        'rfcCount': !runtime_1.exists(json, 'rfcCount') ? undefined : json['rfcCount'],
        'rfcAmount': !runtime_1.exists(json, 'rfcAmount') ? undefined : json['rfcAmount'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'assetOwner': !runtime_1.exists(json, 'assetOwner') ? undefined : json['assetOwner'],
        'businessLegalName': !runtime_1.exists(json, 'businessLegalName') ? undefined : json['businessLegalName'],
    };
}
exports.DisputeSummaryFromJSONTyped = DisputeSummaryFromJSONTyped;
function DisputeSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchNbr': value.merchNbr,
        'merchName': value.merchName,
        'minDaysRemaining': value.minDaysRemaining,
        'daysRemainingThreshold': value.daysRemainingThreshold,
        'cbkCount': value.cbkCount,
        'cbkAmount': value.cbkAmount,
        'rfcCount': value.rfcCount,
        'rfcAmount': value.rfcAmount,
        'region': value.region,
        'assetOwner': value.assetOwner,
        'businessLegalName': value.businessLegalName,
    };
}
exports.DisputeSummaryToJSON = DisputeSummaryToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentResponseToJSON = exports.DocumentResponseFromJSONTyped = exports.DocumentResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DocumentResponseFromJSON(json) {
    return DocumentResponseFromJSONTyped(json, false);
}
exports.DocumentResponseFromJSON = DocumentResponseFromJSON;
function DocumentResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalResults': json['totalResults'],
        'documents': !runtime_1.exists(json, 'documents') ? undefined : (json['documents'].map(_1.DocumentFromJSON)),
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    };
}
exports.DocumentResponseFromJSONTyped = DocumentResponseFromJSONTyped;
function DocumentResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalResults': value.totalResults,
        'documents': value.documents === undefined ? undefined : (value.documents.map(_1.DocumentToJSON)),
        'offset': value.offset,
    };
}
exports.DocumentResponseToJSON = DocumentResponseToJSON;

import axios, { AxiosInstance } from "axios";
import { KeycloakInstance } from "keycloak-js";
import { AuthService } from "../../services/auth";

export class ImpersonateService implements AuthService {
  private axios: AxiosInstance;

  constructor(
    private keycloak: KeycloakInstance,
    private onRetrieveToken: (token: string) => void
  ) {
    this.axios = axios.create();
  }

  private static buildImpersonateRequestBody(
    userId: string,
    keycloakClientId: string,
    currentUserAccessToken: string
  ): URLSearchParams {
    const body = new URLSearchParams();

    body.set("grant_type", "urn:ietf:params:oauth:grant-type:token-exchange");
    body.set("subject_token", currentUserAccessToken);
    body.set("client_id", keycloakClientId);
    body.set("requested_subject", userId);
    body.set(
      "requested_token_type",
      "urn:ietf:params:oauth:token-type:access_token"
    );
    return body;
  }

  private static getOptions() {
    return {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
  }

  public impersonateUser(userId: string) {
    const body = ImpersonateService.buildImpersonateRequestBody(
      userId,
      this.keycloak.clientId!,
      this.keycloak.token!
    );

    // remember to replace master realm with your own
    return this.axios
      .post<{ access_token: string }>(
        `${this.keycloak.authServerUrl}/realms/POSTAT/protocol/openid-connect/token`,
        body.toString(),
        ImpersonateService.getOptions()
      )
      .then(({ data }) => this.onRetrieveToken(data.access_token));
  }
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnAuthorizedToJSON = exports.UnAuthorizedFromJSONTyped = exports.UnAuthorizedFromJSON = void 0;
var runtime_1 = require("../runtime");
function UnAuthorizedFromJSON(json) {
    return UnAuthorizedFromJSONTyped(json, false);
}
exports.UnAuthorizedFromJSON = UnAuthorizedFromJSON;
function UnAuthorizedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pagetitle': !runtime_1.exists(json, 'pagetitle') ? undefined : json['pagetitle'],
        'menutitle': !runtime_1.exists(json, 'menutitle') ? undefined : json['menutitle'],
    };
}
exports.UnAuthorizedFromJSONTyped = UnAuthorizedFromJSONTyped;
function UnAuthorizedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pagetitle': value.pagetitle,
        'menutitle': value.menutitle,
    };
}
exports.UnAuthorizedToJSON = UnAuthorizedToJSON;

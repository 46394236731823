import { Merchant } from "../../models/merchant";
import {
  MerchantBankSortColumn,
  MerchantDetailsSearchParameters,
  MerchantDetailsSortColumn,
  MerchantService,
  OutletSortColumn,
} from "../../services/merchant";
import {
  Configuration,
  MerchantsApi,
  OutletApi,
  Outlet as SDKOutlet,
  Merchant as SDKMerchant,
  MerchantsApiGetMerchantDetailsRequest,
} from "ebocomsdk";
import { getPreAuthMiddleware } from "./auth_middleware";
import { Outlet } from "../../models/outlet";
import { PaginatedResult } from "../../models/paginated_result";
import { SortOrder } from "../../models/sort_order";
import { getSdkSortColumn } from "./sort";
import { CONTENT_DISPOSITION } from "./constants";
import { getFilenameFromContentDisposition } from "./filename_extractor";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";

export class EbocomMerchantService implements MerchantService {
  private api: MerchantsApi;
  private outletApi: OutletApi;
  constructor(baseUrl: string) {
    const configuration = new Configuration({
      basePath: baseUrl,
    });
    this.outletApi = new OutletApi(configuration)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
    this.api = new MerchantsApi(configuration)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
  }
  getMerchantBankDetails = async (
    parameters: MerchantDetailsSearchParameters<MerchantBankSortColumn>
  ): Promise<PaginatedResult<Merchant>> => {
    const response = await this.api.getMerchantBankInfo({
      merchNbr: parameters.merchantNumber,
      sortBy: getSdkSortColumn(parameters.sortColumn),
      sortOrder:
        parameters.sortDirection !== undefined
          ? parameters.sortDirection === SortOrder.ASC
            ? "ASC"
            : "DESC"
          : undefined,
      maxResults: parameters.pageSize,
    });

    return {
      data: response.merchants?.map(this.serializeMerchant) ?? [],
      offset: response.offset ?? 0,
      total: response.totalResults ?? 0,
    };
  };
  serializeMerchant(sdkMerchant: SDKMerchant): Merchant {
    const m = new Merchant();
    m.accountNumber = sdkMerchant.merchAcntNbr;
    m.accountNumber = sdkMerchant.merchAcntNbr;
    m.accountNumber2 = sdkMerchant.merchAcntNbr2;
    m.accountNumberC = sdkMerchant.merchAcntNbrC;
    m.accountNumberF = sdkMerchant.merchAcntNbrF;
    m.accountNumberM = sdkMerchant.merchAcntNbrM;
    m.accountType = sdkMerchant.merchAcntType;
    m.address1 = sdkMerchant.address1;
    m.address2 = sdkMerchant.address2;
    m.businessLegalName = sdkMerchant.businessLegalName;
    m.city = sdkMerchant.city;
    m.contractEntity = sdkMerchant.contractEntity;
    m.country = sdkMerchant.country;
    m.customerServicePhone = sdkMerchant.customerSvcPhone;
    m.formattedCustomerServicePhone = sdkMerchant.fmtcustservicephone;
    m.formattedMainPhone = sdkMerchant.fmtmainphone;
    m.managementCompany = sdkMerchant.mgmtCompany;
    m.name = sdkMerchant.merchName ?? "";
    m.number = sdkMerchant.merchNbr ?? "";
    m.propertyPhone = sdkMerchant.propertyPhone;
    m.region = sdkMerchant.region;
    m.routingTransitNumber = sdkMerchant.routingTransitNbr;
    m.routingTransitNumber2 = sdkMerchant.routingTransitNbr2;
    m.routingTransitNumberC = sdkMerchant.routingTransitNbrC;
    m.routingTransitNumberF = sdkMerchant.routingTransitNbrF;
    m.routingTransitNumberM = sdkMerchant.routingTransitNbrM;
    m.state = sdkMerchant.state;
    m.taxId = sdkMerchant.taxID;
    m.zip = sdkMerchant.zip;

    return m;
  }
  async getAllMerchants(): Promise<Merchant[]> {
    try {
      const merchants = await this.api.getAllMerchants();
      return merchants.map(this.serializeMerchant);
    } catch (e) {
      throw e;
    }
  }

  serializeMerchantDetailSearchParameters = (
    parameters: MerchantDetailsSearchParameters<MerchantDetailsSortColumn>
  ): MerchantsApiGetMerchantDetailsRequest => {
    return {
      merchNbr: parameters.merchantNumber,
      sortBy: getSdkSortColumn(parameters.sortColumn),
      offset: parameters.offset,
      sortOrder:
        parameters.sortDirection !== undefined
          ? parameters.sortDirection === SortOrder.ASC
            ? "ASC"
            : "DESC"
          : undefined,
      maxResults: parameters.pageSize,
    };
  };
  async getMerchantDetails(
    parameters: MerchantDetailsSearchParameters<MerchantDetailsSortColumn>
  ): Promise<PaginatedResult<Merchant>> {
    const sdkParameters =
      this.serializeMerchantDetailSearchParameters(parameters);
    const results = await this.api.getMerchantDetails(sdkParameters);
    const reducedResults = results.merchants?.map(this.serializeMerchant);

    return {
      data: reducedResults ?? [],
      offset: results.offset ?? 0,
      total: results.totalResults ?? 0,
    };
  }
  getMerchantOutlets = async (
    parameters: MerchantDetailsSearchParameters<OutletSortColumn>
  ): Promise<PaginatedResult<Outlet>> => {
    const response = await this.outletApi.outletlistGet({
      merchNbr: parameters.merchantNumber,
      sortBy: getSdkSortColumn(parameters.sortColumn),
      sortOrder:
        parameters.sortDirection !== undefined
          ? parameters.sortDirection === SortOrder.ASC
            ? "ASC"
            : "DESC"
          : undefined,
      offset: parameters.offset,
      maxResults: parameters.pageSize,
    });
    return {
      data: response?.outlet?.map(EbocomMerchantService.serializeOutlets) ?? [],
      offset: response.offset ?? 0,
      total: response.totalResults,
    };
  };

  exportMerchantBankDetails = async (
    parameters: MerchantDetailsSearchParameters<MerchantBankSortColumn>
  ): Promise<[Blob, string?]> => {
    const response = await this.api.exportMerchantBankInfoRaw({
      merchNbr: parameters.merchantNumber,
      sortBy: getSdkSortColumn(parameters.sortColumn),
      sortOrder:
        parameters.sortDirection !== undefined
          ? parameters.sortDirection === SortOrder.ASC
            ? "ASC"
            : "DESC"
          : undefined,
      maxResults: parameters.pageSize,
    });
    return [
      await response.raw.blob(),
      getFilenameFromContentDisposition(
        response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
      ),
    ];
  };

  exportMerchantDetails = async (
    parameters: MerchantDetailsSearchParameters<MerchantDetailsSortColumn>
  ): Promise<[Blob, string?]> => {
    const response = await this.api.exportMerchantDetailsRaw({
      merchNbr: parameters.merchantNumber,
      sortBy: getSdkSortColumn(parameters.sortColumn),
      maxResults: parameters.pageSize,
      offset: 0,
      sortOrder:
        parameters.sortDirection !== undefined
          ? parameters.sortDirection === SortOrder.ASC
            ? "ASC"
            : "DESC"
          : undefined,
    });
    return [
      await response.raw.blob(),
      getFilenameFromContentDisposition(
        response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
      ),
    ];
  };

  exportMerchantOutlets = async (
    parameters: MerchantDetailsSearchParameters<OutletSortColumn>
  ): Promise<[Blob, string?]> => {
    const response = await this.outletApi.exportOutletListRaw({
      merchNbr: parameters.merchantNumber,
      maxResults: parameters.pageSize,
      offset: 0,
    });
    return [
      await response.raw.blob(),
      getFilenameFromContentDisposition(
        response.raw.headers.get(CONTENT_DISPOSITION)
      ) ?? "outlets.csv",
    ];
  };
  private static serializeOutlets = (outlet: SDKOutlet): Outlet => {
    const o = new Outlet();
    o.amex = outlet.amexMerchantNbr;
    o.discoverJcb = outlet.discoverMerchantNbr;
    o.division = outlet.divisionName;
    o.name = outlet.outletName;
    o.mcVisa = outlet.mcvisaMerchantNbr;
    o.merchantName = outlet.merchantName;
    o.number = outlet.outlet;
    return o;
  };
}

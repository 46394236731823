"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutletSortedResponseToJSON = exports.OutletSortedResponseFromJSONTyped = exports.OutletSortedResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function OutletSortedResponseFromJSON(json) {
    return OutletSortedResponseFromJSONTyped(json, false);
}
exports.OutletSortedResponseFromJSON = OutletSortedResponseFromJSON;
function OutletSortedResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalResults': json['totalResults'],
        'outlet': !runtime_1.exists(json, 'outlet') ? undefined : (json['outlet'].map(_1.OutletFromJSON)),
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    };
}
exports.OutletSortedResponseFromJSONTyped = OutletSortedResponseFromJSONTyped;
function OutletSortedResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalResults': value.totalResults,
        'outlet': value.outlet === undefined ? undefined : (value.outlet.map(_1.OutletToJSON)),
        'offset': value.offset,
    };
}
exports.OutletSortedResponseToJSON = OutletSortedResponseToJSON;

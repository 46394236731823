"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClosedChargebackResponseToJSON = exports.ClosedChargebackResponseFromJSONTyped = exports.ClosedChargebackResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ClosedChargebackResponseFromJSON(json) {
    return ClosedChargebackResponseFromJSONTyped(json, false);
}
exports.ClosedChargebackResponseFromJSON = ClosedChargebackResponseFromJSON;
function ClosedChargebackResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'chargebackClosedDetails': (json['chargebackClosedDetails'].map(_1.ChargebackClosedFromJSON)),
        'chargebackRFCDetails': (json['chargebackRFCDetails'].map(_1.ChargebackClosedFromJSON)),
        'resultsTotalClosed': !runtime_1.exists(json, 'resultsTotalClosed') ? undefined : json['resultsTotalClosed'],
        'resultsTotalRFC': !runtime_1.exists(json, 'resultsTotalRFC') ? undefined : json['resultsTotalRFC'],
        'offsetClosed': !runtime_1.exists(json, 'offsetClosed') ? undefined : json['offsetClosed'],
        'offsetRFC': !runtime_1.exists(json, 'offsetRFC') ? undefined : json['offsetRFC'],
    };
}
exports.ClosedChargebackResponseFromJSONTyped = ClosedChargebackResponseFromJSONTyped;
function ClosedChargebackResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'chargebackClosedDetails': (value.chargebackClosedDetails.map(_1.ChargebackClosedToJSON)),
        'chargebackRFCDetails': (value.chargebackRFCDetails.map(_1.ChargebackClosedToJSON)),
        'resultsTotalClosed': value.resultsTotalClosed,
        'resultsTotalRFC': value.resultsTotalRFC,
        'offsetClosed': value.offsetClosed,
        'offsetRFC': value.offsetRFC,
    };
}
exports.ClosedChargebackResponseToJSON = ClosedChargebackResponseToJSON;

import { useState } from "react";

export function useDebounce(delay: number) {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const debounce = (callbackFn: VoidFunction) => {
    if (timer) {
      clearTimeout(timer);
    }
    setTimer(setTimeout(callbackFn, delay));
  };
  return debounce;
}

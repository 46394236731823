"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonthToJSON = exports.MonthFromJSONTyped = exports.MonthFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MonthFromJSON(json) {
    return MonthFromJSONTyped(json, false);
}
exports.MonthFromJSON = MonthFromJSON;
function MonthFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'month': !runtime_1.exists(json, 'month') ? undefined : json['month'],
        'days': !runtime_1.exists(json, 'days') ? undefined : (json['days'].map(_1.ReportsDataFromJSON)),
    };
}
exports.MonthFromJSONTyped = MonthFromJSONTyped;
function MonthToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'month': value.month,
        'days': value.days === undefined ? undefined : (value.days.map(_1.ReportsDataToJSON)),
    };
}
exports.MonthToJSON = MonthToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChargebackClosedToJSON = exports.ChargebackClosedFromJSONTyped = exports.ChargebackClosedFromJSON = void 0;
var runtime_1 = require("../runtime");
function ChargebackClosedFromJSON(json) {
    return ChargebackClosedFromJSONTyped(json, false);
}
exports.ChargebackClosedFromJSON = ChargebackClosedFromJSON;
function ChargebackClosedFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNbr': !runtime_1.exists(json, 'merchantNbr') ? undefined : json['merchantNbr'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'assetOwner': !runtime_1.exists(json, 'assetOwner') ? undefined : json['assetOwner'],
        'businessLegalName': !runtime_1.exists(json, 'businessLegalName') ? undefined : json['businessLegalName'],
        'acctNumber': !runtime_1.exists(json, 'acctNumber') ? undefined : json['acctNumber'],
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'tranAmount': !runtime_1.exists(json, 'tranAmount') ? undefined : json['tranAmount'],
        'tranDate': !runtime_1.exists(json, 'tranDate') ? undefined : json['tranDate'],
        'cbrfTranDate': !runtime_1.exists(json, 'cbrfTranDate') ? undefined : json['cbrfTranDate'],
        'tranType': !runtime_1.exists(json, 'tranType') ? undefined : json['tranType'],
        'dateClosed': !runtime_1.exists(json, 'dateClosed') ? undefined : json['dateClosed'],
        'disputeDescription': !runtime_1.exists(json, 'disputeDescription') ? undefined : json['disputeDescription'],
        'maskedAcctNumber': !runtime_1.exists(json, 'maskedAcctNumber') ? undefined : json['maskedAcctNumber'],
        'tranStatusDescription': !runtime_1.exists(json, 'tranStatusDescription') ? undefined : json['tranStatusDescription'],
        'closedDescription': !runtime_1.exists(json, 'closedDescription') ? undefined : json['closedDescription'],
    };
}
exports.ChargebackClosedFromJSONTyped = ChargebackClosedFromJSONTyped;
function ChargebackClosedToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNbr': value.merchantNbr,
        'merchantName': value.merchantName,
        'region': value.region,
        'assetOwner': value.assetOwner,
        'businessLegalName': value.businessLegalName,
        'acctNumber': value.acctNumber,
        'cardType': value.cardType,
        'tranAmount': value.tranAmount,
        'tranDate': value.tranDate,
        'cbrfTranDate': value.cbrfTranDate,
        'tranType': value.tranType,
        'dateClosed': value.dateClosed,
        'disputeDescription': value.disputeDescription,
        'maskedAcctNumber': value.maskedAcctNumber,
        'tranStatusDescription': value.tranStatusDescription,
        'closedDescription': value.closedDescription,
    };
}
exports.ChargebackClosedToJSON = ChargebackClosedToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsListToJSON = exports.ReportsListFromJSONTyped = exports.ReportsListFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ReportsListFromJSON(json) {
    return ReportsListFromJSONTyped(json, false);
}
exports.ReportsListFromJSON = ReportsListFromJSON;
function ReportsListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'year': !runtime_1.exists(json, 'year') ? undefined : json['year'],
        'month': !runtime_1.exists(json, 'month') ? undefined : json['month'],
        'dateList': !runtime_1.exists(json, 'dateList') ? undefined : (json['dateList'].map(_1.ReportsDataFromJSON)),
    };
}
exports.ReportsListFromJSONTyped = ReportsListFromJSONTyped;
function ReportsListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'year': value.year,
        'month': value.month,
        'dateList': value.dateList === undefined ? undefined : (value.dateList.map(_1.ReportsDataToJSON)),
    };
}
exports.ReportsListToJSON = ReportsListToJSON;

export const contentDispositionFilename = new RegExp(/"(.*?)"/);

export const getFilenameFromContentDisposition = (
  contentDisposition?: string | null
): string | undefined => {
  if (!contentDisposition) {
    return undefined;
  }
  const match = contentDisposition.match(contentDispositionFilename)?.[1];
  return match;
};

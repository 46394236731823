import Axios from "axios";
import { getAuthHeader } from "./auth_middleware";
export const createAxiosInstance = (baseUrl: string) => {
  const axiosInstance = Axios.create({ baseURL: baseUrl });
  axiosInstance.interceptors.request.use((request) => {
    return ({
      ...request,
      headers: {
        ...request.headers,
        ...getAuthHeader(),
      },
    })
  });
  return axiosInstance;
};

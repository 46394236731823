"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OutletToJSON = exports.OutletFromJSONTyped = exports.OutletFromJSON = void 0;
var runtime_1 = require("../runtime");
function OutletFromJSON(json) {
    return OutletFromJSONTyped(json, false);
}
exports.OutletFromJSON = OutletFromJSON;
function OutletFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'outlet': !runtime_1.exists(json, 'outlet') ? undefined : json['outlet'],
        'outletName': !runtime_1.exists(json, 'outletName') ? undefined : json['outletName'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'divisionName': !runtime_1.exists(json, 'divisionName') ? undefined : json['divisionName'],
        'mcvisaMerchantNbr': !runtime_1.exists(json, 'mcvisaMerchantNbr') ? undefined : json['mcvisaMerchantNbr'],
        'amexMerchantNbr': !runtime_1.exists(json, 'amexMerchantNbr') ? undefined : json['amexMerchantNbr'],
        'discoverMerchantNbr': !runtime_1.exists(json, 'discoverMerchantNbr') ? undefined : json['discoverMerchantNbr'],
        'jcbMerchantNbr': !runtime_1.exists(json, 'jcbMerchantNbr') ? undefined : json['jcbMerchantNbr'],
    };
}
exports.OutletFromJSONTyped = OutletFromJSONTyped;
function OutletToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'outlet': value.outlet,
        'outletName': value.outletName,
        'merchantName': value.merchantName,
        'divisionName': value.divisionName,
        'mcvisaMerchantNbr': value.mcvisaMerchantNbr,
        'amexMerchantNbr': value.amexMerchantNbr,
        'discoverMerchantNbr': value.discoverMerchantNbr,
        'jcbMerchantNbr': value.jcbMerchantNbr,
    };
}
exports.OutletToJSON = OutletToJSON;

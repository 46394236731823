import { CardDetails } from "../../models/card_details";
import "./issuer_lookup.scss";
export interface IssuerLookupResultProps {
  cardDetails: CardDetails;
}
export function IssuerLookupResult({
  cardDetails,
}: IssuerLookupResultProps) {
      return (
        <div
          style={{
            margin: "auto",
            width: "50%",
          }}
        >
          <table className="table is-bordered issuer-lookup-results">
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th className="is-uppercase has-text-centered">Issuer</th>
                <th className="is-uppercase has-text-centered">Country</th>
                <th className="is-uppercase has-text-centered">Card Type</th>
              </tr>
              <tr>
                <td className="is-uppercase has-text-left is-vcentered">
                  {cardDetails.instName}
                </td>
                <td className="is-uppercase has-text-centered is-vcentered">
                  {cardDetails.instCountry}
                </td>
                <td className="is-uppercase has-text-centered is-vcentered">
                  {cardDetails.cardType}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table is-bordered issuer-lookup-results pb-8">
            <colgroup>
              <col style={{ width: "40%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <tbody>
              <tr>
                <th className="is-uppercase has-text-centered is-vcentered">
                  Service
                </th>
                <th className="is-uppercase has-text-centered is-vcentered">
                  Phone
                </th>
                <th className="is-uppercase has-text-centered is-vcentered">
                  Fax
                </th>
              </tr>
              {cardDetails.serviceProviders?.map((provider) => (
                <tr>
                  <td className="is-uppercase has-text-left is-vcentered">
                    {provider.name}
                  </td>
                  <td className="is-uppercase has-text-centered is-vcentered">
                    {provider.phone}
                  </td>
                  <td className="is-uppercase has-text-centered is-vcentered">
                    {provider.fax}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
}

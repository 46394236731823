import React from "react";
import { PageTitle } from "../components/page_title";
import { Link } from "react-router-dom";

export const TermsAndConditions = () => {
  return (
    <div className="content  privacy">
      <PageTitle title="" />
      <div className="privacyHeader">
        <h3>
          POST Integrations Inc. ("Company")
          <br />
          Terms and Conditions of Use
        </h3>
      </div>
      <br/>
      <h4 className="privacySubHeader">Terms and Conditions of Use</h4>
      <p>
        Welcome to the Company’s website (the "Site"). Please read the following
        information carefully.
      </p>
      <p>
        By accessing this Site, using any of the applications contained therein
        or viewing any of its contents you agree to accept, without limitation
        or qualification, all of the terms and conditions set forth on the
        individual pages within this Site and these Terms and Conditions of Use
        (collectively, the “Terms”), which also specifically includes the
        Company’s Privacy Policy. If you do not agree to be bound by and comply
        with all of the foregoing, please do not access this Site.
      </p>
      <p>
        The Company, at its sole and absolute discretion, reserves the right at
        any time to change any of the terms or conditions contained herein or
        any information contained on this Site without notice or liability to
        you. Unless otherwise indicated, any such changes shall be effective
        immediately.
      </p>
      <p>
        You agree to review these Terms periodically and agree that any
        subsequent use by you of this Site following changes to these Terms
        shall constitute your acceptance of all such changes.
      </p>
      <p>Quick Reference Guide</p>
      <ul>
        <li>
          <a className="privacyLink"  href="#Privacy Policy">Privacy Policy</a>
        </li>
        <li>
          <a className="privacyLink"  href="#License and Website Access">License and Website Access</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Restrictions on Use of Materials">
            Restrictions on Use of Materials
          </a>
        </li>
        <li>
          <a className="privacyLink"  href="#Links to Third Party Websites">
            Links to Third Party Websites
          </a>
        </li>
        <li>
          <a className="privacyLink"  href="#No Warranties; Disclaimers">No Warranties; Disclaimers</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Limitations on Liability">Limitations on Liability</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Confidentiality and Transmissions over the Internet">
            Confidentiality and Transmissions over the Internet
          </a>
        </li>
        <li>
          <a className="privacyLink"  href="#Disclosure of Information Pursuant to the Law">
            Disclosure of Information Pursuant to the Law
          </a>
        </li>
        <li>
          <a className="privacyLink"  href="#Indemnification">Indemnification</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Breach">Breach</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Jurisdiction">Jurisdiction</a>
        </li>
        <li>
          <a className="privacyLink"  href="#No waiver; Severability">No waiver; Severability</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Assignability">Assignability</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Relationship">Relationship</a>
        </li>
        <li>
          <a className="privacyLink"  href="#Contact Information">Contact Information</a>
        </li>
      </ul>
      <h4 className="privacySubHeader" id="Privacy Policy">Privacy Policy</h4>
      <p>
        You have read and agree to all of the terms and conditions of the
        Company’s Privacy Policy, which is located at&nbsp;
        <Link
          to="/security"
          className={`privacyLink`}
        >
          http://www.postint.com/privacy
        </Link>
        .
      </p>
      <h4 className="privacySubHeader" id="License and Website Access">License and Website Access</h4>
      <p>
        Users under the age of thirteen (13) are prohibited from using the Site.
        Provided that you are not under the age of thirteen (13), the Company
        hereby grants you a personal, non-exclusive, non-transferable, revocable
        license to access the Site and the information contained within the
        Site. You may not assign or sublicense this license. The foregoing
        constitutes a single copy, single use license ("License"), subject to
        the limitations set forth in these Terms.
      </p>
      <p>
        The Company reserves the right to terminate the License granted to you
        herein at any time and for any reason. The License will terminate
        immediately without notice from the Company, in its sole discretion,
        should you fail to comply with any term or provision of the Terms. Upon
        such termination, you may not access, use, or view the Site.
      </p>
      <p>
        The Company reserves the right to investigate suspected violations of
        the Terms. If the Company becomes aware of possible violations, the
        Company may initiate an investigation that may include gathering
        information from you, other users or the complaining party, if any, and
        examination of other material. The Company may suspend access to the
        Site temporarily, or the Company may permanently remove the material
        involved from its servers, provide warnings to you, or suspend or
        terminate your access to the Site. The Company will determine what
        action will be taken in response to a violation in its sole discretion.
        The Company will fully cooperate with law enforcement authorities in
        investigating suspected lawbreakers.
      </p>
      <p>
        The Site contains software tools and databases that allow you to search
        and retrieve information. You may not: (a) sell, distribute or
        commercially exploit such software tools, databases or the results of
        any database search performed on this Site; (b) use any data mining,
        robots, or similar gathering and extraction tools; or (c) sell, rent,
        lease, lend, or transfer any services provided on this Site.
      </p>
      <h4 className="privacySubHeader" id="Restrictions on Use of Materials">
        Restrictions on Use of Materials
      </h4>
      <p>
        The content of this Site is protected by copyright and trademark laws,
        and is the property of the Company and its licensors. Materials located
        within the Site may be accessed only for your internal business use. You
        agree not to sell, license, rent, modify, distribute, copy, reproduce,
        transmit, publicly display, publicly perform, publish, adapt, edit or
        create works from such materials or content or in any way exploit the
        material or content of this Site. This means, you may download copies of
        presented materials for internal business use only, so long as you
        neither change nor delete any author attribution, trademark, legend or
        copyright or other proprietary notices. When you download copyrighted
        material, you do not obtain any ownership rights to that material.
        Reproduction, copying, or redistribution of any material within this
        Site is strictly prohibited without the express written permission of
        the Company. You also may not, without the permission of the Company,
        “mirror” any materials contained within this Site. Any unauthorized use
        of any material contained within this Site may violate copyright laws,
        trademark laws, the laws of privacy and publicity, and communications
        regulations and statutes.
      </p>
      <p>
        You may use the Site for lawful purposes only. You may not use the Site
        to post, transmit or otherwise distribute unlawful material. Examples of
        unlawful material include, but are not limited to, threats of physical
        harm, defamatory statements, pornographic material, and copyrighted,
        trademarked, and other proprietary material used without proper
        authorization. You may not attempt to use or distribute tools designed
        for compromising security or take action to compromise the security of
        this Site. Examples of these tools include, but are not limited to,
        password guessing programs, cracking tools, or network probing tools.
        You may not "flood" or disrupt the Site through any means or process.
        You are responsible for all use of the Site, with or without your
        consent, by yourself and others, which occurs by, through, or in
        connection with access to the Site via your computer or any user id used
        to access the Site.
      </p>
      <h4 className="privacySubHeader" id="Links to Third Party Websites">Links to Third Party Websites</h4>
      <p>
        The Company may make available to you links to other websites on the
        World Wide Web. The Company believes that the links contained on its
        pages are to reputable companies. Nevertheless, the Company makes no
        representations or warranties regarding non-Company websites or the
        companies maintaining them. The Company does not control or monitor such
        websites and the Company does not endorse the content or use of such
        websites. If you choose to access non-Company websites through links on
        the Company’s pages, you accept responsibility for all related risks.
      </p>
      <h4 className="privacySubHeader" id="No Warranties; Disclaimers">No Warranties; Disclaimers</h4>
      <p>
        THE INFORMATION AND MATERIALS CONTAINED IN THIS SITE ARE PROVIDED "AS
        IS" WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING
        BUT NOT LIMITED TO, ANY WARRANTY OF ACCURACY, ADEQUACY OR COMPLETENESS
        OF THE INFORMATION AND MATERIALS, TITLE, NON-INFRINGEMENT OF THIRD PARTY
        RIGHTS, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND FREEDOM
        FROM COMPUTER VIRUS.
      </p>
      <p>
        AS THE USER OF THIS SITE, YOU ASSUME FULL RISK AND RESPONSIBILITY FOR
        ANY AND ALL USE OF THIS SITE, INCLUDING THE INFORMATION PRESENTED ON THE
        SITE. YOU AGREE TO HOLD THE COMPANY AND ITS AFFILIATES HARMLESS FROM ANY
        AND ALL CLAIMS RELATING TO YOUR USE OF, YOUR RELIANCE UPON, OR ERRORS OR
        OMISSIONS IN, INFORMATION FOUND ON THIS SITE.
      </p>
      <h4 className="privacySubHeader" id="Limitation of Liability">Limitation of Liability</h4>
      <p>
        IN NO EVENT WILL THE COMPANY OR ANY OF ITS SUBSIDIARIES OR AFFILIATES BE
        LIABLE FOR ANY DAMAGES, LOSSES OR EXPENSES, INCLUDING WITHOUT
        LIMITATION, DIRECT OR INDIRECT, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
        DAMAGES ARISING IN CONNECTION WITH THIS SITE, USE THEREOF, THE ACCESS TO
        DATA, INCLUDING BUT NOT LIMITED TO CREDIT CARD AND OTHER FINANCIAL DATA,
        OR RELIANCE ON ANY INFORMATION CONTAINED HEREIN, EVEN IF THE COMPANY OR
        ANY OF ITS SUBSIDIARIES OR AFFILIATES ARE NOTIFIED OF THE POSSIBILITY OF
        SUCH DAMAGES, WHETHER UNDER THEORY OF CONTRACT, TORT (INCLUDING
        NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE.
      </p>
      <h4 className="privacySubHeader" id="Confidentiality and Transmissions over the Internet">
        Confidentiality and Transmissions over the Internet
      </h4>
      <p>
        The transmission of data or information (including communications by
        email) over the Internet or other publicly accessible networks is not
        secure, and is subject to possible loss, interception or alteration
        while in transit. Accordingly, the Company does not assume any liability
        for any damage you may experience or costs you may incur as a result of
        any transmissions over the Internet or other publicly accessible
        networks, such as transmissions involving the exchange of information
        including but not limited to credit card and other financial data, or
        email with the Company (including those which may contain your personal
        information). Please do not send transactional data information to the
        Company via email. While the Company takes commercially reasonable
        efforts to safeguard the privacy of the information that you provide and
        treats such information in accordance with the Company’s Privacy Policy,
        in no event will the information you provide the Company be deemed
        confidential, create any fiduciary obligations to you on the part of the
        Company, or result in any liability to you on the part of the Company in
        the event that such information is inadvertently released by the Company
        or accessed by third parties without the consent of the Company.
      </p>
      <h4 className="privacySubHeader" id="Disclosure of Information Pursuant to the Law">
        Disclosure of Information Pursuant to the Law
      </h4>
      <p>
        This is a United States website and is subject to United States law. The
        Company will disclose any information regarding the use of this Site
        including personal information pertaining to you without your permission
        when required by law, or in good faith belief that such action is
        necessary, as set forth in the Company’s Privacy Policy and elsewhere
        herein.
      </p>
      <h4 className="privacySubHeader" id="Indemnification">Indemnification</h4>
      <p>
        You agree to defend, indemnify and hold the Company and its directors,
        officers, employees, agents, affiliates and successors harmless from and
        against any and all claims, demands, liabilities, judgments, losses,
        damages, costs, fees and expenses (including but not limited to
        reasonable attorneys' fees) arising from or relating to your use of the
        Site or your acts or omissions, including but not limited to: (a)
        infringement or misappropriation of any intellectual property rights;
        (b) defamation, libel, slander, obscenity, pornography, or violation of
        the rights of privacy or publicity; (c) spamming, or any other
        offensive, harassing or illegal conduct or violation of these Terms; (d)
        any damage or destruction to the Site, to the Company, or to other
        persons or parties; or (e) access, use or disclosure of data, including
        but not limited to, credit card and other financial data.
      </p>
      <h4 className="privacySubHeader" id="Breach">Breach</h4>
      <p>
        You hereby acknowledge that your breach of the Terms may result in
        immediate and irreparable harm to the Company, for which there may be no
        adequate remedy at law, and that the Company shall be entitled to
        equitable relief to compel you to cease and desist all unauthorized use,
        evaluation and/or disclosure of information obtained through the Site.
        Your breach of the Terms may also entitle us to bring an action against
        you for any and all other remedies available at law or in equity.
      </p>
      <h4 className="privacySubHeader" id="Jurisdiction">Jurisdiction</h4>
      <p>
        The Company operates and controls the Site from its location in the
        State of Arizona, United States of America. The Company makes no
        representation that any information, materials or functions included on
        or through the Site are appropriate for use in any other jurisdiction.
        If you choose to access the Site from locations other than within the
        State of Arizona, you do so on your own initiative and are responsible
        for compliance with applicable laws and regulations.
      </p>
      <p>
        You agree that the Terms, and the rights and obligations hereunder,
        shall be governed by, and construed in accordance with, the laws of the
        State of Arizona. You further agree that any legal action or proceeding
        between you and the Company arising out of or relating to the Terms or
        your use of the Site shall be brought exclusively in a federal or state
        court of competent jurisdiction sitting in Phoenix, Arizona, and you
        hereby expressly and irrevocably consent to be bound by the personal
        jurisdiction and venue of such courts.
      </p>
      <h4 className="privacySubHeader" id="No waiver; Severability">No waiver; Severability</h4>
      <p>
        A waiver of any breach of any provision of the Terms shall not be deemed
        to be a waiver of any repetition of such breach or in any manner affect
        any other terms or conditions of the Terms. In the event that any
        provision of the Terms is held to be unenforceable, it will not affect
        the validity or enforceability of the remaining provisions and will be
        replaced by an enforceable provision that is the closest to the
        intention underlying the unenforceable provision.
      </p>
      <h4 className="privacySubHeader" id="Assignability">Assignability</h4>
      <p>
        The Company may assign its rights and delegate its duties under the
        Terms either in whole or in part at any time, in its sole discretion,
        and without your consent. You may not assign, sublicense or otherwise
        transfer your rights or obligations, in whole or in part, under the
        Terms to anyone else.
      </p>
      <h4 className="privacySubHeader" id="Relationship">Relationship</h4>
      <p>
        This Agreement does not establish any relationship of partnership, joint
        venture, employment, franchise or agency between you and the Company.
      </p>
      <h4 className="privacySubHeader" id="Contact Information">Contact Information</h4>
      <p>
        If you have any questions or concerns about the Company’s Site, the
        Terms and Conditions of Use, the Privacy Policy and/or other notices
        posted on this Site, please contact the Company at info@postint.com.
        Please note that any communication or material transmitted to the
        Company via this Site or Internet email is transmitted on a
        non-confidential basis.
      </p>
      <p style={{marginBottom: "20px"}}>Updated August 1, 2005</p>
    </div>
  );
};

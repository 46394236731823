"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEntityToJSON = exports.UserEntityFromJSONTyped = exports.UserEntityFromJSON = void 0;
var runtime_1 = require("../runtime");
function UserEntityFromJSON(json) {
    return UserEntityFromJSONTyped(json, false);
}
exports.UserEntityFromJSON = UserEntityFromJSON;
function UserEntityFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'name': !runtime_1.exists(json, 'name') ? undefined : json['name'],
        'phone': !runtime_1.exists(json, 'phone') ? undefined : json['phone'],
        'email': !runtime_1.exists(json, 'email') ? undefined : json['email'],
        'accountLocked': !runtime_1.exists(json, 'accountLocked') ? undefined : json['accountLocked'],
    };
}
exports.UserEntityFromJSONTyped = UserEntityFromJSONTyped;
function UserEntityToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'phone': value.phone,
        'email': value.email,
        'accountLocked': value.accountLocked,
    };
}
exports.UserEntityToJSON = UserEntityToJSON;

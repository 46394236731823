import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-datepicker/dist/react-datepicker.css";
import Keycloak from "keycloak-js";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { EbocomSdk } from "./pkg/ebocomsdk/init";
import axios from "axios";
import { useIdleTimer } from 'react-idle-timer';
import {
  ApplicationConfig,
  ConfigContext,
  createApplicationConfig,
} from "./config";
import { TokenContext } from "./contexts/token_context";
import { GlobalLoader } from "./components/global_loader";
import { clearLocalStorage } from "./utils";
const renderReact = (
  config: ApplicationConfig,
  Component: ({ children }: { children: any }) => JSX.Element = ({
    children,
  }) => <div>{children}</div>
) =>
  ReactDOM.render(
    <React.StrictMode>
      <ConfigContext.Provider value={config}>
        <TokenContext.Provider value={{ setToken() { } }}>
          <Component>
            <GlobalLoader appBaseUrl={config.ebocomBackendBaseUrl}>
              <App />
            </GlobalLoader>
          </Component>
        </TokenContext.Provider>
      </ConfigContext.Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );

const TokenProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = React.useState<string | undefined>(undefined);

  const { getIdleTime, reset } = useIdleTimer({
    timeout: 1000,
    onActive: () => reset(),
  });

  return (
    <TokenContext.Provider value={{ token, setToken, getIdleTime }}>
      {children}
    </TokenContext.Provider>
  );
};
const KeyCloakComponent = (config: ApplicationConfig) => {
  return ({ children }: { children: any }) => {
    const keycloak = Keycloak({
      clientId: config.kcClientId,
      realm: config.kcRealm,
      url: config.kcUrl,
    });
    return (
      <TokenProvider>
        <TokenContext.Consumer>
          {({ setToken, getIdleTime }) => (
            <ReactKeycloakProvider
              initOptions={{
                onLoad: "login-required",
                checkLoginIframe: false,
              }}
              authClient={keycloak}
              onTokens={(tokens) => {
                if (tokens.token) {
                  const parsedToken = JSON.parse(atob(tokens.token.split('.')[1]));
                  window.localStorage.setItem('tokenExpirationTime', (parsedToken.exp * 1000).toString());
                  const logoutUrl = keycloak.createLogoutUrl({
                    redirectUri: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                  });
                  console.log('logout Url:', logoutUrl);
                  window.localStorage.setItem('logoutUrl', logoutUrl);
                  EbocomSdk.updateToken(tokens.token);
                  setToken(tokens.token);
                }
              }}
              LoadingComponent={<div />}
              autoRefreshToken={true}
              onEvent={(event, error) => {
                switch (event) {
                  case 'onAuthRefreshError':
                  case 'onAuthError':
                    clearLocalStorage();
                    keycloak.logout({
                      redirectUri: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                    });
                    break;
                  // We check for inactivity every time the user's token gets expired
                  // Time interval can be adjusted by changing "Access Token Lifespan" inside SSO => Realm Settings => Tokens
                  case 'onTokenExpired':
                    if (getIdleTime) {
                      const idleTime = getIdleTime();

                      console.log('Current idle time (minutes):', idleTime / 1000 / 60);

                      // The default maximum idle time is 15 minutes for external users
                      let maxIdleTime = 15 * 60 * 1000;

                      // maxIdleTime === refresh token lifespan
                      // It can be adjusted for external users by changing "Client Session Idle" inside SSO => Realm Settings => Tokens
                      if (keycloak.refreshTokenParsed?.exp && keycloak.refreshTokenParsed?.iat) {
                        maxIdleTime = (keycloak.refreshTokenParsed?.exp - keycloak.refreshTokenParsed?.iat) * 1000;
                      }

                      // The hardcoded maximum idle time is 4 hours for internal users
                      if (keycloak.tokenParsed?.realm_access?.roles.includes("InternalUser")) {
                        maxIdleTime = 4 * 60 * 60 * 1000;
                        console.log('The current user is internal');
                      } else {
                        console.log('The current user is external');
                      }

                      console.log('Max idle time (minutes):', maxIdleTime / 1000 / 60);

                      if (idleTime >= maxIdleTime) {
                        console.log('idle time is higher than max idle time');
                        clearLocalStorage();
                        keycloak.logout({
                          redirectUri: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                        });
                      }
                    } else {
                      console.log('failed to get idle time');
                      clearLocalStorage();
                      keycloak.logout({
                        redirectUri: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
                      });
                    }
                    break;
                  case 'onAuthLogout':
                    console.log('onAuthLogout');
                    clearLocalStorage();
                    break;
                }
              }}
            >
              {children}
            </ReactKeycloakProvider>
          )}
        </TokenContext.Consumer>
      </TokenProvider>
    );
  };
};
(async function () {
  const { data } = await axios.get("/config.json?q=" + Date.now().toString());
  const config = createApplicationConfig({
    ebocomBaseUrl: data.ebocom_base_url,
    ebocomKcClientId: data.ebocom_kc_client_id,
    ebocomKcRealm: data.ebocom_kc_realm,
    ebocomKcUrl: data.ebocom_kc_url,
  });
  if (process.env.REACT_APP_USE_KEYCLOAK?.toLowerCase?.() === "true") {
    renderReact(config, KeyCloakComponent(config));
  } else {
    renderReact(config);
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

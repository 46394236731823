import React from "react";
import { Copyright } from "../components/copyright";

export interface FooterProps {
  primaryUser?: string;
  impersonatedUser?: string;
  availableUsers: string[];
  onSelectUser: (user: string) => void;
  showWidget: boolean;
}

export interface WidgetProps {
  primaryUser?: string;
  availableUsers: string[];
  onSelectUser: (user: string) => void;
}

export function Footer({
  primaryUser,
  impersonatedUser,
  availableUsers,
  onSelectUser,
  showWidget = false
}: FooterProps) {
  function ImpersonateWidget() {
    return <div data-testid="impersonate-dropdown">
      ACTIVE USER: &nbsp;<ActiveUserSelectComponent
        primaryUser={impersonatedUser ?? primaryUser}
        availableUsers={availableUsers}
        onSelectUser={onSelectUser}
      />
    </div>
  }
  function ImpersonateText() {
    return <div data-testid="impersonate-text">ACTIVE USER: {impersonatedUser}</div>
  }
  function RightComponent() {
    return (
      <div className="is-flex is-align-items-end">
        {
          availableUsers.length > 1 || impersonatedUser // can impersonate
          ? showWidget // we want the widget instead of the text?
            ? ImpersonateWidget()
            : impersonatedUser && impersonatedUser !== primaryUser // is impersonating
              ? ImpersonateText()
            : null // not impersonating
          : null // cant impersonate
        }
      </div>
    )
  }
  return (
    <div className="footer p-0 is-flex is-uppercase">
      <div className="col-left is-align-items-center is-justify-content-start is-flex" data-testid="footer-userid"><span>USER ID: {primaryUser}</span></div>
      <div className="col-mid is-align-items-center is-justify-content-center is-flex"><span><Copyright /></span></div>
      <div className="col-right is-align-items-center is-justify-content-end is-flex"><span><RightComponent /></span></div>
    </div>
  );
}

const ActiveUserSelectComponent = ({
  availableUsers = [],
  primaryUser,
  onSelectUser = () => {},
}: WidgetProps) => {
  return (
    <select
      data-testid="active-user-select-dropdown"
      style={{ height: 18, fontSize: 11, maxWidth: 200 }}
      value={primaryUser}
      onChange={(e) => onSelectUser(e.target.value)}
    >
      {availableUsers.map((user) => {
        return (
          <option key={user} value={user}>
            {user.toUpperCase()}
          </option>
        );
      })}
    </select>
  );
};

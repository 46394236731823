import {
  Transaction,
  TransactionSortField,
  TransactionType,
} from "../../models/transaction";
import {
  TransactionService,
  TransactionSearchParameters,
} from "../../services/transaction";
import { DateService } from "../../services/date_service";
import { CardType } from "../../models/card_type";
import { PaginatedResult } from "../../models/paginated_result";
import {
  Configuration,
  CardTypes as SdkCardType,
  TransactionSearchTypes as SdkTransactionTypes,
  Transaction as EbocomSdkTransaction,
  SupersearchApi,
  SupersearchApiSearchTransactionsRequest,
  SortOrder as SdkSortOrder,
  SortByColumn,
} from "ebocomsdk";
import { getPreAuthMiddleware } from "./auth_middleware";
import { SortOrder } from "../../models/sort_order";
import { getFilenameFromContentDisposition } from "./filename_extractor";
import { CONTENT_DISPOSITION } from "./constants";
import { ebocomSdkNumberFormatter } from "./number_parser";
import { getPostLoadingMiddleware, getPreLoadingMiddleware } from "./loading_middleware";

export class EbocomTransactionService implements TransactionService {
  private api: SupersearchApi;
  constructor(baseUrl: string) {
    const configuration = new Configuration({
      basePath: baseUrl,
    });
    this.api = new SupersearchApi(configuration)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
  }
  private serialzeTxType(txType: TransactionType): SdkTransactionTypes {
    if (txType === TransactionType.AuthOnly) {
      return SdkTransactionTypes.AuthOnly;
    } else if (txType === TransactionType.SalesCredits) {
      return SdkTransactionTypes.SalesCredits;
    } else if (txType === TransactionType.CBRev) {
      return SdkTransactionTypes.CbRev;
    } else if (txType === TransactionType.RFC) {
      return SdkTransactionTypes.Rfc;
    } else if (txType === TransactionType.CBLifeCycle) {
      return SdkTransactionTypes.CbLifeCycle;
    } else {
      throw TypeError("could not find the requested transaction type");
    }
  }
  private serializeCardType(cardType: CardType): SdkCardType {
    if (cardType === CardType.visa) {
      return SdkCardType.Vs;
    } else if (cardType === CardType.mastercard) {
      return SdkCardType.Mc;
    } else if (cardType === CardType.discover) {
      return SdkCardType.Dr;
    } else if (cardType === CardType.americanExpress) {
      return SdkCardType.Ax;
    } else if (cardType === CardType.jcb) {
      return SdkCardType.Jcb;
    } else {
      throw TypeError("Could not find desired card type");
    }
  }
  private deserializeResultDate = (stringifiedDate: string): Date => {
    const yyyyMMdd = stringifiedDate.split(" ")[0];
    const dateParts = yyyyMMdd.split("-").map((s) => parseInt(s, 10));
    const date = new Date();
    date.setUTCFullYear(dateParts[0]);
    date.setUTCMonth(dateParts[1] - 1);
    date.setUTCDate(dateParts[2]);
    return date;
  };
  private deserializeResult = (result: EbocomSdkTransaction): Transaction => {
    const resultDate = result.tranDate
      ? this.deserializeResultDate(result.tranDate)
      : undefined;
    const tx = new Transaction();
    tx.merchantNumber = result.merchNbr;
    tx.id = result.id ?? "";
    tx.merchantName = result.merchantName;
    tx.authCode = result.authCode;
    tx.posEntry = result.posEntryMode;
    tx.transactionType = result.tranType?.replaceAll('_',' ').trim();
    tx.depositDate = result.tranDate
      ? new Date(Date.parse(result.tranDate))
      : undefined;
    tx.rfcCode = result.messageReasonCode;
    tx.transactionStatus = result.tranStatus;
    tx.posEntryDesc = result.posEntryModeDesc;
    tx.transactionDate = resultDate;
    tx.processDate = result.processDate
      ? this.deserializeResultDate(result.processDate)
      : undefined;
    tx.transactionAmount = ebocomSdkNumberFormatter(result.tranAmount);
    tx.cardNumber = result.acctNumberRaw;
    tx.outletNumber = result.mid;
    tx.outletName = result.midName;
    tx.billedDate = result.billedDate
      ? this.deserializeResultDate(result.billedDate)
      : undefined;
    tx.cardType = result.cardType;
    tx.maskedCardNumber = result.acctNumber;
    tx.closedDescription = result.closedDescription;
    tx.mid = result.mid;
    tx.midName = result.midName;
    tx.disputeDescription = result.disputeDescription;
    tx.expDate = result.expDate;
    tx.lifeCycleIndGroup = result.lifeCycleIndGroup;
    tx.loadBatchNumber = result.loadBatchNumber;
    tx.loadEntryNumber = result.loadEntryNumber;
    tx.loadFileNumber = result.loadFileNumber;
    return tx;
  };
  private static sortFieldMap: { [key: number]: SortByColumn } = {
    [TransactionSortField.authCode]: SortByColumn.Authcode,
    [TransactionSortField.cardNumber]: SortByColumn.Cardnumber,
    [TransactionSortField.cardType]: SortByColumn.Cardtype,
    [TransactionSortField.tranDate]: SortByColumn.Trandate,
    [TransactionSortField.tranAmount]: SortByColumn.Tranamt,
    [TransactionSortField.tranType]: SortByColumn.Trantype,
    [TransactionSortField.merchantName]: SortByColumn.Merchantname,
    [TransactionSortField.merchantNumber]: SortByColumn.Merchantnbr,
    [TransactionSortField.outletName]: SortByColumn.Outletname,
    [TransactionSortField.outletNumber]: SortByColumn.Outlet,
    [TransactionSortField.tranStatus]: SortByColumn.Transtatus,
    [TransactionSortField.rfcCode]: SortByColumn.Disputecode,
    [TransactionSortField.posEntry]: SortByColumn.Posmode,
    [TransactionSortField.depositDate]: SortByColumn.Billingdate,
  };
  private sdkSerializeSearchParameters(
    params: TransactionSearchParameters
  ): SupersearchApiSearchTransactionsRequest {
    return {
      transactionSearch: {
        dateBegin: params.dateBegin
          ? DateService.formatDate(params.dateBegin, "-")
          : undefined,
        dateEnd: params.dateEnd
          ? DateService.formatDate(params.dateEnd, "-")
          : "",
        merchantNbr: params.merchantNumber ?? "9999999999",
        amount: params.amount,
        amountOp: params.amountOperator,
        cardNbr: params.cardNumber ?? "1234",
        cardTypes: params.cardTypes?.map(this.serializeCardType) ?? [],
        divisionNbr: params.divisionNumber
          ? parseInt(params.divisionNumber)
          : undefined,
        outlet: params.outletName,
        offset: params.offset,
        maxResults: params.limit,
        transactionTypes:
          params.transactionTypes?.map(this.serialzeTxType) ?? [],
        sortBy:
          params.sortField !== undefined
            ? EbocomTransactionService.sortFieldMap[params.sortField!]
            : undefined,
        sortOrder:
          params.sortOrder === SortOrder.DESC
            ? SdkSortOrder.Desc
            : SdkSortOrder.Asc,
      },
    };
  }
  public getTransactionReport = async (
    searchParameters: TransactionSearchParameters
  ): Promise<[Blob, string?]> => {
    const parameters = this.sdkSerializeSearchParameters(searchParameters);
    const response = await this.api.exportTransactionsRaw({
      transactionSearch: parameters.transactionSearch,
    });
    const filename = getFilenameFromContentDisposition(
      response.raw.headers.get(CONTENT_DISPOSITION) ?? ""
    );
    const blob = await response.raw.blob();
    return [blob, filename];
  };
  public getTransactions = async (
    searchParameters: TransactionSearchParameters
  ): Promise<PaginatedResult<Transaction>> => {
    return new Promise(async (resolve, reject) => {
      try {
        const request = this.sdkSerializeSearchParameters(searchParameters);
        const data = await this.api.searchTransactions(request);
        const deserializedData =
          data?.transactions?.map?.(this.deserializeResult) ?? [];
        resolve({
          data: deserializedData,
          offset: data.offset ?? 0,
          total: data.totalResults,
        });
      } catch (error) {
        const errResponse = await (error as any).json();
        reject(errResponse);
      }
    });
  };
}

import { CardDetails } from "../models/card_details";
import { CardService } from "../services/card_service";
import faker from "faker";
import { LookupResult } from "../layout/search_page";

export function mockCardService(): CardService {
  return {
    async getCardDetails() {
      const card = new CardDetails();
      card.cardType = "VISA";
      card.instName = "NAVY FEDERAL CREDIT UNION";
      card.provider = "provider";
      card.serviceProviders = Array.from(new Array(3), () => ({
        name: "providerService",
        phone: faker.phone.phoneNumber(),
        fax: faker.phone.phoneNumber(),
      }));
      card.instCountry = "U.S.A.";
      card.binNumber = 12345678;
      return { card: card, result: LookupResult.OK };
    },
  };
}

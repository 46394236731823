import { useCallback, useEffect, useState } from "react";
import { UserService } from "../services/user";

export function useImpersonatableUsers(userService?: UserService) {
  const [loading, setLoading] = useState(false);
  const [impersonatableUsers, setImpersonatableUsers] = useState<string[]>([]);
  const refreshImpersonatable = useCallback(() => {
    setLoading(true);
    try {
      if (!userService) {
        return;
      }
      userService?.getImpersonatableAccounts().then((users) => {
        setImpersonatableUsers(users);
      });
    } finally {
      setLoading(false);
    }
  }, [userService]);
  useEffect(() => {
    refreshImpersonatable();
  }, [refreshImpersonatable]);
  return {
    impersonatableUsers,
    refreshImpersonatableUsers: refreshImpersonatable,
    loading,
  };
}

import React from "react";
import { Modal } from "../../components/modal";
import { DisputeSummary } from "../../models/dispute";
import { DisputeSummaryView } from "./components/dispute_summary_view";
import Close from "@material-ui/icons/Close";
import "./welcome.css";
import { useEffect } from "react";
import { User } from "../../models/user";
export interface WelcomeRouteProps {
  disputes?: DisputeSummary[];
  welcomeMessage?: React.ReactNode;
  children?: React.ReactNode;
  onDismissed: () => void;
  hasDismissedModal: boolean;
  loadingDisputes: boolean;
  currentUser?: User;
}
export const WelcomeRoute = ({
  disputes = [],
  currentUser,
  ...props
}: WelcomeRouteProps) => {

  useEffect(() => {
    setTimeout(() => {
      document.body.style.cursor = 'default';
      document.body.scrollIntoView();
    }, 2000);
    return () => {
      document.body.style.cursor = 'auto';
    }
  }, [])

  function setLogin() {
    window.localStorage.setItem("Loggedin", "true");
    props.onDismissed();
  };
  let modal = (
    <Modal
      closeDisabled={props.loadingDisputes}
      isActive={!props.hasDismissedModal}
      onClose={props.onDismissed}
    >
      <div
        className="dispute-summary-modal"
        style={{ backgroundColor: "white", padding: 10 }}
      >
        <div className="is-flex is-flex-direction-row is-justify-content-flex-end">
          {!props.loadingDisputes && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => setLogin()}
              className="material-icons md-36"
            >
              <Close />
            </span>
          )}
        </div>
        {!props.loadingDisputes && <DisputeSummaryView disputes={disputes} />}
      </div>
    </Modal>
  );
  return (
    <div className="welcome-container">
      {!window.localStorage.getItem("Loggedin") && currentUser?.roles.includes('ChargebackRFCAging') && modal}
      <div className="welcome is-align-items-center is-flex is-flex-direction-column">
        {props.welcomeMessage}
        {props.children}
      </div>
    </div>
  );
};
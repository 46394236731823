import { useContext } from "react";
import { UserContext } from "../contexts/user_context";
import { Role } from "../models/roles";

export interface FeatureProps {
  fallbackComponent?: JSX.Element;
  wantedRole: Role;
  children?: any;
}
export const Feature = ({
  fallbackComponent = <div />,
  wantedRole,
  children = null,
}: FeatureProps): JSX.Element => {
  const user = useContext(UserContext);
  if (!user?.roles.includes(wantedRole)) {
    return fallbackComponent;
  }
  return children;
};

import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "./contactus.css";
import { PageTitle } from "../../components/page_title";
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';

export interface ContactUsProps {
  onSubmitFeedback(feedback: string): void;
}
export const ContactUs = ({ onSubmitFeedback }: ContactUsProps) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [isMessageSent, setMessageSent] = useState(false);

  const feedbackErrorTooltipRef = useRef<TooltipRefProps>(null);

  useEffect(() => {
    if (errors.feedback) feedbackErrorTooltipRef.current?.open();
  }, [
    errors.feedback,
  ])

  return (
    <div>
      <PageTitle title="Contact Us" />
      {isMessageSent ? (
        <div style={{ margin: '20px 20px 40px 20px' }}>
          Thank you for taking time to submit feedback to us! We will review your suggestions and continue to provide you with superior service.
        </div>
      ) : (
        <div>
          <div className="is-uppercase title is-7 pt-5">
            Our Clients are our most valuable asset
          </div>
          <div>
            At POST Integrations, our goal is to always exceed your expectations.
            Thank you for allowing us the opportunity to do so, each and every
            day!
          </div>
          <br />
          <div className="is-uppercase title is-7 pt-3">We welcome your feedback</div>
          <div>
            We are very interested in hearing from you. Please use the convenient
            form below to provide us with your valuable feedback about this web
            site and any of our services. If you prefer, you may also send an
            email directly to{" "}
            <a href="mailto:greatideas@postint.com" style={{ color: "purple" }}>
              greatideas@postint.com
            </a>
            .
          </div>
          <br />
          <div
            className="is-fullwidth"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/greatideasicon.jpg`}
              alt="ideas"
              width="80"
              height="80"
              style={{ marginBottom: "55px" }}
            />
            <div style={{ position: "relative", textAlign: "center" }}>
              <textarea
                data-tooltip-id={!!errors.feedback ? "error-tooltip-feedback" : undefined}
                data-tooltip-content={
                  (errors.feedback &&
                    errors.feedback.type === "required") ?
                    'Please provide your feedback' :
                    undefined
                }
                className={`is-fullwidth${!!errors.feedback ? ' input-error' : ''}`}
                data-testid="feedback-textarea"
                rows={10}
                defaultValue={""}
                {...register("feedback", { required: true })}
              />
              <div>
                <button
                  className="is-primary ie-styled"
                  data-testid="submit-feedback-button"
                  onClick={handleSubmit((data) => {
                    setMessageSent(true);
                    onSubmitFeedback(data.feedback);
                  }
                  )}
                  style={{
                    fontSize: "10px",
                    color: "black",
                    backgroundColor: "#e9e9ed",
                    margin: "10px 2px 20px 0",
                    border: "1px solid #8f8f9d",
                  }}
                >
                  Send
                </button>
                <button
                  className="ie-styled"
                  data-testid="clear-feedback-button"
                  style={{
                    fontSize: "10px",
                    color: "black",
                    backgroundColor: "#e9e9ed",
                    margin: "10px 0 20px 2px",
                    border: "1px solid #8f8f9d",
                  }}
                  onClick={() =>
                    reset({
                      feedback: "",
                    })
                  }
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <ReactTooltip
        id="error-tooltip-feedback"
        ref={feedbackErrorTooltipRef}
        opacity={1}
        style={{
          color: '#fff',
          backgroundColor: '#cc0f35',
          fontSize: '12px',
        }}
      />
    </div>
  );
};

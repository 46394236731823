import React from "react";
import "./ebocom_warning_message.scss";

export interface EbocomWarningMessageProps {
  message: string;
}
export function EbocomWarningMessage({ message }: EbocomWarningMessageProps) {
  return (
    <div className="ebocom-warning-message is-flex is-align-items-center">
      <img src={`${process.env.PUBLIC_URL}/images/warning.gif`} alt="warning" />
      &nbsp;
      <div><span data-testid="ebocom-warning-message" className="message-text">{message}</span></div>
    </div>
  );
}

export function EbocomDetailWarningMessage({ message }: EbocomWarningMessageProps) {
  return (
      <div className="ebocom-detail-warning-message"><span data-testid="ebocom-detail-warning-message" className="message-text">{message}</span></div>
  );
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearToJSON = exports.YearFromJSONTyped = exports.YearFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function YearFromJSON(json) {
    return YearFromJSONTyped(json, false);
}
exports.YearFromJSON = YearFromJSON;
function YearFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'year': !runtime_1.exists(json, 'year') ? undefined : json['year'],
        'months': !runtime_1.exists(json, 'months') ? undefined : (json['months'].map(_1.MonthFromJSON)),
    };
}
exports.YearFromJSONTyped = YearFromJSONTyped;
function YearToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'year': value.year,
        'months': value.months === undefined ? undefined : (value.months.map(_1.MonthToJSON)),
    };
}
exports.YearToJSON = YearToJSON;

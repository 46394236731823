import React, { useEffect } from "react";
import { PageTitle } from "../../components/page_title";
import { TableTitle } from "../../components/table_title";
import { useUserCollection } from "../../hooks/use_user_collection";
import { useUserPrivileges } from "../../hooks/use_user_privileges";
import { SearchPage } from "../../layout/search_page";
import {
  UserEntity,
  UserPrivilege,
  UserPrivilegeTitle,
} from "../../models/user";
import { UserService } from "../../services/user";
import { AvailableUsersTable } from "./available_users_table";
import { UserPrivilegeTable } from "./user_privilege_table";

export interface UserPrivilegesContainerProps {
  userService: UserService;
}
export function UserPrivilegesContainer({
  userService,
}: UserPrivilegesContainerProps) {
  const {
    users,
    loading: collectionLoading,
    pagination,
  } = useUserCollection(userService);
  const [drilldownUser, setDrilldownUser] = React.useState<
    UserEntity | undefined
  >(undefined);
  const currentUser = drilldownUser ?? users?.[0];
  const { loading: drilldownLoading, userPrivileges } = useUserPrivileges(
    userService,
    currentUser?.id
  );
  useEffect(() => {
    setDrilldownUser(undefined);
  }, [pagination.page]);
  const groupedPrivileges = React.useMemo(
    () =>
      userPrivileges
        .sort(({ order: orderA = 0 }, { order: orderB = 0 }) => orderA - orderB)
        .reduce<Record<UserPrivilegeTitle, UserPrivilege[]>>(
          (previous, current) => {
            if (current.title === 0 || current.title) {
              previous[current.title].push(current);
            }
            return previous;
          },
          {
            [UserPrivilegeTitle.daily]: [],
            [UserPrivilegeTitle.reference]: [],
            [UserPrivilegeTitle.management]: [],
          }
        ),
    [userPrivileges]
  );
  return (
    <div className="userPrivileges-wrapper">
      <div className="title-bar">
        <PageTitle title="User Privileges" />
      </div>
      <SearchPage
        isLoading={collectionLoading || drilldownLoading}
        searchPanel={
          <>
            <TableTitle
              onPageNext={pagination.nextPage}
              onPagePrevious={pagination.previousPage}
              onPageFirst={pagination.firstPage}
              onPageLast={pagination.lastPage}
              totalResults={pagination.totalResults}
              currentResultCount={users.length}
              offset={pagination.offset}
              title="User List"
              hideShowAllButton
              pageSize={pagination.pageSize}
            />
            <AvailableUsersTable
              onSelectUser={(user) => setDrilldownUser(user)}
              highlightedUserId={currentUser?.id}
              users={users}
            />
          </>
        }
        searchResults={
          userPrivileges?.length > 0 && (
            <div>
              <TableTitle
                title={`Reference Information Privileges for ${currentUser?.name}`}
                hideShowAllButton
              />
              <UserPrivilegeTable
                userPrivileges={groupedPrivileges[UserPrivilegeTitle.reference]}
              />
              <TableTitle
                title={`Daily Operations Privileges for ${currentUser?.name}`}
                hideShowAllButton
              />
              <UserPrivilegeTable
                userPrivileges={groupedPrivileges[UserPrivilegeTitle.daily]}
              />
              <TableTitle
                title={`Management Module Privileges for ${currentUser?.name}`}
                hideShowAllButton
              />
              <UserPrivilegeTable
                userPrivileges={groupedPrivileges[UserPrivilegeTitle.management]}
              />
            </div>
          )
        }
      />
    </div>

  );
}

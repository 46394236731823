import { useCallback, useEffect, useState } from "react";
import { Outlet } from "../models/outlet";
import { MerchantService, OutletSortColumn } from "../services/merchant";
import { usePagination } from "./use_pagination";
import { useSorting } from "./use_sorting";

const PAGE_SIZE = 500;

export function useOutlets(
  merchantService: MerchantService,
  merchantNumber?: string
) {
  const [outlets, setOutlets] = useState<Outlet[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { sorting, handleSort } = useSorting<OutletSortColumn>("merchantName");
  const [totalResults, setTotalResults] = useState(0);
  const pagination = usePagination(PAGE_SIZE, totalResults);
  const reset = useCallback(() => {
    pagination.firstPage();
    pagination.setPageSize(PAGE_SIZE);
    setTotalResults(0);
    setOutlets([]);
    handleSort();
  }, [pagination, setTotalResults, handleSort]);
  useEffect(() => {
    if (!merchantNumber) {
      return;
    }
    setIsLoading(true);
    merchantService
      .getMerchantOutlets({
        merchantNumber,
        sortColumn: sorting?.field!,
        sortDirection: sorting?.direction!,
        offset: pagination.page,
        pageSize: pagination.pageSize,
      })
      .then((result) => {
        setOutlets(result.data);
        setTotalResults(result.total);
      })
      .finally(() => setIsLoading(false));
  }, [
    merchantNumber,
    merchantService,
    sorting,
    pagination.page,
    pagination.pageSize,
  ]);

  return {
    outlets,
    isLoading,
    sorting,
    pagination,
    handleSort,
    reset,
  };
}

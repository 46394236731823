export class ReportGroup {
  id: string;
  name: string;
  children?: ReportGroup[];
  url?: string;
  constructor(
    id: string,
    name: string,
    children?: ReportGroup[],
    url?: string
  ) {
    this.id = id;
    this.name = name;
    this.children = children;
    this.url = url;
  }
  get hasChildren() {
    return Array.isArray(this.children) && this.children.length > 0;
  }
}

export class ReportCategory {
  constructor(public id: string, public description: string) { }
}

export class ReportCategoryValue {
  constructor(
    public categoryId: string,
    public valueId: string,
    public value: string
  ) { }
}
export enum ReportType {
  REFUNDAUDLOCATION,
  REFUNDAUDTREND,
  BYCARDTYPETREND,
  CARDACCEPTANCEREGION,
  CARDPRODUCTACCEPTANCE,
  CARDPRODUCTACCEPTANCETREND,
  CARDREGIONTREND,
  CARDTYPE,
  CHARGEBACKAGING,
  CHARGEBACKAGINGOUTSTANDING,
  CHARGEBACKPERFORMANCE,
  CHARGEBACKPERFORMANCEREASON,
  CHARGEBACKPERFORMANCETREND,
  CLOSEDCHARGEBACKPERFORMANCE,
  CLOSEDCHARGEBACKPERFORMANCEREASON,
  CLOSEDCHARGEBACKPERFORMANCETREND,
  DAILYDEPOSIT,
  DAILYDEPOSITLOCATION,
  DAILYTRANSMITTEDACTIVITY,
  DEBITCARDPRODUCTACCEPTANCE,
  DEBITCARDPRODUCTACCEPTANCETREND,
  DEPOSITACTIVITY,
  DEPOSITACTIVITYTREND,
  DISPUTEAGING,
  DISPUTEAGINGLOCATION,
  EXCESSIVECHARGEBACK,
  EXCESSIVECREDIT,
  INTERCHANGEDETAIL,
  INTERCHANGEPERFORMANCE,
  INTERCHANGEPERFORMANCELOCATION,
  INTERCHANGEQUALIFICATIONPERFORMANCE,
  INTERCHANGEQUALIFICATIONTREND,
  MERCHANTFEES,
  MERCHANTFEESTREND,
  MERCHANTREFERENCESHEET,
  MONTHLYSTATEMENT,
  MONTHLYSTATEMENTLOCATION,
  POINTOFACCEPTANCE,
  POINTOFACCEPTANCETREND,
  RFCAGING,
  RFCPERFORMANCE,
  RFCPERFORMANCETREND,
  REFUNDAUDEXCEPTION,
}
const reportTypeStringMap: { [key: string]: ReportType } = {
  REFUNDAUDEXCEPTION: ReportType.REFUNDAUDEXCEPTION,
  REFUNDAUDTREND: ReportType.REFUNDAUDTREND,
  BYCARDTYPETREND: ReportType.BYCARDTYPETREND,
  CARDACCEPTANCEREGION: ReportType.CARDACCEPTANCEREGION,
  CARDPRODUCTACCEPTANCE: ReportType.CARDPRODUCTACCEPTANCE,
  CARDPRODUCTACCEPTANCETREND: ReportType.CARDPRODUCTACCEPTANCETREND,
  CARDREGIONTREND: ReportType.CARDREGIONTREND,
  CARDTYPE: ReportType.CARDTYPE,
  CHARGEBACKAGING: ReportType.CHARGEBACKAGING,
  CHARGEBACKPERFORMANCE: ReportType.CHARGEBACKPERFORMANCE,
  CHARGEBACKPERFORMANCEREASON: ReportType.CHARGEBACKPERFORMANCEREASON,
  CHARGEBACKPERFORMANCETREND: ReportType.CHARGEBACKPERFORMANCETREND,
  CLOSEDCHARGEBACKPERFORMANCE: ReportType.CLOSEDCHARGEBACKPERFORMANCE,
  CLOSEDCHARGEBACKPERFORMANCEREASON:
    ReportType.CLOSEDCHARGEBACKPERFORMANCEREASON,
  CLOSEDCHARGEBACKPERFORMANCETREND: ReportType.CLOSEDCHARGEBACKPERFORMANCETREND,
  DAILYDEPOSIT: ReportType.DAILYDEPOSIT,
  DAILYTRANSMITTEDACTIVITY: ReportType.DAILYTRANSMITTEDACTIVITY,
  DEBITCARDPRODUCTACCEPTANCE: ReportType.DEBITCARDPRODUCTACCEPTANCE,
  DEBITCARDPRODUCTACCEPTANCETREND: ReportType.DEBITCARDPRODUCTACCEPTANCETREND,
  DEPOSITACTIVITY: ReportType.DEPOSITACTIVITY,
  DEPOSITACTIVITYTREND: ReportType.DEPOSITACTIVITYTREND,
  EXCESSIVECREDIT: ReportType.EXCESSIVECREDIT,
  INTERCHANGEDETAIL: ReportType.INTERCHANGEDETAIL,
  INTERCHANGEPERFORMANCE: ReportType.INTERCHANGEPERFORMANCE,
  INTERCHANGEQUALIFICATIONPERFORMANCE:
    ReportType.INTERCHANGEQUALIFICATIONPERFORMANCE,
  INTERCHANGEQUALIFICATIONTREND: ReportType.INTERCHANGEQUALIFICATIONTREND,
  MERCHANTFEES: ReportType.MERCHANTFEES,
  MERCHANTFEESTREND: ReportType.MERCHANTFEESTREND,
  MONTHLYSTATEMENT: ReportType.MONTHLYSTATEMENT,
  POINTOFACCEPTANCE: ReportType.POINTOFACCEPTANCE,
  POINTOFACCEPTANCETREND: ReportType.POINTOFACCEPTANCETREND,
  RFCAGING: ReportType.RFCAGING,
  RFCPERFORMANCE: ReportType.RFCPERFORMANCE,
  RFCPERFORMANCETREND: ReportType.RFCPERFORMANCETREND,
};
export function serializeReportTypeStringToEnum(
  rawReportType: string
): ReportType | undefined {
  if (rawReportType in reportTypeStringMap) {
    return reportTypeStringMap[rawReportType];
  }
  return undefined;
}

export const reportTypeFromString = (type: string): ReportType => {
  const t = ReportType[type as any];
  return t as unknown as ReportType;
};
export const reportTypeToFullName: { [key in ReportType]?: string } = {
  [ReportType.DAILYDEPOSITLOCATION]: "Daily Deposit Report",
  [ReportType.DISPUTEAGINGLOCATION]: "Weekly Chargeback Report",
  [ReportType.MONTHLYSTATEMENTLOCATION]: "Monthly Statement",
  [ReportType.INTERCHANGEPERFORMANCELOCATION]: "Monthly Interchange Report",
  [ReportType.DAILYDEPOSIT]: "Daily Deposit Report",
  [ReportType.DISPUTEAGING]: "Weekly Chargeback/RFC Aging Report",
  [ReportType.MONTHLYSTATEMENT]: "Monthly Statement",
  [ReportType.INTERCHANGEPERFORMANCE]: "Monthly Interchange Report",
  [ReportType.CHARGEBACKPERFORMANCE]: "Chargeback Performance By Location Report",
  [ReportType.CHARGEBACKPERFORMANCEREASON]: "Chargeback Performance By Reason Report",
  [ReportType.CLOSEDCHARGEBACKPERFORMANCE]:
    "Closed Chargeback Performance By Location Report",
  [ReportType.CLOSEDCHARGEBACKPERFORMANCEREASON]:
    "Closed Chargeback Performance By Reason Report",
  [ReportType.RFCPERFORMANCE]: "RFC Performance By Location Report",
  [ReportType.CARDTYPE]: "Card Brand Performance By Location Report",
  [ReportType.CARDPRODUCTACCEPTANCE]: "Card Product Acceptance By Location Report",
  [ReportType.DEBITCARDPRODUCTACCEPTANCE]:
    "Debit Card Product Acceptance By Location Report",
  [ReportType.DEPOSITACTIVITY]: "Deposit Activity By Location Report",
  [ReportType.INTERCHANGEDETAIL]: "Interchange Performance Detail Report",
  [ReportType.INTERCHANGEQUALIFICATIONPERFORMANCE]:
    "Interchange Qualification Performance By Location Report",
  [ReportType.CARDACCEPTANCEREGION]:
    "International Card Acceptance By Location Report",
  [ReportType.MERCHANTFEES]: "Merchant Fees By Location Report",
  [ReportType.POINTOFACCEPTANCE]: "Point of Acceptance Performance By Location Report",
  [ReportType.REFUNDAUDLOCATION]:
    "Refund Audit Exception By Location Report",
  [ReportType.EXCESSIVECHARGEBACK]: "Excessive Chargeback",
  [ReportType.EXCESSIVECREDIT]: "Excessive Refund Report",
  [ReportType.REFUNDAUDEXCEPTION]: "Refund Audit Exception Report",
  [ReportType.BYCARDTYPETREND]: "Trend - Card Brand Performance",
  [ReportType.CHARGEBACKPERFORMANCETREND]: "Trend - Chargeback Performance",
  [ReportType.CLOSEDCHARGEBACKPERFORMANCETREND]:
    "Trend - Closed Chargeback Performance",
  [ReportType.RFCPERFORMANCETREND]: "Trend - RFC Performance",
  [ReportType.CARDPRODUCTACCEPTANCETREND]: "Trend - Card Product Acceptance",
  [ReportType.DEBITCARDPRODUCTACCEPTANCETREND]:
    "Trend - Debit Card Product Acceptance",
  [ReportType.DEPOSITACTIVITYTREND]: "Trend - Deposit Activity",
  [ReportType.INTERCHANGEQUALIFICATIONTREND]:
    "Trend - Interchange Qualification Performance",
  [ReportType.CARDREGIONTREND]: "Trend - International Card Acceptance",
  [ReportType.MERCHANTFEESTREND]: "Trend - Merchant Fees",
  [ReportType.POINTOFACCEPTANCETREND]:
    "Trend - Point of Acceptance Performance",
  [ReportType.REFUNDAUDTREND]: "Trend -  Refund Audit Exceptions",
  [ReportType.MERCHANTREFERENCESHEET]: "Merchant Reference Sheet",
};

export const CORPORATE_REPORT_TYPES = [
  ReportType.DAILYDEPOSIT,
  ReportType.DISPUTEAGING,
  ReportType.MONTHLYSTATEMENT,
  ReportType.INTERCHANGEPERFORMANCE,
];

import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { CardType, reversedCardTypes } from "../models/card_type";
import { create999Merchant, Merchant } from "../models/merchant";
import { reversedTxType, TransactionType } from "../models/transaction";
import { DateService } from "../services/date_service";
import { AmountOperator } from "../services/transaction";
import { Tooltip } from "./tooltip";
import "./transaction_search_panel.scss";
import { EbocomSelect } from "./select";
import { ALL_DIVISIONS, ALL_OUTLETS } from "../constants";
import { Division, DivisionOutlet } from "../models/division";
import useSuperSearchParametersState from "../store/super_search_parameters_state";
import { useState } from "react";
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';
import { useRef } from "react";

/**
 * @see validation rules: patv/Postadvantage/Postadvantage/postadvantage/public_html/search-transaction.jsp#L180
 */

export interface CardOptions {
  cardName: string;
  tabIndex: number;
  isCheckedByDefault?: boolean;
  isDisabled?: boolean;
  cardValue: CardType;
}
export interface TransactionTypeOption {
  txType: TransactionType;
  tabIndex: number;
  isDisabled?: boolean;
  requiresMerchant?: boolean;
  mustBeIsolated?: boolean;
  isCheckedByDefault?: boolean;
  tooltipText?: string;
}
export interface TransactionSearchProps {
  defaultMerchantId?: string;
  isLoading?: boolean;
  transactionTypes?: TransactionTypeOption[];
  divisions?: Division[];
  outlets?: DivisionOutlet[];
  cardTypes?: CardOptions[];
  onSearch?(searchParameters: TransactionSearchPanelParameters): void;
  currentMerchant?: Merchant;
  onMerchantChange?(merchant: Merchant): void;
  onDivisionChange?(divisionNumber?: string): void;
  onReset?(): void;
  merchants?: Merchant[];
  disabledFields?: (keyof TransactionSearchPanelData)[];
  requiresCardNumberForDatesGreaterThan31DaysApart?: boolean;
  loadingComponent?: React.ReactNode;
  defaultBeginDate?: Date;
  defaultEndDate?: Date;
  defaultOutletNumber?: string;
  merchantRequired?: boolean;
  onPopState?(): void;
}

export interface TransactionSearchPanelData {
  merchantNumber?: { value: string; label: string };
  cardTypes: string[];
  divisionName?: string;
  transactionTypes?: string[];
  merchantName: { value: string; label: string };
  cardNumber?: string;
  outletName?: string;
  beginDate: string;
  endDate: string;
  amount?: number;
  amountOperator?: AmountOperator;
}

export interface TransactionSearchPanelParameters {
  merchantNumber: string;
  cardTypes?: CardType[];
  divisionName?: string;
  transactionTypes?: TransactionType[];
  merchantName: string;
  cardNumber?: string;
  outletName?: string;
  outletNumber?: string;
  beginDate: Date;
  divisionNumber?: string;
  endDate: Date;
  amount?: number;
  amountOperator?: AmountOperator;
}

enum FIELD_NAME {
  MERCHANT_NUMBER = "merchantNumber",
  MERCHANT_NAME = "merchantName",
  CARD_NUMBER = "cardNumber",
  AMOUNT = "amount",
  AMOUNT_OPERATOR = "amountOperator",
  BEGIN_DATE = "beginDate",
  END_DATE = "endDate",
  DIVISION = "division",
  OUTLET = "outlet",
  TRANSACTION_TYPE = "transactionTypes",
  CARD_TYPE = "cardTypes",
};

export function TransactionSearchPanel({
  merchants = [],
  cardTypes = [],
  divisions = [],
  transactionTypes = [],
  outlets = [],
  defaultMerchantId,
  merchantRequired = false,
  onMerchantChange = () => { },
  onDivisionChange = () => { },
  onReset = () => { },
  currentMerchant,
  isLoading = false,
  loadingComponent: LoadingComponent,
  disabledFields = [],
  requiresCardNumberForDatesGreaterThan31DaysApart = true,
  onSearch,
  defaultBeginDate,
  defaultEndDate,
  defaultOutletNumber,
  onPopState,
}: TransactionSearchProps) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    getValues,
    clearErrors,
    setValue,
    reset,
    watch,
  } = useForm({
    reValidateMode: "onSubmit",
    mode: "onSubmit",
  });

  const tranTypeErrorTooltipRef = useRef<TooltipRefProps>(null);
  const merchantNumberErrorTooltipRef = useRef<TooltipRefProps>(null);
  const cardNumberErrorTooltipRef = useRef<TooltipRefProps>(null);
  const beginDateErrorTooltipRef = useRef<TooltipRefProps>(null);
  const endDateErrorTooltipRef = useRef<TooltipRefProps>(null);
  const merchantNameErrorTooltipRef = useRef<TooltipRefProps>(null);

  useEffect(() => {
    if (errors.transactionTypes) tranTypeErrorTooltipRef.current?.open();
    if (errors.merchantName) merchantNameErrorTooltipRef.current?.open();
    if (errors.merchantNumber) merchantNumberErrorTooltipRef.current?.open();
    if (errors.cardNumber) cardNumberErrorTooltipRef.current?.open();
    if (errors.beginDate) beginDateErrorTooltipRef.current?.open();
    if (errors.endDate) endDateErrorTooltipRef.current?.open();
  }, [
    errors.transactionTypes,
    errors.merchantName,
    errors.merchantNumber,
    errors.cardNumber,
    errors.beginDate,
    errors.endDate
  ])

  const [wasBackButtonPressed, setWasBackButtonPressed] = useState(false);

  const divisionName = watch(FIELD_NAME.DIVISION);

  const { searchParams, formFillingInProgress, shouldFillFromWithPreviousParameters }: {
    searchParams: TransactionSearchPanelParameters | null,
    formFillingInProgress: boolean,
    shouldFillFromWithPreviousParameters: boolean
  } = useSuperSearchParametersState((state) => ({
    searchParams: state.params,
    formFillingInProgress: state.formFillingInProgress,
    shouldFillFromWithPreviousParameters: state.shouldFillWithPreviousParameters
  }));

  const {
    clearSavedFormState,
    setFormFillingState,
  } = useSuperSearchParametersState(
    (state) => ({
      clearSavedFormState: state.resetParamsState,
      setFormFillingState: state.setFormFillingState,
    })
  )

  const getMerchantByNumber = useCallback(
    (merchantNumber: string) => {
      return merchants.find((m) => m.number === merchantNumber);
    },
    [merchants]
  );
  useEffect(() => {
    onDivisionChange(divisionName);
  }, [divisionName, onDivisionChange]);
  const validateAtLeastOneTxType = () => {
    const txTypes = getValues(FIELD_NAME.TRANSACTION_TYPE);
    return txTypes.length > 0;
  };
  function transactionTypeOptions(txType: TransactionTypeOption[]) {
    return (
      <div style={{ height: "100%" }}>
        <fieldset
          disabled={txType.some((t) => t.isDisabled)}
          style={{ height: "100%" }}
        >
          <div
            style={{ height: "100%" }}
            data-tooltip-id={!!errors.transactionTypes ? "error-tooltip-tran-type" : undefined}
            data-tooltip-content={
              (errors.transactionTypes &&
                errors.transactionTypes.type === "atLeastOneSelected") ?
                'Select a Tran Type to perform search' :
                (errors.transactionTypes &&
                  errors.transactionTypes?.type === "onlyOneAllowed") ?
                  `${transactionTypes.find(
                    (txType) =>
                      getValues("transactionTypes") && txType.mustBeIsolated
                  )?.txType
                  } may not be selected with any other transaction type` :
                  undefined
            }
            className={`is-flex is-flex-direction-column is-justify-content-space-around${!!errors.transactionTypes ? ' input-error' : ''}`}
          >
            {txType.map(
              ({ txType, isCheckedByDefault, tooltipText, tabIndex }, index) => {
                return (
                  <div key={`${txType}${tooltipText}`}>
                    <Tooltip.Container>
                      <input
                        tabIndex={tabIndex}
                        id={`tx-type-check-${txType}`}
                        type="checkbox"
                        data-testid={txType}
                        defaultChecked={isCheckedByDefault ?? false}
                        value={txType}
                        style={{
                          verticalAlign: "middle",
                        }}
                        {...register(FIELD_NAME.TRANSACTION_TYPE, {
                          validate: {
                            onlyOneAllowed: () => {
                              const selectedTxTypes: string[] = getValues(
                                FIELD_NAME.TRANSACTION_TYPE
                              );
                              const result = transactionTypes.some((t) => {
                                const isTheTransactionTypeSelected =
                                  selectedTxTypes?.includes?.(t.txType);
                                const isMoreThanOneTransactionTypeSelected =
                                  selectedTxTypes.length > 1;
                                return (
                                  !!t.mustBeIsolated &&
                                  !!isTheTransactionTypeSelected &&
                                  !!isMoreThanOneTransactionTypeSelected
                                );
                              });
                              return !result;
                            },
                            atLeastOneSelected: validateAtLeastOneTxType,
                          },
                        })}
                      />
                      <label
                        htmlFor={`tx-type-check-${txType}`}
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          verticalAlign: "middle",
                        }}
                        className="is-uppercase"
                      >
                        {txType}
                      </label>
                      {tooltipText && <Tooltip>{tooltipText}</Tooltip>}
                    </Tooltip.Container>
                  </div>
                );
              }
            )}
          </div>
        </fieldset>
        <ReactTooltip
          id="error-tooltip-tran-type"
          ref={tranTypeErrorTooltipRef}
          opacity={1}
          style={{
            color: '#fff',
            backgroundColor: '#cc0f35',
            fontSize: '12px',
          }}
        />
      </div>
    );
  }

  const handleSearch = (data: { [key: string]: any }) => {
    const serializedFormData: TransactionSearchPanelParameters = {
      ...data,
      merchantName: data[FIELD_NAME.MERCHANT_NAME].label ?? "",
      merchantNumber: data[FIELD_NAME.MERCHANT_NUMBER]?.value ?? "",
      cardTypes: (data[FIELD_NAME.CARD_TYPE] ?? []).map(
        (cardType: string) => reversedCardTypes[cardType]
      ),
      transactionTypes: data[FIELD_NAME.TRANSACTION_TYPE]?.map?.(
        (txType: string) => reversedTxType[txType]
      ),
      beginDate: new Date(DateService.resetTimeZoneInDateString(data[FIELD_NAME.BEGIN_DATE])),
      endDate: new Date(DateService.resetTimeZoneInDateString(data[FIELD_NAME.END_DATE])),
      divisionNumber: data[FIELD_NAME.DIVISION],
      outletNumber: data[FIELD_NAME.OUTLET],
      cardNumber: (data[FIELD_NAME.CARD_NUMBER] as string)?.replaceAll(
        "-",
        ""
      ),
      /*amountOperator: !!data[FIELD_NAME.AMOUNT]
        ? data[FIELD_NAME.AMOUNT_OPERATOR]
        : undefined,*/
      amountOperator: data[FIELD_NAME.AMOUNT_OPERATOR], 
      outletName:
        data[FIELD_NAME.OUTLET] === ALL_OUTLETS
          ? undefined
          : data[FIELD_NAME.OUTLET],
    };
    onSearch?.(serializedFormData);
  };
  const selectedDivision = watch(FIELD_NAME.DIVISION);
  const selectedOutlet = watch(FIELD_NAME.OUTLET);

  // Select proper division and outlet after getting back to Super Search
  useEffect(() => {
    if (!formFillingInProgress) {
      setFieldData(FIELD_NAME.DIVISION, "divisionName", searchParams?.divisionNumber ?? ALL_DIVISIONS)
      setFieldData(FIELD_NAME.OUTLET, "outletName", searchParams?.outletNumber ?? ALL_OUTLETS);
    }
    // eslint-disable-next-line
  }, [formFillingInProgress])

  useEffect(() => {
    if (!formFillingInProgress && defaultOutletNumber && selectedOutlet) {
      const foundOutlet = outlets.find(outlet => outlet.number === selectedOutlet)
      const foundDivision = divisions.find(division => division.number === foundOutlet?.divisionNumber)
      if (foundDivision) {
        setFieldData(FIELD_NAME.DIVISION, "divisionName", foundDivision?.number);
        setFieldData(FIELD_NAME.OUTLET, "outletName", selectedOutlet);
      }
    }
    // eslint-disable-next-line
  }, [outlets, defaultOutletNumber])

  const merchantNumberSelectValues = useMemo(
    () =>
      merchants.map((m) => ({
        value: m.number,
        label: m.number,
      })),
    [merchants]
  );
  const merchantNameSelectValues = useMemo(
    () => {
      const sortedMerchants = merchants.filter((merchant) => merchant.number !== '9999999999')
        .sort((a, b) => a.name.localeCompare(b.name))
      return [create999Merchant(), ...sortedMerchants].map((m) => ({
        value: m.number,
        label: m.name,
      }))
    },
    [merchants]
  );

  useEffect(() => {
    const currentMerchantNumber = getValues([
      FIELD_NAME.MERCHANT_NUMBER,
      FIELD_NAME.MERCHANT_NAME,
    ]);
    if (
      !currentMerchant ||
      (currentMerchant.number === currentMerchantNumber?.[0]?.value &&
        currentMerchant.number === currentMerchantNumber?.[1]?.value)
    )
      return;
    const number = merchantNumberSelectValues.find(
      ({ value }) => value === currentMerchant.number
    );
    const name = merchantNameSelectValues.find(
      ({ value }) => value === currentMerchant.number
    );
    reset(
      {
        [FIELD_NAME.MERCHANT_NAME]: number,
        [FIELD_NAME.MERCHANT_NUMBER]: name,
      },
      {
        keepValues: true
      }
    );
    clearErrors(["merchantNumber", "merchantName"]);
    // eslint-disable-next-line
  }, [currentMerchant, merchantNumberSelectValues, merchantNameSelectValues]);
  const validateDates = (): boolean => {
    clearErrors([
      FIELD_NAME.BEGIN_DATE,
      FIELD_NAME.END_DATE,
      FIELD_NAME.CARD_NUMBER,
    ]);
    const beginDate = new Date(getValues(FIELD_NAME.BEGIN_DATE));
    beginDate.setHours(0, 0, 0, 0);
    const endDate = new Date(getValues(FIELD_NAME.END_DATE));
    endDate.setHours(0, 0, 0, 0);
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    const date24MonthsAgo = new Date(todaysDate.setMonth(todaysDate.getMonth() - 24));
    date24MonthsAgo.setHours(0, 0, 0, 0);

    if (beginDate.getTime() < date24MonthsAgo.getTime()) {
      setError(FIELD_NAME.BEGIN_DATE, {
        message: "Begin date must be within the last 24 months",
        type: "24Months",
      });
    }
    const endDateIsAfterBeginDate = beginDate.getTime() <= endDate.getTime();

    if (!endDateIsAfterBeginDate) {
      setError(FIELD_NAME.BEGIN_DATE, {
        message: "End date must be after begin date",
        type: "validate",
      });
      setError(FIELD_NAME.END_DATE, {
        message: "End date must be after begin date",
        type: "validate",
      });
    } else if (
      requiresCardNumberForDatesGreaterThan31DaysApart &&
      areDatesGreaterThan31DaysApart() &&
      !getValues(FIELD_NAME.CARD_NUMBER)
    ) {
      setError(FIELD_NAME.CARD_NUMBER, { type: "required" });
    }
    return endDateIsAfterBeginDate;
  };
  const areDatesGreaterThan31DaysApart = (): boolean => {
    return (
      Date.parse(getValues(FIELD_NAME.END_DATE)) -
      Date.parse(getValues(FIELD_NAME.BEGIN_DATE)) >
      31 * 1000 * 60 * 60 * 24
    );
  };
  function cardTypeOptions(options: CardOptions[] = []) {
    return (
      <fieldset
        disabled={disabledFields.includes("cardTypes")}
        style={{ height: "100%" }}
      >
        <div
          className="is-flex is-justify-content-space-around is-flex-direction-column"
          style={{ height: "100%" }}
        >
          {options.map(
            ({ cardValue, cardName, tabIndex, isCheckedByDefault = false }) => {
              return (
                <div key={`${cardName}${cardValue}`}>
                  <input
                    tabIndex={tabIndex}
                    id={`card-type-check-${cardName}`}
                    type="checkbox"
                    data-testid={cardName}
                    defaultChecked={isCheckedByDefault}
                    value={cardValue}
                    style={{ verticalAlign: "middle" }}
                    {...register(FIELD_NAME.CARD_TYPE)}
                  />
                  <label
                    htmlFor={`card-type-check-${cardName}`}
                    className="is-uppercase"
                    style={{
                      verticalAlign: "middle",
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {cardName}
                  </label>
                </div>
              );
            }
          )}
        </div>
      </fieldset>
    );
  }
  const defaultMerchantName = merchantNameSelectValues.find(
    (m) => m.value === defaultMerchantId
  );
  const defaultMerchantNumber = merchantNumberSelectValues.find(
    (m) => m.value === defaultMerchantId
  );
  useEffect(() => {
    const defaultMerchantName = merchantNameSelectValues.find(
      (m) => m.value === defaultMerchantId
    );
    const defaultMerchantNumber = merchantNumberSelectValues.find(
      (m) => m.value === defaultMerchantId
    );
    setValue("merchantName", defaultMerchantName);
    setValue("merchantNumber", defaultMerchantNumber);
  }, [
    defaultMerchantId,
    setValue,
    merchantNameSelectValues,
    merchantNumberSelectValues,
  ]);

  const setFieldData = (
    fieldName: FIELD_NAME,
    disabledFieldName: (keyof TransactionSearchPanelData),
    value: TransactionSearchPanelParameters[keyof TransactionSearchPanelParameters]
  ) => {
    !disabledFields.includes(disabledFieldName) &&
      setValue(fieldName, value);
  }

  const getDefaultCardTypes = (): CardType[] =>
    cardTypes.map(cardType => cardType.isCheckedByDefault ? cardType.cardValue : null).filter(cardType => cardType !== null) as CardType[];

  const getDefaultTransactionTypes = (): TransactionType[] =>
    transactionTypes.map(transactionType => transactionType.isCheckedByDefault ? transactionType.txType : null)
      .filter(transactionType => !!transactionType) as TransactionType[];

  const fillFormWithParamsData = useCallback((setDefaultValues: boolean = false, merchantNumberToBeSet?: string) => {
    console.log('filling form with params data');
    const searchParamsToBeSet = setDefaultValues ? undefined : searchParams;

    setFieldData(FIELD_NAME.BEGIN_DATE, FIELD_NAME.BEGIN_DATE, searchParamsToBeSet?.beginDate ?
      DateService.formatForInput(searchParamsToBeSet?.beginDate) : defaultBeginDate ?
        DateService.formatForInput(defaultBeginDate) : undefined);
    setFieldData(FIELD_NAME.END_DATE, FIELD_NAME.END_DATE, searchParamsToBeSet?.endDate ?
      DateService.formatForInput(searchParamsToBeSet?.endDate) : defaultEndDate ?
        DateService.formatForInput(defaultEndDate) : undefined);
    setFieldData(FIELD_NAME.CARD_NUMBER, FIELD_NAME.CARD_NUMBER, searchParamsToBeSet?.cardNumber || '');
    setFieldData(FIELD_NAME.DIVISION, "divisionName", searchParamsToBeSet?.divisionNumber || ALL_DIVISIONS);
    setFieldData(FIELD_NAME.CARD_TYPE, FIELD_NAME.CARD_TYPE, searchParamsToBeSet?.cardTypes || getDefaultCardTypes());
    setFieldData(FIELD_NAME.TRANSACTION_TYPE, FIELD_NAME.TRANSACTION_TYPE, searchParamsToBeSet?.transactionTypes || getDefaultTransactionTypes());
    setFieldData(
      FIELD_NAME.MERCHANT_NAME,
      FIELD_NAME.MERCHANT_NAME,
      searchParamsToBeSet?.merchantName || defaultMerchantName as TransactionSearchPanelParameters[keyof TransactionSearchPanelParameters]
    );
    console.log(merchantNumberToBeSet ?? searchParamsToBeSet?.merchantNumber ?? defaultMerchantNumber);
    setFieldData(
      FIELD_NAME.MERCHANT_NUMBER,
      FIELD_NAME.MERCHANT_NUMBER,
      merchantNumberToBeSet ?? searchParamsToBeSet?.merchantNumber ?? defaultMerchantNumber as TransactionSearchPanelParameters[keyof TransactionSearchPanelParameters]
    );
    setFieldData(FIELD_NAME.AMOUNT, FIELD_NAME.AMOUNT, searchParamsToBeSet?.amount || '');
    setFieldData(FIELD_NAME.AMOUNT_OPERATOR, FIELD_NAME.AMOUNT_OPERATOR, searchParamsToBeSet?.amountOperator || AmountOperator.Equal);
    setFieldData(FIELD_NAME.OUTLET, "outletName", searchParamsToBeSet?.outletNumber || ALL_OUTLETS);
  },
    // eslint-disable-next-line
    [
      defaultBeginDate,
      defaultEndDate,
      defaultMerchantName,
      defaultMerchantNumber,
      searchParams,
    ]);

  window.onpopstate = (e: PopStateEvent) => {
    if (document.location.pathname === '/transactions/search')
      setWasBackButtonPressed(true);
  }

  useEffect(() => {
    if (searchParams && shouldFillFromWithPreviousParameters && wasBackButtonPressed) {
      setFormFillingState(true);
      fillFormWithParamsData();
      if (onPopState) onPopState();
      setWasBackButtonPressed(false);
      // setSuperSearchParametersRoutes('');
    }
    // eslint-disable-next-line
  }, [
    shouldFillFromWithPreviousParameters,
    searchParams,
    wasBackButtonPressed
  ])

  const divisionFieldProps = register(FIELD_NAME.DIVISION);
  const outletFieldProps = register(FIELD_NAME.OUTLET);

  return (
    <form>
      <div className="is-flex is-flex-direction-column">
        <table
          className="is-bordered table transaction-search-panel m-0"
          style={{ width: 950 }}
        >
          <colgroup>
            <col style={{ width: 290 }} />
            <col style={{ width: 185 }} />
            <col style={{ width: 110 }} />
            <col style={{ width: 185 }} />
            <col style={{ width: 60 }} />
            <col style={{ width: 120 }} />
          </colgroup>
          <tbody>
            <tr className="has-text-centered">
              <th className="is-uppercase has-text-white">Merchant Number</th>
              <th className="has-text-white">
                <div className="is-uppercase">Card Number</div>
                <div>only last 4 digits required</div>
              </th>
              <th className="is-uppercase has-text-white">Begin Date</th>
              <th className="is-uppercase has-text-white">Division</th>
              <th className="is-uppercase has-text-white">
                Card
                <br />
                Type
              </th>
              <th className="is-uppercase has-text-white">Tran Type</th>
            </tr>
            <tr>
              <td>
                <div
                  data-tooltip-id={!!errors.merchantNumber ? "error-tooltip-merchant-number" : undefined}
                  data-tooltip-content={
                    !!errors.merchantNumber ? errors.merchantNumber.message as string : undefined
                  }
                  className={`field${!!errors.merchantNumber ? ' input-error' : ''}`}
                  style={{
                    height: 23,
                    borderRadius: 5,
                  }}
                >
                  <Controller
                    name={FIELD_NAME.MERCHANT_NUMBER}
                    control={control}
                    defaultValue={defaultMerchantNumber}
                    rules={{
                      required: merchantRequired,
                      validate: {
                        cbRequiresNon999Merchant: () => {
                          const txTypes: string[] =
                            getValues(FIELD_NAME.TRANSACTION_TYPE) ?? [];
                          const merchantNumber = getValues(
                            FIELD_NAME.MERCHANT_NUMBER
                          );
                          const allMerchant = create999Merchant();
                          const hasCb =
                            Array.isArray(txTypes) &&
                            txTypes.findIndex((t) => {
                              const reversedType = reversedTxType[t];
                              return (
                                reversedType === TransactionType.CBLifeCycle
                              );
                            }) > -1;
                          const is999 =
                            merchantNumber?.value === allMerchant.number;
                          if (hasCb && is999) {
                            return "The selected transaction type requires a merchant number other than '9999999999'";
                          }
                          return true;
                        },
                        txTypeRequiresMerchant: () => {
                          const merchantName = getValues(
                            FIELD_NAME.MERCHANT_NAME
                          );
                          const merchantNumber = getValues(
                            FIELD_NAME.MERCHANT_NUMBER
                          );
                          const watchedTxTypes: TransactionTypeOption[] =
                            getValues(FIELD_NAME.TRANSACTION_TYPE)?.map?.(
                              (t: string) =>
                                transactionTypes.find((tr) => tr.txType === t)
                            ) ?? [];
                          for (const { requiresMerchant } of watchedTxTypes) {
                            if (
                              requiresMerchant &&
                              !merchantName &&
                              !merchantNumber
                            ) {
                              return "The selected transaction type requires a merchant name or number";
                            }
                          }
                          return true;
                        },
                      },
                    }}
                    render={({ field: { onChange, ...field } }) => (
                      <div
                        style={{ width: "100%" }}
                      >
                        <EbocomSelect
                          tabIndex={-1}
                          data-testid="merchant-number-dropdown"
                          isSearchable={true}
                          className={`merchant-select`}
                          options={merchantNumberSelectValues}
                          onChange={(value) => {
                            const merchant = getMerchantByNumber(value.value);
                            onChange(value.value);
                            if (merchant) {
                              fillFormWithParamsData(true, value.value);
                              onMerchantChange(merchant);
                            }
                          }}
                          {...field}
                        />
                      </div>
                    )}
                  />
                </div>
                <ReactTooltip
                  id="error-tooltip-merchant-number"
                  ref={merchantNumberErrorTooltipRef}
                  opacity={1}
                  style={{
                    color: '#fff',
                    backgroundColor: '#cc0f35',
                    fontSize: '12px',
                  }}
                />
              </td>
              <td>
                <div className="field">
                  <Tooltip.Container>
                    <input
                      tabIndex={1}
                      style={{ width: "100%" }}
                      type="text"
                      className={!!errors.cardNumber ? 'input-error' : undefined}
                      data-tooltip-id={!!errors.cardNumber ? "error-tooltip-card-number" : undefined}
                      data-tooltip-content={
                        (errors.cardNumber && errors.cardNumber.type === "required") ?
                          'Enter a card number to search beyond 31 days' :
                          undefined
                      }
                      disabled={disabledFields.includes(FIELD_NAME.CARD_NUMBER)}
                      data-testid="card-number-input"
                      {...register(FIELD_NAME.CARD_NUMBER, {
                        required:
                          requiresCardNumberForDatesGreaterThan31DaysApart
                            ? areDatesGreaterThan31DaysApart()
                            : false,
                      })}
                    />
                    {!disabledFields.includes(FIELD_NAME.CARD_NUMBER) && (
                      <Tooltip title="Card Number Search Options">
                        <table
                          className="table is-borderless"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td>Last 4 digits only</td>
                              <td>1234</td>
                            </tr>
                            <tr>
                              <td> First 4-6 digits*last 4 digts</td>
                              <td>49900*1234</td>
                            </tr>
                            <tr>
                              <td>Full Card Number</td>
                              <td>49990000000001234</td>
                            </tr>
                          </tbody>
                        </table>
                      </Tooltip>
                    )}
                  </Tooltip.Container>
                  <ReactTooltip
                    id="error-tooltip-card-number"
                    ref={cardNumberErrorTooltipRef}
                    opacity={1}
                    style={{
                      color: '#fff',
                      backgroundColor: '#cc0f35',
                      fontSize: '12px',
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="field">
                  <input
                    tabIndex={4}
                    type="date"
                    data-testid="begin-date-input"
                    max={"2099-12-31"}
                    style={{ width: "100%" }}
                    className={!!errors.beginDate ? 'input-error' : undefined}
                    data-tooltip-id={!!errors.beginDate ? "error-tooltip-begin-date" : undefined}
                    data-tooltip-content={
                      !!errors.beginDate ?
                        ((errors.beginDate.type === '24Months') ?
                          errors.beginDate.message as string :
                          (errors.beginDate.type === "validate") ?
                            'The begin date must be before the end date.' : '') :
                        undefined
                    }
                    defaultValue={
                      defaultBeginDate
                        ? DateService.formatForInput(defaultBeginDate)
                        : undefined
                    }
                    {...register(FIELD_NAME.BEGIN_DATE, {
                      required: true,
                      validate: validateDates,
                    })}
                  />
                  <ReactTooltip
                    id="error-tooltip-begin-date"
                    ref={beginDateErrorTooltipRef}
                    opacity={1}
                    style={{
                      color: '#fff',
                      backgroundColor: '#cc0f35',
                      fontSize: '12px',
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="field">
                  <select
                    tabIndex={6}
                    data-testid="division-name-dropdown"
                    disabled={disabledFields.includes("divisionName")}
                    style={{ width: "100%", maxWidth: "unset" }}
                    {...divisionFieldProps}
                    onChange={(e) => {
                      divisionFieldProps.onChange(e);
                      const selectedDivision = e.target.value;
                      if ((selectedDivision && selectedDivision !== ALL_DIVISIONS) && (selectedOutlet && selectedOutlet !== ALL_OUTLETS)) {
                        const foundOutlet = outlets.find(outlet => outlet.number === selectedOutlet)
                        const foundDivision = divisions.find(division => division.number === foundOutlet?.divisionNumber)
                        if ((foundDivision?.number !== selectedDivision) && (selectedDivision !== ALL_DIVISIONS)) {
                          setFieldData(FIELD_NAME.OUTLET, "outletName", ALL_OUTLETS);
                        }
                      }
                    }}
                  >
                    {divisionNameOptions(divisions)}
                  </select>
                </div>
              </td>
              <td rowSpan={3} style={{ height: "inherit" }}>
                {cardTypeOptions(cardTypes)}
              </td>
              <td rowSpan={3} style={{ height: "inherit" }}>
                {transactionTypeOptions(transactionTypes)}
              </td>
            </tr>
            <tr className="has-text-centered">
              <th className="is-uppercase has-text-white">Merchant Name</th>
              <th className="is-uppercase has-text-white">Amount</th>
              <th className="is-uppercase has-text-white">End Date</th>
              <th className="is-uppercase has-text-white">Outlet Name</th>
            </tr>
            <tr>
              <td>
                <div className={`field${!!errors.merchantName ? 'input-error' : ''}`}
                  data-tooltip-id={!!errors.merchantName ? "error-tooltip-merchant-name" : undefined}
                  data-tooltip-content={
                    (!!errors.merchantName &&
                      errors.merchantName.type === "required") ?
                      "Merchant name musn't be empty" :
                      undefined
                  }
                  style={{
                    height: 23,
                    borderRadius: 5,
                  }}
                >
                  <Controller
                    name={FIELD_NAME.MERCHANT_NAME}
                    control={control}
                    defaultValue={defaultMerchantName}
                    rules={{
                      required: merchantRequired,
                    }}
                    render={({ field: { onChange, ...field } }) => (
                      <div
                        style={{ width: "100%" }}
                      >
                        <EbocomSelect
                          tabIndex={-1}
                          className={`merchant-select`}
                          options={merchantNameSelectValues}
                          isSearchable={true}
                          onChange={(value) => {
                            const merchant = getMerchantByNumber(value.value);
                            onChange(value.value);
                            if (merchant) {
                              fillFormWithParamsData(true);
                              onMerchantChange(merchant);
                            }
                          }}
                          {...field}
                          data-testid={"merchant-name-dropdown"}
                        />
                      </div>
                    )}
                  />
                </div>
                <ReactTooltip
                  id="error-tooltip-merchant-name"
                  ref={merchantNameErrorTooltipRef}
                  opacity={1}
                  style={{
                    color: '#fff',
                    backgroundColor: '#cc0f35',
                    fontSize: '12px',
                  }}
                />
              </td>
              <td>
                <div className="field">
                  <div className="is-flex is-flex-direction-row">
                    <input
                      tabIndex={2}
                      type="text"
                      disabled={disabledFields.includes("amount")}
                      data-testid="amount-input"
                      style={{ width: "100%" }}
                      {...register(FIELD_NAME.AMOUNT, {
                        valueAsNumber: true,
                      })}
                    />
                    <select
                      tabIndex={3}
                      disabled={disabledFields.includes("amountOperator")}
                      {...register(FIELD_NAME.AMOUNT_OPERATOR)}
                    >
                      <option value={AmountOperator.Equal}>=</option>
                      <option value={AmountOperator.LessThanEq}>&lt;=</option>
                      <option value={AmountOperator.GreaterThanEq}>
                        &gt;=
                      </option>
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <div className="field">
                  <input
                    tabIndex={5}
                    type="date"
                    data-testid="end-date-input"
                    data-tooltip-id={!!errors.beginDate ? "error-tooltip-end-date" : undefined}
                    data-tooltip-content={
                      (errors.beginDate &&
                        errors.beginDate.type === "validate") ?
                        'The begin date must be before the end date.' :
                        undefined
                    }
                    className={`${!!errors.beginDate ? 'input-error' : ''}`}
                    defaultValue={
                      defaultEndDate
                        ? DateService.formatForInput(defaultEndDate)
                        : undefined
                    }
                    {...register(FIELD_NAME.END_DATE, {
                      validate: validateDates,
                    })}
                  />
                  <ReactTooltip
                    id="error-tooltip-end-date"
                    ref={endDateErrorTooltipRef}
                    opacity={1}
                    style={{
                      color: '#fff',
                      backgroundColor: '#cc0f35',
                      fontSize: '12px',
                    }}
                  />
                </div>
              </td>
              <td>
                <div className="field">
                  <select
                    tabIndex={7}
                    data-testid="outlet-name-dropdown"
                    disabled={disabledFields.includes("outletName")}
                    defaultValue={defaultOutletNumber ?? undefined}
                    style={{ width: "100%", maxWidth: "unset" }}
                    {...outletFieldProps}
                    onChange={(e) => {
                      outletFieldProps.onChange(e);
                      const selectedOutlet = e.target.value;
                      if (selectedOutlet && selectedOutlet !== ALL_OUTLETS) {
                        const foundOutlet = outlets.find(outlet => outlet.number === selectedOutlet)
                        const foundDivision = divisions.find(division => division.number === foundOutlet?.divisionNumber)
                        if (foundDivision) {
                          setFieldData(FIELD_NAME.DIVISION, "divisionName", foundDivision?.number);
                        }
                      }
                    }}
                  >
                    {outletNameDropdown(
                      !selectedDivision || selectedDivision === ALL_DIVISIONS
                        ? outlets
                        : outlets.filter(
                          (o) =>
                            (o as any).divisionNumber === selectedDivision
                        )
                    )}
                  </select>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="is-flex is-flex-direction-row is-justify-content-flex-end">
          <div>{isLoading && LoadingComponent}</div>
          <div className="search-button-bar">
            <button
              tabIndex={18}
              className="is-uppercase ie-styled"
              data-testid="search-button"
              onClick={handleSubmit(handleSearch)}
            >
              Submit
            </button>
            <button
              tabIndex={19}
              className="is-uppercase ie-styled"
              data-testid="reset-button"
              type="reset"
              onClick={() => {
                clearSavedFormState();
                reset(
                  {
                    [FIELD_NAME.MERCHANT_NAME]: currentMerchant?.number,
                    [FIELD_NAME.MERCHANT_NUMBER]: currentMerchant?.number,
                  },
                  {
                    keepDefaultValues: true,
                  }
                );
                onReset();
              }}
              disabled={isLoading}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

function outletNameDropdown(outlets: Pick<DivisionOutlet, "name" | "number">[]) {
  return [{ name: ALL_OUTLETS, number: undefined }, ...outlets].map(
    (outlet) => (
      <option key={`${outlet.number}${outlet.name}`} value={outlet.number}>
        {outlet.name}
      </option>
    )
  );
}

function divisionNameOptions(divisions: Pick<Division, "name" | "number">[]) {
  return [{ name: ALL_DIVISIONS, number: ALL_DIVISIONS }, ...divisions].map(
    (division) => (
      <option key={`${division.name}${division.number}`} value={division.number}>
        {division.name}
      </option>
    )
  );
}

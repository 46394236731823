import {
  DepositActivity,
  DepositActivityParams,
  TransmittedActivity,
  TransmittedActivitySearchParameters,
  TransmittedService,
} from "../services/transmitted";
import { mockDepositDetail } from "./deposit_detail.mock";
import faker from "faker";

export const mockTransmittedActivity = (): TransmittedActivity => {
  return {
    isEmpty: faker.datatype.number(10) % 3 === 0,
    merchantDetails: {
      pageSize: 30,
      offset: 0,
      items: Array.from(new Array(10), () => mockDepositDetail()),
      totalResults: 20,
    },
    summaryDetails: {
      pageSize: 30,
      offset: 0,
      items: Array.from(new Array(10), () => mockDepositDetail()),
      totalResults: 20,
    },
    batchDetails: {
      pageSize: 30,
      offset: 0,
      items: Array.from(new Array(10), () => mockDepositDetail()),
      totalResults: 20,
    },
    cardTypeDetails: {
      pageSize: 30,
      offset: 0,
      items: Array.from(new Array(10), () => mockDepositDetail()),
      totalResults: 20,
    },
    dateDetails: {
      pageSize: 30,
      offset: 0,
      items: Array.from(new Array(10), () => mockDepositDetail()),
      totalResults: 20,
    },
    outletDetails: {
      pageSize: 30,
      offset: 0,
      items: Array.from(new Array(10), () => mockDepositDetail()),
      totalResults: 20,
    },
    transactionDetails: {
      pageSize: 30,
      offset: 0,
      items: Array.from(new Array(10), () => mockDepositDetail()),
      totalResults: 20,
    },
  };
};

export function mockTransmittedService(): TransmittedService {
  return {
    async getTransmittedReport() {
      return [new Blob(), "testreport.xls"];
    },
    async getDepositReport() {
      return [new Blob(), "testreport.xls"];
    },
    getTransmittedActivity: async (
      parameters: TransmittedActivitySearchParameters
    ) => {
      return new Promise((resolve) =>
        setTimeout(() => resolve(mockTransmittedActivity()), 2000)
      );
    },
    getDepositActivity: async (
      parameters: DepositActivityParams
    ): Promise<DepositActivity> => {
      return {
        isEmpty: faker.datatype.number(10) % 3 === 0,
        dateDetails: {
          offset: 0,
          pageSize: 10,
          items: Array.from(new Array(10), () => mockDepositDetail()),
          totalResults: 30,
        },
        chargebackDetails: {
          offset: 0,
          pageSize: 10,
          items: Array.from(new Array(10), () => mockDepositDetail()),
          totalResults: 30,
        },
        merchantDetails: {
          offset: 0,
          pageSize: 10,
          items: Array.from(new Array(10), () => mockDepositDetail()),
          totalResults: 30,
        },
        outletDetails: {
          offset: 0,
          pageSize: 10,
          items: Array.from(new Array(10), () => mockDepositDetail()),
          totalResults: 30,
        },
        summaryDetails: {
          pageSize: 10,
          items: Array.from(new Array(10), () => mockDepositDetail()),
          offset: 0,
          totalResults: 30,
        },
        transactionDetails: {
          pageSize: 10,
          offset: 0,
          items: Array.from(new Array(10), () => mockDepositDetail()),
          totalResults: 30,
        },
      };
    },
  };
}
export const exampleJson = {
  merchantDetails: [
    {
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "98,887",
      grossVolume: "13,678,928.93",
      creditItems: "2,555",
      creditVolume: "-649,397.64",
      visaGrossItems: "68,807",
      visaGrossVolume: "9,112,011.28",
      visaCreditItems: "1,711",
      visaCreditVolume: "-429,100.60",
      visaNetSales: "70,518",
      visaNetVolume: "8,682,910.68",
      mcGrossItems: "24,227",
      mcGrossVolume: "3,587,258.50",
      mcCreditItems: "658",
      mcCreditVolume: "-170,138.67",
      mcNetSales: "24,885",
      mcNetVolume: "3,417,119.83",
      amexGrossItems: "2,087",
      amexGrossVolume: "64,452.50",
      amexCreditItems: "9",
      amexCreditVolume: "-247.49",
      amexNetSales: "2,096",
      amexNetVolume: "64,205.01",
      discoverGrossItems: "3,766",
      discoverGrossVolume: "915,206.65",
      discoverCreditItems: "177",
      discoverCreditVolume: "-49,910.88",
      discoverNetSales: "3,943",
      discoverNetVolume: "865,295.77",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "101,442",
      netVolume: "13,029,531.29",
      cardTypes: ["VS", "AX", "MC", "DC", "DR", "JCB"],
      beginDate: "06-01-21",
      endDate: "07-29-21",
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
  ],
  dateDetails: [
    {
      reportDate: 1622790000000,
      formattedReportDate: "06-04-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "1,524",
      grossVolume: "184,613.12",
      creditItems: "38",
      creditVolume: "-9,979.80",
      visaGrossItems: "1,032",
      visaGrossVolume: "111,217.17",
      visaCreditItems: "25",
      visaCreditVolume: "-6,348.65",
      visaNetSales: "1,057",
      visaNetVolume: "104,868.52",
      mcGrossItems: "398",
      mcGrossVolume: "58,283.93",
      mcCreditItems: "11",
      mcCreditVolume: "-2,826.39",
      mcNetSales: "409",
      mcNetVolume: "55,457.54",
      amexGrossItems: "31",
      amexGrossVolume: "1,009.91",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "31",
      amexNetVolume: "1,009.91",
      discoverGrossItems: "63",
      discoverGrossVolume: "14,102.11",
      discoverCreditItems: "2",
      discoverCreditVolume: "-804.76",
      discoverNetSales: "65",
      discoverNetVolume: "13,297.35",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "1,562",
      netVolume: "174,633.32",
      cardTypes: ["VS", "AX", "MC", "DC", "DR", "JCB"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1622703600000,
      formattedReportDate: "06-03-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "1,481",
      grossVolume: "190,062.05",
      creditItems: "39",
      creditVolume: "-10,818.58",
      visaGrossItems: "980",
      visaGrossVolume: "118,356.92",
      visaCreditItems: "25",
      visaCreditVolume: "-5,590.11",
      visaNetSales: "1,005",
      visaNetVolume: "112,766.81",
      mcGrossItems: "395",
      mcGrossVolume: "52,290.84",
      mcCreditItems: "8",
      mcCreditVolume: "-3,452.70",
      mcNetSales: "403",
      mcNetVolume: "48,838.14",
      amexGrossItems: "33",
      amexGrossVolume: "997.23",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "33",
      amexNetVolume: "997.23",
      discoverGrossItems: "73",
      discoverGrossVolume: "18,417.06",
      discoverCreditItems: "6",
      discoverCreditVolume: "-1,775.77",
      discoverNetSales: "79",
      discoverNetVolume: "16,641.29",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "1,520",
      netVolume: "179,243.47",
      cardTypes: ["VS", "AX", "MC", "DC", "DR", "JCB"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1622617200000,
      formattedReportDate: "06-02-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "1,528",
      grossVolume: "193,414.79",
      creditItems: "41",
      creditVolume: "-9,144.78",
      visaGrossItems: "1,095",
      visaGrossVolume: "126,997.79",
      visaCreditItems: "33",
      visaCreditVolume: "-6,829.52",
      visaNetSales: "1,128",
      visaNetVolume: "120,168.27",
      mcGrossItems: "357",
      mcGrossVolume: "53,159.72",
      mcCreditItems: "4",
      mcCreditVolume: "-1,152.23",
      mcNetSales: "361",
      mcNetVolume: "52,007.49",
      amexGrossItems: "22",
      amexGrossVolume: "494.51",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "22",
      amexNetVolume: "494.51",
      discoverGrossItems: "54",
      discoverGrossVolume: "12,762.77",
      discoverCreditItems: "4",
      discoverCreditVolume: "-1,163.03",
      discoverNetSales: "58",
      discoverNetVolume: "11,599.74",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "1,569",
      netVolume: "184,270.01",
      cardTypes: ["VS", "AX", "MC", "DC", "DR", "JCB"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1622530800000,
      formattedReportDate: "06-01-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "1,705",
      grossVolume: "211,929.63",
      creditItems: "41",
      creditVolume: "-9,336.21",
      visaGrossItems: "1,190",
      visaGrossVolume: "136,477.09",
      visaCreditItems: "28",
      visaCreditVolume: "-6,990.19",
      visaNetSales: "1,218",
      visaNetVolume: "129,486.90",
      mcGrossItems: "407",
      mcGrossVolume: "60,892.38",
      mcCreditItems: "12",
      mcCreditVolume: "-2,252.15",
      mcNetSales: "419",
      mcNetVolume: "58,640.23",
      amexGrossItems: "40",
      amexGrossVolume: "991.48",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "40",
      amexNetVolume: "991.48",
      discoverGrossItems: "68",
      discoverGrossVolume: "13,568.68",
      discoverCreditItems: "1",
      discoverCreditVolume: "-93.87",
      discoverNetSales: "69",
      discoverNetVolume: "13,474.81",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "1,746",
      netVolume: "202,593.42",
      cardTypes: ["VS", "AX", "MC", "DC", "DR", "JCB"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
  ],
  cardTypeDetails: [
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "37",
      grossVolume: "1,398.53",
      creditItems: "0",
      creditVolume: "0.00",
      netSales: "37",
      netVolume: "1,398.53",
      formattedCardType: "AX",
      cardTypes: ["AX"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "72",
      grossVolume: "14,670.51",
      creditItems: "6",
      creditVolume: "-584.00",
      netSales: "78",
      netVolume: "14,086.51",
      formattedCardType: "DR",
      cardTypes: ["DR"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      grossItems: "429",
      grossVolume: "50,372.65",
      creditItems: "8",
      creditVolume: "-1,138.28",
      netSales: "437",
      netVolume: "49,234.37",
      formattedCardType: "MC",
      cardTypes: ["MC"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "-99",
      grossItems: "1,253",
      grossVolume: "126,908.11",
      creditItems: "28",
      creditVolume: "-5,829.83",
      netSales: "1,281",
      netVolume: "121,078.28",
      formattedCardType: "VS",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
  ],
  outletDetails: [
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "20",
      divisionName: "F&B",
      outletNbr: "76342841",
      outletName: "OUTLET - 76342841",
      grossItems: "12",
      grossVolume: "587.30",
      creditItems: "1",
      creditVolume: "-23.30",
      visaGrossItems: "12",
      visaGrossVolume: "587.30",
      visaCreditItems: "1",
      visaCreditVolume: "-23.30",
      visaNetSales: "13",
      visaNetVolume: "564.00",
      mcGrossItems: "2",
      mcGrossVolume: "97.48",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "2",
      mcNetVolume: "97.48",
      amexGrossItems: "2",
      amexGrossVolume: "135.98",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "2",
      amexNetVolume: "135.98",
      discoverGrossItems: "0",
      discoverGrossVolume: "0.00",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "0",
      discoverNetVolume: "0.00",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "13",
      netVolume: "564.00",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "20",
      divisionName: "F&B",
      outletNbr: "76342843",
      outletName: "OUTLET - 76342843",
      grossItems: "71",
      grossVolume: "816.66",
      creditItems: "0",
      creditVolume: "0.00",
      visaGrossItems: "71",
      visaGrossVolume: "816.66",
      visaCreditItems: "0",
      visaCreditVolume: "0.00",
      visaNetSales: "71",
      visaNetVolume: "816.66",
      mcGrossItems: "14",
      mcGrossVolume: "181.26",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "14",
      mcNetVolume: "181.26",
      amexGrossItems: "3",
      amexGrossVolume: "42.52",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "3",
      amexNetVolume: "42.52",
      discoverGrossItems: "1",
      discoverGrossVolume: "9.84",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "1",
      discoverNetVolume: "9.84",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "71",
      netVolume: "816.66",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "20",
      divisionName: "F&B",
      outletNbr: "76342850",
      outletName: "OUTLET - 76342850",
      grossItems: "83",
      grossVolume: "1,537.17",
      creditItems: "0",
      creditVolume: "0.00",
      visaGrossItems: "83",
      visaGrossVolume: "1,537.17",
      visaCreditItems: "0",
      visaCreditVolume: "0.00",
      visaNetSales: "83",
      visaNetVolume: "1,537.17",
      mcGrossItems: "22",
      mcGrossVolume: "380.09",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "22",
      mcNetVolume: "380.09",
      amexGrossItems: "1",
      amexGrossVolume: "10.58",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "1",
      amexNetVolume: "10.58",
      discoverGrossItems: "1",
      discoverGrossVolume: "60.93",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "1",
      discoverNetVolume: "60.93",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "83",
      netVolume: "1,537.17",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "30",
      divisionName: "RETAIL",
      outletNbr: "76353925",
      outletName: "OUTLET - 76353925",
      grossItems: "62",
      grossVolume: "1,478.58",
      creditItems: "0",
      creditVolume: "0.00",
      visaGrossItems: "62",
      visaGrossVolume: "1,478.58",
      visaCreditItems: "0",
      visaCreditVolume: "0.00",
      visaNetSales: "62",
      visaNetVolume: "1,478.58",
      mcGrossItems: "33",
      mcGrossVolume: "678.40",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "33",
      mcNetVolume: "678.40",
      amexGrossItems: "4",
      amexGrossVolume: "112.95",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "4",
      amexNetVolume: "112.95",
      discoverGrossItems: "0",
      discoverGrossVolume: "0.00",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "0",
      discoverNetVolume: "0.00",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "62",
      netVolume: "1,478.58",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "20",
      divisionName: "F&B",
      outletNbr: "79510768",
      outletName: "OUTLET - 79510768",
      grossItems: "117",
      grossVolume: "1,731.05",
      creditItems: "0",
      creditVolume: "0.00",
      visaGrossItems: "117",
      visaGrossVolume: "1,731.05",
      visaCreditItems: "0",
      visaCreditVolume: "0.00",
      visaNetSales: "117",
      visaNetVolume: "1,731.05",
      mcGrossItems: "34",
      mcGrossVolume: "550.50",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "34",
      mcNetVolume: "550.50",
      amexGrossItems: "2",
      amexGrossVolume: "12.98",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "2",
      amexNetVolume: "12.98",
      discoverGrossItems: "4",
      discoverGrossVolume: "83.10",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "4",
      discoverNetVolume: "83.10",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "117",
      netVolume: "1,731.05",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
  ],
  batchDetails: [
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "10",
      divisionName: "LODGING",
      outletNbr: "76782482",
      outletName: "OUTLET - 76782482",
      merchantBatchNbr: "00294",
      grossItems: "20",
      grossVolume: "6,925.39",
      creditItems: "0",
      creditVolume: "0.00",
      visaGrossItems: "20",
      visaGrossVolume: "6,925.39",
      visaCreditItems: "0",
      visaCreditVolume: "0.00",
      visaNetSales: "20",
      visaNetVolume: "6,925.39",
      mcGrossItems: "3",
      mcGrossVolume: "1,634.90",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "3",
      mcNetVolume: "1,634.90",
      amexGrossItems: "0",
      amexGrossVolume: "0.00",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "0",
      amexNetVolume: "0.00",
      discoverGrossItems: "0",
      discoverGrossVolume: "0.00",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "0",
      discoverNetVolume: "0.00",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "20",
      netVolume: "6,925.39",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "10",
      divisionName: "LODGING",
      outletNbr: "76782482",
      outletName: "OUTLET - 76782482",
      merchantBatchNbr: "00295",
      grossItems: "7",
      grossVolume: "4,318.92",
      creditItems: "1",
      creditVolume: "-67.20",
      visaGrossItems: "7",
      visaGrossVolume: "4,318.92",
      visaCreditItems: "1",
      visaCreditVolume: "-67.20",
      visaNetSales: "8",
      visaNetVolume: "4,251.72",
      mcGrossItems: "2",
      mcGrossVolume: "565.15",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "2",
      mcNetVolume: "565.15",
      amexGrossItems: "0",
      amexGrossVolume: "0.00",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "0",
      amexNetVolume: "0.00",
      discoverGrossItems: "1",
      discoverGrossVolume: "504.96",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "1",
      discoverNetVolume: "504.96",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "8",
      netVolume: "4,251.72",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "10",
      divisionName: "LODGING",
      outletNbr: "76782482",
      outletName: "OUTLET - 76782482",
      merchantBatchNbr: "00296",
      grossItems: "200",
      grossVolume: "63,766.33",
      creditItems: "17",
      creditVolume: "-4,483.10",
      visaGrossItems: "200",
      visaGrossVolume: "63,766.33",
      visaCreditItems: "17",
      visaCreditVolume: "-4,483.10",
      visaNetSales: "217",
      visaNetVolume: "59,283.23",
      mcGrossItems: "82",
      mcGrossVolume: "28,310.00",
      mcCreditItems: "6",
      mcCreditVolume: "-1,088.30",
      mcNetSales: "88",
      mcNetVolume: "27,221.70",
      amexGrossItems: "0",
      amexGrossVolume: "0.00",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "0",
      amexNetVolume: "0.00",
      discoverGrossItems: "34",
      discoverGrossVolume: "11,407.90",
      discoverCreditItems: "4",
      discoverCreditVolume: "-493.65",
      discoverNetSales: "38",
      discoverNetVolume: "10,914.25",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "217",
      netVolume: "59,283.23",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
    {
      reportDate: 1627196400000,
      formattedReportDate: "07-25-21",
      merchantNbr: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      divisionNbr: "10",
      divisionName: "LODGING",
      outletNbr: "76782482",
      outletName: "OUTLET - 76782482",
      merchantBatchNbr: "00297",
      grossItems: "5",
      grossVolume: "2,910.87",
      creditItems: "0",
      creditVolume: "0.00",
      visaGrossItems: "5",
      visaGrossVolume: "2,910.87",
      visaCreditItems: "0",
      visaCreditVolume: "0.00",
      visaNetSales: "5",
      visaNetVolume: "2,910.87",
      mcGrossItems: "0",
      mcGrossVolume: "0.00",
      mcCreditItems: "0",
      mcCreditVolume: "0.00",
      mcNetSales: "0",
      mcNetVolume: "0.00",
      amexGrossItems: "0",
      amexGrossVolume: "0.00",
      amexCreditItems: "0",
      amexCreditVolume: "0.00",
      amexNetSales: "0",
      amexNetVolume: "0.00",
      discoverGrossItems: "0",
      discoverGrossVolume: "0.00",
      discoverCreditItems: "0",
      discoverCreditVolume: "0.00",
      discoverNetSales: "0",
      discoverNetVolume: "0.00",
      jcbGrossItems: "0",
      jcbGrossVolume: "0.00",
      jcbCreditItems: "0",
      jcbCreditVolume: "0.00",
      jcbNetSales: "0",
      jcbNetVolume: "0.00",
      netSales: "5",
      netVolume: "2,910.87",
      cardTypes: ["VS"],
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
    },
  ],
  transactionDetails: [
    {
      merchantNumber: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      outletName: "OUTLET - 76782482",
      cardType: "VS",
      cardNumber: "4400663459274588",
      posEntryMode: "05",
      posEntryModeDesc: "EMV/CHIP",
      transactionDate: 1627110000000,
      billingDate: 1627196400000,
      activityType: "SALE",
      amount: 477.0,
      outletNbr: "76782482",
      divisionNbr: "10",
      divisionName: "LODGING",
      formattedCardNumber: "4400663459274588",
      formattedTranDate: "07-24-21",
      formattedBillingDate: "07-25-21",
      formattedAmount: "477.00",
      formattedCardType: "VS",
      expDate: "&nbsp;",
      cardNumberMasked: "4400663459274588",
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
      cardTypes: ["VS"],
    },
    {
      merchantNumber: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      outletName: "OUTLET - 76782482",
      cardType: "VS",
      cardNumber: "4430510067874271",
      posEntryMode: "05",
      posEntryModeDesc: "EMV/CHIP",
      transactionDate: 1627110000000,
      billingDate: 1627196400000,
      activityType: "SALE",
      amount: 477.0,
      outletNbr: "76782482",
      divisionNbr: "10",
      divisionName: "LODGING",
      formattedCardNumber: "4430510067874271",
      formattedTranDate: "07-24-21",
      formattedBillingDate: "07-25-21",
      formattedAmount: "477.00",
      formattedCardType: "VS",
      expDate: "&nbsp;",
      cardNumberMasked: "4430510067874271",
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
      cardTypes: ["VS"],
    },
    {
      merchantNumber: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      outletName: "OUTLET - 76782482",
      cardType: "VS",
      cardNumber: "4744770152539178",
      posEntryMode: "05",
      posEntryModeDesc: "EMV/CHIP",
      transactionDate: 1627110000000,
      billingDate: 1627196400000,
      activityType: "SALE",
      amount: 772.46,
      outletNbr: "76782482",
      divisionNbr: "10",
      divisionName: "LODGING",
      formattedCardNumber: "4744770152539178",
      formattedTranDate: "07-24-21",
      formattedBillingDate: "07-25-21",
      formattedAmount: "772.46",
      formattedCardType: "VS",
      expDate: "&nbsp;",
      cardNumberMasked: "4744770152539178",
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
      cardTypes: ["VS"],
    },
    {
      merchantNumber: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      outletName: "OUTLET - 76782482",
      cardType: "VS",
      cardNumber: "4207670259952680",
      posEntryMode: "90",
      posEntryModeDesc: "SWIPED",
      transactionDate: 1627110000000,
      billingDate: 1627196400000,
      activityType: "CREDIT",
      amount: -577.68,
      outletNbr: "76782482",
      divisionNbr: "10",
      divisionName: "LODGING",
      formattedCardNumber: "4207670259952680",
      formattedTranDate: "07-24-21",
      formattedBillingDate: "07-25-21",
      formattedAmount: "-577.68",
      formattedCardType: "VS",
      expDate: "&nbsp;",
      cardNumberMasked: "4207670259952680",
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
      cardTypes: ["VS"],
    },
    {
      merchantNumber: "8358006750",
      merchantName: "MERCHANT - 8358006750",
      outletName: "OUTLET - 76782482",
      cardType: "VS",
      cardNumber: "4388576123237342",
      posEntryMode: "90",
      posEntryModeDesc: "SWIPED",
      transactionDate: 1627110000000,
      billingDate: 1627196400000,
      activityType: "CREDIT",
      amount: -54.5,
      outletNbr: "76782482",
      divisionNbr: "10",
      divisionName: "LODGING",
      formattedCardNumber: "4388576123237342",
      formattedTranDate: "07-24-21",
      formattedBillingDate: "07-25-21",
      formattedAmount: "-54.50",
      formattedCardType: "VS",
      expDate: "&nbsp;",
      cardNumberMasked: "4388576123237342",
      region: " ",
      assetOwner: "&nbsp;",
      businessLegalName: "Shea And Thomas LLC",
      cardTypes: ["VS"],
    },
  ],
  summaryDetails: [],
  resultsTotalMerchants: 1,
  resultsTotalDates: 59,
  resultsTotalCardTypes: 4,
  resultsTotalOutlets: 15,
  resultsTotalBatch: 9,
  resultsTotalTrans: 15,
};

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPrivilegeTitleToJSON = exports.UserPrivilegeTitleFromJSONTyped = exports.UserPrivilegeTitleFromJSON = exports.UserPrivilegeTitle = void 0;
/**
 * Titles of the User Privileges Sections
 * @export
 * @enum {string}
 */
var UserPrivilegeTitle;
(function (UserPrivilegeTitle) {
    UserPrivilegeTitle["ReferenceInformation"] = "REFERENCE_INFORMATION";
    UserPrivilegeTitle["DailyOperations"] = "DAILY_OPERATIONS";
    UserPrivilegeTitle["ManagementModules"] = "MANAGEMENT_MODULES";
})(UserPrivilegeTitle = exports.UserPrivilegeTitle || (exports.UserPrivilegeTitle = {}));
function UserPrivilegeTitleFromJSON(json) {
    return UserPrivilegeTitleFromJSONTyped(json, false);
}
exports.UserPrivilegeTitleFromJSON = UserPrivilegeTitleFromJSON;
function UserPrivilegeTitleFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.UserPrivilegeTitleFromJSONTyped = UserPrivilegeTitleFromJSONTyped;
function UserPrivilegeTitleToJSON(value) {
    return value;
}
exports.UserPrivilegeTitleToJSON = UserPrivilegeTitleToJSON;

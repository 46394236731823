"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportGroupToJSON = exports.ReportGroupFromJSONTyped = exports.ReportGroupFromJSON = void 0;
var runtime_1 = require("../runtime");
function ReportGroupFromJSON(json) {
    return ReportGroupFromJSONTyped(json, false);
}
exports.ReportGroupFromJSON = ReportGroupFromJSON;
function ReportGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'text': !runtime_1.exists(json, 'text') ? undefined : json['text'],
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'parentId': !runtime_1.exists(json, 'parentId') ? undefined : json['parentId'],
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'children': !runtime_1.exists(json, 'children') ? undefined : (json['children'].map(ReportGroupFromJSON)),
    };
}
exports.ReportGroupFromJSONTyped = ReportGroupFromJSONTyped;
function ReportGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'text': value.text,
        'id': value.id,
        'parentId': value.parentId,
        'url': value.url,
        'children': value.children === undefined ? undefined : (value.children.map(ReportGroupToJSON)),
    };
}
exports.ReportGroupToJSON = ReportGroupToJSON;

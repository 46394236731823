import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "./hover_menu.scss";

export interface HoverMenuItem {
  title: string;
  id?: string;
  to?: string;
  children?: HoverMenuItem[];
}

interface HoverMenuItemProps {
  item: HoverMenuItem;
  isPrimary?: boolean;
  isActive?: boolean;
  showLogo?: boolean;
  isCompact?: boolean;
}

function HoverMenuItemComponent({ ...props }: HoverMenuItemProps) {
  const [isHovering, setIsHovering] = React.useState(false);
  let title = (
    <span
      style={{
        color: props.isActive ? "yellow" : undefined,
        fontSize: props.isCompact ? 12 : props.isPrimary ? 15.5 : 12,
      }}
    >
      {props.item.title}
    </span>
  );
  let listItemChild = title;
  if (props.item.to) {
    listItemChild = <Link to={props.item.to}>{listItemChild}</Link>;
  }
  listItemChild = <div data-testid={props.item.id}>{listItemChild}</div>;
  listItemChild = (
    <li
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className="is-flex is-align-items-center">
        {props.showLogo && (
          <div className="menu-shift-left">
            <img
              height="12px"
              src={`${process.env.PUBLIC_URL}/images/logo_nav${
                isHovering ? "_mo" : ""
              }.jpg`}
              alt="hover menu bullet point"
            />
          </div>
        )}
        {listItemChild}
      </div>
      {props.item.children && (
        <ul>
          {props.item.children.map((child, index) => (
            <HoverMenuItemComponent
              isCompact={props.isCompact}
              key={index}
              item={child}
            />
          ))}
        </ul>
      )}
    </li>
  );

  return listItemChild;
}

export interface HoverMenuProps {
  items?: HoverMenuItem[];
  inverted?: boolean;
  listStyleImage?: string;
}
export function HoverMenu({
  items = [],
  inverted = false,
  listStyleImage,
  ...props
}: HoverMenuProps) {
  return (
    <ul
      className={`dropdown 
      ${inverted ? "inverted" : ""} 
    `}
    >
      {items.map((item) => (
        <Fragment key={item.title}>
          <div
            className="is-flex is-flex-direction-row is-align-items-center link-row"
          >
            <div
              style={{ width: 30, minWidth: 30 }}
              className="is-flex-1 is-flex"
            >
              {listStyleImage && <img src={listStyleImage} alt="bullet point" />}
            </div>
            <HoverMenuItemComponent
              key={item.title}
              item={item}
              isPrimary={true}
              {...props}
            />
          </div>
        </Fragment>
      ))}
    </ul>
  );
}

export interface ActiveItemProp {
  activePath?: string;
}

HoverMenu.Compact = ({
  items = [],
  inverted = false,
  activePath,
}: Omit<HoverMenuProps, "listStyleImage"> & ActiveItemProp) => {
  const getLinks = (menuLink: HoverMenuItem): string[] => {
    const values: string[] = [];
    if (menuLink.to) {
      values.push(menuLink.to);
    }
    return values.concat(...(menuLink.children?.map?.(getLinks) ?? []));
  };
  return (
    <ul
      style={{
        fontSize: "12px",
      }}
      className={`dropdown compact 
      ${inverted ? "inverted" : ""} 
    `}
    >
      {items.map((item) => {
        const childLinks = getLinks(item);
        return (
          <HoverMenuItemComponent
            isActive={!!activePath && childLinks.includes(activePath)}
            isPrimary={true}
            showLogo={true}
            key={item.title}
            isCompact={true}
            item={item}
          />
        );
      })}
    </ul>
  );
};

import faker from "faker";
import { Division, DivisionOutlet } from "../models/division";
import { DivisionService } from "../services/division";

export function mockDivision() {
  const d = new Division();
  d.name = faker.commerce.department();
  d.number = faker.finance.account();
  return d;
}

export function mockDivisionOutlet() {
  const d = new DivisionOutlet();
  d.name = faker.commerce.department();
  d.number = faker.finance.account();

  d.divisionName = faker.commerce.department();
  d.divisionNumber = faker.finance.account();
  return d;
}

export function mockDivisionService(): DivisionService {
  return {
    async getAllDivisions() {
      return Array.from(new Array(10)).map(mockDivision);
    },
    async getAllOutlets() {
      return Array.from(new Array(10)).map(mockDivisionOutlet);
    },
  };
}

import { useState, useEffect } from "react";
import { ReportGroup } from "../models/report";
import { GetReportGroupsParameters, ReportService } from "../services/report";

export function useReportGroups(
  reportService: ReportService,
  { reportType, merchantNumber }: GetReportGroupsParameters
) {
  const [isLoading, setIsLoading] = useState(false);
  const [reportGroups, setReportGroups] = useState<ReportGroup[]>([]);
  useEffect(() => {
    setIsLoading(true);
    reportService
      .getGroups({ reportType, merchantNumber })
      .then(setReportGroups)
      .finally(() => setIsLoading(false));
  }, [reportService, reportType, merchantNumber]);
  return { isLoading, reportGroups };
}

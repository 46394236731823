"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantSortedResponseToJSON = exports.MerchantSortedResponseFromJSONTyped = exports.MerchantSortedResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MerchantSortedResponseFromJSON(json) {
    return MerchantSortedResponseFromJSONTyped(json, false);
}
exports.MerchantSortedResponseFromJSON = MerchantSortedResponseFromJSON;
function MerchantSortedResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'totalResults': json['totalResults'],
        'merchants': !runtime_1.exists(json, 'merchants') ? undefined : (json['merchants'].map(_1.MerchantFromJSON)),
        'offset': !runtime_1.exists(json, 'offset') ? undefined : json['offset'],
    };
}
exports.MerchantSortedResponseFromJSONTyped = MerchantSortedResponseFromJSONTyped;
function MerchantSortedResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'totalResults': value.totalResults,
        'merchants': value.merchants === undefined ? undefined : (value.merchants.map(_1.MerchantToJSON)),
        'offset': value.offset,
    };
}
exports.MerchantSortedResponseToJSON = MerchantSortedResponseToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DivisionToJSON = exports.DivisionFromJSONTyped = exports.DivisionFromJSON = void 0;
var runtime_1 = require("../runtime");
function DivisionFromJSON(json) {
    return DivisionFromJSONTyped(json, false);
}
exports.DivisionFromJSON = DivisionFromJSON;
function DivisionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'divisionNbr': !runtime_1.exists(json, 'divisionNbr') ? undefined : json['divisionNbr'],
        'divisionName': !runtime_1.exists(json, 'divisionName') ? undefined : json['divisionName'],
    };
}
exports.DivisionFromJSONTyped = DivisionFromJSONTyped;
function DivisionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'divisionNbr': value.divisionNbr,
        'divisionName': value.divisionName,
    };
}
exports.DivisionToJSON = DivisionToJSON;

import React from "react";
import { ValidatedSearchPage } from "../../layout/search_page";
import { StandardReportSearchPanel } from "./standard_report_search";
import EBTreeView, { TreeviewItem } from "../../components/treeview";
import { Merchant } from "../../models/merchant";
import { ReportGroup } from "../../models/report";
import { LookupResult } from "../../layout/search_page";
import { PageTitle } from "../../components/page_title";

export interface StandardReportProps {
  reportGroups: ReportGroup[];
  merchants: Merchant[];
  renderItemTitle: (
    reportGroup: ReportGroup,
    level?: "year" | "month" | "day"
  ) => React.ReactNode;
  title: React.ReactNode;
  defaultMerchantId?: string;
  onSelectMerchant(merchantNumber: string): void;
  isCorporate?: boolean;
  isLoading?: boolean;
}
export const StandardReport = ({
  merchants,
  reportGroups,
  renderItemTitle,
  title = "",
  isLoading = false,
  defaultMerchantId,
  onSelectMerchant,
  isCorporate = false,
}: StandardReportProps) => {
  const reportGroupToTree = (
    reportGroup: ReportGroup,
    level: number = 0
  ): TreeviewItem => {
    const levelTitle = (function () {
      if (level === 0 && !reportGroup.url) {
        return "year";
      } else if (level === 1 && !reportGroup.url) {
        return "month";
      }
    })();
    return {
      id: reportGroup.id,
      title: renderItemTitle(reportGroup, levelTitle),
      icon:
        level === 0 ? (
          <div style={{ width: 25 }}>
            <img
              src={`${process.env.PUBLIC_URL}/images/detail.jpg`}
              alt="post logo"
              style={{ width: "100%", height: "100%" }}
            />
          </div>
        ) : undefined,
      children:
        Array.isArray(reportGroup.children) && reportGroup.children.length > 0
          ? reportGroup.children.map((g) => reportGroupToTree(g, level + 1))
          : [],
    };
  };
  return (
    <div className="standardReport-wrapper">
      <div className="title-bar">
        <PageTitle title={title} />
      </div>
      <ValidatedSearchPage
        searchPanel={
          isCorporate ? null : (
            <StandardReportSearchPanel
              onSelectMerchant={onSelectMerchant}
              defaultMerchantId={defaultMerchantId}
              merchants={merchants}
            />
          )
        }
        isLoading={isLoading}
        errorResults={reportGroups.length === 0 ? isLoading ? LookupResult.StillLoading : LookupResult.ObjectDoesNotExist : LookupResult.OK}
        searchResults={
          <div>
            <EBTreeView
              defaultExpanded={reportGroups?.map((g) => g.id)}
              defaultExpandIcon={
                <div style={{ width: 22 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/folderopenicon.jpg`}
                    alt="expand"
                  />
                </div>
              }
              defaultEndIcon={
                <div style={{ width: 22 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/pdficon_tree.jpg`}
                    alt="pdf"
                    style={{
                      width: 50,
                    }}
                  />
                </div>
              }
              defaultCollapseIcon={
                <div style={{ width: 22 }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/images/folderopenicon.jpg`}
                    alt="collapse"
                  />
                </div>
              }
              tree={reportGroups.map((g) => reportGroupToTree(g, 0))}
            />
          </div>
        }
      />
    </div>
  );
};

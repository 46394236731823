import React from "react";
import { Caret } from "../../components/caret";
import { SortTooltip } from "../../components/sort_tooltip";
import { Merchant } from "../../models/merchant";
import { SortOrder } from "../../models/sort_order";
import { MerchantBankSortColumn } from "../../services/merchant";
import "../../styles/tables.scss";
export interface BankInfoResultProps {
  merchants: Merchant[];
  onSort?: (field: MerchantBankSortColumn) => void;
  sortColumn?: MerchantBankSortColumn;
  sortDirection?: SortOrder;
}
export function BankInfoResult({
  onSort = () => {},
  sortDirection,
  sortColumn,
  ...props
}: BankInfoResultProps) {
  return (
    <table className="table is-bordered">
      <tr className="has-text-centered">
        <th
          rowSpan={2}
          className="has-text-white is-uppercase bank-info-header"
        >
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "merchantNumber" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("merchantNumber")}
            >
              Merchant
              <br />
              Number&nbsp;
              <Caret.Auto
                wantedColumn="merchantNumber"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th
          rowSpan={2}
          className="has-text-white is-uppercase bank-info-header"
        >
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "merchantName" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("merchantName")}
            >
              Merchant Name&nbsp;
              <Caret.Auto
                wantedColumn="merchantName"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th
          colSpan={2}
          className="has-text-white is-uppercase bank-info-header"
        >
          Sales / Refunds
        </th>
        <th
          colSpan={2}
          className="has-text-white is-uppercase bank-info-header"
        >
          Chargebacks
        </th>
        <th
          colSpan={2}
          className="has-text-white is-uppercase bank-info-header"
        >
          Fees
        </th>
        <th
          colSpan={2}
          className="has-text-white is-uppercase bank-info-header"
        >
          Net Negative
        </th>
      </tr>
      <tr className="has-text-centered">
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "saleRtg" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("saleRtg")}
            >
              Routing #&nbsp;
              <Caret.Auto
                wantedColumn="saleRtg"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "saleAcct" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("saleAcct")}
            >
              Account #&nbsp;
              <Caret.Auto
                wantedColumn="saleAcct"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "chbkRtg" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("chbkRtg")}
            >
              Routing #&nbsp;
              <Caret.Auto
                wantedColumn="chbkRtg"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "chbkAcct" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("chbkAcct")}
            >
              Account #&nbsp;
              <Caret.Auto
                wantedColumn="chbkAcct"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>{" "}
          </SortTooltip>
        </th>
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "feeRtg" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("feeRtg")}
            >
              Routing #&nbsp;
              <Caret.Auto
                wantedColumn="feeRtg"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "feeAcct" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("feeAcct")}
            >
              Account #&nbsp;
              <Caret.Auto
                wantedColumn="feeAcct"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "netRtg" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("netRtg")}
            >
              Routing #&nbsp;
              <Caret.Auto
                wantedColumn="netRtg"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
        <th className="has-text-white is-uppercase bank-info-header">
          <SortTooltip>
            <div
              style={{
                color: sortColumn === "netAcct" ? "yellow" : "white",
                cursor: "pointer",
              }}
              onClick={() => onSort("netAcct")}
            >
              Account #&nbsp;
              <Caret.Auto
                wantedColumn="netAcct"
                currentColumn={sortColumn}
                direction={sortDirection}
              />
            </div>
          </SortTooltip>
        </th>
      </tr>
      {props.merchants.map((merchant) => (
        <BankInfoResultRow merchant={merchant} />
      ))}
    </table>
  );
}
export function BankInfoResultRow({ merchant }: { merchant: Merchant }) {
  return (
    <tr key={merchant.number} style={{ height: 26 }}>
      <td className="has-text-centered is-vcentered">{merchant.number}</td>
      <td className="has-text-left is-vcentered">{merchant.name}</td>
      <td className="has-text-centered is-vcentered">
        {merchant.routingTransitNumber}
      </td>
      <td className="has-text-left is-vcentered">{merchant.accountNumber}</td>
      <td className="has-text-centered is-vcentered">
        {merchant.routingTransitNumberC}
      </td>
      <td className="has-text-left is-vcentered">{merchant.accountNumberC}</td>
      <td className="has-text-centered is-vcentered">
        {merchant.routingTransitNumberF}
      </td>
      <td className="has-text-left is-vcentered">{merchant.accountNumberF}</td>
      <td className="has-text-centered is-vcentered">
        {merchant.routingTransitNumber2}
      </td>
      <td className="has-text-left is-vcentered">{merchant.accountNumber2}</td>
    </tr>
  );
}

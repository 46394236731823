import { CardType } from "../models/card_type";
import {
  Transaction,
  TransactionSortField,
  TransactionType,
} from "../models/transaction";
import { SortOrder } from "../models/sort_order";
import { PaginatedResult } from "../models/paginated_result";

export enum AmountOperator {
  LessThanEq = "<=",
  GreaterThanEq = ">=",
  Equal = "=",
}

export interface TransactionSearchParameters {
  offset?: number;
  limit?: number;
  sort?: string;
  divisionName?: string;
  divisionNumber?: string;
  outletName?: string;
  outletNumber?: string;
  merchantNumber?: string;
  amount?: number;
  amountOperator?: AmountOperator;
  cardNumber?: string;
  dateBegin?: Date;
  dateEnd?: Date;
  cardTypes?: CardType[];
  transactionTypes?: TransactionType[];
  sortOrder?: SortOrder;
  sortField?: TransactionSortField;
}
export interface TransactionService {
  getTransactions(
    searchParameters: TransactionSearchParameters
  ): Promise<PaginatedResult<Transaction>>;
  getTransactionReport(
    searchParameters: TransactionSearchParameters
  ): Promise<[Blob, string?]>;
}

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserPrivilegeToJSON = exports.UserPrivilegeFromJSONTyped = exports.UserPrivilegeFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function UserPrivilegeFromJSON(json) {
    return UserPrivilegeFromJSONTyped(json, false);
}
exports.UserPrivilegeFromJSON = UserPrivilegeFromJSON;
function UserPrivilegeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'order': !runtime_1.exists(json, 'order') ? undefined : json['order'],
        'category': !runtime_1.exists(json, 'category') ? undefined : json['category'],
        'option': !runtime_1.exists(json, 'option') ? undefined : json['option'],
        'enabled': !runtime_1.exists(json, 'enabled') ? undefined : json['enabled'],
        'title': !runtime_1.exists(json, 'title') ? undefined : _1.UserPrivilegeTitleFromJSON(json['title']),
    };
}
exports.UserPrivilegeFromJSONTyped = UserPrivilegeFromJSONTyped;
function UserPrivilegeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'order': value.order,
        'category': value.category,
        'option': value.option,
        'enabled': value.enabled,
        'title': _1.UserPrivilegeTitleToJSON(value.title),
    };
}
exports.UserPrivilegeToJSON = UserPrivilegeToJSON;

import React from "react";
import { useParams } from "react-router";
import { ReportType, reportTypeFromString } from "../../models/report";

export interface ManagementModuleRouteParams {
  reportType: string;
  reportCategory: string;
}

export interface ManagementModuleRouteProps {
  children?: (reportType: string, reportCategory: ReportType) => JSX.Element;
}

export const ManagementModuleRoute = ({
  children,
}: ManagementModuleRouteProps) => {
  const { reportType, reportCategory } =
    useParams<ManagementModuleRouteParams>();

  return !!children ? (
    children(reportCategory, reportTypeFromString(reportType))
  ) : (
    <div />
  );
};

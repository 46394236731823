import { useCallback, useEffect, useState } from "react";
import { Merchant } from "../models/merchant";
import { MerchantBankSortColumn, MerchantService } from "../services/merchant";
import { usePagination } from "./use_pagination";
import { useSorting } from "./use_sorting";

export function useMerchantBankInfo(
  merchantService: MerchantService,
  merchantNumber?: string,
  pageSize: number = 500
) {
  const [isLoading, setIsLoading] = useState(false);
  const [merchants, setMerchants] = useState<Merchant[]>([]);
  const { sorting, handleSort } = useSorting<MerchantBankSortColumn>();
  const [totalResults, setTotalResults] = useState(0);
  const pagination = usePagination(pageSize, totalResults);
  const reset = useCallback(() => {
    pagination.firstPage();
    setTotalResults(0);
    handleSort();
    setMerchants([]);
  }, [pagination, setTotalResults, handleSort]);
  const exportMerchantBankInfo = useCallback(() => {
    if (merchantNumber) {
      return merchantService.exportMerchantBankDetails({
        merchantNumber,
        pageSize,
        offset: pagination.page,
        sortColumn: sorting?.field!,
        sortDirection: sorting?.direction!,
      });
    }
  }, [pageSize, merchantNumber, pagination.page, sorting, merchantService]);
  useEffect(() => {
    if (merchantNumber) {
      setIsLoading(true);
      merchantService
        .getMerchantBankDetails({
          merchantNumber,
          sortColumn: sorting?.field!,
          sortDirection: sorting?.direction!,
          pageSize,
          offset: pagination.page,
        })
        .then((response) => {
          setMerchants(response.data);
          setTotalResults(response.total);
        })
        .finally(() => setIsLoading(false));
    }
  }, [merchantService, merchantNumber, sorting, pagination.page, pageSize]);
  return {
    merchants,
    isLoading,
    sorting,
    handleSort,
    reset,
    pagination,
    exportMerchantBankInfo,
  };
}

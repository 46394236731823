import React from "react";
import { PageTitle } from "../../components/page_title";
import { TableTitle } from "../../components/table_title";
import { useDocuments } from "../../hooks/use_documents";
import { SearchPage } from "../../layout/search_page";
import {
  Document,
  DocumentType,
  HumanReadableDocumentType,
} from "../../models/document";
import { DocumentService } from "../../services/document";
import { DocumentLayout } from "./document_layout";

export interface DocumentContainerProps {
  documentTypes: DocumentType[];
  documentService: DocumentService;
}

export const DocumentContainer: React.FC<DocumentContainerProps> = ({
  documentTypes,
  documentService,
}) => {
  const { documents, pagination } = useDocuments(
    documentService,
    documentTypes
  );
  const openDocument = async (eboDoc: Document) => {
    if (!eboDoc.isInternal) {
      window.open(eboDoc.url, "_blank");
    } else {
      const [blob, filename] = await documentService.downloadDocument(eboDoc);
      const link = document.createElement("a");
      link.download = filename ?? "download.pdf";
      link.href = window.URL.createObjectURL(blob);
      link.click();
    }
  };
  return (
    <div className="document-wrapper">
      <div className="title-bar">
        <PageTitle title={HumanReadableDocumentType[documentTypes[0]]} />
      </div>
      <SearchPage
        searchResults={
          <>
            <TableTitle
              title=""
              onPageFirst={pagination.firstPage}
              onPageLast={pagination.lastPage}
              onPageNext={pagination.nextPage}
              onPagePrevious={pagination.previousPage}
              totalResults={pagination.totalResults}
              offset={pagination.offset}
              currentResultCount={documents.length}
              hideShowAllButton
              pageSize={pagination.pageSize}
            />
            <DocumentLayout
              onOpenDocument={openDocument}
              documents={documents}
            />
          </>
        }
      />
    </div>
  );
};

import { EbocomAuthError, EbocomUnexpectedError } from "./errors";
import {
  ChargebacksApi,
  Configuration,
  DisputesApi,
  DisputeSummary as SdkDisputeSummary,
  SortByColumn,
  ChargebackAging as SdkAging,
  ChargebackClosed as SdkClosed,
} from "ebocomsdk";
import { getPreAuthMiddleware } from "./auth_middleware";
import { DisputeSummary } from "../../models/dispute";
import {
  AgingChargebackResponse,
  AgingChargebackSortColumn,
  AgingFilter,
  AgingSorting,
  ChargebackClosedFilter,
  ChargebackSorting,
  ClosedChargebackSortColumn,
  ClosedResponse,
  DisputeService,
} from "../../services/disputes/dispute_service";
import { DateService } from "../../services/date_service";
import { CONTENT_DISPOSITION } from "./constants";
import { getFilenameFromContentDisposition } from "./filename_extractor";
import { v4 as uuidv4 } from "uuid";
import { SortOrder } from "../../models/sort_order";
import { ebocomSdkNumberFormatter } from "./number_parser";
import { ChargebackAging } from "../../models/chargeback_aging";
import { ChargebackClosed } from "../../models/chargeback_closed";
import { getPostLoadingMiddleware, getPreLoadingMiddleware } from "./loading_middleware";

export class EbocomDisputeService implements DisputeService {
  private api: DisputesApi;
  private chargebacksApi: ChargebacksApi;
  constructor(private baseUrl: string) {
    const configuration = new Configuration({ basePath: this.baseUrl });
    this.chargebacksApi = new ChargebacksApi(configuration)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
    this.api = new DisputesApi(configuration)
    .withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
  }
  private serializeDisputesSummaries = (
    sdkDispute: SdkDisputeSummary
  ): DisputeSummary => {
    const dispute = new DisputeSummary(uuidv4());
    dispute.cbkAmount = ebocomSdkNumberFormatter(sdkDispute.cbkAmount);
    dispute.cbkCount = sdkDispute.cbkCount;
    dispute.daysRemainingThreshold = sdkDispute.daysRemainingThreshold;
    dispute.merchantName = sdkDispute.merchName;
    dispute.merchantNumber = sdkDispute.merchNbr;
    dispute.minDaysRemaining = sdkDispute.minDaysRemaining;
    dispute.rfcAmount = ebocomSdkNumberFormatter(sdkDispute.rfcAmount);
    dispute.rfcCount = sdkDispute.rfcCount;
    return dispute;
  };
  getDisputes = async (): Promise<DisputeSummary[]> => {
    try {
      const disputes = await this.api.getDisputeSummary({
        daysRemainingThreshold: 100,
      });
      return disputes.map(this.serializeDisputesSummaries);
    } catch (e) {
      if ((e as any).status === 401) {
        throw new EbocomAuthError();
      }
      throw new EbocomUnexpectedError();
    }
  };
  private getAgingSorting = (
    sorting?: AgingChargebackSortColumn
  ): string | undefined => {
    switch (sorting) {
      case AgingChargebackSortColumn.daysRemaining:
        return SortByColumn.Daysremaining;
      case AgingChargebackSortColumn.dueDate:
        return SortByColumn.Duedate;
      case AgingChargebackSortColumn.merchantNumber:
        return SortByColumn.Merchantnbr;
      case AgingChargebackSortColumn.dateReceived:
        return SortByColumn.Recievedate;
      case AgingChargebackSortColumn.merchantName:
        return SortByColumn.Merchantname;
      case AgingChargebackSortColumn.outletName:
        return SortByColumn.Outletname;
      case AgingChargebackSortColumn.cardNumber:
        return SortByColumn.Cardnumber;
      case AgingChargebackSortColumn.tranDate:
        return SortByColumn.Trandate;
      case AgingChargebackSortColumn.disputeAmount:
        return SortByColumn.Disputeamt;
      case AgingChargebackSortColumn.disputeReason:
        return SortByColumn.Disputereason;
      default:
        return undefined;
    }
  };
  private serializeAgingChargebacks(sdkAging: SdkAging): ChargebackAging {
    return {
      ...sdkAging,
      exceptionAmount: ebocomSdkNumberFormatter(sdkAging.exceptionAmount),
    } as ChargebackAging;
  }
  private serializeClosedChargebacks(sdkClosed: SdkClosed): ChargebackClosed {
    return {
      ...sdkClosed,
      tranAmount: ebocomSdkNumberFormatter(sdkClosed.tranAmount),
    };
  }
  getAgingChargebacks = async (
    filter: AgingFilter,
    sorting: AgingSorting
  ): Promise<AgingChargebackResponse> => {
    const raw = await this.chargebacksApi.agingchargebacksPostRaw({
      sortBy: this.getAgingSorting(sorting.sortColumn),
      sortOrder: sorting.sortOrder === SortOrder.ASC ? "ASC" : "DESC",
      pageSize: 2000,
      disputeRequest: {
        merchantNbr: filter.merchantNumber,
        dateHighString: DateService.formatDate(filter.endDate, "-"),
        dateLowString: DateService.formatDate(filter.startDate, "-"),
      },
    });
    if (raw.raw.status === 204) {
      return {
        isEmpty: true,
      };
    }
    const response = await raw.value();
    return {
      isEmpty: false,
      chargebackAgingDetails: {
        data:
          response.chargebackAgingDetails.map(this.serializeAgingChargebacks) ??
          [],
        offset: response.offsetAging ?? 0,
        total: response.resultsTotalAging ?? 0,
      },
      chargebackSummary: {
        data:
          response.chargebackSummary.map(this.serializeDisputesSummaries) ?? [],
        offset: response.offsetSummary ?? 0,
        total: response.resultsTotalSummary ?? 0,
      },
    };
  };
  private getClosedSorting = (
    column?: ClosedChargebackSortColumn
  ): string | undefined => {
    switch (column) {
      case ClosedChargebackSortColumn.merchantNumber:
        return SortByColumn.Merchantnbr;
      case ClosedChargebackSortColumn.merchantName:
        return SortByColumn.Merchantname;
      case ClosedChargebackSortColumn.cardNumber:
        return SortByColumn.Cardnumber;
      case ClosedChargebackSortColumn.tranDate:
        return SortByColumn.Trandate;
      case ClosedChargebackSortColumn.disputeAmount:
        return SortByColumn.Disputeamt;
      case ClosedChargebackSortColumn.disputeReason:
        return SortByColumn.Disputereason;
      case ClosedChargebackSortColumn.closedDate:
        return SortByColumn.Closeddate;
      case ClosedChargebackSortColumn.closedReason:
        return SortByColumn.Closedreason;
      case ClosedChargebackSortColumn.cbrfTranDate:
        return SortByColumn.Cbrftrandate;
      default:
        return undefined;
    }
  };
  getClosedChargebacks = async (
    filter: ChargebackClosedFilter,
    sorting: ChargebackSorting
  ): Promise<ClosedResponse> => {
    const raw = await this.chargebacksApi.closedchargebacksPostRaw({
      disputeRequest: {
        ...filter,
        dateHighString: DateService.formatDate(filter.endDate, "-"),
        dateLowString: DateService.formatDate(filter.startDate, "-"),
      },
      sortBy: this.getClosedSorting(sorting.sortColumn),
      sortOrder: sorting.sortOrder === SortOrder.ASC ? "ASC" : "DESC",
      pageOffsetChargebacks: filter.pageOffsetChargebacks ?? 0,
      pageSize: filter.pageSize ?? 500,
    });
    if (raw.raw.status === 204) {
      return { isEmpty: true };
    }
    const response = await raw.value();
    return {
      isEmpty: false,
      chargebackClosed: {
        data: response.chargebackClosedDetails.map(
          this.serializeClosedChargebacks
        ),
        offset: response.offsetClosed ?? 0,
        total: response.resultsTotalClosed ?? 0,
      },
      chargebackRFC: {
        data:
          response.chargebackRFCDetails.map(this.serializeClosedChargebacks) ??
          [],
        offset: response.offsetRFC ?? 0,
        total: response.resultsTotalRFC ?? 0,
      },
    };
  };
  exportAgingChargebacks = async (
    filter: AgingFilter
  ): Promise<[Blob, string?]> => {
    const response =
      await this.chargebacksApi.exportAgingchargebacksChargebackPostRaw({
        disputeRequest: {
          merchantNbr: filter.merchantNumber,
          dateHighString: DateService.formatDate(filter.endDate, "-"),
          dateLowString: DateService.formatDate(filter.startDate, "-"),
        },
      });
    return [
      await response.raw.blob(),
      getFilenameFromContentDisposition(
        response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
      ),
    ];
  };
  exportAgingChargebacksSummary = async (
    filter: AgingFilter
  ): Promise<[Blob, string?]> => {
    const response =
      await this.chargebacksApi.exportAgingchargebacksSummaryPostRaw({
        disputeRequest: {
          merchantNbr: filter.merchantNumber,
          dateHighString: DateService.formatDate(filter.endDate, "-"),
          dateLowString: DateService.formatDate(filter.startDate, "-"),
        },
      });
    return [
      await response.raw.blob(),
      getFilenameFromContentDisposition(
        response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
      ),
    ];
  };
  exportClosedChargebacks = async (
    filter: Omit<ChargebackClosedFilter, "pageSize" | "pageOffsetChargebacks">
  ): Promise<[Blob, string?]> => {
    const response =
      await this.chargebacksApi.exportClosedchargebacksChargebackPostRaw({
        disputeRequest: {
          ...filter,
          dateHighString: DateService.formatDate(filter.endDate, "-"),
          dateLowString: DateService.formatDate(filter.startDate, "-"),
        },
      });
    return [
      await response.raw.blob(),
      getFilenameFromContentDisposition(
        response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
      ),
    ];
  };
  exportClosedChargebacksRfc = async (
    filter: Omit<ChargebackClosedFilter, "pageSize" | "pageOffsetChargebacks">
  ): Promise<[Blob, string?]> => {
    const response =
      await this.chargebacksApi.exportClosedchargebacksRfcPostRaw({
        disputeRequest: {
          ...filter,
          dateHighString: DateService.formatDate(filter.endDate, "-"),
          dateLowString: DateService.formatDate(filter.startDate, "-"),
        },
      });
    return [
      await response.raw.blob(),
      getFilenameFromContentDisposition(
        response.raw.headers.get(CONTENT_DISPOSITION) ?? undefined
      ),
    ];
  };
}

import React from "react";

export function Copyright() {
  return (
    <div style={{ textTransform: "none" }}>
      Copyright {new Date().getFullYear()} POST Integrations, Inc. All rights
      reserved.
    </div>
  );
}

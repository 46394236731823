"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MerchantToJSON = exports.MerchantFromJSONTyped = exports.MerchantFromJSON = void 0;
var runtime_1 = require("../runtime");
function MerchantFromJSON(json) {
    return MerchantFromJSONTyped(json, false);
}
exports.MerchantFromJSON = MerchantFromJSON;
function MerchantFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchNbr': !runtime_1.exists(json, 'merchNbr') ? undefined : json['merchNbr'],
        'merchName': !runtime_1.exists(json, 'merchName') ? undefined : json['merchName'],
        'address1': !runtime_1.exists(json, 'address1') ? undefined : json['address1'],
        'address2': !runtime_1.exists(json, 'address2') ? undefined : json['address2'],
        'city': !runtime_1.exists(json, 'city') ? undefined : json['city'],
        'state': !runtime_1.exists(json, 'state') ? undefined : json['state'],
        'zip': !runtime_1.exists(json, 'zip') ? undefined : json['zip'],
        'country': !runtime_1.exists(json, 'country') ? undefined : json['country'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'propertyPhone': !runtime_1.exists(json, 'propertyPhone') ? undefined : json['propertyPhone'],
        'customerSvcPhone': !runtime_1.exists(json, 'customerSvcPhone') ? undefined : json['customerSvcPhone'],
        'taxID': !runtime_1.exists(json, 'taxID') ? undefined : json['taxID'],
        'contractEntity': !runtime_1.exists(json, 'contractEntity') ? undefined : json['contractEntity'],
        'mgmtCompany': !runtime_1.exists(json, 'mgmtCompany') ? undefined : json['mgmtCompany'],
        'brandName': !runtime_1.exists(json, 'brandName') ? undefined : json['brandName'],
        'routingTransitNbr': !runtime_1.exists(json, 'routingTransitNbr') ? undefined : json['routingTransitNbr'],
        'routingTransitNbr2': !runtime_1.exists(json, 'routingTransitNbr2') ? undefined : json['routingTransitNbr2'],
        'routingTransitNbrC': !runtime_1.exists(json, 'routingTransitNbrC') ? undefined : json['routingTransitNbrC'],
        'routingTransitNbrM': !runtime_1.exists(json, 'routingTransitNbrM') ? undefined : json['routingTransitNbrM'],
        'routingTransitNbrF': !runtime_1.exists(json, 'routingTransitNbrF') ? undefined : json['routingTransitNbrF'],
        'merchAcntNbr': !runtime_1.exists(json, 'merchAcntNbr') ? undefined : json['merchAcntNbr'],
        'merchAcntNbr2': !runtime_1.exists(json, 'merchAcntNbr2') ? undefined : json['merchAcntNbr2'],
        'merchAcntNbrC': !runtime_1.exists(json, 'merchAcntNbrC') ? undefined : json['merchAcntNbrC'],
        'merchAcntNbrM': !runtime_1.exists(json, 'merchAcntNbrM') ? undefined : json['merchAcntNbrM'],
        'merchAcntNbrF': !runtime_1.exists(json, 'merchAcntNbrF') ? undefined : json['merchAcntNbrF'],
        'merchAcntType': !runtime_1.exists(json, 'merchAcntType') ? undefined : json['merchAcntType'],
        'fmtmainphone': !runtime_1.exists(json, 'fmtmainphone') ? undefined : json['fmtmainphone'],
        'fmtcustservicephone': !runtime_1.exists(json, 'fmtcustservicephone') ? undefined : json['fmtcustservicephone'],
        'businessLegalName': !runtime_1.exists(json, 'businessLegalName') ? undefined : json['businessLegalName'],
        'totalRooms': !runtime_1.exists(json, 'totalRooms') ? undefined : json['totalRooms'],
        'assetOwner': !runtime_1.exists(json, 'assetOwner') ? undefined : json['assetOwner'],
    };
}
exports.MerchantFromJSONTyped = MerchantFromJSONTyped;
function MerchantToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchNbr': value.merchNbr,
        'merchName': value.merchName,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'country': value.country,
        'region': value.region,
        'propertyPhone': value.propertyPhone,
        'customerSvcPhone': value.customerSvcPhone,
        'taxID': value.taxID,
        'contractEntity': value.contractEntity,
        'mgmtCompany': value.mgmtCompany,
        'brandName': value.brandName,
        'routingTransitNbr': value.routingTransitNbr,
        'routingTransitNbr2': value.routingTransitNbr2,
        'routingTransitNbrC': value.routingTransitNbrC,
        'routingTransitNbrM': value.routingTransitNbrM,
        'routingTransitNbrF': value.routingTransitNbrF,
        'merchAcntNbr': value.merchAcntNbr,
        'merchAcntNbr2': value.merchAcntNbr2,
        'merchAcntNbrC': value.merchAcntNbrC,
        'merchAcntNbrM': value.merchAcntNbrM,
        'merchAcntNbrF': value.merchAcntNbrF,
        'merchAcntType': value.merchAcntType,
        'fmtmainphone': value.fmtmainphone,
        'fmtcustservicephone': value.fmtcustservicephone,
        'businessLegalName': value.businessLegalName,
        'totalRooms': value.totalRooms,
        'assetOwner': value.assetOwner,
    };
}
exports.MerchantToJSON = MerchantToJSON;

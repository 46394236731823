import { useState, useEffect } from "react";
import { ReportType } from "../models/report";
import { ReportService } from "../services/report";

export function useReportPeriod(
  reportService: ReportService,
  reportType?: ReportType
) {
  const [reportPeriods, setReportPeriods] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (reportType === null || reportType === undefined) {
      return setReportPeriods([]);
    }
    setIsLoading(true);
    reportService
      .getReportPeriods(reportType)
      .then(setReportPeriods)
      .finally(() => setIsLoading(false));
  }, [reportService, reportType]);
  return { reportPeriods, isLoading };
}

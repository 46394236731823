import React, { useEffect } from "react";
import { EbocomMerchantSearchPanel } from "../../components/ebocom_merchant_search";
import { TableTitle } from "../../components/table_title";
import { useDefaultMerchant } from "../../hooks/use_default_merchant";
import { useMerchants } from "../../hooks/use_merchant";
import { useMerchantBankInfo } from "../../hooks/use_merchant_bank_info";
import { ValidatedSearchPage } from "../../layout/search_page";
import { addSuperMerchantNumber } from "../../models/merchant";
import { SortOrder } from "../../models/sort_order";
import { LookupResult } from "../../layout/search_page";
import { downloadExcelFile } from "../../services/download";
import { MerchantService } from "../../services/merchant";
import { BankInfoResult } from "./bank_info_result";
import { PageTitle } from "../../components/page_title";

export interface BankInfoContainerProps {
  merchantService: MerchantService;
}
export function BankInfoContainer({ merchantService }: BankInfoContainerProps) {
  const { merchants, isLoading } = useMerchants(merchantService);
  const [selectedMerchant, setSelectedMerchant] = React.useState<
    string | undefined
  >(undefined);
  const {
    merchants: detailedMerchants,
    sorting,
    handleSort,
    reset,
    pagination,
    exportMerchantBankInfo,
  } = useMerchantBankInfo(merchantService, selectedMerchant);
  const { selectedMerchant: defaultMerchant, updateSelectedMerchant } =
    useDefaultMerchant();
  useEffect(() => {
    if (defaultMerchant && defaultMerchant !== selectedMerchant) {
      setSelectedMerchant(defaultMerchant);
    }
  }, [defaultMerchant, selectedMerchant]);
  return (
    <div className="settlementInfo-wrapper">
      <div className="title-bar">
        <PageTitle title="Settlement Account Information" />
      </div>
      <ValidatedSearchPage
        searchPanel={
          <EbocomMerchantSearchPanel
            defaultMerchantId={defaultMerchant}
            onSelectMerchant={(m) => {
              reset();
              setSelectedMerchant(m.number);
              updateSelectedMerchant(m.number);
            }}
            merchants={addSuperMerchantNumber(merchants)}
          />
        }
        isLoading={isLoading}
        errorResults={
          pagination.totalResults === 0
            ? isLoading
              ? LookupResult.StillLoading
              : LookupResult.ObjectDoesNotExist
            : LookupResult.OK
        }
        searchResults={
          detailedMerchants.length > 0 && (
            <>
              <TableTitle
                title="Depository Detail"
                currentResultCount={detailedMerchants.length}
                totalResults={pagination.totalResults}
                onPageFirst={pagination.firstPage}
                onPageLast={pagination.lastPage}
                onPagePrevious={pagination.previousPage}
                onPageNext={pagination.nextPage}
                offset={pagination.offset}
                pageSize={pagination.pageSize}
                onExport={async () => {
                  const info = await exportMerchantBankInfo();
                  if (!info) {
                    return;
                  }
                  downloadExcelFile(info[0], info[1]);
                }}
              />
              <BankInfoResult
                onSort={handleSort}
                sortDirection={sorting?.direction ?? SortOrder.ASC}
                sortColumn={sorting?.field ?? "merchantName"}
                merchants={detailedMerchants}
              />
            </>
          )
        }
      />
    </div>
  );
}

import { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { EbocomSelect } from "../../../components/select";
import { create999Merchant, Merchant } from "../../../models/merchant";
import { DateService } from "../../../services/date_service";
import { Tooltip as ReactTooltip, TooltipRefProps } from 'react-tooltip';

export interface DisputeSearchData {
  merchantNumber: string;
  startDate?: Date;
  endDate?: Date;
}
export interface DisputeSearchPanelProps {
  merchants: Merchant[];
  isLoading?: boolean;
  defaultMerchantId?: string;
  onSearch(data: DisputeSearchData): void;
  onPrint(data: Required<DisputeSearchData>): void;
}
export const DisputeSearchPanel = ({
  merchants,
  defaultMerchantId,
  onSearch,
  onPrint,
  isLoading = false,
}: DisputeSearchPanelProps) => {
  const MERCHANT_NAME_INPUT = "merchantName";
  const MERCHANT_NUMBER_INPUT = "merchantNumber";
  const START_DATE_INPUT = "startDate";
  const END_DATE_INPUT = "endDate";
  const numbers = merchants.map((m) => ({ value: m.number, label: m.number }));
  const merchantsSortedByName = merchants.filter((merchant) => merchant.number !== '9999999999')
    .sort((a, b) => a.name.localeCompare(b.name));
  const names = [create999Merchant(), ...merchantsSortedByName].map((m) => ({
    value: m.number,
    label: m.name,
  }));

  merchants.map((m) => ({ value: m.number, label: m.name }));
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
    register,
    setValue,
    reset,
    clearErrors,
    setError,
  } = useForm({
    reValidateMode: "onSubmit",
    mode: "onSubmit",
  });
  useEffect(() => {
    if (defaultMerchantId) {
      setValue(
        MERCHANT_NUMBER_INPUT,
        numbers.find(({ value }) => value === defaultMerchantId)
      );
      setValue(
        MERCHANT_NAME_INPUT,
        names.find(({ value }) => value === defaultMerchantId)
      );
    }
  }, [names, numbers, defaultMerchantId, setValue]);
  const getParams = (): Partial<DisputeSearchData> => {
    const [merchantValue, startDate, endDate]: [
      { label: string; value: string }?,
      Date?,
      Date?
    ] = getValues([MERCHANT_NUMBER_INPUT, START_DATE_INPUT, END_DATE_INPUT]);
    const merchant = merchants.find((m) => m.number === merchantValue?.value);
    const params = {
      merchantNumber: merchant?.number,
      endDate:
        endDate instanceof Date && isFinite(endDate.valueOf())
          ? endDate
          : undefined,
      startDate:
        startDate instanceof Date && isFinite(startDate.valueOf())
          ? startDate
          : undefined,
    };
    return params;
  };
  const handleChange = () => {
    const params = getParams();
    if (!!params.merchantNumber) {
      onSearch(params as DisputeSearchData);
    }
  };

  const serializeParams = (params: any): Required<DisputeSearchData> => {
    return {
      merchantNumber: params.merchantNumber.value,
      startDate: new Date(DateService.resetTimeZoneInDateString(params.startDate)),
      endDate: new Date(DateService.resetTimeZoneInDateString(params.endDate))
    }
  }

  const handlePrint = (params: any) => {
    if (
      !!params.merchantNumber &&
      !!params.startDate &&
      !!params.endDate
    ) {
      const serializedParams = serializeParams(params);
      onPrint(serializedParams);
    }
  };

  const validateDates = (): boolean => {
    clearErrors([
      START_DATE_INPUT,
      END_DATE_INPUT
    ]);

    const beginDate = new Date(getValues(START_DATE_INPUT));
    beginDate.setHours(0, 0, 0, 0);
    const endDate = new Date(getValues(END_DATE_INPUT));
    endDate.setHours(0, 0, 0, 0);
    const todaysDate = new Date();
    todaysDate.setHours(0, 0, 0, 0);
    const date24MonthsAgo = new Date(todaysDate.setMonth(todaysDate.getMonth() - 24));
    date24MonthsAgo.setHours(0, 0, 0, 0);

    if (beginDate.getTime() < date24MonthsAgo.getTime()) {
      setError(START_DATE_INPUT, {
        message: "Begin date must be within the last 24 months",
        type: "before24",
      });
    }
    const endDateIsAfterBeginDate = beginDate.getTime() <= endDate.getTime();

    if (!endDateIsAfterBeginDate) {
      setError(START_DATE_INPUT, {
        message: "The begin date must be before the end date.",
        type: "validate",
      });
      setError(END_DATE_INPUT, {
        message: "The end date must be after begin date",
        type: "validate",
      });
    }
    return endDateIsAfterBeginDate;
  };

  const startDateErrorTooltipRef = useRef<TooltipRefProps>(null);
  const endDateErrorTooltipRef = useRef<TooltipRefProps>(null);

  useEffect(() => {
    if (errors[START_DATE_INPUT]) startDateErrorTooltipRef.current?.open();
    if (errors[END_DATE_INPUT]) endDateErrorTooltipRef.current?.open();
  }, [
    errors,
    errors[START_DATE_INPUT],
    errors[END_DATE_INPUT],
  ])

  return (
    <div>
      <table className="table is-bordered has-text-centered is-fullwidth m-0">
        <colgroup>
          <col span={1} style={{ width: "291px" }} />
          <col span={1} style={{ width: "120px" }} />
          <col span={1} style={{}} />
        </colgroup>
        <tbody>
          <tr style={{ height: 41 }}>
            <th className="has-text-white is-uppercase">Merchant Number</th>
            <th className="has-text-white is-uppercase">Print Begin Date</th>
            <th></th>
          </tr>
          <tr>
            <td>
              <div className="is-flex is-fullwidth">
                <Controller
                  name={MERCHANT_NUMBER_INPUT}
                  control={control}
                  render={({ field: { onChange, ...rest } }) => (
                    <EbocomSelect
                      tabIndex={-1}
                      data-testid="merchant-number-input"
                      className="input has-text-left"
                      placeholder="Select Merchant Number"
                      options={numbers}
                      defaultValue={numbers.find(
                        (n) => n.value === defaultMerchantId
                      )}
                      onChange={(e) => {
                        onChange();
                        setValue(
                          MERCHANT_NAME_INPUT,
                          names.find((n) => n.value === e.value)
                        );
                        setValue(MERCHANT_NUMBER_INPUT, e);
                        handleChange();
                      }}
                      {...rest}
                    />
                  )}
                />
              </div>
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <input
                data-tooltip-id={!!errors[START_DATE_INPUT] ? "error-tooltip-start-date" : undefined}
                data-tooltip-content={
                  !!errors[START_DATE_INPUT] ?
                    ((errors[START_DATE_INPUT].type === 'before24' || errors[START_DATE_INPUT].type === 'required') ?
                      errors[START_DATE_INPUT].message as string :
                      (errors[START_DATE_INPUT].type === "validate") ?
                        'The begin date must be before the end date.' : '') :
                    undefined
                }
                className={`${!!errors[START_DATE_INPUT] ? 'input-error' : ''}`}
                tabIndex={2}
                data-testid="print-begin-date-input"
                type="date"
                {...register(START_DATE_INPUT, {
                  required: "Begin date is required",
                  validate: validateDates
                })}
              />
              <ReactTooltip
                id="error-tooltip-start-date"
                ref={startDateErrorTooltipRef}
                opacity={1}
                style={{
                  color: '#fff',
                  backgroundColor: '#cc0f35',
                  fontSize: '12px',
                }}
              />
            </td>
            <td
              className="has-text-weight-bold"
              style={{
                textAlign: "left",
                verticalAlign: "middle",
                fontStyle: "italic",
                fontSize: 13
              }}
            >
              To print multiple Dispute Notices, enter Print Dates using Date
              Received
            </td>
          </tr>
          <tr style={{ height: 41 }}>
            <th className="has-text-white is-uppercase">Merchant Name</th>
            <th className="has-text-white is-uppercase">Print End Date</th>
            <th />
          </tr>
          <tr>
            <td>
              <Controller
                name={MERCHANT_NAME_INPUT}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <EbocomSelect
                    tabIndex={-1}
                    data-testid="merchant-name-input"
                    className="input"
                    options={names}
                    placeholder="Select Merchant Name"
                    defaultValue={names.find(
                      (n) => n.value === defaultMerchantId
                    )}
                    onChange={(e) => {
                      onChange();
                      setValue(MERCHANT_NAME_INPUT, e);
                      setValue(
                        MERCHANT_NUMBER_INPUT,
                        numbers.find((n) => n.value === e.value)
                      );
                      handleChange();
                    }}
                    {...rest}
                  />
                )}
              />
            </td>
            <td style={{ verticalAlign: "middle" }}>
              <input
                data-tooltip-id={!!errors[END_DATE_INPUT] ? "error-tooltip-end-date" : undefined}
                data-tooltip-content={
                  !!errors[END_DATE_INPUT] ?
                    ((errors[END_DATE_INPUT].type === 'before24' || errors[END_DATE_INPUT].type === 'required') ?
                      errors[END_DATE_INPUT].message as string :
                      (errors[END_DATE_INPUT].type === "validate") ?
                        'The end date must be after the begin date.' : '') :
                    undefined
                }
                className={`${!!errors[END_DATE_INPUT] ? 'input-error' : ''}`}
                tabIndex={3}
                data-testid="print-end-date-input"
                type="date"
                {...register(END_DATE_INPUT, {
                  required: "End date is required",
                  validate: validateDates
                })}
              />
              <ReactTooltip
                id="error-tooltip-end-date"
                ref={endDateErrorTooltipRef}
                opacity={1}
                style={{
                  color: '#fff',
                  backgroundColor: '#cc0f35',
                  fontSize: '12px',
                }}
              />
            </td>
            <td />
          </tr>
        </tbody>
      </table>
      <div className="is-flex is-fullwidth is-justify-content-flex-end search-button-bar">
        <button
          tabIndex={4}
          disabled={isLoading}
          className="is-uppercase ie-styled"
          onClick={handleSubmit(handlePrint)}
        >
          Print
        </button>
        <button
          tabIndex={5}
          disabled={isLoading}
          className="is-uppercase ie-styled"
          onClick={() => {
            reset([END_DATE_INPUT, START_DATE_INPUT], {
              keepDefaultValues: true,
            });
          }}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

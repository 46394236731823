"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.IssuerLookupToJSON = exports.IssuerLookupFromJSONTyped = exports.IssuerLookupFromJSON = void 0;
var runtime_1 = require("../runtime");
function IssuerLookupFromJSON(json) {
    return IssuerLookupFromJSONTyped(json, false);
}
exports.IssuerLookupFromJSON = IssuerLookupFromJSON;
function IssuerLookupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'instName': !runtime_1.exists(json, 'instName') ? undefined : json['instName'],
        'provider': !runtime_1.exists(json, 'provider') ? undefined : json['provider'],
        'providerService': !runtime_1.exists(json, 'providerService') ? undefined : json['providerService'],
        'servicePhone': !runtime_1.exists(json, 'servicePhone') ? undefined : json['servicePhone'],
        'serviceFAX': !runtime_1.exists(json, 'serviceFAX') ? undefined : json['serviceFAX'],
        'instCountry': !runtime_1.exists(json, 'instCountry') ? undefined : json['instCountry'],
        'binnbr': !runtime_1.exists(json, 'binnbr') ? undefined : json['binnbr'],
        'result': !runtime_1.exists(json, 'result') ? undefined : json['result'],
    };
}
exports.IssuerLookupFromJSONTyped = IssuerLookupFromJSONTyped;
function IssuerLookupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cardType': value.cardType,
        'instName': value.instName,
        'provider': value.provider,
        'providerService': value.providerService,
        'servicePhone': value.servicePhone,
        'serviceFAX': value.serviceFAX,
        'instCountry': value.instCountry,
        'binnbr': value.binnbr,
        'result': value.result,
    };
}
exports.IssuerLookupToJSON = IssuerLookupToJSON;

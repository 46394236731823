import React, { useEffect, useState } from "react";
import { ReportFileDownloader } from "../../components/download_handler";
import { useDefaultMerchant } from "../../hooks/use_default_merchant";
import { useMerchants } from "../../hooks/use_merchant";
import { useReportGroups } from "../../hooks/use_report_groups";
import {
  addSuperMerchantNumber,
  create999Merchant,
} from "../../models/merchant";
import { ReportType, reportTypeToFullName } from "../../models/report";
import { MerchantService } from "../../services/merchant";
import { ReportService } from "../../services/report";
import { StandardReport } from "./standard_report";

export interface StandardReportContainerProps {
  reportType: ReportType;
  merchantService: MerchantService;
  reportService: ReportService;
  isCorporate?: boolean;
}

export const StandardReportContainer = ({
  reportType,
  merchantService,
  reportService,
  isCorporate = false,
}: StandardReportContainerProps) => {
  const { merchants } = useMerchants(merchantService);
  const { selectedMerchant, updateSelectedMerchant } = useDefaultMerchant();
  const [merchantNumber, setMerchantNumber] = useState<string>(
    selectedMerchant ?? ""
  );
  const { reportGroups: groups, isLoading } = useReportGroups(reportService, {
    reportType,
    merchantNumber: isCorporate ? create999Merchant().number : merchantNumber,
  });
  const previousMerchantId = React.useRef<string | undefined>();
  useEffect(() => {
    if (
      selectedMerchant &&
      selectedMerchant !== previousMerchantId.current &&
      merchants?.length > 0
    ) {
      previousMerchantId.current = selectedMerchant;
      setMerchantNumber(selectedMerchant!);
    }
  }, [selectedMerchant, merchants]);
  const [isDownloadingReport, setIsDownloadingReport] = useState(false);
  return (
    <div>
      <StandardReport
        isCorporate={isCorporate}
        onSelectMerchant={(e) => {
          setMerchantNumber(e);
          updateSelectedMerchant(e);
        }}
        title={`${reportTypeToFullName[reportType]}`}
        merchants={addSuperMerchantNumber(merchants)}
        reportGroups={groups}
        defaultMerchantId={selectedMerchant}
        isLoading={isLoading || isDownloadingReport}
        renderItemTitle={(group, level) => {
          return (
            <div className="is-flex is-align-items-center">
              {group.url ? (
                <ReportFileDownloader
                  style={{ padding: 0, margin: 0, height: 13 }}
                  url={group.url}
                  reportService={reportService}
                  defaultFilename={`${ReportType[reportType] ?? "report"}.pdf`}
                  onStartDownload={() => setIsDownloadingReport(true)}
                  onFinishDownload={() => setIsDownloadingReport(false)}
                  >
                  {(isLoading) => (
                    <div style={{ marginTop: 3 }}>{group.name}</div>
                    )}
                </ReportFileDownloader>
              ) : (
                <span 
                  style={{
                    fontSize: level === "year" ? "1.2em" : "1em",
                    color: level === "year" ? "rgb(57, 40, 82)" : "inherit",
                    fontWeight:
                      level === "day" ? "inherit" : "bold",
                      // level === "year" || level === "month"
                      //   ? "bold"
                      //   : "inherit",
                    textTransform: level === "month" ? "capitalize" : "inherit",
                    lineHeight: level === "year" ? "1.2em" : "1.0em",
                  }}
                >
                  {group.name}
                </span>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentToJSON = exports.DocumentFromJSONTyped = exports.DocumentFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DocumentFromJSON(json) {
    return DocumentFromJSONTyped(json, false);
}
exports.DocumentFromJSON = DocumentFromJSON;
function DocumentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !runtime_1.exists(json, 'id') ? undefined : json['id'],
        'title': !runtime_1.exists(json, 'title') ? undefined : json['title'],
        'description': !runtime_1.exists(json, 'description') ? undefined : json['description'],
        'category': !runtime_1.exists(json, 'category') ? undefined : _1.DocumentCategoryFromJSON(json['category']),
        'sortSeq': !runtime_1.exists(json, 'sortSeq') ? undefined : json['sortSeq'],
        'documentDate': !runtime_1.exists(json, 'documentDate') ? undefined : (new Date(json['documentDate'])),
        'url': !runtime_1.exists(json, 'url') ? undefined : json['url'],
        'newWindowFlag': !runtime_1.exists(json, 'newWindowFlag') ? undefined : json['newWindowFlag'],
        'internalFlag': !runtime_1.exists(json, 'internalFlag') ? undefined : json['internalFlag'],
    };
}
exports.DocumentFromJSONTyped = DocumentFromJSONTyped;
function DocumentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'category': _1.DocumentCategoryToJSON(value.category),
        'sortSeq': value.sortSeq,
        'documentDate': value.documentDate === undefined ? undefined : (value.documentDate.toISOString().substr(0, 10)),
        'url': value.url,
        'newWindowFlag': value.newWindowFlag,
        'internalFlag': value.internalFlag,
    };
}
exports.DocumentToJSON = DocumentToJSON;

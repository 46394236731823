"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.InterchangeDetailsToJSON = exports.InterchangeDetailsFromJSONTyped = exports.InterchangeDetailsFromJSON = void 0;
var runtime_1 = require("../runtime");
function InterchangeDetailsFromJSON(json) {
    return InterchangeDetailsFromJSONTyped(json, false);
}
exports.InterchangeDetailsFromJSON = InterchangeDetailsFromJSON;
function InterchangeDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNbr': !runtime_1.exists(json, 'merchantNbr') ? undefined : json['merchantNbr'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'divisionNbr': !runtime_1.exists(json, 'divisionNbr') ? undefined : json['divisionNbr'],
        'divisionName': !runtime_1.exists(json, 'divisionName') ? undefined : json['divisionName'],
        'outletNbr': !runtime_1.exists(json, 'outletNbr') ? undefined : json['outletNbr'],
        'outletName': !runtime_1.exists(json, 'outletName') ? undefined : json['outletName'],
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'qualificationTypeDesc': !runtime_1.exists(json, 'qualificationTypeDesc') ? undefined : json['qualificationTypeDesc'],
        'percentTotalItems': !runtime_1.exists(json, 'percentTotalItems') ? undefined : json['percentTotalItems'],
        'percentTotalVolume': !runtime_1.exists(json, 'percentTotalVolume') ? undefined : json['percentTotalVolume'],
        'mcItems': !runtime_1.exists(json, 'mcItems') ? undefined : json['mcItems'],
        'mcVolume': !runtime_1.exists(json, 'mcVolume') ? undefined : json['mcVolume'],
        'visaItems': !runtime_1.exists(json, 'visaItems') ? undefined : json['visaItems'],
        'visaVolume': !runtime_1.exists(json, 'visaVolume') ? undefined : json['visaVolume'],
        'totalItems': !runtime_1.exists(json, 'totalItems') ? undefined : json['totalItems'],
        'totalVolume': !runtime_1.exists(json, 'totalVolume') ? undefined : json['totalVolume'],
        'beginDate': !runtime_1.exists(json, 'beginDate') ? undefined : json['beginDate'],
        'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
        'formattedCardType': !runtime_1.exists(json, 'formattedCardType') ? undefined : json['formattedCardType'],
        'mcProcDate': !runtime_1.exists(json, 'mcProcDate') ? undefined : json['mcProcDate'],
        'submittedFeeProgram': !runtime_1.exists(json, 'submittedFeeProgram') ? undefined : json['submittedFeeProgram'],
        'interchangeFeeProgram': !runtime_1.exists(json, 'interchangeFeeProgram') ? undefined : json['interchangeFeeProgram'],
        'interchangeRegion': !runtime_1.exists(json, 'interchangeRegion') ? undefined : json['interchangeRegion'],
        'interchangeCardIdent': !runtime_1.exists(json, 'interchangeCardIdent') ? undefined : json['interchangeCardIdent'],
        'tranType': !runtime_1.exists(json, 'tranType') ? undefined : json['tranType'],
        'status': !runtime_1.exists(json, 'status') ? undefined : json['status'],
        'reversal': !runtime_1.exists(json, 'reversal') ? undefined : json['reversal'],
        'loadDate': !runtime_1.exists(json, 'loadDate') ? undefined : json['loadDate'],
        'debitIchgProgYN': !runtime_1.exists(json, 'debitIchgProgYN') ? undefined : json['debitIchgProgYN'],
        'billingDate': !runtime_1.exists(json, 'billingDate') ? undefined : json['billingDate'],
        'qualType': !runtime_1.exists(json, 'qualType') ? undefined : json['qualType'],
        'tItems': !runtime_1.exists(json, 'tItems') ? undefined : json['tItems'],
        'tVolume': !runtime_1.exists(json, 'tVolume') ? undefined : json['tVolume'],
        'percentMcItems': !runtime_1.exists(json, 'percentMcItems') ? undefined : json['percentMcItems'],
        'percentMcVolume': !runtime_1.exists(json, 'percentMcVolume') ? undefined : json['percentMcVolume'],
        'percentVisaItems': !runtime_1.exists(json, 'percentVisaItems') ? undefined : json['percentVisaItems'],
        'percentVisaVolume': !runtime_1.exists(json, 'percentVisaVolume') ? undefined : json['percentVisaVolume'],
        'productType': !runtime_1.exists(json, 'productType') ? undefined : json['productType'],
        'mtdItems': !runtime_1.exists(json, 'mtdItems') ? undefined : json['mtdItems'],
        'mtdVolume': !runtime_1.exists(json, 'mtdVolume') ? undefined : json['mtdVolume'],
        'percentMtdItems': !runtime_1.exists(json, 'percentMtdItems') ? undefined : json['percentMtdItems'],
        'percentMtdVolume': !runtime_1.exists(json, 'percentMtdVolume') ? undefined : json['percentMtdVolume'],
        'ytdItems': !runtime_1.exists(json, 'ytdItems') ? undefined : json['ytdItems'],
        'ytdVolume': !runtime_1.exists(json, 'ytdVolume') ? undefined : json['ytdVolume'],
        'percentYtdItems': !runtime_1.exists(json, 'percentYtdItems') ? undefined : json['percentYtdItems'],
        'percentYtdVolume': !runtime_1.exists(json, 'percentYtdVolume') ? undefined : json['percentYtdVolume'],
        'interchangeRegionCode': !runtime_1.exists(json, 'interchangeRegionCode') ? undefined : json['interchangeRegionCode'],
    };
}
exports.InterchangeDetailsFromJSONTyped = InterchangeDetailsFromJSONTyped;
function InterchangeDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNbr': value.merchantNbr,
        'merchantName': value.merchantName,
        'divisionNbr': value.divisionNbr,
        'divisionName': value.divisionName,
        'outletNbr': value.outletNbr,
        'outletName': value.outletName,
        'cardType': value.cardType,
        'qualificationTypeDesc': value.qualificationTypeDesc,
        'percentTotalItems': value.percentTotalItems,
        'percentTotalVolume': value.percentTotalVolume,
        'mcItems': value.mcItems,
        'mcVolume': value.mcVolume,
        'visaItems': value.visaItems,
        'visaVolume': value.visaVolume,
        'totalItems': value.totalItems,
        'totalVolume': value.totalVolume,
        'beginDate': value.beginDate,
        'endDate': value.endDate,
        'formattedCardType': value.formattedCardType,
        'mcProcDate': value.mcProcDate,
        'submittedFeeProgram': value.submittedFeeProgram,
        'interchangeFeeProgram': value.interchangeFeeProgram,
        'interchangeRegion': value.interchangeRegion,
        'interchangeCardIdent': value.interchangeCardIdent,
        'tranType': value.tranType,
        'status': value.status,
        'reversal': value.reversal,
        'loadDate': value.loadDate,
        'debitIchgProgYN': value.debitIchgProgYN,
        'billingDate': value.billingDate,
        'qualType': value.qualType,
        'tItems': value.tItems,
        'tVolume': value.tVolume,
        'percentMcItems': value.percentMcItems,
        'percentMcVolume': value.percentMcVolume,
        'percentVisaItems': value.percentVisaItems,
        'percentVisaVolume': value.percentVisaVolume,
        'productType': value.productType,
        'mtdItems': value.mtdItems,
        'mtdVolume': value.mtdVolume,
        'percentMtdItems': value.percentMtdItems,
        'percentMtdVolume': value.percentMtdVolume,
        'ytdItems': value.ytdItems,
        'ytdVolume': value.ytdVolume,
        'percentYtdItems': value.percentYtdItems,
        'percentYtdVolume': value.percentYtdVolume,
        'interchangeRegionCode': value.interchangeRegionCode,
    };
}
exports.InterchangeDetailsToJSON = InterchangeDetailsToJSON;

import React from "react";
import { Document } from "../../models/document";
import "../../styles/tables.scss";
import "./document.css";
export interface DocumentLayoutProps {
  documents: Document[];
  onOpenDocument: (document: Document) => void;
}

export const DocumentLayout: React.FC<DocumentLayoutProps> = ({
  documents,
  onOpenDocument,
}) => {
  return (
    <div className="document">
      <table className="table table-striped is-fullwidth">
        <tbody>
          <tr>
            <th className="has-text-white">SELECT</th>
            <th className="has-text-white" style={{
              textAlign: 'left'
            }}>TITLE</th>
          </tr>
          {documents.map((document) => (
            <tr key={document.id}>
              <td>
                <div
                  data-testid="deposit-detail-button"
                  onClick={() => onOpenDocument(document)}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={`${process.env.PUBLIC_URL}/images/detail.jpg`}
                    alt="Details"
                  />
                </div>
              </td>
              <td>
                <button
                  className="plain-text"
                  style={{
                    fontSize: 13,
                    color: "purple",
                    textAlign: "left",
                    textDecoration: "underline",
                    cursor: 'pointer',
                  }}
                  onClick={() => onOpenDocument(document)}
                >
                  {document.title}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

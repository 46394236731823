import React from "react";
import { DisputeSummary } from "../../../models/dispute";

export interface OutstandingDisputeTableProps {
  disputes?: DisputeSummary[];
}
export function OutstandingDisputeTable({
  disputes = [],
}: OutstandingDisputeTableProps) {
  return (
    <div>
      <table className="table is-bordered has-text-centered">
        <tbody>
          <tr className="is-vcentered">
            <th className="has-text-white is-uppercase" rowSpan={2}>
              Merchant Number
            </th>
            <th className="has-text-white is-uppercase" rowSpan={2}>
              Merchant Name
            </th>
            <th className="has-text-white is-uppercase" colSpan={2}>
              Outstanding CBKS
            </th>
            <th className="has-text-white is-uppercase" colSpan={2}>
              Outstanding RFCS
            </th>
          </tr>
          <tr>
            <th className="has-text-white is-uppercase"># Items</th>
            <th className="has-text-white is-uppercase">$ Amount</th>
            <th className="has-text-white is-uppercase"># Items</th>
            <th className="has-text-white is-uppercase">$ Amount</th>
          </tr>
          {disputes.map((dispute) => {
            return (
              <tr
                key={`${dispute.merchantName}${dispute.merchantNumber}${dispute.minDaysRemaining}`}
                style={{ height: 26 }}
                className="is-vcentered"
              >
                <td className="is-vcentered">{dispute.merchantNumber}</td>
                <td className="is-vcentered has-text-left has-text-centered">
                  {dispute.merchantName}
                </td>
                <td className="is-vcentered has-text-right has-text-centered">
                  {dispute.cbkCount}
                </td>
                <td className="is-vcentered has-text-right has-text-centered">
                  {dispute.cbkAmount}
                </td>
                <td className="is-vcentered has-text-right has-text-centered">
                  {dispute.rfcCount}
                </td>
                <td className="is-vcentered has-text-right has-text-centered">
                  {dispute.rfcAmount}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

import React from "react";
import { PageTitle } from "../components/page_title";
import { Link } from "react-router-dom";

export const PrivacyPolicy = () => {
  return (
    <div className="content privacy">
      <PageTitle title="" />
      <div className="privacyHeader"><h3>POST Integrations Inc. (&ldquo;Company&rdquo;) <br/>Privacy Policy</h3></div>
      <br/>
      <h4 className="privacySubHeader">Privacy Policy</h4>
      <p>
        As described in this Privacy Policy (&#8220;policy&#8221;) the Company
        is committed to properly using and safeguarding the privacy of all
        information that is collected from you when you visit and use the
        Company website.
      </p>
      <h4 className="privacySubHeader">The Company Commitment</h4>
      <p>
        As described in this Privacy Policy (&#8220;policy&#8221;) the Company
        is committed to properly using and safeguarding the privacy of all
        information that is collected from you when you visit and use the
        Company website.{" "}
      </p>
      <h4 className="privacySubHeader">Quick Reference Guide</h4>
      <div>
        <ul>
          <li>
            <a className="privacyLink" href="#Collection of Information">Collection of Information</a>
          </li>
          <li>
            <a className="privacyLink" href="#Protecting Client Information">
              Protecting Client Information
            </a>
          </li>
          <li>
            <a className="privacyLink" href="#Information Sharing">Information Sharing</a>
          </li>
          <li>
            <a className="privacyLink" href="#Tracking">Tracking</a>
          </li>
          <li>
            <a className="privacyLink" href="#Cookies">Cookies</a>
          </li>
          <li>
            <a className="privacyLink" href="#Links to Third Party Websites">
              Links to Third Party Websites
            </a>
          </li>
          <li>
            <a className="privacyLink" href="#Changes to Privacy Policy">Changes to Privacy Policy</a>
          </li>
        </ul>
      </div>
      <br/>
      <h4 className="privacySubHeader" id="Collection of Information">Collection of Information</h4>
      <div>
        The Company collects, retains and uses information about you only for
        specific business purposes and where the Company reasonably believes
        that it will help administer its business or provide products and
        services to you. <br/>The Company collects the following types of information
        about or from its clients (&#8220;client information&#8221;):
        <ul>
          <li>Name and Contact Information</li>
          <li>Transactional Data Information</li>
          <li>
            Financial Data, including credit card and other financial
            information
          </li>
        </ul>
      </div>
      <br/>
      <h4 className="privacySubHeader" id="Protecting Client Information">Protecting Client Information</h4>
      <p>
        The Company takes comprehensive precautions to protect client
        information. Information is protected both on-line and off-line. The
        Company maintains physical, electronic and procedural safeguards that
        comply with government and industry regulations to guard client
        information.
      </p>
      <p>
        All sensitive information sent from your browser is encrypted en route
        to the Company and decrypted once it reaches the Company server.
        However, information sent to the Company through some channels
        (including email) may not be secure. As a result, the Company asks that
        you do not send confidential information such as cardholder account
        numbers to the Company via unsecured email. These communications should
        be sent to the Company by facsimile, postal mail or telephone.
      </p>
      <h4 className="privacySubHeader" id="Information Sharing">Information Sharing</h4>
      <p>
        The Company is the sole owner of all information collected on the
        website. The Company does not sell, give, or disclose any client
        information gathered on the website to any third party, unless
        disclosure is necessary to: (a) comply with a valid legal inquiry or
        process such as a search warrant, subpoena, statute or court order; (b)
        support your Merchant Agreement, if applicable; (c) protect a physical
        or property threat to you, to others or to the Company; (d) respond to a
        breach or attempted breach of the security of the Company website; (e)
        defend or assert our legal rights; (f) enforce our Terms of Use; or (g)
        comply with requirements of the Card Associations (e.g., VISA,
        MasterCard, American Express, Discover, JCB) or any Bank Sponsor
        thereof. The Company may also transfer information in connection with a
        corporate merger, consolidation, the sale of related assets or corporate
        division or other fundamental corporate change. In the event the Company
        is legally compelled to disclose your information to a third party, we
        will attempt to notify you unless doing so would violate the law or a
        court order.
      </p>
      <h4 className="privacySubHeader" id="Tracking">Tracking</h4>
      <p>
        For security purposes, and to better serve your needs, the Company
        monitors access to this website and traffic within this website. By
        accessing the Company’s website, you agree to have your usage monitored.
        The tracking technology used on this website is typical of many
        commercial websites, and includes Internet Protocol address, browser
        type, browser language, the date and time of your visit, and cookies
        that uniquely identify your browser. The Company uses this information
        to operate, develop and improve its services.
      </p>
      <h4 className="privacySubHeader" id="Cookies">Cookies</h4>
      <p>
        A cookie is a small piece of computer code that enables web servers to
        "identify" users. Each time you login onto the Company’s website, a
        session is initiated and a session cookie is set in order to identify
        you and determine your access privileges. Cookies do not store any of
        the information that you have provided to the website. They are simply
        identifiers.
      </p>
      <p>
        To simplify your on-line experience, the Company uses cookies to
        facilitate your ability to navigate through the website and to reduce
        the amount of information you have to re-enter. The Company also uses
        cookies to track visitor activity which helps the Company determine new
        ways to enhance your on-line experience.
      </p>
      <p>
        You do not have to accept cookies. Most browsers provide you with the
        ability to make a selection that will cause your browser to restrict or
        completely reject cookies. You also have the ability to delete cookie
        files from your hard drive at any time. However, you should know that
        cookies may be necessary to provide you with access to much of the
        content and many of the features of the Company’s website. The Company
        uses cookie technology to enable registered users to move quickly and
        securely through access-controlled areas of the Company’s website.
      </p>
      <p>
        The Company does not use cookies to collect or track personal
        information, or record information that will enable anyone to contact
        you via telephone, email, or regular mail. In addition, when you logout
        of the website, the session cookie is cleared.
      </p>
      <h4 className="privacySubHeader" id="Children">Children</h4>
      <p>The Company is committed to protecting the privacy of children. Therefore, 
        the Company will not knowingly collect personally identifiable information 
        from children under 13. Furthermore, we may, at our discretion, require users 
        under 18 to obtain the consent of a parent or guardian to view certain content, 
        and we may limit access to certain content to users above a specified age. You 
        agree to abide by any such restrictions, and not to help anyone avoid these 
        restrictions.
      </p>
      <h4 className="privacySubHeader" id="Links to Third Party Websites">Links to Third Party Websites</h4>
      <p>
        The Company may provide you with access to information, products or
        services offered on non-affiliated websites through the use of
        hyperlinks that automatically move you from the Company’s website to a
        third party website. However, the Company cannot guarantee how these
        third parties use cookies, and makes no representations or warranties
        regarding non-Company websites or the companies maintaining them. If you
        choose to access non-Company websites through links on the Company’s
        pages, the Company encourages you to review the privacy and security
        polices on those websites before you provide them with any personal or
        financial information. Please see the Company’s &nbsp;
        <Link
        to="/terms"
        className={`privacyLink`}
      >
        Terms and Conditions of Use
      </Link>
        &nbsp; for other terms and conditions governing your access to and use of
        the Company’s website.
      </p>
      <h4 className="privacySubHeader" id="Changes to Privacy Policy">Changes to Privacy Policy</h4>
      <p>
        In the future, the Company may need to change this policy. All changes
        will be made here so that you will always know what information the
        Company will gather, how the Company might use that information and
        whether the Company will disclose it to anyone. Any such changes will be
        effective immediately upon posting here.
      </p>
      <p style={{marginBottom: "20px"}}>Updated - August 1, 2005</p>
    </div>
  );
};

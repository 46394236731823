import React, { useEffect } from "react";
import { EbocomMerchantSearchPanel } from "../../components/ebocom_merchant_search";
import { TableTitle } from "../../components/table_title";
import { useDefaultMerchant } from "../../hooks/use_default_merchant";
import { useMerchants } from "../../hooks/use_merchant";
import { useOutlets } from "../../hooks/use_outlets";
import { ValidatedSearchPage } from "../../layout/search_page";
import { addSuperMerchantNumber } from "../../models/merchant";
import { SortOrder } from "../../models/sort_order";
import { LookupResult } from "../../layout/search_page";
import { downloadExcelFile } from "../../services/download";
import { MerchantService } from "../../services/merchant";
import { OutletListingResult } from "./outlet_listing_result";
import { PageTitle } from "../../components/page_title";

export interface OutletListingContainerProps {
  merchantService: MerchantService;
}
export function OutletListingContainer({
  merchantService,
}: OutletListingContainerProps) {
  const { merchants } = useMerchants(merchantService);
  const { selectedMerchant, updateSelectedMerchant } = useDefaultMerchant();
  const [selectedMerchantNumber, setSelectedMerchantNumber] = React.useState<
    string | undefined
  >(selectedMerchant);
  useEffect(() => {
    setSelectedMerchantNumber(selectedMerchant);
  }, [selectedMerchant]);
  const { outlets, reset, sorting, handleSort, pagination, isLoading } =
    useOutlets(merchantService, selectedMerchantNumber);
  return (
    <div className="outletListing-wrapper">
      <div className="title-bar">
        <PageTitle title="Outlet Listing" />
      </div>
      <ValidatedSearchPage
        searchPanel={
          <EbocomMerchantSearchPanel
            defaultMerchantId={selectedMerchant}
            merchants={addSuperMerchantNumber(merchants)}
            onSelectMerchant={(merchant) => {
              reset();
              updateSelectedMerchant(merchant.number);
              setSelectedMerchantNumber(merchant.number);
            }}
          />
        }
        isLoading={isLoading}
        errorResults={outlets.length === 0 ? isLoading ? LookupResult.StillLoading : LookupResult.ObjectDoesNotExist : LookupResult.OK}
        searchResults={
          outlets.length > 0 && (
            <>
              <TableTitle
                title="Outlet Detail"
                onPageNext={pagination.nextPage}
                onPagePrevious={pagination.previousPage}
                onPageFirst={pagination.firstPage}
                onPageLast={pagination.lastPage}
                pageSize={pagination.pageSize}
                totalResults={pagination.totalResults}
                currentResultCount={outlets.length}
                onExport={async () => {
                  if (!selectedMerchantNumber) return;
                  const response = await merchantService.exportMerchantOutlets({
                    merchantNumber: selectedMerchantNumber,
                    pageSize: pagination.totalResults ?? 0,
                  });
                  if (!response) return;
                  downloadExcelFile(response[0], response[1]);
                }}
                offset={pagination.offset}
              />
              <OutletListingResult
                sortDirection={sorting?.direction ?? SortOrder.ASC}
                onSort={handleSort}
                sortColumn={sorting?.field ?? "merchantName"}
                outlets={outlets}
              />
            </>
          )
        }
      />
    </div>
  );
}

export class ChargebackAging {
  merchantNbr: string = "";
  merchantName: string = "";
  divisionName: string = "";
  outletNbr: string = "";
  outletName: string = "";
  acctNumber: string = "";
  beginDate: string = "";
  endDate: string = "";
  origTranDate: string = "";
  exceptionAmount: string = "";
  exceptionReason: string = "";
  exceptionType: string = "";
  exceptionUsage: string = "";
  exceptionResolved: string = "";
  exceptionClosed: string = "";
  dateResolved: string = "";
  dateClosed: string = "";
  tranLoadFileNumber: string = "";
  tranLoadBatchNumber: string = "";
  tranLoadEntryNumber: string = "";
  exceptionMessage: string = "";
  cardType: string = "";
  daysRemaining: string = "";
  dueDate: string = "";
  maskedAcctNumber: string = "";
  loadFileNumber: string = "";
  loadBatchNumber: string = "";
  loadEntryNumber: string = "";
  loadDate: string = "";
  assetOwner: string = "";
}

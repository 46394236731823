import { useEffect, useRef } from "react";
import queryString from "query-string";

import { useHistory, useLocation } from "react-router-dom";

export const ExternalLinkHandlerRoute = () => {
  const { search } = useLocation();
  const { goBack } = useHistory();
  const { url } = queryString.parse(search);
  const hasHandled = useRef(false);
  useEffect(() => {
    if (hasHandled.current) {
      return;
    }
    hasHandled.current = true;
    if (url && typeof url === "string" && url.indexOf("http") > -1) {
      window.open(url, "_blank");
    }
    goBack();
  }, [url, goBack]);
  return null;
};

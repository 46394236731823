"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisputeRequestToJSON = exports.DisputeRequestFromJSONTyped = exports.DisputeRequestFromJSON = void 0;
var runtime_1 = require("../runtime");
function DisputeRequestFromJSON(json) {
    return DisputeRequestFromJSONTyped(json, false);
}
exports.DisputeRequestFromJSON = DisputeRequestFromJSON;
function DisputeRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNbr': json['merchantNbr'],
        'dateLowString': !runtime_1.exists(json, 'dateLowString') ? undefined : json['dateLowString'],
        'jcb': !runtime_1.exists(json, 'jcb') ? undefined : json['jcb'],
        'dateHighString': !runtime_1.exists(json, 'dateHighString') ? undefined : json['dateHighString'],
        'amex': !runtime_1.exists(json, 'amex') ? undefined : json['amex'],
        'discover': !runtime_1.exists(json, 'discover') ? undefined : json['discover'],
        'mastercard': !runtime_1.exists(json, 'mastercard') ? undefined : json['mastercard'],
        'visa': !runtime_1.exists(json, 'visa') ? undefined : json['visa'],
    };
}
exports.DisputeRequestFromJSONTyped = DisputeRequestFromJSONTyped;
function DisputeRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNbr': value.merchantNbr,
        'dateLowString': value.dateLowString,
        'jcb': value.jcb,
        'dateHighString': value.dateHighString,
        'amex': value.amex,
        'discover': value.discover,
        'mastercard': value.mastercard,
        'visa': value.visa,
    };
}
exports.DisputeRequestToJSON = DisputeRequestToJSON;

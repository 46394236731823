import React from "react";
import { Tooltip } from "./tooltip";

export const SortTooltip = ({ children }: { children: any }) => {
  return (
    <Tooltip.Container>
      {children}
      <Tooltip>SORT</Tooltip>
    </Tooltip.Container>
  );
};

import { Configuration, ContactApi } from "ebocomsdk";
import { FeedbackService } from "../../services/feedback";
import { getPreAuthMiddleware } from "./auth_middleware";
import { getPreLoadingMiddleware, getPostLoadingMiddleware } from "./loading_middleware";

export class EbocomFeedbackService implements FeedbackService {
  private api: ContactApi;
  constructor(private baseUrl: string) {
    this.api = new ContactApi(
      new Configuration({
        basePath: this.baseUrl,
      })
    ).withPreMiddleware(getPreAuthMiddleware(), getPreLoadingMiddleware())
    .withPostMiddleware(getPostLoadingMiddleware());
  }
  contactUs(feedbackMessage: string): void {
    this.api.contactUs({
      feedback: {
        message: feedbackMessage,
      },
    });
  }
}

import React, { useEffect, useMemo, useRef, useState } from "react";
import { SearchPage } from "../../../layout/search_page";
import {
  Transaction,
  TransactionSortField,
  TransactionType,
} from "../../../models/transaction";
import {
  TransactionSearchPanel,
  TransactionSearchPanelParameters,
} from "../../../components/transaction_search_panel";
import { TransactionSearchResult } from "./transaction_search_results";
import {
  TransactionSearchParameters,
  TransactionService,
} from "../../../services/transaction";
import { downloadExcelFile } from "../../../services/download";
import {
  addSuperMerchantNumber,
  create999Merchant,
  Merchant,
} from "../../../models/merchant";
import { MerchantService } from "../../../services/merchant";
import { DivisionService } from "../../../services/division";
import { useMerchants } from "../../../hooks/use_merchant";
import { useDivision } from "../../../hooks/use_division";
import { useTransactions } from "../../../hooks/use_transactions";
import { TableTitle } from "../../../components/table_title";
import { CardType } from "../../../models/card_type";
import { useDefaultMerchant } from "../../../hooks/use_default_merchant";
import { useHistory, useLocation } from "react-router";
import { TxParams } from "../../deposit_details/deposit_activity_route";
import { EbocomWarningMessage } from "../../../components/ebocom_warning_message";
import { NoResults } from "../../../components/no_results";
import { PageTitle } from "../../../components/page_title";
import queryString from "query-string";
import useSuperSearchParametersState from "../../../store/super_search_parameters_state";

export interface TransactionSearchContainerProps {
  transactionService: TransactionService;
  merchantService: MerchantService;
  divisionService: DivisionService;
}
export function TransactionSearchContainer({
  transactionService,
  merchantService,
  divisionService,
}: TransactionSearchContainerProps) {
  const [txSearchParams, setTxSearchParams] = React.useState<
    TransactionSearchParameters | undefined
  >();
  const {
    isLoading,
    transactions: txSearchResults,
    pagination,
    reset,
    error,
    sorting,
    handleSort,
  } = useTransactions(transactionService, txSearchParams);
  const handleReset = () => {
    resetParamsState();
    reset();
    setHasSearched(false);
  };
  const { selectedMerchant, updateSelectedMerchant } = useDefaultMerchant();
  const { merchants } = useMerchants(merchantService);
  const [currentMerchant, setCurrentMerchant] = useState<
    Merchant | undefined
  >();
  const [hasSearched, setHasSearched] = useState(false);
  useEffect(() => {
    if (selectedMerchant && !currentMerchant && merchants) {
      const merchant999 = create999Merchant();
      const merchant = merchants.find((m) => m.number === selectedMerchant);
      if (merchant || selectedMerchant === merchant999.number) {
        setCurrentMerchant(merchant ?? merchant999);
      }
    }
  }, [merchants, currentMerchant, setCurrentMerchant, selectedMerchant]);
  const { divisions, outlets } = useDivision(
    divisionService,
    currentMerchant?.number
  );
  const previousMerchantId = useRef<string | undefined>();
  useEffect(() => {
    if (
      selectedMerchant !== previousMerchantId.current &&
      merchants?.length > 0
    ) {
      setCurrentMerchant(merchants.find((m) => m.number === selectedMerchant));
      previousMerchantId.current = selectedMerchant;
    }
  }, [selectedMerchant, merchants]);

  const location = useLocation();
  const history = useHistory();

  const searchParams: TransactionSearchPanelParameters | null = useSuperSearchParametersState((state) => state.params);

  const { setParamsState, resetParamsState } = useSuperSearchParametersState(
    (state) => ({ setParamsState: state.setParamsState, resetParamsState: state.resetParamsState })
  )

  const triggerSearchRequest = (searchParameters: TransactionSearchPanelParameters) => {
    setHasSearched(true);
    setTxSearchParams(getServiceParams(searchParameters, pagination.offset));
  }

  const onPopState = () => {
    console.log('onPopState -> transaction search container', searchParams);
    if (searchParams) {
      const query = queryString.parse(location.search);
      const paginationOffset = parseInt(query.paginationOffset as string) || 0;
      pagination.setInitialPaginationOffset(paginationOffset);
      updateSelectedMerchant(searchParams.merchantNumber);
      triggerSearchRequest(searchParams);
    }
  }

  useEffect(() => {
    window.history.replaceState(null, '', `${location.pathname}?paginationOffset=${pagination.offset}`);
  }, [pagination.offset, location.pathname])

  const getBatchUrl = (tx: Transaction) => {
    const lifeCycle = tx.lifeCycleIndGroup;
    const tranStatus = tx.transactionStatus;
    const cardType = tx.cardType;
    let parameters: TxParams = {
      merchantNumber: tx.merchantNumber,
      loadFileNumber: tx.loadFileNumber,
      loadBatchNumber: tx.loadBatchNumber,
      loadEntryNumber: tx.loadEntryNumber,
      tranAmount: tx.transactionAmount?.toString(),
      authCode: tx.authCode,
      cardType: tx.cardType,
      cardNumber: tx.cardNumber,
      transactionType: tx.transactionType,
      accountNumber: tx.cardNumber,
      outletNumber: tx.outletNumber,
    };
    let url = "";
    if (
      lifeCycle !== 2 &&
      (tranStatus === "TRANSMITTED" || tranStatus === "RECEIVED")
    ) {
      parameters.date = tx.processDate?.toISOString();
      url = "/depositdetailsoc";
    } else if (cardType === CardType.mastercard || cardType === CardType.visa) {
      parameters.date = tx.billedDate?.toISOString();
      url = "/depositdetails";
    }
    if (url) {
      history.push(`${url}?q=${btoa(JSON.stringify(parameters))}`);
    }
  };

  const onRequestDepositActivity = (transaction: Transaction) => {
    getBatchUrl(transaction);
  };
  const getServiceParams = (
    params: TransactionSearchPanelParameters,
    offset: number
  ): TransactionSearchParameters => {
    return {
      offset,
      limit: 10,
      cardTypes: params?.cardTypes,
      transactionTypes: params?.transactionTypes,
      dateBegin: params?.beginDate,
      dateEnd: params?.endDate,
      amount: params?.amount,
      cardNumber: params?.cardNumber,
      outletName: params?.outletName,
      outletNumber: params?.outletNumber,
      merchantNumber: params?.merchantNumber,
      amountOperator: params?.amountOperator,
      divisionNumber: params?.divisionNumber,
    };
  };
  const results = () => {
    if (error) {
      return <EbocomWarningMessage message={error} />;
    } else if (!hasSearched || isLoading) {
      return null;
    } else {
      return (
        <>
          <TableTitle
            title="Transaction Detail"
            paginationDisabled={isLoading}
            onPageFirst={pagination.firstPage}
            onPageLast={pagination.lastPage}
            onPageNext={pagination.nextPage}
            onPagePrevious={pagination.previousPage}
            offset={pagination.offset}
            currentResultCount={txSearchResults?.length ?? 0}
            totalResults={pagination.totalResults}
            onShowAll={pagination.showAll}
            pageSize={pagination.pageSize}
            onExport={async () => {
              if (!txSearchParams) {
                return;
              }
              const [blob, filename] =
                await transactionService.getTransactionReport(txSearchParams);
              downloadExcelFile(blob, filename);
            }}
          />
          <TransactionSearchResult
            onRequestDepositActivity={onRequestDepositActivity}
            requestSort={(sortField) => {
              pagination.firstPage();
              handleSort(sortField);
            }}
            sortColumn={sorting?.field ?? TransactionSortField.cardNumber}
            sortDirection={sorting?.direction}
            transactions={txSearchResults}
          />
          {txSearchResults.length === 0 && (
            <NoResults.Table text="No Transactions match your search criteria" />
          )}
        </>
      );
    }
  };
  const defaultBeginDate = useMemo(() => {
    const d = new Date();
    d.setFullYear(d.getFullYear() - 1);
    return d;
  }, []);
  const defaultEndDate = useMemo(() => {
    return new Date();
  }, []);

  const search = (searchParameters: TransactionSearchPanelParameters) => {
    console.log(searchParameters);
    setParamsState(searchParameters);
    pagination.setInitialPaginationOffset(0);
    pagination.setPageSize(10);
    triggerSearchRequest(searchParameters);
  }

  return (
    <div className="superSearch-wrapper">
      <div className="title-bar">
        <PageTitle title="Super Search" />
      </div>
      <SearchPage
        isLoading={isLoading}
        searchPanel={
          <>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "11px",
                fontStyle: "italic",
                textTransform: "none",
              }}
            >
              <span className="is-uppercase">Note:&nbsp;</span>
              <span>Card Number and/or Amount will reduce search time</span>
            </div>
            <TransactionSearchPanel
              defaultMerchantId={selectedMerchant}
              merchantRequired={true}
              onReset={handleReset}
              onMerchantChange={(e) => {
                reset();
                setHasSearched(false);
                setCurrentMerchant(e);
                updateSelectedMerchant(e.number);
              }}
              isLoading={isLoading}
              defaultBeginDate={defaultBeginDate}
              defaultEndDate={defaultEndDate}
              onSearch={search}
              currentMerchant={currentMerchant}
              cardTypes={[
                {
                  cardName: "AX",
                  cardValue: CardType.americanExpress,
                  tabIndex: 8,
                },
                { cardName: "DR", cardValue: CardType.discover, tabIndex: 9 },
                { cardName: "JCB", cardValue: CardType.jcb, tabIndex: 10 },
                {
                  cardName: "MC",
                  isCheckedByDefault: true,
                  cardValue: CardType.mastercard,
                  tabIndex: 11,
                },
                {
                  cardName: "VS",
                  isCheckedByDefault: true,
                  cardValue: CardType.visa,
                  tabIndex: 12,
                },
              ]}
              transactionTypes={[
                { txType: TransactionType.AuthOnly, tabIndex: 13 },
                {
                  txType: TransactionType.SalesCredits,
                  isCheckedByDefault: true,
                  tabIndex: 14
                },
                { txType: TransactionType.CBRev, tabIndex: 15 },
                { txType: TransactionType.RFC, tabIndex: 16 },
                {
                  txType: TransactionType.CBLifeCycle,
                  mustBeIsolated: true,
                  requiresMerchant: true,
                  tooltipText: "WARNING: This search does not include Refunds",
                  tabIndex: 17
                },
              ]}
              divisions={divisions}
              merchants={addSuperMerchantNumber(merchants) ?? undefined}
              outlets={outlets}
              onPopState={onPopState}
            />
          </>
        }
        searchResults={results()}
      />
    </div>
  );
}

import React, { useCallback, useState } from "react";
import { useEffect } from "react";
import { FC } from "react";
import useGlobalLoaderState from "../store/global_loader_state";
import { Spinner } from "./spinner";
import { uniq } from "lodash";
import "./global_loader.scss";

export interface GlobalLoaderProps {
  appBaseUrl: string;
  children: React.ReactNode;
}

export const GlobalLoader: FC<GlobalLoaderProps> = ({appBaseUrl, children}) => {

  const runningRequests = useGlobalLoaderState((state) => state.runningRequests);
  const isUserProfileLoaded = useGlobalLoaderState((state) => state.isUserProfileLoaded);

  const [filteredRunningRequests, setFilteredRunningRequests] = useState<string[]>([]);

  const getRequestNameFromUrl = useCallback(
    (url: string) => {
      const urlWithoutBase = url.replace(appBaseUrl, "");
      if (urlWithoutBase.indexOf('userprofile') >= 0) return "User Profile";
      if (urlWithoutBase.indexOf('disputes') >= 0) return "Disputes";
      if (urlWithoutBase.indexOf('merchants') >= 0) return "Merchants";
      if (urlWithoutBase.indexOf('divisions') >= 0) return "Divisions";
      if (urlWithoutBase.indexOf('divisionoutlets') >= 0) return "Division Outlets";
      if (urlWithoutBase.indexOf('transactions') >= 0) return "Transactions";
      if (urlWithoutBase.indexOf('depositdetailsoc') >= 0) return "Transmitted Activities";
      if (urlWithoutBase.indexOf('depositdetailsvmc') >= 0) return "Deposit Activities";
      if (urlWithoutBase.indexOf('agingchargebacks') >= 0) return "Aging Chargebacks";
      if (urlWithoutBase.indexOf('closedchargebacks') >= 0) return "Closed Chargebacks";
      if (urlWithoutBase.indexOf('report/generate') >= 0) return "Generated Report";
      if (urlWithoutBase.indexOf('report') >= 0) return "Report";
      if (urlWithoutBase.indexOf('issuerlookup') >= 0) return "Issuer Look-up";
      if (urlWithoutBase.indexOf('documents') >= 0) return "Documents";
      if (urlWithoutBase.indexOf('outletlist') >= 0) return "Outlet List";
      return "Data";
    }, [appBaseUrl]
  );

  useEffect(() => {
    const transformedRunningRequests = runningRequests.map(requestUrl => getRequestNameFromUrl(requestUrl));
    setFilteredRunningRequests(uniq(transformedRunningRequests));
  }, [runningRequests, getRequestNameFromUrl])

  return (
    <>
      {children}
      {(isUserProfileLoaded && filteredRunningRequests.length > 0) && (
        <div className="global-loader-container">
          <div className="global-loader">
            <Spinner size={30} lineWidth={5} />
          </div>
        </div>
      )}
    </>
  );
}
"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuthOnlyToJSON = exports.AuthOnlyFromJSONTyped = exports.AuthOnlyFromJSON = void 0;
var runtime_1 = require("../runtime");
function AuthOnlyFromJSON(json) {
    return AuthOnlyFromJSONTyped(json, false);
}
exports.AuthOnlyFromJSON = AuthOnlyFromJSON;
function AuthOnlyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNumber': !runtime_1.exists(json, 'merchantNumber') ? undefined : json['merchantNumber'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'outletName': !runtime_1.exists(json, 'outletName') ? undefined : json['outletName'],
        'merchantBatchNumber': !runtime_1.exists(json, 'merchantBatchNumber') ? undefined : json['merchantBatchNumber'],
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'cardNumber': !runtime_1.exists(json, 'cardNumber') ? undefined : json['cardNumber'],
        'posEntryMode': !runtime_1.exists(json, 'posEntryMode') ? undefined : json['posEntryMode'],
        'transactionDate': !runtime_1.exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'settleDate': !runtime_1.exists(json, 'settleDate') ? undefined : (new Date(json['settleDate'])),
        'billingDate': !runtime_1.exists(json, 'billingDate') ? undefined : (new Date(json['billingDate'])),
        'activityType': !runtime_1.exists(json, 'activityType') ? undefined : json['activityType'],
        'amount': !runtime_1.exists(json, 'amount') ? undefined : json['amount'],
        'outletNbr': !runtime_1.exists(json, 'outletNbr') ? undefined : json['outletNbr'],
        'divisionNbr': !runtime_1.exists(json, 'divisionNbr') ? undefined : json['divisionNbr'],
        'formattedCardNumber': !runtime_1.exists(json, 'formattedCardNumber') ? undefined : json['formattedCardNumber'],
        'formattedTranDate': !runtime_1.exists(json, 'formattedTranDate') ? undefined : json['formattedTranDate'],
        'formattedSettleDate': !runtime_1.exists(json, 'formattedSettleDate') ? undefined : json['formattedSettleDate'],
        'formattedBillingDate': !runtime_1.exists(json, 'formattedBillingDate') ? undefined : json['formattedBillingDate'],
        'formattedAmount': !runtime_1.exists(json, 'formattedAmount') ? undefined : json['formattedAmount'],
        'formattedCardType': !runtime_1.exists(json, 'formattedCardType') ? undefined : json['formattedCardType'],
        'tranStatus': !runtime_1.exists(json, 'tranStatus') ? undefined : json['tranStatus'],
        'isBadTransaction': !runtime_1.exists(json, 'isBadTransaction') ? undefined : json['isBadTransaction'],
        'expDate': !runtime_1.exists(json, 'expDate') ? undefined : json['expDate'],
        'formattedExpDate': !runtime_1.exists(json, 'formattedExpDate') ? undefined : json['formattedExpDate'],
        'cardNumberMasked': !runtime_1.exists(json, 'cardNumberMasked') ? undefined : json['cardNumberMasked'],
        'settledInterchangeCode': !runtime_1.exists(json, 'settledInterchangeCode') ? undefined : json['settledInterchangeCode'],
        'settledInterchangeDesc': !runtime_1.exists(json, 'settledInterchangeDesc') ? undefined : json['settledInterchangeDesc'],
        'productType': !runtime_1.exists(json, 'productType') ? undefined : json['productType'],
        'qualificationTypeDesc': !runtime_1.exists(json, 'qualificationTypeDesc') ? undefined : json['qualificationTypeDesc'],
        'downgradeReason': !runtime_1.exists(json, 'downgradeReason') ? undefined : json['downgradeReason'],
        'mcc': !runtime_1.exists(json, 'mcc') ? undefined : json['mcc'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'totalItems': !runtime_1.exists(json, 'totalItems') ? undefined : json['totalItems'],
        'totalVolume': !runtime_1.exists(json, 'totalVolume') ? undefined : json['totalVolume'],
        'loadBatch': !runtime_1.exists(json, 'loadBatch') ? undefined : json['loadBatch'],
        'batchNbr': !runtime_1.exists(json, 'batchNbr') ? undefined : json['batchNbr'],
        'tranNbr': !runtime_1.exists(json, 'tranNbr') ? undefined : json['tranNbr'],
        'cardTypes': !runtime_1.exists(json, 'cardTypes') ? undefined : json['cardTypes'],
        'percentMonthlyItems': !runtime_1.exists(json, 'percentMonthlyItems') ? undefined : json['percentMonthlyItems'],
        'percentYearlyItems': !runtime_1.exists(json, 'percentYearlyItems') ? undefined : json['percentYearlyItems'],
    };
}
exports.AuthOnlyFromJSONTyped = AuthOnlyFromJSONTyped;
function AuthOnlyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNumber': value.merchantNumber,
        'merchantName': value.merchantName,
        'outletName': value.outletName,
        'merchantBatchNumber': value.merchantBatchNumber,
        'cardType': value.cardType,
        'cardNumber': value.cardNumber,
        'posEntryMode': value.posEntryMode,
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString()),
        'settleDate': value.settleDate === undefined ? undefined : (value.settleDate.toISOString()),
        'billingDate': value.billingDate === undefined ? undefined : (value.billingDate.toISOString()),
        'activityType': value.activityType,
        'amount': value.amount,
        'outletNbr': value.outletNbr,
        'divisionNbr': value.divisionNbr,
        'formattedCardNumber': value.formattedCardNumber,
        'formattedTranDate': value.formattedTranDate,
        'formattedSettleDate': value.formattedSettleDate,
        'formattedBillingDate': value.formattedBillingDate,
        'formattedAmount': value.formattedAmount,
        'formattedCardType': value.formattedCardType,
        'tranStatus': value.tranStatus,
        'isBadTransaction': value.isBadTransaction,
        'expDate': value.expDate,
        'formattedExpDate': value.formattedExpDate,
        'cardNumberMasked': value.cardNumberMasked,
        'settledInterchangeCode': value.settledInterchangeCode,
        'settledInterchangeDesc': value.settledInterchangeDesc,
        'productType': value.productType,
        'qualificationTypeDesc': value.qualificationTypeDesc,
        'downgradeReason': value.downgradeReason,
        'mcc': value.mcc,
        'region': value.region,
        'totalItems': value.totalItems,
        'totalVolume': value.totalVolume,
        'loadBatch': value.loadBatch,
        'batchNbr': value.batchNbr,
        'tranNbr': value.tranNbr,
        'cardTypes': value.cardTypes,
        'percentMonthlyItems': value.percentMonthlyItems,
        'percentYearlyItems': value.percentYearlyItems,
    };
}
exports.AuthOnlyToJSON = AuthOnlyToJSON;

import create from 'zustand';

interface GlobalLoaderState {
    runningRequests: string[];
    addRunningRequest: (requestUrl: string) => void;
    subtractRunningRequest: (requestUrl: string) => void;
    resetRequestsCount: () => void;
    isUserProfileLoaded: boolean;
    setIsUserProfileLoaded: () => void;
}

const useGlobalLoaderState = create<GlobalLoaderState>((set) => ({
    runningRequests: [],
    addRunningRequest: (requestUrl: string) => set((state) => ({
        ...state,
        runningRequests: [...state.runningRequests, requestUrl],
    })),
    subtractRunningRequest: (requestUrl: string) => set((state) => ({
        ...state,
        runningRequests: state.runningRequests.filter((value, index, array) => index !== array.lastIndexOf(requestUrl)),
    })),
    resetRequestsCount: () => set((state) => ({
        ...state,
        runningRequests: [],
    })),
    isUserProfileLoaded: false,
    setIsUserProfileLoaded: () => set((state) => ({
        ...state,
        isUserProfileLoaded: true,
    })),
}))

export default useGlobalLoaderState;
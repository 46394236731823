"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChargebackAgingToJSON = exports.ChargebackAgingFromJSONTyped = exports.ChargebackAgingFromJSON = void 0;
var runtime_1 = require("../runtime");
function ChargebackAgingFromJSON(json) {
    return ChargebackAgingFromJSONTyped(json, false);
}
exports.ChargebackAgingFromJSON = ChargebackAgingFromJSON;
function ChargebackAgingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantNbr': !runtime_1.exists(json, 'merchantNbr') ? undefined : json['merchantNbr'],
        'merchantName': !runtime_1.exists(json, 'merchantName') ? undefined : json['merchantName'],
        'divisionName': !runtime_1.exists(json, 'divisionName') ? undefined : json['divisionName'],
        'outletNbr': !runtime_1.exists(json, 'outletNbr') ? undefined : json['outletNbr'],
        'outletName': !runtime_1.exists(json, 'outletName') ? undefined : json['outletName'],
        'acctNumber': !runtime_1.exists(json, 'acctNumber') ? undefined : json['acctNumber'],
        'beginDate': !runtime_1.exists(json, 'beginDate') ? undefined : json['beginDate'],
        'endDate': !runtime_1.exists(json, 'endDate') ? undefined : json['endDate'],
        'origTranDate': !runtime_1.exists(json, 'origTranDate') ? undefined : json['origTranDate'],
        'exceptionAmount': !runtime_1.exists(json, 'exceptionAmount') ? undefined : json['exceptionAmount'],
        'exceptionReason': !runtime_1.exists(json, 'exceptionReason') ? undefined : json['exceptionReason'],
        'exceptionType': !runtime_1.exists(json, 'exceptionType') ? undefined : json['exceptionType'],
        'exceptionUsage': !runtime_1.exists(json, 'exceptionUsage') ? undefined : json['exceptionUsage'],
        'exceptionResolved': !runtime_1.exists(json, 'exceptionResolved') ? undefined : json['exceptionResolved'],
        'exceptionClosed': !runtime_1.exists(json, 'exceptionClosed') ? undefined : json['exceptionClosed'],
        'dateResolved': !runtime_1.exists(json, 'dateResolved') ? undefined : json['dateResolved'],
        'dateClosed': !runtime_1.exists(json, 'dateClosed') ? undefined : json['dateClosed'],
        'tranLoadFileNumber': !runtime_1.exists(json, 'tranLoadFileNumber') ? undefined : json['tranLoadFileNumber'],
        'tranLoadBatchNumber': !runtime_1.exists(json, 'tranLoadBatchNumber') ? undefined : json['tranLoadBatchNumber'],
        'tranLoadEntryNumber': !runtime_1.exists(json, 'tranLoadEntryNumber') ? undefined : json['tranLoadEntryNumber'],
        'exceptionMessage': !runtime_1.exists(json, 'exceptionMessage') ? undefined : json['exceptionMessage'],
        'cardType': !runtime_1.exists(json, 'cardType') ? undefined : json['cardType'],
        'daysRemaining': !runtime_1.exists(json, 'daysRemaining') ? undefined : json['daysRemaining'],
        'dueDate': !runtime_1.exists(json, 'dueDate') ? undefined : json['dueDate'],
        'maskedAcctNumber': !runtime_1.exists(json, 'maskedAcctNumber') ? undefined : json['maskedAcctNumber'],
        'loadFileNumber': !runtime_1.exists(json, 'loadFileNumber') ? undefined : json['loadFileNumber'],
        'loadBatchNumber': !runtime_1.exists(json, 'loadBatchNumber') ? undefined : json['loadBatchNumber'],
        'loadEntryNumber': !runtime_1.exists(json, 'loadEntryNumber') ? undefined : json['loadEntryNumber'],
        'loadDate': !runtime_1.exists(json, 'loadDate') ? undefined : json['loadDate'],
        'region': !runtime_1.exists(json, 'region') ? undefined : json['region'],
        'assetOwner': !runtime_1.exists(json, 'assetOwner') ? undefined : json['assetOwner'],
        'businessLegalName': !runtime_1.exists(json, 'businessLegalName') ? undefined : json['businessLegalName'],
    };
}
exports.ChargebackAgingFromJSONTyped = ChargebackAgingFromJSONTyped;
function ChargebackAgingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantNbr': value.merchantNbr,
        'merchantName': value.merchantName,
        'divisionName': value.divisionName,
        'outletNbr': value.outletNbr,
        'outletName': value.outletName,
        'acctNumber': value.acctNumber,
        'beginDate': value.beginDate,
        'endDate': value.endDate,
        'origTranDate': value.origTranDate,
        'exceptionAmount': value.exceptionAmount,
        'exceptionReason': value.exceptionReason,
        'exceptionType': value.exceptionType,
        'exceptionUsage': value.exceptionUsage,
        'exceptionResolved': value.exceptionResolved,
        'exceptionClosed': value.exceptionClosed,
        'dateResolved': value.dateResolved,
        'dateClosed': value.dateClosed,
        'tranLoadFileNumber': value.tranLoadFileNumber,
        'tranLoadBatchNumber': value.tranLoadBatchNumber,
        'tranLoadEntryNumber': value.tranLoadEntryNumber,
        'exceptionMessage': value.exceptionMessage,
        'cardType': value.cardType,
        'daysRemaining': value.daysRemaining,
        'dueDate': value.dueDate,
        'maskedAcctNumber': value.maskedAcctNumber,
        'loadFileNumber': value.loadFileNumber,
        'loadBatchNumber': value.loadBatchNumber,
        'loadEntryNumber': value.loadEntryNumber,
        'loadDate': value.loadDate,
        'region': value.region,
        'assetOwner': value.assetOwner,
        'businessLegalName': value.businessLegalName,
    };
}
exports.ChargebackAgingToJSON = ChargebackAgingToJSON;

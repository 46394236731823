"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationToJSON = exports.NotificationFromJSONTyped = exports.NotificationFromJSON = void 0;
var runtime_1 = require("../runtime");
function NotificationFromJSON(json) {
    return NotificationFromJSONTyped(json, false);
}
exports.NotificationFromJSON = NotificationFromJSON;
function NotificationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'notificationURL': !runtime_1.exists(json, 'notificationURL') ? undefined : json['notificationURL'],
        'notificationDone': !runtime_1.exists(json, 'notificationDone') ? undefined : json['notificationDone'],
        'notificationCN': !runtime_1.exists(json, 'notificationCN') ? undefined : json['notificationCN'],
        'notificationAckDate': !runtime_1.exists(json, 'notificationAckDate') ? undefined : json['notificationAckDate'],
    };
}
exports.NotificationFromJSONTyped = NotificationFromJSONTyped;
function NotificationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'notificationURL': value.notificationURL,
        'notificationDone': value.notificationDone,
        'notificationCN': value.notificationCN,
        'notificationAckDate': value.notificationAckDate,
    };
}
exports.NotificationToJSON = NotificationToJSON;

export abstract class DateService {
  public static formatter = new Intl.DateTimeFormat(undefined, {
    month: "2-digit",
    year: "2-digit",
    day: "2-digit",
    timeZone: "UTC",
  });
  public static formatDate(date?: Date, separator = "/"): string {
    if (!date) return "";
    const parts = this.formatter.formatToParts(date);
    return [
      parts.find((t) => t.type === "month")?.value,
      parts.find((t) => t.type === "day")?.value,
      parts.find((t) => t.type === "year")?.value,
    ].join(separator);
  }
  public static formatForInput(date: Date): string {
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  }
  public static resetTimeZoneInDateString(date: string) {
    return `${date}T00:00:00`;
  }
}

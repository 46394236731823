import faker from "faker";
import { DivisionOutlet } from "../models/division";

export function mockOutlet(): DivisionOutlet {
  const outlet = new DivisionOutlet();
  outlet.number = faker.datatype.number(100000).toString();
  outlet.name = faker.company.companyName();
  outlet.divisionName = faker.company.companyName();
  outlet.divisionNumber = faker.datatype.number(200000).toString();
  return outlet;
}

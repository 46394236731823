import React from "react";
import { useHistory } from "react-router-dom";
import { DisputeSummary } from "../../../models/dispute";
import { DisputesSummaryTable } from "./disputes_summary_table";

export interface DisputeSummaryViewProps {
  disputes: DisputeSummary[];
}
export function DisputeSummaryView(props: DisputeSummaryViewProps) {
  const history = useHistory();
  const hasUrgentDisputes = props.disputes.some(
    (d) => typeof d.minDaysRemaining === "number" && d.minDaysRemaining <= 0
  );
  function setLogin() {
    window.localStorage.setItem("Loggedin", "true");
    history.push("/disputes/aging")
  };
  return (
    <div
      className="is-flex-direction-column is-flex is-align-items-center"
    >
      <div>
        <span
          className="title is-4"
          style={{
            color: "rgb(57,40,82)",
            fontWeight: 700,
            fontSize: 16,
          }}
        >
          Outstanding Disputes Summary
        </span>
      </div>
      {!hasUrgentDisputes && (
        <div
          style={{
            fontSize: 10,
            fontWeight: 700,
            marginBottom: 10,
          }}
        >
          Please respond to outstanding Disputes ASAP
        </div>
      )}
      {hasUrgentDisputes && (
        <div>
          <DueTodayWarning />
        </div>
      )}
      <button
        className="button is-text"
        style={{
          fontSize: 12,
          color: "purple",
        }}
        onClick={() => setLogin()}
      >
        Go to Chargeback/RFC Aging
      </button>
      <DisputesSummaryTable disputes={props.disputes} />
    </div>
  );
}

function DueTodayWarning() {
  return (
    <div
      data-testid="due-today-warning"
      style={{
        backgroundColor: "#FFFF33",
        color: "red",
        fontWeight: 700,
        padding: "2px 30px",
        marginTop: 5,
        fontSize: 18,
        fontFamily: "Times New Roman",
      }}
    >
      URGENT - Disputes Due Today!
    </div>
  );
}

import React from "react";
import { DisputeSummary } from "../../../models/dispute";
import "./disputes_summary_table.scss";

export interface DisputesSummaryTableProps {
  disputes?: DisputeSummary[];
}

export function DisputesSummaryTable({
  disputes = [],
}: DisputesSummaryTableProps) {
  const rows = disputes.map((dispute, index) => (
    <DisputesSummaryTableRow dispute={dispute} key={index} />
  ));
  return (
    <div
      data-testid="disputes-summary-table"
      className="is-uppercase dispute-summary-table"
    >
      <table className="table is-bordered">
        <tbody>
          <tr>
            <th className="has-text-white has-text-centered" rowSpan={2}>
              Merchant Number
            </th>
            <th className="has-text-white has-text-centered" rowSpan={2}>
              Merchant Name
            </th>
            <th className="has-text-white has-text-centered" colSpan={2}>
              Outstanding CBKS
            </th>
            <th className="has-text-white has-text-centered" colSpan={2}>
              Outstanding RFCS
            </th>
          </tr>
          <tr>
            <th className="has-text-white"># Items</th>
            <th className="has-text-white">$ Amount</th>
            <th className="has-text-white"># Items</th>
            <th className="has-text-white">$ Amount</th>
          </tr>
          {rows}
        </tbody>
      </table>
    </div>
  );
}

interface DisputeRowProps {
  dispute: DisputeSummary;
}
function DisputesSummaryTableRow({ dispute }: DisputeRowProps) {
  const isUrgent = dispute?.minDaysRemaining && dispute.minDaysRemaining <= 0;
  return (
    <tr
      style={{
        backgroundColor: isUrgent ? "#FFFF33" : undefined,
        color: isUrgent ? "red" : undefined,
      }}
    >
      <td className="is-vcentered has-text-centered">
        {dispute.merchantNumber}
      </td>
      <td className="is-vcentered has-text-left">{dispute.merchantName}</td>
      <td className="is-vcentered has-text-centered">{dispute.cbkCount}</td>
      <td className="is-vcentered has-text-centered">{dispute.cbkAmount}</td>
      <td className="is-vcentered has-text-centered">{dispute.rfcCount}</td>
      <td className="is-vcentered has-text-centered">{dispute.rfcAmount}</td>
    </tr>
  );
}

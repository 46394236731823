export class Division {
  name: string = "";
  number: string = "";
}

export class DivisionOutlet {
  number: string = "";
  name: string = "";
  divisionNumber: string = "";
  divisionName: string = "";
}

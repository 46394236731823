import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { create999Merchant, Merchant } from "../models/merchant";
import { EbocomSelect } from "./select";

export interface EbocomMerchantSearchProps {
  merchants: Merchant[];
  defaultMerchantId?: string;
  onSelectMerchant(merchant: Merchant): void;
}
export const EbocomMerchantSearchPanel = ({
  merchants,
  defaultMerchantId,
  onSelectMerchant,
}: EbocomMerchantSearchProps) => {
  const MERCHANT_NAME_INPUT = "merchant-name";
  const MERCHANT_NUMBER_INPUT = "merchant-number";
  const numbers = merchants.map((m) => ({ value: m.number, label: m.number }));
  const merchantsSortedByName = merchants.filter((merchant) => merchant.number !== '9999999999')
    .sort((a, b) => a.name.localeCompare(b.name));
  const names = [create999Merchant(), ...merchantsSortedByName].map((m) => ({
    value: m.number,
    label: m.name,
  }));
  const { getValues, control, setValue } = useForm();
  useEffect(() => {
    if (defaultMerchantId) {
      setValue(
        MERCHANT_NUMBER_INPUT,
        numbers.find(({ value }) => value === defaultMerchantId)
      );
      setValue(
        MERCHANT_NAME_INPUT,
        names.find(({ value }) => value === defaultMerchantId)
      );
    }
  }, [names, numbers, defaultMerchantId, setValue]);
  const handleChange = () => {
    const merchantValue = getValues(MERCHANT_NUMBER_INPUT);
    if (!merchantValue?.value) {
      return;
    }
    const merchant = merchants.find((m) => m.number === merchantValue.value);
    if (merchant) {
      onSelectMerchant(merchant);
    }
  };
  return (
    <table
      className="table is-bordered has-text-centered"
      style={{ width: 293 }}
    >
      <tbody>
        <tr style={{ height: 38 }}>
          <th className="has-text-white is-uppercase">Merchant Number</th>
        </tr>
        <tr style={{ height: 38 }}>
          <td className="is-vcentered">
            <div className="is-flex is-fullwidth">
              <Controller
                name={MERCHANT_NUMBER_INPUT}
                control={control}
                render={({ field: { onChange, ...rest } }) => (
                  <EbocomSelect
                    data-testid={"merchant-number-input"}
                    className="input has-text-left"
                    placeholder="Select Merchant Number"
                    options={numbers}
                    defaultValue={numbers.find(
                      (n) => n.value === defaultMerchantId
                    )}
                    onChange={(e) => {
                      onChange();

                      setValue(
                        MERCHANT_NAME_INPUT,
                        names.find((n) => n.value === e.value)
                      );
                      setValue(MERCHANT_NUMBER_INPUT, e);
                      handleChange();
                    }}
                    {...rest}
                  />
                )}
              />
            </div>
          </td>
        </tr>
        <tr style={{ height: 38 }}>
          <th className="has-text-white is-uppercase">Merchant Name</th>
        </tr>
        <tr style={{ height: 38 }}>
          <td className="is-vcentered">
            <Controller
              name={MERCHANT_NAME_INPUT}
              control={control}
              render={({ field: { onChange, ...rest } }) => (
                <EbocomSelect
                  data-testid="merchant-name-input"
                  className="input"
                  options={names}
                  placeholder="Select Merchant Name"
                  defaultValue={names.find(
                    (n) => n.value === defaultMerchantId
                  )}
                  onChange={(e) => {
                    onChange();

                    setValue(MERCHANT_NAME_INPUT, e);
                    setValue(
                      MERCHANT_NUMBER_INPUT,
                      numbers.find((n) => n.value === e.value)
                    );
                    onSelectMerchant(e.value);
                    handleChange();
                  }}
                  {...rest}
                />
              )}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

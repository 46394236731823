import React from "react";
import { UserPrivilege } from "../../models/user";

export interface UserPrivilegeTableProps {
  userPrivileges: UserPrivilege[];
}
export function UserPrivilegeTable(props: UserPrivilegeTableProps) {
  return (
    <table className="table is-bordered is-fullwidth">
      <tbody>
        <tr className="has-text-centered" style={{ height: 41 }}>
          <th className="has-text-white is-uppercase">Order</th>
          <th className="has-text-white is-uppercase">Category</th>
          <th className="has-text-white is-uppercase">Option</th>
          <th className="has-text-white is-uppercase">View</th>
        </tr>
        {props.userPrivileges.map((userPrivilege, index, arr) => {
          return (
            <tr>
              <td className="is-vcentered" style={{ height: 22 }}>
                {(index === 0 ||
                  arr[index - 1].order !== userPrivilege.order) &&
                  userPrivilege.order}
              </td>
              <td className="has-text-weight-bold is-vcentered">
                {(index === 0 ||
                  arr[index - 1].category !== userPrivilege.category) &&
                  userPrivilege.category}
              </td>
              <td className="is-vcentered" style={{ height: 22 }}>
                {userPrivilege.option}
              </td>
              <td className="is-vcentered" style={{ height: 22 }}>
                <input
                  name="locked"
                  type="checkbox"
                  checked={userPrivilege.enabled}
                  disabled={true}
                />
                &nbsp;
                <label htmlFor="locked" className="is-uppercase">
                  {userPrivilege.enabled && "Enabled"}
                </label>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

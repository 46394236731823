// export type DocumentRoutes =
//   | "bestpractices"
//   | "clientnotices"
//   | "datasecurity"
//   | "newsletter"
//   | "refdocsother"

import { DocumentType } from "./models/document";

//   | "refdocspost";
export enum DocumentRoutes {
  bestpractices = "bestpractices",
  clientnotices = "clientnotices",
  datasecurity = "datasecurity",
  newsletter = "newsletter",
  refdocsother = "refdocsother",
  refdocspost = "refdocspost",
}

export const DocumentPathToTypesMap: { [key: string]: DocumentType[] } = {
  [DocumentRoutes.bestpractices]: [DocumentType.BestPractices],
  [DocumentRoutes.clientnotices]: [DocumentType.ClientNotices],
  [DocumentRoutes.datasecurity]: [DocumentType.DataSecurity],
  [DocumentRoutes.newsletter]: [DocumentType.Newsletter],
  [DocumentRoutes.refdocsother]: [
    DocumentType.RefdocsOther,
    DocumentType.RefdocsPost,
  ],
  [DocumentRoutes.refdocspost]: [
    DocumentType.RefdocsPost,
    DocumentType.RefdocsOther,
  ],
};

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionStatusToJSON = exports.TransactionStatusFromJSONTyped = exports.TransactionStatusFromJSON = exports.TransactionStatus = void 0;
/**
 * Type of Transaction Statuses
 * @export
 * @enum {string}
 */
var TransactionStatus;
(function (TransactionStatus) {
    TransactionStatus["Unknown"] = "UNKNOWN";
    TransactionStatus["Approved"] = "APPROVED";
    TransactionStatus["Declined"] = "DECLINED";
    TransactionStatus["Transmitted"] = "TRANSMITTED";
    TransactionStatus["Paid"] = "PAID";
    TransactionStatus["Received"] = "RECEIVED";
    TransactionStatus["Rejected"] = "REJECTED";
    TransactionStatus["Open"] = "OPEN";
    TransactionStatus["Pending"] = "PENDING";
    TransactionStatus["Fulfilled"] = "FULFILLED";
    TransactionStatus["Disputed"] = "DISPUTED";
    TransactionStatus["Closed"] = "CLOSED";
    TransactionStatus["NoResponse"] = "NO_RESPONSE";
})(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
function TransactionStatusFromJSON(json) {
    return TransactionStatusFromJSONTyped(json, false);
}
exports.TransactionStatusFromJSON = TransactionStatusFromJSON;
function TransactionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.TransactionStatusFromJSONTyped = TransactionStatusFromJSONTyped;
function TransactionStatusToJSON(value) {
    return value;
}
exports.TransactionStatusToJSON = TransactionStatusToJSON;

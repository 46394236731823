"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewContactsUtilToJSON = exports.ViewContactsUtilFromJSONTyped = exports.ViewContactsUtilFromJSON = void 0;
var runtime_1 = require("../runtime");
function ViewContactsUtilFromJSON(json) {
    return ViewContactsUtilFromJSONTyped(json, false);
}
exports.ViewContactsUtilFromJSON = ViewContactsUtilFromJSON;
function ViewContactsUtilFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'selectedrow': !runtime_1.exists(json, 'selectedrow') ? undefined : json['selectedrow'],
        'anchorpage': !runtime_1.exists(json, 'anchorpage') ? undefined : json['anchorpage'],
    };
}
exports.ViewContactsUtilFromJSONTyped = ViewContactsUtilFromJSONTyped;
function ViewContactsUtilToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'selectedrow': value.selectedrow,
        'anchorpage': value.anchorpage,
    };
}
exports.ViewContactsUtilToJSON = ViewContactsUtilToJSON;

import React, { useEffect, useMemo, useState } from "react";
import { PageTitle } from "../../components/page_title";
import { TableTitle } from "../../components/table_title";
import { TransactionSearchPanel, TransactionSearchPanelParameters } from "../../components/transaction_search_panel";
import { useDefaultMerchant } from "../../hooks/use_default_merchant";
import {
  DrilldownFields,
  useDepositActivity,
} from "../../hooks/use_deposit_activity";
import { useMerchants } from "../../hooks/use_merchant";
import { LookupResult, ValidatedSearchPage } from "../../layout/search_page";
import { CardType } from "../../models/card_type";
import { DepositDetail } from "../../models/deposit_detail";
import { addSuperMerchantNumber, Merchant } from "../../models/merchant";
import { SalesCredit } from "../../models/sales_credit";
import { TransactionType } from "../../models/transaction";
import { downloadExcelFile } from "../../services/download";
import { MerchantService } from "../../services/merchant";
import {
  DepositActivity,
  DepositActivityParams,
  DepositActivityTable,
  TransmittedService,
} from "../../services/transmitted";
import { DepositDetailTable } from "./components/deposit_detail_table";
import { isEmpty as lodashIsEmpty } from "lodash";
import { getFormattedTransactionAmount } from "../../utils";

export interface DepositActivitySearchParams {
  merchantNumber?: string;
  outletNumber?: string;
  date?: Date;
  loadFileNumber?: string;
  loadEntryNumber?: string;
  loadBatchNumber?: string;
  cardNumber?: string;
  cardType?: CardType;
  transactionType?: string;
  transactionAmount?: string;
  authCode?: string;
}
export interface DepositActivityContainerProps {
  merchantService: MerchantService;
  transmittedService: TransmittedService;
  defaultSearchParameters?: DepositActivitySearchParams;
}
type Tables = keyof Pick<
  DepositActivityParams,
  | "pageOffsetSummary"
  | "pageOffsetChargeback"
  | "pageOffsetDate"
  | "pageOffsetMerchant"
  | "pageOffsetOutlet"
  | "pageOffsetTransaction"
>;
type TablePages = Record<Tables, number>;
export function DepositActivityContainer({
  merchantService,
  transmittedService,
  defaultSearchParameters,
}: DepositActivityContainerProps) {
  const { merchants } = useMerchants(merchantService);
  const [currentMerchant, setCurrentMerchant] = useState<
    Merchant | undefined
  >();
  const defaultBeginDate = useMemo(
    function () {
      if (defaultSearchParameters?.date) {
        return defaultSearchParameters.date;
      }
      const d = new Date();
      d.setDate(d.getDate() - 1);
      d.setMonth(d.getMonth() - 1);
      return d;
    },
    [defaultSearchParameters?.date]
  );
  const defaultEndDate = useMemo(() => {
    if (defaultSearchParameters?.date) {
      return defaultSearchParameters.date;
    }
    const d = new Date();
    d.setDate(d.getDate() - 1);
    return d;
  }, [defaultSearchParameters?.date]);

  const { updateSelectedMerchant, selectedMerchant } = useDefaultMerchant();
  const [parameters, setParameters] = React.useState<DepositActivityParams>();
  useEffect(() => {
    if (
      (selectedMerchant || defaultSearchParameters?.merchantNumber) &&
      !parameters
    ) {
      // const isSaleOrCredit =
      //   defaultSearchParameters?.transactionType === "SALE" ||
      //   defaultSearchParameters?.transactionType === "CREDIT";
      console.log('useEffect setting parameters default - deposit activity container');
      setParameters({
        beginDate: defaultBeginDate,
        endDate: defaultEndDate,
        filterOutletNumber: defaultSearchParameters?.outletNumber
          ? parseInt(defaultSearchParameters.outletNumber!, 10)
          : undefined,
        authCode: defaultSearchParameters?.authCode,
        transactionAmount:
          getFormattedTransactionAmount(defaultSearchParameters?.transactionAmount, defaultSearchParameters?.transactionType),
        merchantNumber: (defaultSearchParameters?.merchantNumber ??
          selectedMerchant) as string,
        loadBatchNumber: defaultSearchParameters?.loadBatchNumber,
        loadFileNumber: defaultSearchParameters?.loadFileNumber,
        loadEntryNumber: defaultSearchParameters?.loadEntryNumber,
        cardNumber: defaultSearchParameters?.cardNumber,
        filterCardType: defaultSearchParameters?.cardType,
        filterCardType2: defaultSearchParameters?.cardType,
        cardTypes: [CardType.visa, CardType.mastercard],
        tranType: defaultSearchParameters?.transactionType as TransactionType,
      });
    }
  }, [
    parameters,
    defaultSearchParameters?.cardNumber,
    defaultSearchParameters?.transactionType,
    defaultSearchParameters?.cardType,
    defaultSearchParameters?.transactionAmount,
    defaultSearchParameters?.authCode,
    selectedMerchant,
    defaultEndDate,
    defaultBeginDate,
    defaultSearchParameters?.outletNumber,
    defaultSearchParameters?.merchantNumber,
    defaultSearchParameters?.loadBatchNumber,
    defaultSearchParameters?.loadEntryNumber,
    defaultSearchParameters?.loadFileNumber,
  ]);
  const {
    depositActivity,
    loading: isLoading,
    pagination,
    compiledParameters,
    clearDrilldowns,
    clearPagination,
    drilldownSelections,
    setDrilldownSelections,
  } = useDepositActivity(transmittedService, parameters);
  useEffect(() => {
    clearPagination();
    clearDrilldowns();
  }, [selectedMerchant, clearPagination, clearDrilldowns]);
  const getPaginationObject = (table: Tables) => {
    switch (table) {
      case "pageOffsetSummary":
        return pagination.summaryPagination;
      case "pageOffsetChargeback":
        return pagination.chargebackPagination;
      case "pageOffsetDate":
        return pagination.datePagination;
      case "pageOffsetMerchant":
        return pagination.merchantPagination;
      case "pageOffsetOutlet":
        return pagination.outletPagination;
      case "pageOffsetTransaction":
        return pagination.transactionPagination;
      default:
        throw Error("could not find pagination object");
    }
  };

  const triggerSearchRequest = (searchParameters: TransactionSearchPanelParameters) => {
    setParameters({
      beginDate: searchParameters.beginDate,
      endDate: searchParameters.endDate,
      cardTypes: searchParameters.cardTypes ?? [],
      divisionName: searchParameters.divisionName,
      merchantNumber: searchParameters.merchantNumber,
      outletName: searchParameters.outletName,
    });
  }

  const search = (searchParameters: TransactionSearchPanelParameters) => {
    clearPagination();
    clearDrilldowns();
    triggerSearchRequest(searchParameters);
  }

  const isDrilldownActive = !lodashIsEmpty(drilldownSelections);

  const selectedMerchantNumber = depositActivity?.merchantDetails?.items?.find?.(
    (d) =>
      d.merchantNumber ===
      (isDrilldownActive ? drilldownSelections?.filterMerchantNumber?.merchantNumber : parameters?.filterMerchantNumber)
  );

  const selectedDate = depositActivity?.dateDetails?.items?.find?.(
    (d) =>
      d.reportDate?.valueOf() ===
      drilldownSelections?.filterDate?.reportDate?.valueOf()
  );

  const selectedOutlet = depositActivity?.outletDetails?.items?.find?.(
    (d) =>
      d.outletNumber ===
      (isDrilldownActive ? drilldownSelections?.filterOutletNumber?.outletNumber : parameters?.filterOutletNumber?.toString())
  );

  return (
    <div className="depositActivity-wrapper">
      <div className="title-bar">
        <PageTitle title="Deposit Activity" />
      </div>
      <ValidatedSearchPage
        errorResults={
          (!pagination.chargebackPagination.totalResults ||
            pagination.chargebackPagination.totalResults === 0) &&
            (!pagination.datePagination.totalResults ||
              pagination.datePagination.totalResults === 0) &&
            (!pagination.merchantPagination.totalResults ||
              pagination.merchantPagination.totalResults === 0) &&
            (!pagination.outletPagination.totalResults ||
              pagination.outletPagination.totalResults === 0) &&
            (!pagination.summaryPagination.totalResults ||
              pagination.summaryPagination.totalResults === 0) &&
            (!pagination.transactionPagination.totalResults ||
              pagination.transactionPagination.totalResults === 0)
            ? false
              ? LookupResult.StillLoading
              : LookupResult.ObjectDoesNotExist
            : LookupResult.OK
        }
        searchPanel={
          <TransactionSearchPanel
            requiresCardNumberForDatesGreaterThan31DaysApart={false}
            defaultMerchantId={
              !parameters ? (defaultSearchParameters?.merchantNumber ?? selectedMerchant) : selectedMerchant
            }
            isLoading={isLoading}
            onSearch={search}
            disabledFields={[
              "cardNumber",
              "amount",
              "divisionName",
              "outletName",
              "cardTypes",
              "amountOperator",
              "transactionTypes",
            ]}
            merchants={addSuperMerchantNumber(merchants)}
            onMerchantChange={(e) => {
              console.log('onMerchantChange', e);
              clearPagination();
              clearDrilldowns();
              setCurrentMerchant(e);
              updateSelectedMerchant(e.number);
              triggerSearchRequest({
                beginDate: defaultBeginDate,
                endDate: defaultEndDate,
                cardTypes: [CardType.visa, CardType.mastercard],
                merchantNumber: e.number ?? "9999999999",
                merchantName: '',
              })
            }}
            currentMerchant={currentMerchant}
            transactionTypes={[
              { txType: TransactionType.AuthOnly, isDisabled: true, tabIndex: 13 },
              {
                txType: TransactionType.SalesCredits,
                isDisabled: true,
                isCheckedByDefault: true,
                tabIndex: 14
              },
              { txType: TransactionType.CBRev, isDisabled: true, tabIndex: 15 },
              { txType: TransactionType.RFC, isDisabled: true, tabIndex: 16 },
              {
                txType: TransactionType.CBLifeCycle,
                tabIndex: 17
              },
            ]}
            cardTypes={[
              {
                cardName: "AX",
                cardValue: CardType.americanExpress,
                tabIndex: 8,
              },
              { cardName: "DR", cardValue: CardType.discover, tabIndex: 9 },
              { cardName: "JCB", cardValue: CardType.jcb, tabIndex: 10 },
              {
                cardName: "MC",
                isCheckedByDefault: true,
                cardValue: CardType.mastercard,
                tabIndex: 11
              },
              {
                cardName: "VS",
                isCheckedByDefault: true,
                cardValue: CardType.visa,
                tabIndex: 12
              },
            ]}
            defaultBeginDate={defaultBeginDate}
            defaultEndDate={defaultEndDate}
            onReset={() => triggerSearchRequest({
              beginDate: defaultBeginDate,
              endDate: defaultEndDate,
              cardTypes: [CardType.visa, CardType.mastercard],
              merchantNumber: currentMerchant?.number ?? "9999999999",
              merchantName: '',
            })}
          />
        }
        searchResults={
          depositActivity && (
            <DepositActivityTables
              highlightedItems={{
                pageOffsetMerchant: selectedMerchantNumber,
                pageOffsetDate: selectedDate,
                pageOffsetOutlet: selectedOutlet,
              }}
              onExport={async (table) => {
                if (!compiledParameters) return;

                const isTransactionTable = table === DepositActivityTable.TRANSACTION;
                const isSummaryTable = table === DepositActivityTable.SUMMARY;
                const isMerchantTable = table === DepositActivityTable.MERCHANT;

                let temporaryParameters = {
                  ...compiledParameters,
                  merchantNumber: (isSummaryTable || isMerchantTable) ?
                    (parameters?.merchantNumber ?? '') :
                    selectedMerchantNumber ?
                      (selectedMerchantNumber.merchantNumber ?? '') :
                      (depositActivity?.merchantDetails?.items[0].merchantNumber ?? parameters?.merchantNumber ?? ''),
                };

                if (isTransactionTable) {
                  const dateDetailTemp = depositActivity?.dateDetails?.items?.sort((a, b) => {
                    if (!a.reportDate || !b.reportDate) return -1;
                    return new Date(a.reportDate).getTime() - new Date(b.reportDate).getTime()
                  }).reverse();

                  const dateArrayLength = dateDetailTemp?.length;
                  if (!dateDetailTemp || !dateArrayLength || dateArrayLength === 0 || !dateDetailTemp[0].reportDate || !dateDetailTemp[dateArrayLength - 1].reportDate) return;

                  const endDate = dateDetailTemp[0].reportDate;
                  const beginDate = dateDetailTemp[dateArrayLength - 1].reportDate;

                  if (!endDate || !beginDate) return;

                  temporaryParameters = { ...temporaryParameters, beginDate, endDate };
                }

                const [data, filename] =
                  await transmittedService.getDepositReport(
                    temporaryParameters,
                    table
                  );
                downloadExcelFile(data, filename);
              }}
              onFirstPage={(table) => {
                getPaginationObject(table).firstPage();
              }}
              showAll={(table) => {
                getPaginationObject(table)?.showAll?.();
              }}
              onLastPage={(table) => {
                getPaginationObject(table)?.lastPage?.();
              }}
              currentPages={{
                pageOffsetSummary: pagination.summaryPagination.offset,
                pageOffsetChargeback: pagination.chargebackPagination.offset,
                pageOffsetDate: pagination.datePagination.offset,
                pageOffsetMerchant: pagination.merchantPagination.offset,
                pageOffsetOutlet: pagination.outletPagination.offset,
                pageOffsetTransaction:
                  pagination.transactionPagination.offset,
              }}
              isLoading={isLoading}
              onPreviousPage={(page) => {
                getPaginationObject(page).previousPage();
              }}
              onNextPage={(page) => {
                getPaginationObject(page)?.nextPage?.();
              }}
              activity={depositActivity}
              onSelectDrilldown={setDrilldownSelections}
              pageSize={(page) => getPaginationObject(page)?.pageSize}
            />
          )
        }
      ></ValidatedSearchPage>
    </div>
  );
}

type SelectDrilldownCallback = <T extends keyof DrilldownFields>(
  field: T,
  value: Partial<DepositDetail>
) => void;

const DepositActivityTables = ({
  onExport,
  activity,
  onSelectDrilldown,
  currentPages,
  onNextPage,
  onFirstPage,
  onLastPage,
  onPreviousPage,
  isLoading,
  highlightedItems,
  pageSize,
}: {
  highlightedItems?: Partial<
    Record<Tables, (DepositDetail & SalesCredit) | undefined>
  >;
  onExport(table: DepositActivityTable): void;
  showHighlights?: boolean;
  onLastPage(page: Tables): void;
  onFirstPage(page: Tables): void;
  activity?: DepositActivity;
  onSelectDrilldown: SelectDrilldownCallback;
  currentPages: TablePages;
  onNextPage(page: Tables): void;
  onPreviousPage(page: Tables): void;
  showAll(page: Tables): void;
  isLoading: boolean;
  pageSize(page: Tables): number;
}) => {
  return (
    <div className="depositActivity-wrapper">
      {(activity?.summaryDetails?.items?.length ?? 0) > 0 && (
        <div className="search-results-container all-locations-summary">
          <TableTitle
            title="Deposit - All Locations Summary"
            offset={currentPages.pageOffsetSummary}
            totalResults={activity?.summaryDetails?.totalResults ?? 0}
            currentResultCount={activity?.summaryDetails?.items?.length ?? 0}
            onPageFirst={() => onFirstPage("pageOffsetSummary")}
            onPageLast={() => onLastPage("pageOffsetSummary")}
            onPageNext={() => onNextPage("pageOffsetSummary")}
            onPagePrevious={() => onPreviousPage("pageOffsetSummary")}
            onExport={() => onExport(DepositActivityTable.SUMMARY)}
            hideShowAllButton
            pageSize={pageSize("pageOffsetSummary")}
          />
          {
            <DepositDetailTable
              isLoading={isLoading}
              hightlightedItem={
                highlightedItems?.pageOffsetSummary ??
                activity?.summaryDetails?.items?.[0]
              }
              includedColumns={[
                "emptyDetail",
                "merchantNumber",
                "merchant name",
                "salesTxCount",
                "salesTxVolume",
                "creditsTxCount",
                "creditsTxVolume",
                "netSalesTxCount",
                "netSalesTxVolume",
                "netChbksItemsCount",
                "netChbksItemsVolume",
                "miscAdj",
                "dailyFees",
                "netDeposit",
              ]}
              deposits={activity?.summaryDetails?.items}
              isActivity={true}
              NoResultsErrorMessage={"No Activity found"}
            />
          }

        </div>
      )}

      <div className="search-results-container merchant-detail">
        <TableTitle
          title="Deposit - Merchant Detail"
          offset={currentPages.pageOffsetMerchant}
          totalResults={activity?.merchantDetails?.totalResults ?? 0}
          currentResultCount={activity?.merchantDetails?.items?.length ?? 0}
          onPageFirst={() => onFirstPage("pageOffsetMerchant")}
          onPageLast={() => onLastPage("pageOffsetMerchant")}
          onPageNext={() => onNextPage("pageOffsetMerchant")}
          onPagePrevious={() => onPreviousPage("pageOffsetMerchant")}
          onExport={() => onExport(DepositActivityTable.MERCHANT)}
          hideShowAllButton
          pageSize={pageSize("pageOffsetMerchant")}
        />
        {
          <DepositDetailTable
            detailTooltip="Show detail for this merchant"
            isLoading={isLoading}
            showNetDepositTooltip={true}
            hightlightedItem={
              highlightedItems?.pageOffsetMerchant ??
              activity?.merchantDetails?.items?.[0]
            }
            onDrilldown={(detail) =>
              onSelectDrilldown("filterMerchantNumber", detail)
            }
            includedColumns={[
              "detail",
              "merchantNumber",
              "merchant name",
              "salesTxCount",
              "salesTxVolume",
              "creditsTxCount",
              "creditsTxVolume",
              "netSalesTxCount",
              "netSalesTxVolume",
              "netChbksItemsCount",
              "netChbksItemsVolume",
              "miscAdj",
              "dailyFees",
              "netDeposit",
            ]}
            deposits={activity?.merchantDetails?.items}
            isActivity={true}
            NoResultsErrorMessage={"No Merchant Detail found"}

          />
        }

      </div>
      <div className="search-results-container date-detail">
        <TableTitle
          title="Deposit - Date Detail"
          currentResultCount={activity?.dateDetails?.items?.length ?? 0}
          offset={currentPages.pageOffsetDate}
          totalResults={activity?.dateDetails?.totalResults ?? 0}
          onPageFirst={() => onFirstPage("pageOffsetDate")}
          onPageLast={() => onLastPage("pageOffsetDate")}
          onPageNext={() => onNextPage("pageOffsetDate")}
          onPagePrevious={() => onPreviousPage("pageOffsetDate")}
          onExport={() => onExport(DepositActivityTable.DATE)}
          hideShowAllButton
          pageSize={pageSize("pageOffsetDate")}
        />

        {
          <DepositDetailTable
            detailTooltip="Show detail for this date"
            showNetDepositTooltip={true}
            hightlightedItem={
              highlightedItems?.pageOffsetDate ?? activity?.dateDetails?.items?.[0]
            }
            isLoading={isLoading}
            onDrilldown={(detail) => onSelectDrilldown("filterDate", detail)}
            includedColumns={[
              "detail",
              "report date",
              "merchantNumber",
              "salesTxCount",
              "salesTxVolume",
              "creditsTxCount",
              "creditsTxVolume",
              "netSalesTxCount",
              "netSalesTxVolume",
              "netChbksItemsCount",
              "netChbksItemsVolume",
              "miscAdj",
              "dailyFees",
              "netDeposit",
            ]}
            deposits={activity?.dateDetails?.items}
            isActivity={true}
            tableName={"date"}
            NoResultsErrorMessage={"No Date Detail found"}
          />
        }

      </div>
      <div className="search-results-container outlet-detail">
        <TableTitle
          currentResultCount={activity?.outletDetails?.items?.length ?? 0}
          onExport={() => onExport(DepositActivityTable.OUTLET)}
          title="Deposit - Outlet Detail"
          offset={currentPages.pageOffsetOutlet}
          totalResults={activity?.outletDetails?.totalResults ?? 0}
          onPageFirst={() => onFirstPage("pageOffsetOutlet")}
          onPageLast={() => onLastPage("pageOffsetOutlet")}
          onPageNext={() => onNextPage("pageOffsetOutlet")}
          onPagePrevious={() => onPreviousPage("pageOffsetOutlet")}
          hideShowAllButton
          pageSize={pageSize("pageOffsetOutlet")}
        />
        {
          <DepositDetailTable
            detailTooltip="Show detail for this outlet"
            hightlightedItem={
              highlightedItems?.pageOffsetOutlet ??
              activity?.outletDetails?.items?.[0]
            }
            isLoading={isLoading}
            onDrilldown={(detail) =>
              onSelectDrilldown("filterOutletNumber", detail)
            }
            includedColumns={[
              "detail",
              "outletName",
              "salesTxCount",
              "salesTxVolume",
              "creditsTxCount",
              "creditsTxVolume",
              "netSalesTxCount",
              "netSalesTxVolume",
              "netChbksItemsCount",
              "netChbksItemsVolume",
              "miscAdj",
              "dailyFees",
              "netDeposit",
            ]}
            deposits={activity?.outletDetails?.items}
            isActivity={true}
            tableName={"date"}
            NoResultsErrorMessage={"No Outlet Detail found"}
          />
        }


      </div>
      <div className="search-results-container transaction-detail">
        <TableTitle
          onExport={() => onExport(DepositActivityTable.TRANSACTION)}
          currentResultCount={activity?.transactionDetails?.items?.length ?? 0}
          title="Deposit - Transaction Detail"
          offset={currentPages.pageOffsetTransaction}
          totalResults={activity?.transactionDetails?.totalResults ?? 0}
          onPageFirst={() => onFirstPage("pageOffsetTransaction")}
          onPageLast={() => onLastPage("pageOffsetTransaction")}
          onPageNext={() => onNextPage("pageOffsetTransaction")}
          onPagePrevious={() => onPreviousPage("pageOffsetTransaction")}
          hideShowAllButton
          pageSize={pageSize("pageOffsetTransaction")}
        />

        {
          <DepositDetailTable
            hightlightedItem={activity?.transactionDetails?.items?.[0]}
            includedColumns={[
              "detail",
              "summaryDate",
              "merchantNumber",
              "outletName",
              "abbreviatedCardType",
              "cardNumber",
              "posEntryMode",
              "tranDate",
              "tranType",
              "tranAmount",
            ]}
            deposits={activity?.transactionDetails?.items}
            isActivity={true}
            tableName={"tran"}
            NoResultsErrorMessage={"No Transaction Detail found"}
          />
        }

      </div>
      <div className="search-results-container chargeback-detail">
        <TableTitle
          onExport={() => onExport(DepositActivityTable.CHARGEBACK)}
          currentResultCount={activity?.chargebackDetails?.items?.length ?? 0}
          title="Deposit - Chargeback Detail"
          onPageFirst={() => onFirstPage("pageOffsetChargeback")}
          onPageLast={() => onLastPage("pageOffsetChargeback")}
          onPageNext={() => onNextPage("pageOffsetChargeback")}
          onPagePrevious={() => onPreviousPage("pageOffsetChargeback")}
          offset={currentPages.pageOffsetChargeback}
          totalResults={activity?.chargebackDetails?.totalResults ?? 0}
          hideShowAllButton
          pageSize={pageSize("pageOffsetChargeback")}
        />
        {
          <DepositDetailTable
            hightlightedItem={
              highlightedItems?.pageOffsetChargeback ??
              activity?.chargebackDetails?.items?.[0]
            }
            isLoading={isLoading}
            includedColumns={[
              "emptyDetail",
              "settleDate",
              "merchantNumber",
              "outletName",
              "abbreviatedCardType",
              "cardNumber",
              "reasonCode",
              "tranDate",
              "tranType",
              "tranAmount",
            ]}
            deposits={activity?.chargebackDetails?.items}
            isActivity={true}
            tableName={"tran"}
            NoResultsErrorMessage={"No Chargeback Detail found"}
          />
        }

      </div>
    </div>
  );
};

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTypeToJSON = exports.ReportTypeFromJSONTyped = exports.ReportTypeFromJSON = exports.ReportType = void 0;
/**
 * Type of Report
 * @export
 * @enum {string}
 */
var ReportType;
(function (ReportType) {
    ReportType["Refundaudlocation"] = "REFUNDAUDLOCATION";
    ReportType["Refundaudtrend"] = "REFUNDAUDTREND";
    ReportType["Bycardtypetrend"] = "BYCARDTYPETREND";
    ReportType["Cardacceptanceregion"] = "CARDACCEPTANCEREGION";
    ReportType["Cardproductacceptance"] = "CARDPRODUCTACCEPTANCE";
    ReportType["Cardproductacceptancetrend"] = "CARDPRODUCTACCEPTANCETREND";
    ReportType["Cardregiontrend"] = "CARDREGIONTREND";
    ReportType["Cardtype"] = "CARDTYPE";
    ReportType["Chargebackaging"] = "CHARGEBACKAGING";
    ReportType["Chargebackagingoutstanding"] = "CHARGEBACKAGINGOUTSTANDING";
    ReportType["Chargebackagingrfc"] = "CHARGEBACKAGINGRFC";
    ReportType["Chargebackperformance"] = "CHARGEBACKPERFORMANCE";
    ReportType["Chargebackperformancereason"] = "CHARGEBACKPERFORMANCEREASON";
    ReportType["Chargebackperformancetrend"] = "CHARGEBACKPERFORMANCETREND";
    ReportType["Closedchargebackperformance"] = "CLOSEDCHARGEBACKPERFORMANCE";
    ReportType["Closedchargebackperformancereason"] = "CLOSEDCHARGEBACKPERFORMANCEREASON";
    ReportType["Closedchargebackperformancetrend"] = "CLOSEDCHARGEBACKPERFORMANCETREND";
    ReportType["Dailydeposit"] = "DAILYDEPOSIT";
    ReportType["Dailydepositlocation"] = "DAILYDEPOSITLOCATION";
    ReportType["Dailytransmittedactivity"] = "DAILYTRANSMITTEDACTIVITY";
    ReportType["Debitcardproductacceptance"] = "DEBITCARDPRODUCTACCEPTANCE";
    ReportType["Debitcardproductacceptancetrend"] = "DEBITCARDPRODUCTACCEPTANCETREND";
    ReportType["Depositactivity"] = "DEPOSITACTIVITY";
    ReportType["Depositactivitytrend"] = "DEPOSITACTIVITYTREND";
    ReportType["Disputeaging"] = "DISPUTEAGING";
    ReportType["Disputeaginglocation"] = "DISPUTEAGINGLOCATION";
    ReportType["Excessivechargeback"] = "EXCESSIVECHARGEBACK";
    ReportType["Excessivecredit"] = "EXCESSIVECREDIT";
    ReportType["Interchangedetail"] = "INTERCHANGEDETAIL";
    ReportType["Interchangeperformance"] = "INTERCHANGEPERFORMANCE";
    ReportType["Interchangeperformancelocation"] = "INTERCHANGEPERFORMANCELOCATION";
    ReportType["Interchangequalificationperformance"] = "INTERCHANGEQUALIFICATIONPERFORMANCE";
    ReportType["Interchangequalificationtrend"] = "INTERCHANGEQUALIFICATIONTREND";
    ReportType["Merchantfees"] = "MERCHANTFEES";
    ReportType["Merchantfeestrend"] = "MERCHANTFEESTREND";
    ReportType["Merchantreferencesheet"] = "MERCHANTREFERENCESHEET";
    ReportType["Monthlystatement"] = "MONTHLYSTATEMENT";
    ReportType["Monthlystatementlocation"] = "MONTHLYSTATEMENTLOCATION";
    ReportType["Pointofacceptance"] = "POINTOFACCEPTANCE";
    ReportType["Pointofacceptancetrend"] = "POINTOFACCEPTANCETREND";
    ReportType["Rfcaging"] = "RFCAGING";
    ReportType["Rfcperformance"] = "RFCPERFORMANCE";
    ReportType["Rfcperformancetrend"] = "RFCPERFORMANCETREND";
    ReportType["Refundaudexception"] = "REFUNDAUDEXCEPTION";
})(ReportType = exports.ReportType || (exports.ReportType = {}));
function ReportTypeFromJSON(json) {
    return ReportTypeFromJSONTyped(json, false);
}
exports.ReportTypeFromJSON = ReportTypeFromJSON;
function ReportTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.ReportTypeFromJSONTyped = ReportTypeFromJSONTyped;
function ReportTypeToJSON(value) {
    return value;
}
exports.ReportTypeToJSON = ReportTypeToJSON;

"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * PostatVantage
 * OpenAPI Spec for the Post@Vantage Application
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DepositDetailsVMCResponseToJSON = exports.DepositDetailsVMCResponseFromJSONTyped = exports.DepositDetailsVMCResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function DepositDetailsVMCResponseFromJSON(json) {
    return DepositDetailsVMCResponseFromJSONTyped(json, false);
}
exports.DepositDetailsVMCResponseFromJSON = DepositDetailsVMCResponseFromJSON;
function DepositDetailsVMCResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'merchantDetails': (json['merchantDetails'].map(_1.DepositDetailsFromJSON)),
        'dateDetails': (json['dateDetails'].map(_1.DepositDetailsFromJSON)),
        'outletDetails': (json['outletDetails'].map(_1.DepositDetailsFromJSON)),
        'transactionDetails': (json['transactionDetails'].map(_1.SalesCreditsFromJSON)),
        'summaryDetails': !runtime_1.exists(json, 'summaryDetails') ? undefined : (json['summaryDetails'].map(_1.DepositDetailsFromJSON)),
        'chargebackDetails': (json['chargebackDetails'].map(_1.DepositDetailsFromJSON)),
        'resultsTotalMerchants': !runtime_1.exists(json, 'resultsTotalMerchants') ? undefined : json['resultsTotalMerchants'],
        'resultsTotalDates': !runtime_1.exists(json, 'resultsTotalDates') ? undefined : json['resultsTotalDates'],
        'resultsTotalOutlets': !runtime_1.exists(json, 'resultsTotalOutlets') ? undefined : json['resultsTotalOutlets'],
        'resultsTotalTrans': !runtime_1.exists(json, 'resultsTotalTrans') ? undefined : json['resultsTotalTrans'],
        'resultsTotalSummary': !runtime_1.exists(json, 'resultsTotalSummary') ? undefined : json['resultsTotalSummary'],
        'resultsTotalChargeback': !runtime_1.exists(json, 'resultsTotalChargeback') ? undefined : json['resultsTotalChargeback'],
        'offsetMerchants': !runtime_1.exists(json, 'offsetMerchants') ? undefined : json['offsetMerchants'],
        'offsetDates': !runtime_1.exists(json, 'offsetDates') ? undefined : json['offsetDates'],
        'offsetOutlets': !runtime_1.exists(json, 'offsetOutlets') ? undefined : json['offsetOutlets'],
        'offsetTrans': !runtime_1.exists(json, 'offsetTrans') ? undefined : json['offsetTrans'],
        'offsetChargeback': !runtime_1.exists(json, 'offsetChargeback') ? undefined : json['offsetChargeback'],
    };
}
exports.DepositDetailsVMCResponseFromJSONTyped = DepositDetailsVMCResponseFromJSONTyped;
function DepositDetailsVMCResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'merchantDetails': (value.merchantDetails.map(_1.DepositDetailsToJSON)),
        'dateDetails': (value.dateDetails.map(_1.DepositDetailsToJSON)),
        'outletDetails': (value.outletDetails.map(_1.DepositDetailsToJSON)),
        'transactionDetails': (value.transactionDetails.map(_1.SalesCreditsToJSON)),
        'summaryDetails': value.summaryDetails === undefined ? undefined : (value.summaryDetails.map(_1.DepositDetailsToJSON)),
        'chargebackDetails': (value.chargebackDetails.map(_1.DepositDetailsToJSON)),
        'resultsTotalMerchants': value.resultsTotalMerchants,
        'resultsTotalDates': value.resultsTotalDates,
        'resultsTotalOutlets': value.resultsTotalOutlets,
        'resultsTotalTrans': value.resultsTotalTrans,
        'resultsTotalSummary': value.resultsTotalSummary,
        'resultsTotalChargeback': value.resultsTotalChargeback,
        'offsetMerchants': value.offsetMerchants,
        'offsetDates': value.offsetDates,
        'offsetOutlets': value.offsetOutlets,
        'offsetTrans': value.offsetTrans,
        'offsetChargeback': value.offsetChargeback,
    };
}
exports.DepositDetailsVMCResponseToJSON = DepositDetailsVMCResponseToJSON;

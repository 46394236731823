import { useEffect, useState } from "react";
import { Document, DocumentType } from "../models/document";
import { DocumentService } from "../services/document";
import { usePagination } from "./use_pagination";

export interface UseDocumentsResult {
  isLoading: boolean;
  documents: Document[];
}

const PAGE_SIZE = 10;

export const useDocuments = (
  documentService: DocumentService,
  documentTypes: DocumentType[]
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [totalResults, setTotalResults] = useState<number | undefined>(
    undefined
  );
  const pagination = usePagination(PAGE_SIZE, totalResults);

  useEffect(() => {
    Promise.all(
      documentTypes.map((t) =>
        documentService.getDocuments(t, {
          page: pagination.page,
          pageSize: pagination.pageSize,
        })
      )
    )
      .then((results) => {
        setDocuments(results.map((r) => r.data).flat());
        setTotalResults(results.reduce((acc, r) => acc + (r.total ?? 0), 0));
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [documentService, documentTypes, pagination.page, pagination.pageSize]);

  return { isLoading, documents, pagination };
};
